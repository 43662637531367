<div *ngIf="maxPages > 1">
  <div class="pager-info" [ngClass]="{ open: pageSelectMenuVisible }" (click)="showPageDrowDown($event)">Seite {{ curPage }}/{{ maxPages }}</div>
  <div *ngIf="curPage > 1" class="pager" [ngClass]="appMode ? 'mobile' : ''" (click)="switchPage(-999, true)">zurück</div>
  <div *ngIf="curPage != maxPages" class="pager" [ngClass]="appMode ? 'mobile' : ''" (click)="switchPage(999, true)">weiter</div>
  <div *ngIf="curPage != 1" class="pager" [ngClass]="appMode ? 'mobile' : ''" (click)="switchPage(1001, true)">Erste Seite</div>
  <div *ngIf="curPage != maxPages" class="pager" [ngClass]="appMode ? 'mobile' : ''" (click)="switchPage(1000, true)">Letzte Seite</div>
  <div class="pager-setup"></div>
  <br /><br />
</div>

<div class="pager-selectPage" #pageSelectMenu>
  <div *ngFor="let page of pages" (click)="switchPage(page.dir, true)">
    {{ page.title }}
  </div>
</div>
