<app-content-header mat-dialog-title contentTitle="User bearbeiten"></app-content-header>
<div mat-dialog-content>
  <form [formGroup]="form" class="detail">
    <mat-form-field>
      <mat-label>E-Mail</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Benutzerprofil</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="0" disabled> Administrator </mat-option>
        <mat-option [value]="4">
          {{ "USER.MERCHANT_ADMIN" | translate }}
        </mat-option>
        <mat-option [value]="1">
          {{ "USER.SALES" | translate }}
        </mat-option>
        <mat-option [value]="2">
          {{ "USER.USER" | translate }}
        </mat-option>
        <mat-option [value]="5"> Monteur (Gestelle) </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="firstName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <input matInput formControlName="lastName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Straße</mat-label>
      <input matInput formControlName="street" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hausnummer</mat-label>
      <input matInput formControlName="houseNumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>PLZ</mat-label>
      <input matInput formControlName="zipCode" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Stadt</mat-label>
      <input matInput formControlName="city" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Land</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let c of countryOptions" [value]="c.code">
          {{ c.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Handelspartner</mat-label>
      <input matInput formControlName="companyName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Telefonnummer</mat-label>
      <input matInput formControlName="phone" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Mobilnummer</mat-label>
      <input matInput formControlName="mobile" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Händlernummer</mat-label>
      <input matInput formControlName="merchantNumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Account Typ</mat-label>
      <mat-select formControlName="accountType">
        <mat-option [value]="0"> Default </mat-option>
        <mat-option [value]="1"> Shared </mat-option>
        <mat-option [value]="2"> GVL </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="needsPasswordChange">Erzwinge Passwort Änderung</mat-checkbox>
    <mat-checkbox formControlName="isMainMerchantUser">Händler Haupt-Account</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbruch</button>
  <button mat-button (click)="save()" [disabled]="form.invalid">Speichern</button>
</div>
