import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { Approve } from "../../../models/approve";
@Component({
  selector: "app-action-archive-order-dialog",
  templateUrl: "./action-archive-order-dialog.component.html",
  styleUrls: ["./action-archive-order-dialog.component.scss"],
})
export class ActionArchiveOrderDialogComponent implements OnInit {
  public confirmText: string;
  public approve: Approve = Object.assign({});
  constructor(
    public dialogRef: MatDialogRef<ActionArchiveOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private orderService: ApiOrderService
  ) {}

  ngOnInit(): void {
    this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_ARCHIVE", { orderNumber: this.data.item.orderNumber });
    this.approve.orderNumber = this.data.item.orderNumber;
  }
  public closeConfirm() {
    this.dialogRef.close();
  }
  public confirmOrder() {
    this.orderService.archive(this.approve.orderNumber).subscribe({
      next: (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.dialogRef.close(false);
      },
    });
  }
}
