import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { NativeFileService } from "src/app/services/native-file.service";
import { DocumentType } from "../models/document";
import { IOffer } from "../models/offer";
import { ApiOfferService } from "../services/api/api-offer.service";
import { LoaderService } from "./loader.service";
import { MessageService } from "./message.service";
import { UiShareService } from "./ui-share.service";

@Injectable({
  providedIn: "root",
})
export class OfferService {
  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private nativeFileService: NativeFileService,
    private uiShareService: UiShareService,
    private apiOfferService: ApiOfferService
  ) {}

  //TODO check type and offer.status
  public getPdf(evt: any, item: IOffer, showPricesInPDF: any): void {
    evt.stopPropagation();
    let object = Object.assign({});
    object.offerNumber = item.offerNumber;
    object.type = 0;
    if (item.confirmationPdfType == 3) {
      object.type = DocumentType.Quotation;
      if (showPricesInPDF) object.finalFileName = "Bestaetigung_" + item.offerNumber + ".pdf";
      else object.finalFileName = "Bestaetigung_np_" + item.offerNumber + ".pdf";
    }
    object.fileName = object.finalFileName;
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.apiOfferService.pdf(object, showPricesInPDF).subscribe(
        (data) => {
          if (Capacitor.isNativePlatform()) {
            this.openPdfInApp(data, object);
          } else {
            this.loaderService.hideTransparentLoader();
            let filename: string = data.headers.get("content-disposition");
            filename = filename.substring(filename.indexOf("filename=") + "filename=".length);
            saveAs(data.body, filename);
          }
        },
        (error) => {
          this.messageService.error(this.translateService.instant("OFFER.PDF_ERROR"));
          this.loaderService.hideTransparentLoader();
        }
      );
    });
  }

  private openPdfInApp(data: any, object: any) {
    this.nativeFileService.openPdf(data, object);
  }
  public getOfferImagesPdf(offerNumber: string) {
    return this.apiOfferService.getOfferImagesPdf(offerNumber);
  }
  public getOfferArchiveImagesPdf(offerNumber: string) {
    return this.apiOfferService.getOfferArchiveImagesPdf(offerNumber);
  }
}
