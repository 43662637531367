export class Setting {
  id: string;
  key: string;
  value: string;
  type: SettingType;
  dspKey?: string;
  boolValue?: boolean;
  constructor(type: SettingType, key: string, value: string) {
    this.type = type;
    this.key = key;
    this.value = value;
    this.boolValue = value == "true";
  }
}
export enum SettingType {
  Mail,
  Translation,
  Push,
  Notification,
  System,
  Holiday,
}
