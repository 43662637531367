import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Approve } from "src/app/models/approve";
import { IOffer } from "src/app/models/offer";
import { IOfferPosition } from "src/app/models/offer-position";
import { ApiOfferService } from "src/app/services/api/api-offer.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-offer-modals",
  templateUrl: "./offer-modals.component.html",
  styleUrls: ["./offer-modals.component.scss"],
})
export class OfferModalsComponent implements OnInit {
  public isOpenApproveDialog: boolean;
  public isOpenChangeDialog: boolean;
  public isOpenChangePerPositionDialog: boolean;
  public item: IOffer;
  public requestDoneSubject: BehaviorSubject<IOffer> = new BehaviorSubject(null);
  public approve: Approve = Object.assign({});
  public isOpenPreviewImageDialog: boolean;
  public previewImage: string;
  public itemPosition: IOfferPosition;
  public confirmText: string;
  public confirmMode: number;
  public isOpenConfirmDialog: boolean;
  public releaseOnChange = false;
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private apiOfferService: ApiOfferService,
    private loaderService: LoaderService,
    private uiShareService: UiShareService
  ) {}

  ngOnInit() {}

  public openDialog(evt: any, type: string, item: IOffer): void {
    evt.stopPropagation();
    this.item = item;
    this.approve.offerNumber = this.item.offerNumber;
    this.approve.changeMessage = "";
    if (type == "ARCHIVE") {
      this.confirmText = this.translateService.instant("OFFER_DIALOG.CONFIRM_ARCHIVE", { offerNumber: item.offerNumber });
      this.confirmMode = 4;
      this.approve.offerNumber = item.offerNumber;
      this.isOpenConfirmDialog = true;
    } else if (type == "PARK") {
      this.confirmText = this.translateService.instant("OFFER_DIALOG.CONFIRM_PARK", { offerNumber: item.offerNumber });
      this.confirmMode = 5;
      this.approve.offerNumber = item.offerNumber;
      this.isOpenConfirmDialog = true;
    } else if (type == "CHANGE") {
      this.isOpenChangeDialog = true;
      this.approve.positions = item.positions.filter((p) => p.changeMessage);
    } else this.isOpenApproveDialog = true;
  }
  public closeDialog(): void {
    this.isOpenApproveDialog = false;
    this.isOpenPreviewImageDialog = false;
    this.isOpenChangeDialog = false;
    this.isOpenChangePerPositionDialog = false;
    this.item = null;
  }
  public changeChangeMessageOfPosition(evt: any, item: IOfferPosition) {
    evt.stopPropagation();
    this.itemPosition = item;
    this.isOpenChangePerPositionDialog = true;
    this.approve.changeMessage = item.changeMessage;
  }
  public setChangeToPosition(): void {
    this.itemPosition.changeMessage = this.approve.changeMessage;
    this.isOpenChangePerPositionDialog = false;
    this.approve = Object.assign({});
  }
  public closeConfirm(): void {
    this.isOpenConfirmDialog = false;
  }
  public confirmOffer(): void {
    this.closeConfirm();
    if (this.confirmMode == 1) {
      this.postChangeOrReleaseOffer();
    } else if (this.confirmMode == 4) {
      this.postArchiveOffer();
    } else if (this.confirmMode == 5) {
      this.postParkOffer();
    }
  }

  public changeOffer(): void {
    if (this.approve.positions.length == 0) {
      // double ask for positions!
      this.confirmText = this.translateService.instant("OFFER_DIALOG.CONFIRM_APPROVE_NO_ARTICLES");
      this.confirmMode = 1;
      this.isOpenConfirmDialog = true;
    } else {
      this.postChangeOrReleaseOffer();
    }
  }
  private postChangeOrReleaseOffer(): void {
    this.approve.type = 0; //Change
    this.approve.changeMessagesPerPosition = Object.assign({});
    this.approve.approveOnChange = this.releaseOnChange;
    this.approve.positions.forEach((p) => {
      this.approve.changeMessagesPerPosition[p.positionIndex] = p.changeMessage;
    });
    if (this.approve.changeMessage == "" && this.approve.positions.every((p) => p.changeMessage == "")) {
      this.approve.type = 1; //Release
    }
    let success = this.translateService.instant("OFFER_DIALOG.CHANGE_SUCCESS");
    let error = this.translateService.instant("OFFER_DIALOG.CHANGE_ERROR");
    this.organizeRequest(success, error);
  }
  private postArchiveOffer(): void {
    let successMessage = this.translateService.instant("OFFER_DIALOG.ARCHIVE_SUCCESS");
    let errorMessage = this.translateService.instant("OFFER_DIALOG.ARCHIVE_ERROR");

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiOfferService.archive(this.approve.offerNumber).subscribe({
        next: (data) => {
          this.uiShareService.offersNeedReload = true;
          this.requestDoneSubject.next(data);
          this.loaderService.hideLoader();
          this.approve = Object.assign({});
          this.messageService.success(successMessage);
        },
        error: (error) => {
          this.loaderService.hideLoader();
          this.messageService.error(errorMessage);
        },
      });
    });
  }
  private postParkOffer(): void {
    let successMessage = this.translateService.instant("OFFER_DIALOG.PARK_SUCCESS");
    let errorMessage = this.translateService.instant("OFFER_DIALOG.PARK_ERROR");

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiOfferService.park(this.approve.offerNumber).subscribe({
        next: (data) => {
          this.uiShareService.offersNeedReload = true;
          this.requestDoneSubject.next(data);
          this.loaderService.hideLoader();
          this.approve = Object.assign({});
          this.messageService.success(successMessage);
        },
        error: (error) => {
          this.loaderService.hideLoader();
          this.messageService.error(errorMessage);
        },
      });
    });
  }
  public releaseOffer(): void {
    this.approve.type = 1;
    let success = this.translateService.instant("OFFER_DIALOG.APPROVE_SUCCESS");
    let error = this.translateService.instant("OFFER_DIALOG.APPROVE_ERROR");
    this.organizeRequest(success, error);
  }
  public organizeRequest(successMessage: string, errorMessage): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiOfferService.approve(this.approve).subscribe({
        next: (data) => {
          this.uiShareService.offersNeedReload = true;
          this.requestDoneSubject.next(data);
          this.isOpenApproveDialog = false;
          this.isOpenChangeDialog = false;
          this.loaderService.hideLoader();
          this.approve = Object.assign({});
          this.messageService.success(successMessage);
        },
        error: (error) => {
          this.loaderService.hideLoader();
          this.messageService.error(errorMessage);
        },
      });
    });
  }
  batchRunning = false;
  batchCompleted = false;
  showConfirmOfferBatchArchiveDialog = false;
  public confirmOfferBatchArchiveDialog$: Subject<boolean>;
  public openOfferBatchArchiveModal(text: string) {
    this.confirmText = text;
    // this.showConfirmOfferBatchArchiveDialog = true;
    this.confirmOfferBatchArchiveDialog$ = new Subject<boolean>();
    return this;
  }
  public closeBatchModal() {
    this.confirmOfferBatchArchiveDialog$.next(false);
    this.confirmOfferBatchArchiveDialog$.complete();
    this.showConfirmOfferBatchArchiveDialog = false;
    this.confirmOfferBatchArchiveDialog$ = null;
    this.batchCompleted = false;
    this.batchRunning = false;
  }
  public confirmBatchModal() {
    this.confirmOfferBatchArchiveDialog$.next(true);
    this.confirmOfferBatchArchiveDialog$.complete();
    if (this.batchCompleted === true) {
      this.confirmOfferBatchArchiveDialog$ = null;
      this.showConfirmOfferBatchArchiveDialog = false;
      this.batchCompleted = false;
      this.batchRunning = false;
    }
  }
  changeDialogSaveStateText = "";
  getChangeDialogDisabledState(): boolean {
    if (this.releaseOnChange) {
      if (this.approve.changeMessage == "" && this.approve.positions.every((p) => p.changeMessage == "")) {
        this.changeDialogSaveStateText = "Überführen";
      } else {
        this.changeDialogSaveStateText = "Ändern und Überführen";
      }
      return false;
    } else {
      this.changeDialogSaveStateText = "Ändern";

      return this.approve.changeMessage == "";
    }
  }
}
