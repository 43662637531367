import { Injectable } from "@angular/core";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { AlertController, Platform } from "@ionic/angular";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { NativeSettings, IOSSettings } from "capacitor-native-settings";
import { Geolocation } from "@capacitor/geolocation";

@Injectable({
  providedIn: "root",
})
export class GeoService {
  public geoUsableSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private diagnostic: Diagnostic, private platform: Platform, private alertController: AlertController, private nativeGeocoder: NativeGeocoder) {}

  public checkForLocationServiceEnabled(noDialog: boolean) {
    // check if location service is enabled
    this.diagnostic
      .isLocationEnabled()
      .then((data) => {
        if (!data) {
          console.log("location service is not enabled");
          // not enabled, check if we have asked before
          if (!localStorage.getItem("location_service")) {
            // no, ask user
            if (noDialog) {
              if (this.platform.is("ios")) {
                NativeSettings.openIOS({
                  option: IOSSettings.App,
                });
              } else {
                this.diagnostic.switchToLocationSettings();
              }
            } else {
              this.aksForLocationService();
            }
          } else {
            console.log("already asked, POST declined");
            this.geoUsableSubject.next("declined");
          }
        } else {
          console.log("location service is enabled");
          // location service is enabled, check if app can use location
          this.checkForLocationEnabled();
        }
      })
      .catch((error) => {
        localStorage.setItem("location_service", "failed");
      });
  }
  public checkForLocationEnabled() {
    // check if location is enabled
    this.diagnostic
      .isLocationAvailable()
      .then((data) => {
        if (data) {
          // location is allowed
          console.log("location is enabled " + data + ", POST granted");
          this.geoUsableSubject.next("granted");
        } else {
          // location is not allowed
          console.log("location is not enabled");
          this.diagnostic
            .getLocationAuthorizationStatus()
            .then((status) => {
              console.log("location status is " + status);
              if (status == "not_requested" || status == "not_determined") {
                console.log("ask for request (" + localStorage.getItem("location_app") + ")");
                if (!localStorage.getItem("location_app")) {
                  console.log("never asked");
                  this.aksForLocation(false);
                } else if (localStorage.getItem("location_app") != "declined") {
                  console.log("state is not declined");
                  this.aksForLocation(false);
                } else {
                  this.geoUsableSubject.next("declined");
                }
              } else if (status == "authorized_when_in_use" || status == "authorized") {
                console.log("GRANTED (FAST)");
                this.geoUsableSubject.next("granted");
              } else {
                console.log("NOT GRANTED (FAST)");
                if (!localStorage.getItem("location_app")) {
                  console.log("NOT GRANTED (FAST, ask user)");
                  this.aksForLocation(true);
                } else {
                  this.geoUsableSubject.next("declined");
                }
              }
            })
            .catch((error) => {});
          //this.aksForLocation();
        }
      })
      .catch((error) => {
        localStorage.setItem("location_app", "failed");
      });
  }
  private async aksForLocationService() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: "Standortbestimmung",
      message: "Diese App kann Ihren Standort ermitteln. Bitte akzeptieren Sie dazu die nachfolgende Nachfrage.",
      buttons: [
        {
          text: "Ablehnen",
          role: "cancel",
          cssClass: "alert-button",
          handler: () => {
            localStorage.setItem("location_service", "declined");
          },
        },
        {
          text: "OK",
          cssClass: "alert-button",
          handler: () => {
            localStorage.setItem("location_service", "asked");
            if (this.platform.is("ios")) {
              NativeSettings.openIOS({
                option: IOSSettings.App,
              });
            } else {
              this.diagnostic.switchToLocationSettings();
            }
          },
        },
      ],
    });

    await alert.present();
  }
  private async aksForLocation(openNative: boolean) {
    console.log("ask for location via settings");
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: "Standortbestimmung",
      message: "Diese App kann Ihren Standort ermitteln für die Gestellabholung. Bitte erlauben Sie dafür die Standortbestimmung.",
      buttons: [
        {
          text: "Ablehnen",
          role: "cancel",
          cssClass: "alert-button",
          handler: () => {
            localStorage.setItem("location_app", "declined");
          },
        },
        {
          text: "OK",
          cssClass: "alert-button",
          handler: () => {
            if (openNative) {
              localStorage.setItem("location_app", "asked");
              NativeSettings.openIOS({
                option: IOSSettings.App,
              });
            } else {
              let mode = "when_in_use";
              if (this.platform.is("android")) {
                mode = "always;";
              }
              this.diagnostic
                .requestLocationAuthorization(mode)
                .then((status) => {
                  console.log("GRANT status: " + status);
                  if (status == "authorized_when_in_use" || status == "authorized") {
                    console.log("GRANTED");
                    localStorage.setItem("location_app", "asked");
                    this.geoUsableSubject.next("granted");
                  } else {
                    console.log("DECLINED");
                    localStorage.setItem("location_app", "declined");
                    this.geoUsableSubject.next("declined");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          },
        },
      ],
    });

    await alert.present();
  }

  public resolveGeoCoords(): Observable<any> {
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5,
    };
    console.log("resolveGeoCoords()");
    return new Observable((observer: Observer<any>) => {
      console.log("resolveGeoCoords() get position");
      Geolocation.getCurrentPosition()
        .then((resp) => {
          this.nativeGeocoder
            .reverseGeocode(resp.coords.latitude, resp.coords.longitude, options)
            .then((result: NativeGeocoderResult[]) => {
              observer.next([resp.coords.latitude, resp.coords.longitude, result[0]]);
              observer.complete();
              //console.debug(JSON.stringify(result[0])
            })
            .catch((error: any) => {
              console.log("resolveGeoCoords() got position");
              observer.next([resp.coords.latitude, resp.coords.longitude]);
              observer.complete();
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("resolveGeoCoords() failed to get position");
          console.error(error);
          observer.error(error);
        });
    });
  }
}
