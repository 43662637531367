import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { environment } from "src/environments/environment";
import { Approve } from "../../../models/approve";
@Component({
  selector: "app-action-change-order-dialog",
  templateUrl: "./action-change-order-dialog.component.html",
  styleUrls: ["./action-change-order-dialog.component.scss"],
})
export class ActionChangeOrderDialogComponent implements OnInit {
  public approve: Approve = Object.assign({});
  public confirmText: string;
  public approveOnChange: boolean;
  public approveOnChangeEnabled: boolean;
  constructor(
    public dialogRef: MatDialogRef<ActionChangeOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiOrderService: ApiOrderService
  ) {
    this.approveOnChangeEnabled = environment.APPROVEAFTERCHANGE;
  }

  ngOnInit(): void {
    this.approve.positions = this.data.item.positions.filter((p) => p.changeMessage);
    this.approve.orderNumber = this.data.item.orderNumber;
  }
  public closeDialog() {
    this.approve = Object.assign({});
    this.dialogRef.close();
  }
  public changeOrder(): void {
    // if (this.approve.positions.length == 0) {
    //   // double ask for positions!
    //   this.confirmText = this.translateService.instant(
    //     "ORDER_DIALOG.CONFIRM_APPROVE_NO_ARTICLES"
    //   );
    // } else {
    // if (this.approveOnChange) {
    //   // double ask for approve on change!
    //   this.confirmText = this.translateService.instant(
    //     "ORDER_DIALOG.CONFIRM_APPROVE_ON_CHANGE"
    //   );
    // } else {

    // }
    // }
    this.postChangeOrder();
  }
  private postChangeOrder(): void {
    this.approve.changeMessagesPerPosition = Object.assign({});
    this.approve.positions.forEach((p) => {
      this.approve.changeMessagesPerPosition[p.positionIndex] = p.changeMessage;
    });
    this.approve.type = 1;
    this.approve.approveOnChange = this.approveOnChange;
    this.organizeRequest();
  }
  public organizeRequest(): void {
    this.apiOrderService.approve(this.approve).subscribe({
      next: (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.dialogRef.close(false);
      },
    });
  }
  public approveAfterChangeChanged(evt: any) {
    this.approveOnChange = evt;
  }
}
