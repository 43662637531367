import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "src/app/services/loader.service";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmAccount } from "src/app/models/user";
import { RoutingService } from "src/app/services/routing.service";

@Component({
  selector: "app-confirm-account",
  templateUrl: "./confirm-account.component.html",
  styleUrls: ["./confirm-account.component.scss"],
})
export class ConfirmAccountComponent implements OnInit {
  code = "";
  confirmAccountResultText = "";
  confirmAccountModalShow = false;
  successState = false;
  showPrivacyModal = false;
  privacyChecked = false;
  agbChecked = false;
  notificationsActivated = true;

  constructor(
    private router: RoutingService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private apiUsersService: ApiUsersService,
    private translateService: TranslateService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("code");
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiUsersService.getConfirmData(this.code).subscribe({
        next: (data) => {
          if (data) {
            // fast confirm
            this.doConfirmAccount();
          } else {
            this.loaderService.hideLoader();
            this.confirmAccountModalShow = true;
          }
        },
        error: (error) => {
          this.confirmAccountModalShow = true;
          this.loaderService.hideLoader();
        },
      });
    });
  }

  doConfirmAccount() {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let data = new ConfirmAccount();
      data.code = this.code;
      data.activateNotifications = this.notificationsActivated;
      this.apiUsersService.confirm(data).subscribe({
        next: (result) => {
          this.loaderService.hideLoader();
          this.successState = true;

          if (result.merchantLinkAfterApprove != null && result.merchantLinkAfterApprove != "") {
            this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_DONE_MULTIMERCHANT_TEXT");
          } else {
            this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_DONE_TEXT");
          }
        },
        error: (error) => {
          this.loaderService.hideLoader();
          this.successState = false;
          if (error.error && !error.error.type) {
            if (error.error == "CODE_INVALID") {
              this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_INVALID_CODE_TEXT");
            } else if (error.error == "ALREADY_CONFIRMED") {
              this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_ALREADY_CONFIRMED_TEXT");
            } else if (error.error == "ACCOUNT_DEACTIVATED") {
              this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_ACCOUNT_DEACTIVATED_TEXT");
            } else {
              this.confirmAccountResultText = error.error;
            }
          } else {
            this.confirmAccountResultText = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_TEXT");
          }
          console.error(error);
        },
      });
    });
  }
  doOpenLogin() {
    this.router.forwardByUrl("/login");
    this.loaderService.hideLoader();
  }
  doRetry() {
    this.confirmAccountResultText = "";
    this.doConfirmAccount();
  }
  doClosePrivacyModal() {
    this.showPrivacyModal = false;
  }
  privacyChanged(evt) {
    this.privacyChecked = evt;
  }
  agbChanged(evt) {
    this.agbChecked = evt;
  }
  notificationsChanged(evt) {
    this.notificationsActivated = evt;
  }
  private attachRunning = false;
  attachLink() {
    if (!this.attachRunning) {
      this.attachRunning = true;
      let that = this;
      setTimeout(() => {
        this.attachRunning = false;
        if (this.el.nativeElement.querySelector(".privacyLink1") && !this.el.nativeElement.querySelector(".privacyLink1").getAttribute("attached")) {
          this.el.nativeElement.querySelector(".privacyLink1").setAttribute("attached", "1");
          this.el.nativeElement.querySelector(".privacyLink1").addEventListener("click", function () {
            that.showPrivacyModal = true;
          });
          this.el.nativeElement.querySelector(".privacyLink2").addEventListener("click", function () {
            that.showPrivacyModal = true;
          });
        }
      }, 500);
    }
  }
}
