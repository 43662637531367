import { Injectable } from "@angular/core";
import { AES, enc, mode, pad, lib, algo } from "crypto-ts";
import { Observable, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CryptedStorageService {
  private k = enc.Utf8.parse("TRCpVmKwbiABn1z6GdvkQQAzQhU5rAqn");
  private iv = enc.Utf8.parse("1IHSrQfRxpNXsa3y");

  constructor() {}

  public del(key: string) {
    if (localStorage.getItem(key)) localStorage.removeItem(key);
  }
  public delAsync(key: string): void {
    if (localStorage.getItem(key)) localStorage.removeItem(key);
  }

  public exists(key: string): boolean {
    return localStorage.getItem(key) != null;
  }
  public existsInstant(key: string): boolean {
    return localStorage.getItem(key) != null;
  }
  public get(key: string): string {
    if (localStorage.getItem(key)) {
      const decryptedBytes = AES.decrypt(localStorage.getItem(key), this.k);
      const decryptedValue = decryptedBytes.toString(enc.Utf8);
      return decryptedValue;
    } else {
      return null;
    }
  }

  public getValue(key: string): string {
    if (localStorage.getItem(key)) {
      return AES.decrypt(localStorage.getItem(key), this.k).toString(enc.Utf8);
    }
    return null;
  }
  public set(key: string, value: string) {
    localStorage.setItem(key, AES.encrypt(value, this.k).toString());
  }
}
