<ng-template #defaultModalFooter>
  <button style="float: right" (click)="changeShowState(false)">
    {{ okText }}
  </button>
</ng-template>
<div class="dialog-modal" [ngClass]="modalClass + (higherIndex ? ' to-front' : '')" *ngIf="showModal">
  <div
    class="dialog-modal-container"
    [ngStyle]="{
      'max-width': maxWithCalculated,
      'min-width': minWidthCalculated,
      maxHeight: calculatedHeightContainer
    }"
  >
    <div
      class="dialog-modal-content"
      [ngStyle]="{
        'max-width': maxWithCalculated,
        'min-width': minWidthCalculated,
        maxHeight: calculatedHeightContent
      }"
    >
      <ng-template [ngTemplateOutlet]="modalContent"></ng-template>
    </div>
    <div class="dialog-modal-footer">
      <ng-template [ngTemplateOutlet]="modalFooter ? modalFooter : defaultModalFooter"></ng-template>
    </div>
  </div>
</div>
