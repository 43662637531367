<app-modal id="preview-image-dialog" [show]="isOpenPreviewImageDialog" maxWidth="800px">
  <ng-template #modalContent>
    <img [src]="previewImageData" width="100%" alt="" />
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="reclamation-admin-dialog" [show]="isOpenAdminDialog" minWidth="350px" maxWidth="400px" maxHeight="">
  <ng-template #modalContent>
    <app-content-header contentTitle="Admin-Edit"></app-content-header>
    <br />
    New status:
    <select class="form-control" [(ngModel)]="editNewStatus">
      <option value="0">Done</option>
      <option value="1">Processing</option>
      <option value="2">New</option>
    </select>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="editSetNewStatus()">Set data</button>
  </ng-template>
</app-modal>

<app-modal id="reclamation-selectorder-dialog" [show]="isOpenSelectOrderDialog" minWidth="350px" maxWidth="800px" height="80%">
  <ng-template #modalContent>
    <div class="table">
      <div class="cell-50">
        <app-content-header contentTitle="{{ 'RECLAMATION_DIALOG.SELECT_ORDER.TITLE1' | translate }}"></app-content-header>

        <div class="dialog-row" style="padding-bottom: 10px">
          <div class="cell-50-nomin">
            <span class="tab" [ngClass]="selectOrderTabId === 0 ? 'active' : ''" (click)="switchSelectOrderTab(0)">Aktiv</span>
          </div>
          <div class="cell-50-nomin">
            <span class="tab" [ngClass]="selectOrderTabId === 1 ? 'active' : ''" (click)="switchSelectOrderTab(1)">Archiv</span>
          </div>
        </div>

        <div class="dialog-row" style="padding-bottom: 10px">
          <div class="cell-100">
            <input class="form-control" [(ngModel)]="selectOrderSearchText" (keyup)="selectOrderSearchChanged()" />
          </div>
        </div>
        <div class="dialog-row" *ngFor="let o of orderOptions">
          <div class="cell-70-fixed">
            {{ o.orderNumber }}<br />
            {{ o.customerReference }}
          </div>
          <div class="cell-30-fixed">
            <button class="dialog-add-button" (click)="selectOrder(o)">wählen</button>
          </div>
        </div>
      </div>
      <div class="cell-50">
        <app-content-header contentTitle="{{ 'RECLAMATION_DIALOG.SELECT_ORDER.TITLE2' | translate }}"></app-content-header>

        {{ "RECLAMATION_DIALOG.SELECT_ORDER.ORDER_NUMBER" | translate }}*:<br />
        <input class="form-control" [(ngModel)]="freeOrderNumber" />
        <div class="form-error" *ngIf="freeOrderNumber.length !== 9">
          {{ "RECLAMATION_DIALOG.SELECT_ORDER.ORDER_NUMBER_VALIDATION" | translate }}
        </div>
        <br />
        <button type="button" (click)="selectFreeOrder()" [disabled]="freeOrderNumber.length !== 9">
          {{ "RECLAMATION_DIALOG.SELECT_ORDER.SELECT_FREE" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeSelectOrderDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="reclamation-selectorderpos-dialog" [show]="isOpenSelectOrderPositionDialog" minWidth="350px" maxWidth="800px" height="80%">
  <ng-template #modalContent>
    <div class="table">
      <div class="cell-50" *ngIf="orderPosOptions.length > 0">
        <app-content-header contentTitle="{{ 'RECLAMATION_DIALOG.SELECT_ORDER_POSITION.TITLE1' | translate }}"></app-content-header>

        <div class="dialog-row" style="padding-bottom: 10px">
          <div class="cell-100">
            <input class="form-control" [(ngModel)]="selectOrderSearchText" (keyup)="selectOrderSearchChanged()" />
          </div>
        </div>
        <div class="dialog-row" *ngFor="let o of orderPosOptions">
          <div class="cell-70-fixed">
            {{ o.elementId }}<br />
            <div class="article-number" [innerHtml]="o.articleNumberHtml"></div>
            {{ o.description }}<br />
          </div>
          <div class="cell-30-fixed">
            <button class="dialog-add-button" (click)="selectOrderPosition(o)">hinzufügen</button>
          </div>
        </div>
        <div *ngIf="orderPosOptions.length === 0">-</div>
      </div>
      <div class="cell-50">
        <app-content-header contentTitle="{{ 'RECLAMATION_DIALOG.SELECT_ORDER_POSITION.TITLE2' | translate }}"></app-content-header>
        <br />
        {{ "RECLAMATION_DIALOG.SELECT_ORDER_POSITION.ELEMENT_ID" | translate }}*:<br />
        <input class="form-control" [(ngModel)]="freeElementId" />
        <div class="form-error" *ngIf="freeElementId.length !== 10">
          {{ "RECLAMATION_DIALOG.SELECT_ORDER_POSITION.ELEMENT_ID_VALIDATION" | translate }}
        </div>
        <br />
        <button type="button" (click)="selectFreeOrderPosition()" [disabled]="freeElementId.length !== 10">
          {{ "RECLAMATION_DIALOG.SELECT_ORDER_POSITION.ADD" | translate }}
        </button>
        <br />
        <br />
        <app-content-header contentTitle="{{ 'RECLAMATION_DIALOG.SELECT_ORDER_POSITION.TITLE3' | translate }}"></app-content-header>

        <div *ngIf="elementIds.length === 0">-</div>
        <div class="table">
          <div class="dialog-row" *ngFor="let id of elementIds">
            <div class="cell-70-fixed">{{ id }}<br /></div>
            <div class="cell-30-fixed">
              <button class="dialog-add-button" (click)="removeOrderPosition(id)">entfernen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeSelectOrderPositionDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>
