import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-selected-archives-order-dialog",
  templateUrl: "./selected-archives-order-dialog.component.html",
  styleUrls: ["./selected-archives-order-dialog.component.scss"],
})
export class SelectedArchivesOrderDialogComponent implements OnInit {
  public confirmText: string;
  constructor(public dialogRef: MatDialogRef<SelectedArchivesOrderDialogComponent>) {}
  ngOnInit(): void {
    this.confirmText = "Wollen Sie die ausgewählten Aufträge archivieren? \n Dieser Schritt ist nicht umkehrbar.";
  }
  public closeBatchModal() {
    this.dialogRef.close();
  }
  public confirmBatchModal() {
    this.dialogRef.close(true);
  }
}
