<div class="expandedContent">
  <div class="description">
    <div>
      <p *ngIf="frame.status == 0">
        <strong>{{ "FRAME.DELIVERY_ADDRESS" | translate }}:</strong><br />
        {{ frame.deliverStreet }} {{ frame.deliverHouseNumber }}<br />
        {{ frame.deliverZipCode }} {{ frame.deliverCity }}<br />
        {{ getCountryName(frame.deliverCountry) }}
      </p>
      <p *ngIf="frame.status != 0">
        <strong>{{ "FRAME.PICKUP_ADDRESS" | translate }}:</strong><br />
        {{ frame.pickupStreet }} {{ frame.pickupHouseNumber }}<br />
        {{ frame.pickupZipCode }} {{ frame.pickupCity }}<br />
        <span *ngIf="frame.pickupLatitude != -1">{{ frame.pickupLatitude }} {{ frame.pickupLongitude }}<br /></span>
        {{ getCountryName(frame.pickupCountry) }}
      </p>
    </div>
    <div class="admin-edit-container">
      <app-admin-edit (editFired)="adminEdit()"></app-admin-edit>
    </div>
  </div>
</div>
