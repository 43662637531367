import { DeleteObject } from "../../../models/delete";

import { EventEmitter } from "@angular/core";
import { MessageService } from "./../../../services/message.service";
import { Component, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { TranslateService } from "@ngx-translate/core";
import { FormService } from "src/app/services/form.service";
import { User } from "src/app/models/user";
import { UiShareService } from "src/app/services/ui-share.service";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-user-admin-modals",
  templateUrl: "./user-admin-modals.component.html",
  styleUrls: ["./user-admin-modals.component.scss"],
})
export class UserAdminModalsComponent implements OnInit {
  public isEditUser: boolean = false;
  public isOpenUser: boolean = false;
  public isOpenDeleteDialog: boolean = false;
  public form: UntypedFormGroup;
  public user: User;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  public countryOptions = [];

  constructor(
    private loaderService: LoaderService,
    private uiShareService: UiShareService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private formService: FormService,
    private userService: ApiUsersService
  ) {}

  public ngOnInit(): void {
    try {
      this.countryOptions = this.uiShareService.getCountryOptions(this.translateService.currentLang);
    } catch (e) {}
  }

  public getFormControl(formControlName: string): string {
    return this.formService.getFormControl(this.form, formControlName);
  }

  public onSubmit(): void {
    this.isEditUser ? this.submitEditUser() : this.submitNewUser();
  }
  public submitEditUser(): void {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      let user: User = Object.assign({});
      user.id = this.user.id;
      user.email = this.form.get("email").value.trim().toLowerCase();
      user.type = this.form.get("userType").value;
      user.firstName = this.form.get("firstName").value;
      user.lastName = this.form.get("lastName").value;
      user.phone = this.form.get("phone").value;
      user.mobile = this.form.get("mobile").value;
      user.street = this.form.get("street").value;
      user.houseNumber = this.form.get("houseNumber").value;
      user.zipCode = this.form.get("zipCode").value;
      user.city = this.form.get("city").value;
      user.country = this.form.get("country").value;
      user.companyName = this.uiShareService.loggedInUser.companyName;
      this.userService.updateUser(user).subscribe({
        next: (data) => {
          this.loaderService.hideTransparentLoader();
          this.closeDialog();
          this.refresh.emit(true);
          this.messageService.success(this.translateService.instant("USER.EDIT_SUCCESS"));
        },
        error: (error) => {
          this.loaderService.hideTransparentLoader();
          this.closeDialog();
          this.messageService.error(this.translateService.instant("USER.EDIT_ERROR"));
        },
      });
    });
  }
  public submitNewUser(): void {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      let user: User = Object.assign({});
      user.email = this.form.get("email").value.trim().toLowerCase();
      user.type = this.form.get("userType").value;
      user.firstName = this.form.get("firstName").value;
      user.lastName = this.form.get("lastName").value;
      user.phone = this.form.get("phone").value;
      user.mobile = this.form.get("mobile").value;
      user.street = this.form.get("street").value;
      user.houseNumber = this.form.get("houseNumber").value;
      user.zipCode = this.form.get("zipCode").value;
      user.city = this.form.get("city").value;
      user.country = this.form.get("country").value;
      user.companyName = this.uiShareService.loggedInUser.companyName;
      this.userService.addUser(user).subscribe({
        next: (data) => {
          this.loaderService.hideTransparentLoader();
          this.closeDialog();
          this.refresh.emit(true);
          this.messageService.success(this.translateService.instant("USER.SUCCESS"));
        },
        error: (error) => {
          this.loaderService.hideTransparentLoader();
          //this.closeDialog();
          if (error.error) {
            if (error.error == "EMAIL_ADDRESS_IN_USE") {
              this.messageService.error(this.translateService.instant("USER.ERROR_IN_USE"));
            } else {
              this.messageService.error(this.translateService.instant("USER.ERROR"));
            }
          } else {
            this.messageService.error(this.translateService.instant("USER.ERROR"));
          }
        },
      });
    });
  }
  public openDialogNewUser(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"),
          Validators.required,
        ])
      ),
      userType: new UntypedFormControl("", Validators.required),
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      street: new UntypedFormControl(""),
      houseNumber: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      country: new UntypedFormControl(""),
      zipCode: new UntypedFormControl("", Validators.compose([Validators.pattern("^[0-9]+$")])),
      phone: new UntypedFormControl("", Validators.compose([Validators.pattern("^[+0][0-9 ]+$")])),
      mobile: new UntypedFormControl("", Validators.compose([Validators.pattern("^[+0][0-9 ]+$")])),
    });
    this.isOpenUser = true;
  }
  public closeDialog(): void {
    this.user = null;
    this.isEditUser = false;
    this.isOpenDeleteDialog = false;
    this.isOpenUser = false;
  }
  public openDeleteUser(user: User): void {
    this.user = user;
    this.isOpenDeleteDialog = true;
  }
  public deleteUser(): void {
    let deleteObject: DeleteObject = Object.assign({});
    deleteObject.id = this.user.id;
    deleteObject.confirm = this.user.email;
    this.userService.deleteUser(deleteObject).subscribe({
      next: (data) => {
        this.isOpenDeleteDialog = false;
        this.refresh.emit(true);
        this.messageService.success(this.translateService.instant("USER.SUCCESS_DELETE"));
      },
      error: (error) => {
        this.messageService.error(this.translateService.instant("USER.ERROR_DELETE"));
        this.isOpenDeleteDialog = false;
      },
    });
  }

  public editUser(user: User): void {
    this.user = user;
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(
        user.email,
        Validators.compose([
          Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"),
          Validators.required,
        ])
      ),
      userType: new UntypedFormControl(user.type, Validators.required),
      firstName: new UntypedFormControl(user.firstName, Validators.required),
      lastName: new UntypedFormControl(user.lastName, Validators.required),
      street: new UntypedFormControl(user.street),
      houseNumber: new UntypedFormControl(user.houseNumber),
      city: new UntypedFormControl(user.city),
      country: new UntypedFormControl(user.country),
      zipCode: new UntypedFormControl(user.zipCode, Validators.compose([Validators.pattern("^[0-9]+$")])),
      phone: new UntypedFormControl(user.phone, Validators.compose([Validators.pattern("^[+0][0-9 ]+$")])),
      mobile: new UntypedFormControl(user.mobile, Validators.compose([Validators.pattern("^[+0][0-9 ]+$")])),
    });
    this.isEditUser = true;
    this.isOpenUser = true;
  }
}
