<div class="dialog-modal-content">
  <app-content-header contentTitle="{{ 'ORDER_DIALOG.CHANGE_TITLE' | translate }}"></app-content-header>
  <div>
    <label class="field-title plain">{{ "CONTACT_FORM.ORDER_NUMBER" | translate }}: {{ this.data.item?.orderNumber }}</label>
    <br />
    <div class="table">
      <div class="cell-50">
        <label class="field-title plain">{{ "ORDER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
        <div class="field">
          <textarea class="form-control" name="changeMessage" [(ngModel)]="approve.changeMessage" placeholder=""></textarea>
        </div>
      </div>
      <div class="cell-50">
        <div *ngFor="let position of approve.positions; let i = index" class="field-container">
          <label class="field-title plain">{{ "ORDER_DIALOG.CHANGE_MESSAGE_FOR_ARTICLE" | translate }}
            {{ position?.articleNumber }}
          </label>
          <div class="field">{{ position.changeMessage }}</div>
        </div>
        <div class="no-articles" *ngIf="!approve.positions || approve.positions.length == 0">
          {{ "ORDER_DIALOG.NO_CHANGE_ARTICLES_SET" | translate }}
        </div>
      </div>
    </div>

    <div class="table" style="padding-top: 5px" *ngIf="approveOnChangeEnabled">
      <div class="cell-cb">
        <app-checkbox [checked]="approveOnChange" (onChanged)="approveAfterChangeChanged($event)"> </app-checkbox>
      </div>
      <div class="cell-cb-text">
        <span class="field-title plain small">{{ "ORDER_DIALOG.APPROVE_ON_CHANGE" | translate }}</span>
      </div>
    </div>
  </div>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="closeDialog()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="changeOrder()"
    [disabled]="(!approve.changeMessage || approve.changeMessage == '') && (!approve.positions || approve.positions.length == 0)">
    <span *ngIf="approveOnChange; else elseBlock"> {{ "ORDER_DIALOG.CHANGE" | translate }} und Freigeben </span>
    <ng-template #elseBlock>
      <span> {{ "ORDER_DIALOG.CHANGE" | translate }}</span>
    </ng-template>
  </button>
</div>