import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { IFrameEditVM } from "../../models/edit";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiFrameService {
  private dataUrl = environment.BASE_URL + "Frames";

  constructor(private http: HttpService) {}

  public getFrames(): Observable<any> {
    return this.http.get(this.dataUrl + "/getall");
  }
  public getArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/Archive");
  }
  public postFrame(frame: any): Observable<any> {
    return this.http.postNoContentType(this.dataUrl, frame);
  }

  public editFrame(edit: IFrameEditVM): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Edit", edit);
  }
}
