import { CurrencyPipe, DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild, effect } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import saveAs from "file-saver";
import { Observable, Subject, forkJoin, merge, of } from "rxjs";
import { catchError, startWith, switchMap, takeUntil } from "rxjs/operators";
import { ActionArchiveDialogComponent } from "src/app/dialogs/action/action-archive-dialog/action-archive-dialog.component";
import { ActionChangeDialogComponent } from "src/app/dialogs/action/action-change-dialog/action-change-dialog.component";
import { ActionParkDialogComponent } from "src/app/dialogs/action/action-park-dialog/action-park-dialog.component";
import { SelectedArchivesDialogComponent } from "src/app/dialogs/selected-archives-dialog/selected-archives-dialog.component";
import { CustomIonHeaderCondenseComponent } from "src/app/header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "src/app/header/custom-ion-header/custom-ion-header.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { PagerComponent } from "src/app/includes/pager/pager.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { IOffer, IOfferStatus } from "src/app/models/offer";
import { IOfferActionHistory } from "src/app/models/offer-action-history";
import { IOfferPosition } from "src/app/models/offer-position";
import { SortType } from "src/app/models/sort";
import { Tab, TabGroup } from "src/app/models/tab";
import { ApiOfferService } from "src/app/services/api/api-offer.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { NativeFileService } from "src/app/services/native-file.service";
import { OfferService } from "src/app/services/offer.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"],
})
export class OffersComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public environment = environment;
  @ViewChild(CustomIonHeaderCondenseComponent)
  header1: CustomIonHeaderCondenseComponent;
  @ViewChild(CustomIonHeaderComponent) header2: CustomIonHeaderComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("pager1") pager1: PagerComponent;
  @ViewChild("pager2") pager2: PagerComponent;
  dataSource = new MatTableDataSource<IOffer>([]);
  expandedRows: IOffer[] = [];
  public showPricesInPDF = true;
  defaultSort: SortType = {
    type: "offerNumber",
    sort: "desc",
  };

  ngUnsubscribe = new Subject<void>();
  refresh = new Subject<void>();
  loadOffers = () => this.apiOfferService.getOffers();
  loadArchive = () => this.apiOfferService.getArchive();

  public tabGroups: TabGroup[] = [
    {
      dataLoader: this.loadOffers,
      cacheLoader: () => this.uiShareService.offers,
      cacheStorer: (data) => (this.uiShareService.offers = data),
      defaultColumns: [
        "expand",
        "select",
        "offerNumber",
        "createdDate",
        "customerReference",
        "calculatedStatus",
        "calculatedAmountGross",
        "discountPercentage",
        "calculatedAmountNet",
        "validTo",
        "confirmationPdf",
        "offerImagesPdf",
        "actions",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["select", "actions", "contact"],
      defaultSublevelColumns: ["expand", "thumbnailFileName", "description", "quantity", "price", "amount", "qPos", "validTo", "change", "adminEdit"],
      defaultReadOnlyExcludedSublevelColumns: ["change"],
      tabs: [
        {
          title: "Bestätigung",
          active: true,
          id: 0,
          tabFilter: (o) => !o.parked && o.calculatedStatus == IOfferStatus.Created,
          excludedColumns: ["calculatedStatus"],
          excludedSublevelColumns: [],
        },
        {
          title: "Überführt",
          id: 1,
          tabFilter: (o) => !o.parked && o.calculatedStatus == IOfferStatus.Confirmed,
          excludedColumns: [],
          excludedSublevelColumns: ["change"],
        },
        {
          title: "In Bearbeitung",
          id: 2,
          tabFilter: (o) => !o.parked && o.calculatedStatus == IOfferStatus.Processing,
          excludedColumns: [],
          excludedSublevelColumns: ["change"],
        },
        {
          title: "Storniert",
          id: 3,
          tabFilter: (o) => !o.parked && o.calculatedStatus == IOfferStatus.Cancelled,
          excludedColumns: ["calculatedStatus"],
          excludedSublevelColumns: ["change"],
        },
        {
          title: "Geparkt",
          id: 5,
          tabFilter: (o) => o.parked,
          excludedColumns: ["calculatedStatus"],
          excludedSublevelColumns: [],
        },
        {
          title: "Abgelaufen",
          id: 6,
          tabFilter: (o) => !o.parked && o.calculatedStatus == IOfferStatus.Expired,
          excludedColumns: ["calculatedStatus"],
          excludedSublevelColumns: ["change"],
        },
      ],
    },
    {
      dataLoader: this.loadArchive,
      cacheLoader: () => this.uiShareService.offersArchive,
      cacheStorer: (data) => (this.uiShareService.offersArchive = data),
      defaultColumns: [
        "expand",
        "offerNumber",
        "createdDate",
        "customerReference",
        "calculatedAmountGross",
        "discountPercentage",
        "calculatedAmountNet",
        "validTo",
        "confirmationPdf",
        "offerImagesPdf",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      defaultSublevelColumns: ["expand", "description", "quantity", "price", "amount", "qPos", "validTo"],
      tabs: [
        {
          title: "Im Archiv",
          id: 4,
        },
      ],
    },
  ];

  currentTabGroup = this.tabGroups[0];
  currentTab = this.currentTabGroup.tabs[0];
  displayedColumns: string[] = [];
  innerDisplayedColumns: string[] = [];
  currentData: IOffer[] = [];
  mobileData: IOffer[] = [];

  constructor(
    private loaderService: LoaderService,
    private apiOfferService: ApiOfferService,
    public offerService: OfferService,
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private router: RoutingService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private nativeFileService: NativeFileService
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.OFFER"),
        page: null,
      },
    ];
    this.dataSource.filterPredicate = (data, filter) => {
      let term =
        data.offerNumber +
        this.getFixedDate(data.createdDate) +
        this.currencyPipe.transform(data.calculatedAmountNet, "EUR", "symbol", undefined, "de-DE") +
        this.currencyPipe.transform(data.calculatedAmountGross, "EUR", "symbol", undefined, "de-DE") +
        data.discountPercentage +
        data.customerReference +
        this.getFixedDate(data.validTo);
      term = term.toLowerCase().trim();
      filter = filter.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    effect(() => {
      this.getColumnsToDisplay(this.currentTabGroup, this.currentTab);
      this.getInnerColumnsToDisplay(this.currentTabGroup, this.currentTab);
    });
  }

  ngOnDestroy(): void {
    try {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    merge(this.header1.onReloadClickedSubject, this.header2.onReloadClickedSubject, this.refresh)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          if (this.uiShareService.offersNeedReload) {
            this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.needReload = true)));
          }
          return this.getData(this.currentTabGroup, this.currentTab).pipe(catchError(() => of(null)));
        })
      )
      .subscribe((data: IOffer[]) => {
        this.loaderService.hideLoader();
        this.uiShareService.offersNeedReload = false;
        if (data) {
          data = data.sort((a, b) => (b.createdDate as any).localeCompare(a.createdDate as any)); // sort for mobile
          this.storeData(this.currentTabGroup, this.currentTab, data);
          this.currentData = data;
          data.forEach((d) => {
            d.positionsDataSource = new MatTableDataSource<IOfferPosition>(d.positions);
            d.historyDataSource = new MatTableDataSource<IOfferActionHistory>();
          });
          this.currentTabGroup.tabs.forEach((tab) => {
            tab.needReload = false;
            tab.count = this.getTabData(tab).length;
          });
          const tabData = this.getCurrentTabData();
          this.dataSource.data = tabData;
          this.pager1.resetPage(this.dataSource.filteredData.length);
          this.updateSearchCount();
        }
      });

    this.globalSearchField.valueChanges.subscribe((term) => {
      this.dataSource.filter = term;
      this.pager1.resetPage(this.dataSource.filteredData.length);
      this.updateSearchCount();
    });

    this.sort.sortChange.subscribe((e) => {
      this.uiShareService.currentOfferSortTypes[this.currentTab.id] = {
        type: e.active,
        sort: e.direction,
      };
      this.uiShareService.saveViewOptions();
    });

    this.pager1.init(1, this.pager2);
    this.pager2.init(2, this.pager1);

    // restore sort
    if (!this.uiShareService.currentOfferSortTypes[this.currentTab.id]) {
      this.uiShareService.currentOfferSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentOfferSortTypes[this.currentTab.id]);

    // restore last tab
    for (let i = 0; i < this.tabGroups.length; i++) {
      for (let j = 0; j < this.tabGroups[i].tabs.length; j++) {
        if (this.tabGroups[i].tabs[j].id == this.uiShareService.currentOfferTabId) {
          this.switchTab(this.tabGroups[i], this.tabGroups[i].tabs[j]);
          break;
        }
      }
    }
  }

  private getData(tabGroup: TabGroup, tab: Tab): Observable<any> {
    if (!tab.needReload && tabGroup.cacheLoader && tabGroup.cacheLoader()) {
      return of(tabGroup.cacheLoader());
    } else {
      return tabGroup.dataLoader();
    }
  }

  private storeData(tabGroup: TabGroup, tab: Tab, data: IOffer[]) {
    if (tabGroup.cacheStorer) {
      tabGroup.cacheStorer(data);
    }
  }

  private getCurrentTabData() {
    return this.getTabData(this.currentTab);
  }

  private getTabData(tab: Tab) {
    if (tab.tabFilter) {
      return this.currentData.filter(tab.tabFilter);
    } else {
      return this.currentData;
    }
  }

  private getCachedTabData(tabGroup: TabGroup, tab: Tab): any[] {
    if (tabGroup.cacheLoader) {
      if (tab.tabFilter) {
        return tabGroup.cacheLoader().filter(tab.tabFilter);
      } else {
        return tabGroup.cacheLoader();
      }
    }
    return [];
  }

  private updateSearchCount() {
    this.tabGroups.forEach((tabGroup) => {
      tabGroup.tabs
        .forEach((tab) => {
          if (this.globalSearchField.value && tab.count > 0 && !tab.needReload) {
            const tempDataSource = new MatTableDataSource();
            tempDataSource.filterPredicate = this.dataSource.filterPredicate;
            tempDataSource.data = this.getCachedTabData(tabGroup, tab);
            tempDataSource.filter = this.dataSource.filter;
            tab.searchCount = tempDataSource.filteredData.length;
          } else {
            tab.searchCount = undefined;
          }
        });
    });
  }

  public openContactForm(mode, item): void {
    this.router.forwardByUrl("/" + mode + "/offer/" + item.offerNumber + "/-");
  }

  public openRequestAgainForm(offer: IOffer) {
    this.router.forwardByUrl("/request/offer/" + offer.offerNumber);
  }

  public reloadData(force = false) {
    if (force) {
      this.uiShareService.offersNeedReload = true;
    }
    this.refresh.next();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public switchTab(tabGroup: TabGroup, tab: Tab) {
    if (tab.active) {
      return;
    }
    this.expandedRows = [];
    this.getColumnsToDisplay(tabGroup, tab);
    this.getInnerColumnsToDisplay(tabGroup, tab);
    const previousLoader = this.currentTabGroup.dataLoader;
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.active = false)));
    tab.active = true;
    this.currentTab = tab;
    this.currentTabGroup = tabGroup;
    this.uiShareService.currentOfferTabId = this.currentTab.id;
    if (!this.uiShareService.currentOfferSortTypes[this.currentTab.id]) {
      this.uiShareService.currentOfferSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentOfferSortTypes[this.currentTab.id]);
    this.uiShareService.saveViewOptions();
    if (previousLoader !== tabGroup.dataLoader) {
      this.reloadData();
    } else {
      const tabData = this.getCurrentTabData();
      this.dataSource.data = tabData;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    }
  }

  public allSelected() {
    if (this.dataSource.filteredData.length == 0) {
      return false;
    }
    return this.dataSource.filteredData.every((o) => o.selected);
  }

  public selectAll(evt: any) {
    this.dataSource.filteredData.forEach((o) => (o.selected = evt?.currentTarget?.checked));
  }

  public selectedItemCount(): number {
    return this.dataSource.data?.filter((offer) => offer.selected).length;
  }

  public batchArchive() {
    const offersToArchive = this.dataSource.data.filter((offer) => offer.selected);
    if (!offersToArchive) {
      return;
    }
    let dialogRef = this.dialog.open(SelectedArchivesDialogComponent, {
      width: "400px",
      data: {
        itemName: "Angebote",
      },
    });
    dialogRef.afterClosed().subscribe({
      next: async (res) => {
        if (res === true) {
          let successMessage = "Archivierung abgeschlossen";
          for await (const offer of offersToArchive) {
            await this.apiOfferService
              .archive(offer.offerNumber)
              .toPromise()
              .then(
                (res) => {},
                (e) => {
                  console.debug(`${e}`);
                }
              );
          }
          this.reloadData(true);
          setTimeout(() => {
            this.messageService.success(successMessage);
          }, 1000);
        }
      },
      error: (e) => {
        console.debug(`${e}`);
      },
    });
  }

  public batchPark() {
    const offersToPark = this.dataSource.data.filter((offer) => offer.selected);
    if (!offersToPark) {
      return;
    }
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      data: {
        title: "Achtung",
        confirmText: "Möchten Sie die ausgewählten Angebote wirklich parken? Sie erhalten keine Informationen mehr per E-Mail oder per Push Nachricht.",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let errorMessage = this.translateService.instant("OFFER_DIALOG.PARK_ERROR");
        const requests: Observable<any>[] = [];
        for (const offer of offersToPark) {
          requests.push(this.apiOfferService.park(offer.offerNumber));
        }
        forkJoin(requests).subscribe({
          next: () => {
            this.reloadData(true);
            this.messageService.success("Die Angebote wurden erfolgreich geparkt.");
          },
          error: () => {
            this.messageService.error(errorMessage);
            this.reloadData(true);
          },
        });
      }
    });
  }

  public batchUnPark() {
    const offersToUnPark = this.dataSource.data.filter((offer) => offer.selected);
    if (!offersToUnPark) {
      return;
    }
    const requests: Observable<any>[] = [];
    for (const offer of offersToUnPark) {
      requests.push(this.apiOfferService.unpark(offer.offerNumber));
    }
    forkJoin(requests).subscribe({
      next: () => {
        this.reloadData(true);
      },
      error: () => {
        this.messageService.error("Es ist ein Fehler aufgetreten");
        this.reloadData(true);
      },
    });
  }

  public openActionChangeOfferDialog(offer: IOffer) {
    let dialogRef = this.dialog.open(ActionChangeDialogComponent, {
      height: "430px",
      width: "850px",
      data: { item: offer },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_SUCCESS");
      let errorMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  openActionParkDialog(item: any) {
    let dialogRef = this.dialog.open(ActionParkDialogComponent, {
      width: "400px",
      data: { offer: item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("OFFER_DIALOG.PARK_SUCCESS");
      let errorMessage = this.translateService.instant("OFFER_DIALOG.PARK_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  openActionUnParkDialog(item: any) {
    this.apiOfferService.unpark(item.offerNumber).subscribe({
      next: (data) => {
        this.reloadData(true);
      },
      error: (error) => {
        this.messageService.error("Es ist ein Fehler aufgetreten");
        this.reloadData(true);
      },
    });
  }

  public openActionArchiveDialog(offer: IOffer) {
    let dialogRef = this.dialog.open(ActionArchiveDialogComponent, {
      height: "auto",
      width: "400px",
      data: { item: offer },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("OFFER_DIALOG.ARCHIVE_SUCCESS");
      let errorMessage = this.translateService.instant("OFFER_DIALOG.ARCHIVE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public getOfferImagesPdf(offer: IOffer) {
    let request;
    if (this.currentTab.id === 4) {
      request = this.offerService.getOfferArchiveImagesPdf(offer.offerNumber);
    } else {
      request = this.offerService.getOfferImagesPdf(offer.offerNumber);
    }
    request.subscribe({
      next: (res) => {
        if (Capacitor.isNativePlatform()) {
          this.nativeFileService.openPdf(res, {
            fileName: `Angebot_${offer.offerNumber}`,
          });
        } else {
          saveAs(res, `Angebot_${offer.offerNumber}`);
        }
      },
      error: (e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.messageService.warn("Daten werden aktuell noch verarbeitet, bitte versuchen Sie es später erneut.");
        } else {
          this.messageService.error("Zugehörige Bilder konnten nicht geladen werden.");
        }
      },
    });
  }

  public getColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.columns || tabGroup.defaultColumns;

    if (tabGroup.defaultReadOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedColumns.includes(c));
    }
    if (tab.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedColumns.includes(c));
    }
    if (!this.uiShareService.showPrices()) {
      columns = columns.filter((c) => !["calculatedAmountNet", "calculatedAmountGross", "discountPercentage"].includes(c));
    }
    if (tab.excludedColumns) {
      columns = columns.filter((c) => !tab.excludedColumns.includes(c));
    }
    this.displayedColumns = columns;
  }

  public getInnerColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.sublevelColumns || tabGroup.defaultSublevelColumns;

    if (tabGroup.defaultReadOnlyExcludedSublevelColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedSublevelColumns.includes(c));
    }
    if (tab.readOnlyExcludedSublevelColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedSublevelColumns.includes(c));
    }
    if (!this.uiShareService.showPrices()) {
      columns = columns.filter((c) => !["price", "amount"].includes(c));
    }
    if (tab.excludedSublevelColumns) {
      columns = columns.filter((c) => !tab.excludedSublevelColumns.includes(c));
    }
    if (!this.uiShareService.isAdminEditAllowed()) {
      columns = columns.filter((c) => "adminEdit" !== c);
    }
    this.innerDisplayedColumns = columns;
  }

  private doSort(sortType: SortType) {
    sortType.sort = sortType.sort.replace("ASC", "asc").replace("DESC", "desc").replace("DSC", "desc") as any; // for legacy support
    if (!sortType.sort) {
      sortType.sort = this.defaultSort.sort;
    }
    if (!sortType.type) {
      sortType.type = this.defaultSort.type;
    }
    this.sort.sort({ id: null, start: sortType.sort, disableClear: true });
    this.sort.sort({ id: sortType.type, start: sortType.sort, disableClear: true });
  }

  openActionChangeDialog(item: any) {
    let dialogRef = this.dialog.open(ActionChangeDialogComponent, {
      height: "430px",
      width: "850px",
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_SUCCESS");
      let errorMessage = this.translateService.instant("OFFER_DIALOG.CHANGE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public onPageChanged(pagerData: any): void {
    // no need to sort again, the filteredData order never changes
    this.mobileData = this.dataSource.filteredData.slice(pagerData.start, pagerData.end);
  }

  public isPastDate(date: string | Date) {
    if (!date) {
      return false;
    }
    return new Date(date) < new Date();
  }
}
