import { TranslateService } from "@ngx-translate/core";

import { Component, OnInit } from "@angular/core";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { BarcodeService } from "src/app/services/barcode.service";
import { LoaderService } from "src/app/services/loader.service";
import { PermissionService } from "src/app/services/permission.service";
import { ProductDetailsService } from "src/app/services/product-details.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-product-search",
  templateUrl: "./product-search.component.html",
  styleUrls: ["./product-search.component.scss"],
})
export class ProductSearchComponent implements OnInit {
  public searchNumber: string;
  public language: string = "de-DE";

  constructor(
    private barcodeService: BarcodeService,
    private orderSerivce: ApiOrderService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private uiShareService: UiShareService,
    private router: RoutingService,
    private permissionService: PermissionService,
    private productDetailsService: ProductDetailsService,
  ) {}

  public ngOnInit(): void {
    this.language = this.translateService.getDefaultLang();
  }
  public scanQRCode(): void {
    this.barcodeService.scan().then(
      (data) => {
        console.log("SCAN RESULT", data.ScanResult);
        if (data.ScanResult.indexOf("article/") != -1) {
          this._searchArticle(data.ScanResult.substring(data.ScanResult.indexOf("article/") + 8, data.ScanResult.length));
        } else {
          // use input as number
          this._searchArticle(data.ScanResult);
        }
      },
      (error) => {
        console.error(error);
        this.uiShareService.commonModals.showErrorModal(null, this.translateService.instant("PRODUCTS.CODE_ERROR")).subscribe();
        this.permissionService.checkCamera();
      },
    );
  }
  public searchArticle() {
    this._searchArticle(this.searchNumber);
  }

  public _searchArticle(searchData: string) {
    this.router.forward(["/article", searchData]);
  }
}
