<div mat-dialog-content>
  <div class="table-mobil">
    <div class="card" [ngClass]="i === shipmentOrder.positions.length - 1 ? ' no-border' : ''" *ngFor="let item of shipmentOrder.positions; let i = index">
      <div class="card-content">
        <span class="header-cell-mobil">
          {{ item?.articleNumber }}
        </span>
        <span class="cell-mobil"><span [innerHtml]="'ORDER.POSITION_INDEX' | translate"></span>: {{ item.positionIndex }}</span>
        <span class="cell-mobil">{{ "ORDER.QUANTITY_DELIVERED" | translate }}: {{ item.quantityDelivered }}</span>
        <span class="cell-mobil">{{ "ORDER.STATUS" | translate }}: {{ "ORDER_STATUS." + item.status | translate }}</span>
      </div>
      <div class="description-container">
        <span class="cell-mobil" *ngIf="item.hasPerformanceStatementPdf">
          <button (click)="orderService.getShipmentPdf($event, shipmentOrder, item)">
            <span>Leistungserklärung</span>
          </button>
        </span>
      </div>
      <div class="card-image" *ngIf="item.thumbnailFileBase64">
        <img
          width="50"
          id="image{{ item.thumbnailFileName }}"
          src="data:image/gif;base64,{{ item.thumbnailFileBase64 }}"
          alt="{{ item.thumbnailFileName }}"
          (click)="previewImage(item)"
        />
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbruch</button>
</div>
