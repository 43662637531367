<div class="dialog-modal-content">
  <app-content-header contentTitle="{{ 'ORDER_DIALOG.TITLE' | translate }}"></app-content-header>
  <div cell="100">
    <label class="field-title">{{ "CONTACT_FORM.ORDER_NUMBER" | translate }}: {{ this.data.item?.orderNumber }}</label>
    <br />
    <label class="field-title plain"
      >{{ "ORDER_DIALOG.EARLIEST_WISH_DELIVERY_DATE" | translate }}: KW {{ wishDeliveryDateMinWeek }}, {{ wishDeliveryDateMinYear }}</label
    >
    <div *ngIf="wishDeliveryDateDelay > 0" class="delay">
      Das maximale Freigabedatum für diesen Auftrag ist verstrichen. Die geplante Lieferwoche wurde um {{ wishDeliveryDateDelay }} Tage verschoben.
    </div>
    <div *ngIf="holidayDelay > 0" class="delay">
      Die frühest mögliche Auswahlwoche wurde aufgrund von Feiertagen um
      {{ holidayDelay }} Woche(n) verschoben.
    </div>
    {{ confirmText }}
    <br />
    <br />
    <label class="field-title plain">{{ "ORDER_DIALOG.WISH_DELIVERY_DATE" | translate }}</label>
    <div class="field">
      Jahr:
      <select class="form-control short" name="wishDeliveryDateYear" [(ngModel)]="wishDeliveryDateYear" (ngModelChange)="wishDeliveryDateYearChanged($event)">
        <option *ngFor="let y of wishDeliveryYears" value="{{ y }}">
          {{ y }}
        </option>
      </select>
      Woche:
      <select class="form-control short" name="wishDeliveryDateWeek" [(ngModel)]="wishDeliveryDateWeek">
        <option *ngFor="let w of wishDeliveryWeeks" value="{{ w }}">
          {{ w }}
        </option>
      </select>
    </div>
    <br />
  </div>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="onCancel()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="acceptOrder()">
    {{ "ORDER_DIALOG.ACCEPT" | translate }}
  </button>
</div>
