import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IReclamationEdit } from "../../models/edit";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class ApiReclamationService {
  private dataUrl = environment.apiConfig.uri + "Reclamation";

  constructor(private http: HttpService) {}

  public getReclamations(): Observable<any> {
    return this.http.get(this.dataUrl + "/getall");
  }
  public getPreviewImages(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/previewimages/" + number);
  }
  public getImageById(number: string, id: string): Observable<any> {
    return this.http.get(this.dataUrl + "/image/" + number + "/" + id);
  }
  public getArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/Archive");
  }
  public create(files: [any], data: any): Observable<any> {
    //console.log(JSON.stringify(files));
    //console.log(JSON.stringify(data));
    const formData: FormData = new FormData();
    formData.append("reclamation", JSON.stringify({ reclamation: data, files: files }));

    return this.http.postNoContentType(this.dataUrl, formData, true);

    /*
    for (let i = 1; i <= files.length; i++) {
      formData.append("file" + i, files[i - 1]);
    }
    return this.http.postNoContentType(this.dataUrl, formData, true);*/
  }

  public editReclamation(edit: IReclamationEdit): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Edit", edit);
  }
  public pdf(object: any): Observable<any> {
    return this.http.postBlob(this.dataUrl + "/PDF", object, { observe: "response" });
  }

  public archive(reclamationIds: string[]): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Archive/", reclamationIds);
  }
}
