import { MessageService } from "./../../services/message.service";
import { ApiUsersService } from "../../services/api/api-users.service";
import { User } from "../../models/user";

import { TranslateService } from "@ngx-translate/core";
import { Breadcrumb } from "../../models/breadcrumb";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UserAdminModalsComponent } from "../includes/user-admin-modals/user-admin-modals.component";
import { LoaderService } from "src/app/services/loader.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-user-administration",
  templateUrl: "./user-administration.component.html",
  styleUrls: ["./user-administration.component.scss"],
})
export class UserAdministrationComponent implements OnInit {
  public breadcrumb: [Breadcrumb];
  public users: User[];
  @ViewChild("userAdminModals")
  userAdminModals: UserAdminModalsComponent;

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private userService: ApiUsersService,
    private messageService: MessageService,
    public uiShareService: UiShareService
  ) {}

  public ngOnInit(): void {
    this.breadcrumb = [{ title: this.translateService.instant("MENU.USER"), page: null }];

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.userService.getAllUserByMerchant().subscribe({
        next: (data) => {
          this.loaderService.hideLoader();
          this.users = data;
        },
        error: (error) => {
          this.loaderService.hideLoader();
          console.log(error);
        },
      });
    });
  }
}
