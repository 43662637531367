<app-custom-ion-header [selected]="'MENU.RECLAMATION'"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.RECLAMATION'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.RECLAMATION'"></app-custom-ion-condense-header>

  <div>
    <app-header [selected]="'MENU.RECLAMATION'"></app-header>
    <app-custom-content-wrapper>
      <ng-template #wrapperContent>
        <div class="description-container">
          <app-content-header [contentTitle]="'PRODUCT.SEARCH.BY_NUMBER' | translate"></app-content-header>
          <p class="description">{{ "PRODUCT.SEARCH.RECLAMATION_DESCRIPTION" | translate }}</p>
        </div>
        <div class="row">
          <input class="input-cell form-control" type="text" [(ngModel)]="searchNumber" />
          <button class="button-cell" ion-button tappable [disabled]="!searchNumber" (click)="searchArticle()">
            {{ "PRODUCT.SEARCH.BUTTON_TEXT" | translate }}
          </button>
        </div>

        <div class="description-container">
          <app-content-header [contentTitle]="'PRODUCT.SCAN.QRCode' | translate"></app-content-header>
          <p class="description">{{ "PRODUCT.SCAN.DESCRIPTION" | translate }}</p>
        </div>
        <div class="row">
          <button class="scan" ion-button icon-left full tappable (click)="scanQRCode()">
            <ion-icon name="scan-sharp"></ion-icon>
            {{ "PRODUCT.SCAN.BUTTON_TEXT" | translate }}
          </button>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
