<div class="tabs">
  <div class="tab" [ngClass]="currentTab == 'common' ? 'active' : ''" (click)="loadCommon()">
    {{ "ADMIN.SYSTEM.COMMON" | translate }}
  </div>
  <div class="tab" [ngClass]="currentTab == lng ? 'active' : ''" *ngFor="let lng of availableLanguages" (click)="loadLng(lng)">
    {{ lng }}
  </div>
</div>

<div class="box" *ngIf="currentTab == 'common'">
  <div class="code">
    <form>
      <div class="checkbox">
        <app-checkbox [checked]="loginMessageEnabled.value == 'true'" (onChanged)="loginMessageEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.LOGIN_MESSAGE_ENABLED" | translate }}
      </div>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="importLogsEnabled.value == 'true'" (onChanged)="importLogsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.IMPORT_LOGS_ENABLED" | translate }}
      </div>
      <br />
      <br />
      <app-content-header contentTitle="{{ 'ADMIN.SYSTEM.MAILS_ENABLED_TITLE' | translate }}"></app-content-header>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="orderMailsEnabled.value == 'true'" (onChanged)="orderMailsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.ORDER_MAILS_ENABLED" | translate }}
      </div>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="offerMailsEnabled.value == 'true'" (onChanged)="offerMailsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.OFFER_MAILS_ENABLED" | translate }}
      </div>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="reclamationMailsEnabled.value == 'true'" (onChanged)="reclamationMailsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.RECLAMATION_MAILS_ENABLED" | translate }}
      </div>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="shipmentMailsEnabled.value == 'true'" (onChanged)="shipmentMailsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.SHIPMENT_MAILS_ENABLED" | translate }}
      </div>
      <br />
      <div class="checkbox">
        <app-checkbox [checked]="frameMailsEnabled.value == 'true'" (onChanged)="frameMailsEnabledChanged($event)"></app-checkbox>
      </div>
      <div class="checkbox-text">
        {{ "ADMIN.SYSTEM.FRAME_MAILS_ENABLED" | translate }}
      </div>
      <br />
      <br />
      <button (click)="saveSetting()">
        {{ "ADMIN.SYSTEM.SAVE" | translate }}
      </button>
    </form>
  </div>
</div>
<div class="box" *ngIf="currentTab != 'common'">
  <div class="code">
    <form>
      <label class="field-title">{{ "ADMIN.SYSTEM.LOGIN_MESSAGE" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="loginMessage" [(ngModel)]="loginMessage.value" placeholder="" />
      </div>
      <br />
      <br />
      <button (click)="saveSetting()">
        {{ "ADMIN.SYSTEM.SAVE" | translate }}
      </button>
    </form>
  </div>
</div>
