<div class="container">
  <app-home-logo></app-home-logo>
  <div class="root-box">
    <app-lined-header pageTitle="{{ 'RESET_PASSWORD.TITLE' | translate }}"></app-lined-header>
    <br />
    <br />
    <p innerHtml="{{ 'RESET_PASSWORD.TEXT' | translate }}"></p>
    <br />
    <form>
      <label class="field-title">{{ "RESET_PASSWORD.PASSWORD" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="password1" [(ngModel)]="password1" type="password" placeholder="" />
      </div>
      <app-password-validator password="{{ password1 }}" [successLevel]="5" (onPasswordValidated)="passwordValidated($event)"></app-password-validator>
      <br />
      <label class="field-title">{{ "RESET_PASSWORD.PASSWORD_REPEAT" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="password2" [(ngModel)]="password2" type="password" placeholder="" />
      </div>
      <br />
      <button (click)="doResetPassword()" [disabled]="password1 == '' || password1 != password2 || !passwordValid">
        {{ "RESET_PASSWORD.RESET_PASSWORD" | translate }}
      </button>
    </form>
    <br />
    <a (click)="doOpenLogin()">{{ "RESET_PASSWORD.LOGIN" | translate }}</a>
  </div>
</div>

<app-modal id="resetPasswordResultModal" [show]="resetPasswordResultText != ''" minWidth="350px" maxWidth="400px" (modalClosed)="modalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'RESET_PASSWORD.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ resetPasswordResultText }}"></p>
  </ng-template>
</app-modal>
