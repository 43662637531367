import { DeleteObject } from "../../models/delete";
import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { LostPassword, ResetPassword, User, ConfirmAccount, MyData, MyDataInfos, UpdatePassword } from "../../models/user";
import { Observable } from "rxjs";
import { UserCredentials, UserMerchantAccess } from "../../services/token.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiUsersService {
  private dataUrl = environment.BASE_URL + "Users";

  constructor(private http: HttpService) {}

  getMerchantAddress(): Observable<any> {
    return this.http.get(this.dataUrl + "/MerchantAddress");
  }
  getMerchantAccessInfos(merchantAccess: UserMerchantAccess) {
    return this.http.post(this.dataUrl + "/Merchant/GetMerchantAccessInfos", merchantAccess);
  }
  getMyMerchantAccessInfos() {
    return this.http.get(this.dataUrl + "/Merchant/GetMyMerchantAccessInfos");
  }
  getMerchantInfo(customerId: string) {
    return this.http.get(this.dataUrl + "/Merchant/GetMerchantInfo/" + customerId);
  }
  getMerchantMasterData() {
    return this.http.get(this.dataUrl + "/Merchant/MasterData/");
  }
  switchMerchantContext(id: any) {
    return this.http.get(this.dataUrl + "/Merchant/SwitchToMerchant/" + id);
  }
  leaveMerchantContext() {
    return this.http.get(this.dataUrl + "/Merchant/LeaveMerchantContext/");
  }
  lostPassword(data: LostPassword): Observable<any> {
    return this.http.post(this.dataUrl + "/LostPassword", JSON.stringify(data));
  }
  confirm(data: ConfirmAccount): Observable<any> {
    return this.http.post(this.dataUrl + "/Confirm", JSON.stringify(data));
  }

  getConfirmData(id: string): Observable<any> {
    return this.http.get(this.dataUrl + "/Confirm/" + id);
  }
  updateMyData(data: MyData): Observable<any> {
    return this.http.put(this.dataUrl + "/MyData", JSON.stringify(data));
  }

  updateMyDataInfos(data: MyDataInfos): Observable<any> {
    return this.http.put(this.dataUrl + "/MyData/Infos", JSON.stringify(data));
  }

  updatePassword(data: UpdatePassword): Observable<any> {
    return this.http.put(this.dataUrl + "/MyData/Password", JSON.stringify(data));
  }

  resetPassword(data: ResetPassword): Observable<any> {
    return this.http.post(this.dataUrl + "/ResetPassword", JSON.stringify(data));
  }

  register(data: User): Observable<any> {
    return this.http.post(this.dataUrl + "/Register", JSON.stringify(data));
  }
  public getAllUser(): Observable<any> {
    return this.http.get(this.dataUrl + "/GetAll");
  }

  public updateUser(data: User): Observable<any> {
    return this.http.put(this.dataUrl + "/UserManagement", data);
  }
  public addUser(data: User): Observable<any> {
    return this.http.post(this.dataUrl + "/UserManagement", data);
  }
  public deleteUser(data: DeleteObject): Observable<any> {
    return this.http.delete(this.dataUrl + "/UserManagement", data);
  }
  public getAllUserByMerchant(): Observable<any> {
    return this.http.get(this.dataUrl + "/UserManagement");
  }

  public addMultiMerchant(data: User): Observable<any> {
    return this.http.post(this.dataUrl + "/MultiMerchant", data);
  }

  public switchMultiMerchant(data: UserCredentials): Observable<any> {
    return this.http.post(this.dataUrl + "/MultiMerchant/Switch", data);
  }

  public switchUser(data: UserCredentials): Observable<any> {
    return this.http.post(this.dataUrl + "/SwitchUser", data);
  }
}
