import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import { EventLogFilterVM } from "../../models/eventLog";

@Injectable({
  providedIn: "root",
})
export class ApiEventLogService {
  private dataUrl = environment.apiConfig.uri + "EventLog";

  constructor(private http: HttpService) {}

  getEventLog(filter: EventLogFilterVM): Observable<any> {
    return this.http.postNoContentType(this.dataUrl, filter);
  }
}
