import buildInfo from "../../buildInfo.json";
export const environment = {
  production: true,
  BASE_URL: "https://dev01.aldra.multitrade.hosting/api/",
  LOG_LEVEL: 10,
  ENTRIES_PER_PAGE: 25,
  MULTIMERCHANT: true,
  APPROVEAFTERCHANGE: true,
  BUILD_NUMBER: buildInfo.buildNumber,
};
