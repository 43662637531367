import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject, of } from "rxjs";
import { catchError, debounceTime, startWith, switchMap, takeUntil } from "rxjs/operators";
import { AdminCustomerEditDialogComponent } from "src/app/dialogs/admin/admin-customer-edit-dialog/admin-customer-edit-dialog.component";
import { Customer } from "src/app/models/customer";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-admin-customers",
  templateUrl: "./admin-customers.component.html",
  styleUrls: ["./admin-customers.component.scss"],
})
export class AdminCustomersComponent implements OnInit, AfterViewInit, OnDestroy {
  customerSearch = new FormControl();
  displayedColumns: string[] = ["merchantNumber", "companyName", "email", "actions"];
  dataSource: MatTableDataSource<Customer> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  refresh = new Subject<void>();
  ngUnsubscribe = new Subject<void>();

  constructor(
    private adminService: ApiAdminService,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.dataSource.filterPredicate = (customer, filter) => {
      let term = customer.merchantNumber + customer.companyName + customer.email;
      term = term.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    this.refresh
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          return this.adminService.getCustomers().pipe(catchError(() => of(null)));
        }),
      )
      .subscribe({
        next: (data: Customer[]) => {
          this.dataSource.data = data;
          this.loaderService.hideLoader();
        },
        error: (e) => {
          console.log(e);
          this.loaderService.hideLoader();
        },
      });

    this.customerSearch.valueChanges.pipe(debounceTime(300)).subscribe((filter) => {
      this.dataSource.filter = filter.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public editCustomer(customer: Customer) {
    const dialogRef = this.matDialog.open(AdminCustomerEditDialogComponent, {
      data: customer,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh.next();
      }
    });
  }
}
