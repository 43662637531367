import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, QueryList, ViewChildren, AfterViewChecked } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit, AfterViewChecked {
  @ViewChildren("highlightCell") tableCells: QueryList<ElementRef>;
  globalSearchField = new UntypedFormControl();
  originalTableCells;
  constructor() {}

  ngOnInit(): void {}
  public getFixedDate(date: any): string {
    if (!date) return "-";
    date = new Date(date);
    if (date.getFullYear() == 1970) return "-";
    const datePipe = new DatePipe("de-DE");
    return datePipe.transform(date, "dd.MM.yyyy");
  }
  ngAfterViewChecked() {
    this.originalTableCells = new Array(...this.tableCells);
    this.highlight();
  }
  highlight() {
    const keywords = this.globalSearchField.value?.toLowerCase().split(" ");
    if (this.originalTableCells?.length > 0) {
      this.tableCells = Object.assign(this.tableCells, this.originalTableCells);
    }
    this.originalTableCells?.forEach((element, index) => {
      const cellContent = element.nativeElement?.textContent;
      const actualCell = this.tableCells.toArray()[index];
      if (actualCell && cellContent) this.tableCells.toArray()[index].nativeElement.innerHTML = this.transformContent(cellContent, keywords);
    });
  }

  transformContent(content, keywords: string[] = []) {
    let temp = content;
    const keywordString = keywords?.join("|");
    if (keywordString) {
      try {
        const regex = new RegExp(keywordString, "ig");
        temp = content.replaceAll(regex, this.wrapKeywordWithHTML("$&"));
      } catch (error) {}
    }
    return temp;
  }

  wrapKeywordWithHTML(keyword) {
    return `<span style="background-color:yellow;">${keyword}</span>`;
  }
}
