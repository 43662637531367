import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { Observable } from "rxjs";
import { Dashboard } from "../../models/dashboard";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiDashboardService {
  private dataUrl = environment.BASE_URL + "Dashboard";

  constructor(private http: HttpService) {}

  public get(): Observable<Dashboard> {
    return this.http.get(this.dataUrl);
  }
}
