import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-lined-header",
  templateUrl: "./lined-header.component.html",
  styleUrls: ["./lined-header.component.scss"],
})
export class LinedHeaderComponent implements OnInit {
  @Input() pageTitle: string = "PageTitle";

  constructor() {}

  ngOnInit() {}
}
