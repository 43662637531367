<app-modal id="autoLoginModal" [show]="showAutoLoginDialog" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.TITLE' | translate }}"></app-content-header>
    <p>{{ "LOGIN.AUTO_LOGIN" | translate }}</p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeAutoLoginDialog()">
      {{ "COMMON.NO" | translate }}
    </button>
    <button style="float: right" (click)="setAutoLoginDialog()">
      {{ "COMMON.YES" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="loginResultModal" [show]="loginResultText != ''" maxWidth="400px" (modalClosed)="resultModalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ loginResultText }}" style="word-wrap: break-word"></p>
  </ng-template>
</app-modal>

<app-modal id="multiLoginModal" [show]="showMultiLoginDialog" minWidth="350px" maxWidth="400px" height="80%">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.MULTI_LOGIN_TITLE' | translate }}"></app-content-header>

    <div class="dialog-row" *ngFor="let user of multiLoginUsers">
      <div class="cell-75-fixed">{{ user.companyName }} ({{ user.merchantNumber }})</div>
      <div class="cell-25-fixed">
        <button class="dialog-add-button" (click)="selectLogin(user)" *ngIf="multiLoginDialogDefault">Login</button>
        <button class="dialog-add-button" (click)="selectLogin(user)" *ngIf="!multiLoginDialogDefault">Wählen</button>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeMultiLoginDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="changePasswordModal" [show]="showChangePasswordModal" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ 'LOGIN.CHANGE_PASSWORD.REQUEST_TEXT' | translate }}"></p>
    <form>
      <label class="field-title">{{ "LOGIN.PASSWORD" | translate }}*:</label>
      <div class="field">
        <input class="form-control" name="password2" [(ngModel)]="password2" type="password" placeholder="" />
      </div>
      <app-password-validator password="{{ password2 }}" [successLevel]="5" (onPasswordValidated)="passwordValidated($event)"></app-password-validator>

      <label class="field-title">{{ "LOGIN.PASSWORD_REPEAT" | translate }}*:</label>
      <div class="field">
        <input class="form-control" autocomplete="new-password" name="password3" [(ngModel)]="password3" type="password" placeholder="" />
      </div>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: right" [disabled]="password2 != password3 || !passwordValid" (click)="doRequestChangePassword()">
      {{ "LOGIN.CHANGE_PASSWORD.REQUEST" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="changePasswordResultModal" [show]="changePasswordResultText != ''" maxWidth="400px" (modalClosed)="resultModalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ changePasswordResultText }}" style="word-wrap: break-word"></p>
  </ng-template>
</app-modal>

<app-modal id="lostPasswordModal" [show]="showLostPasswordModal" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.LOST_PASSWORD.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ 'LOGIN.LOST_PASSWORD.REQUEST_TEXT' | translate }}"></p>
    <form>
      <label class="field-title">{{ "LOGIN.MAIL_ADDRESS" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="mailAddressPW" [(ngModel)]="mailAddressPW" placeholder="" />
      </div>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="showLostPasswordModal = false">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" [disabled]="mailAddressPW == ''" (click)="doRequestPasswordFinal()">
      {{ "LOGIN.LOST_PASSWORD.REQUEST" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="lostPasswordResultModal" [show]="lostPasswordResultText != ''" maxWidth="400px" (modalClosed)="resultModalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.LOST_PASSWORD.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ lostPasswordResultText }}" style="word-wrap: break-word"></p>
  </ng-template>
</app-modal>

<app-modal id="createAccountModal" [show]="showCreateAccountModal" [maximize]="true" maxWidth="800px">
  <ng-template #modalContent>
    <form>
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.TITLE' | translate }}"></app-content-header>

      <div class="table">
        <div class="cell-100">
          <div>
            <label class="field-title">{{ "LOGIN.MERCHANT_ID" | translate }}*:</label>
            <label class="form-error" *ngIf="merchantId == ''">{{ "LOGIN.CREATE_ACCOUNT.FIELD_REQUIRED" | translate }}</label>
          </div>
          <div class="field">
            <input class="form-control" autocomplete="new-password" name="merchantId" [(ngModel)]="merchantId" placeholder="" />
          </div>
        </div>
        <div class="cell-50">
          <div>
            <label class="field-title">{{ "LOGIN.PASSWORD" | translate }}*:</label>
            <label class="form-error" *ngIf="password2 == ''">{{ "LOGIN.CREATE_ACCOUNT.FIELD_REQUIRED" | translate }}</label>
          </div>
          <div class="field">
            <input class="form-control" name="password2" [(ngModel)]="password2" type="password" placeholder="" />
          </div>
          <app-password-validator password="{{ password2 }}" [successLevel]="5" (onPasswordValidated)="passwordValidated($event)"></app-password-validator>
          <div class="form-error" *ngIf="password2 != '' && !passwordValid">
            {{ "LOGIN.CREATE_ACCOUNT.PASSWORD_INVALID" | translate }}
          </div>
        </div>
        <div class="cell-50">
          <div>
            <label class="field-title">{{ "LOGIN.PASSWORD_REPEAT" | translate }}*:</label>
            <label class="form-error" *ngIf="password3 == '' && password2 == ''">{{ "LOGIN.CREATE_ACCOUNT.FIELD_REQUIRED" | translate }}</label>
            <label class="form-error" *ngIf="password3 != password2">{{ "LOGIN.CREATE_ACCOUNT.PASSWORD_MISSMATCH_REQUIRED" | translate }}</label>
          </div>
          <div class="field">
            <input class="form-control" autocomplete="new-password" name="password3" [(ngModel)]="password3" type="password" placeholder="" />
          </div>
        </div>

        <div class="cell-100">
          <div>
            <label class="field-title">{{ "LOGIN.MAIL_ADDRESS" | translate }}*:</label>
            <label class="form-error" *ngIf="mailAddressAct == ''">{{ "LOGIN.CREATE_ACCOUNT.FIELD_REQUIRED" | translate }}</label>
          </div>
          <div class="field">
            <input class="form-control" autocomplete="new-password" name="mailAddressAct" [(ngModel)]="mailAddressAct" type="email" placeholder="" />
          </div>
        </div>

        <div class="cell-100">
          <div class="table">
            <div class="cell-cb">
              <app-checkbox [checked]="notificationsActivated" (onChanged)="notificationsChanged($event)"></app-checkbox>
            </div>
            <div class="cell-cb-text">
              <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.NOTIFICATIONS_ACTIVATED' | translate }}" style="word-wrap: break-word; font-size: 16px"></div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.DATA_PRIVACY' | translate }}"></app-content-header>
      <br />
      <br />
      <div class="table">
        <div class="cell-cb">
          <app-checkbox [checked]="privacyChecked" (onChanged)="privacyChanged($event)"></app-checkbox>
        </div>
        <div class="cell-cb-text">
          <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.DATA_PRIVACY_TEXT' | translate }}" style="word-wrap: break-word"></div>
          {{ attachLink() }}
        </div>
      </div>
      <br />
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.AGB' | translate }}"></app-content-header>
      <br />
      <br />
      <div class="table">
        <div class="cell-cb">
          <app-checkbox [checked]="agbChecked" (onChanged)="agbChanged($event)"></app-checkbox>
        </div>
        <div class="cell-cb-text">
          <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.AGB_TEXT' | translate }}" style="word-wrap: break-word"></div>
        </div>
      </div>
      <br />
      <div class="info">{{ "LOGIN.CREATE_ACCOUNT.REG_INFO" | translate }}</div>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="addMerchantAccountMode = false; showCreateAccountModal = false">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button
      style="float: right"
      [disabled]="mailAddressAct == '' || merchantId == '' || password2 == '' || password2 != password3 || !passwordValid || !agbChecked || !privacyChecked"
      (click)="doRequestAccount()"
    >
      {{ "LOGIN.CREATE_ACCOUNT.BUTTON" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="createAccountResultModal" [show]="createAccountResultText != ''" maxWidth="400px" (modalClosed)="resultModalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ createAccountResultText }}" style="word-wrap: break-word"></p>
  </ng-template>
</app-modal>

<app-modal id="privacyModal" [show]="showPrivacyModal" maxWidth="800px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.PRIVACY.TITLE' | translate }}"></app-content-header>
    <app-privacy-text></app-privacy-text>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: right" (click)="doClosePrivacyModal()">
      {{ "LOGIN.PRIVACY.CLOSE" | translate }}
    </button>
  </ng-template>
</app-modal>
