<app-content-header mat-dialog-title contentTitle="Händler bearbeiten"></app-content-header>
<div mat-dialog-content>
  <form [formGroup]="form" class="detail">
    <mat-form-field>
      <mat-label>Händlernummer</mat-label>
      <input matInput formControlName="merchantNumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>E-Mail</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="firstName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <input matInput formControlName="lastName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Straße</mat-label>
      <input matInput formControlName="street" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hausnummer</mat-label>
      <input matInput formControlName="houseNumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>PLZ</mat-label>
      <input matInput formControlName="zipCode" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Stadt</mat-label>
      <input matInput formControlName="city" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Land</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let c of countryOptions" [value]="c.code">
          {{ c.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Handelspartner</mat-label>
      <input matInput formControlName="companyName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Telefonnummer</mat-label>
      <input matInput formControlName="phone" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Mobilnummer</mat-label>
      <input matInput formControlName="mobile" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fax</mat-label>
      <input matInput formControlName="fax" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Web Seite</mat-label>
      <input matInput formControlName="webPage" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Region</mat-label>
      <input matInput formControlName="region" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Region Name</mat-label>
      <input matInput formControlName="regionName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Portal Client</mat-label>
      <input matInput formControlName="portalClient" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Backoffice</mat-label>
      <input matInput formControlName="backOffice" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Portal Admin Account</mat-label>
      <input matInput formControlName="portalAdminAccount" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Portal Gültig Ab</mat-label>
      <input matInput formControlName="portalValidFrom" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Portal Gültig Bis</mat-label>
      <input matInput formControlName="portalValidTo" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbruch</button>
  <button mat-button (click)="save()" [disabled]="form.invalid">Speichern</button>
</div>
