import { Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { AccountToolsComponent } from "./includes/account-tools/account-tools.component";
import { CommonModalsComponent } from "./includes/common-modals/common-modals.component";
import { BarcodeService } from "./services/barcode.service";
import { DeviceType, TokenRefreshMode } from "./services/token.service";
import { UiShareService } from "./services/ui-share.service";
declare var require: any;

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "aldra-meinaldra-portal";
  private language: string;
  @ViewChild("accountTools")
  accountTools: AccountToolsComponent;
  @ViewChild("commonModals")
  commonModals: CommonModalsComponent;

  constructor(private translate: TranslateService, public uiShareService: UiShareService, private barcodeService: BarcodeService) {
    this.language = "de"; //this.translate.getBrowserLang();
    this.translate.setDefaultLang("de");
    this.translate.use("de");

    //TODO add Manufacturer
    this.uiShareService.tokenService.deviceData = {
      token: null,
      manufacturer: "",
      model: null,
      type: DeviceType.Browser,
    };

    this.uiShareService.loadVersion();
    this.uiShareService.loadInformationalVersion();
    this.uiShareService.tokenService.setRefreshMode(TokenRefreshMode.Manual);
    this.uiShareService.tokenService.timedoutSubject.subscribe((next) => {
      if (next) {
        // timed out
        console.warn("TIMED OUT");
      }
    });
    this.uiShareService.tokenService.refreshedSubject.subscribe((next) => {
      if (next) {
        // timed out
        console.warn("REFRESHED");
        //console.log(next);
        this.uiShareService.loggedInUser = next.user;
        if (!this.uiShareService.selectedMerchant) {
          this.uiShareService.selectedMerchant = {
            merchantNumber: next.user.merchantNumber,
            companyName: next.user.companyName,
            email: next.user.email,
            uniqueID: next.user.uniqueID,
          };
        }
        this.uiShareService.handleLoginMessage();
        this.uiShareService.accountTools.reloadImageConfig();
      }
    });
  }
  ngAfterViewInit() {
    this.uiShareService.accountTools = this.accountTools;
    this.uiShareService.commonModals = this.commonModals;

    //console.log(i18nIsoCountries.getAlpha2Codes());
    //console.log(i18nIsoCountries.getName("DEU", "de"));
  }

  public closeView(): void {
    // this.barcodeService.stopScan();
  }
}
