import { Component, OnInit } from "@angular/core";
import { Setting, SettingType } from "src/app/models/setting";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable, forkJoin } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-admin-translations",
  templateUrl: "./admin-translations.component.html",
  styleUrls: ["./admin-translations.component.scss"],
})
export class AdminTranslationsComponent implements OnInit {
  availableLanguages: Array<string> = ["DE"];
  currentLanguage = "DE";
  data: Setting[];
  lngData: Setting[];
  currentTab = "DE";
  curSetting: Setting = new Setting(SettingType.Translation, "", "");

  constructor(
    private apiSettingsService: ApiSettingsService,
    private loaderService: LoaderService,
    private translate: TranslateService,
  ) {
    this.lngData = [];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiSettingsService.getAll().subscribe((data) => {
      this.data = data.filter((d) => {
        if (d.type == SettingType.Translation) {
          d.dspKey = d.key.replace(this.currentLanguage + "_", "");
          return d;
        }
      });
      this.loadLng(this.currentTab);
    });
  }
  loadLng(lng: string) {
    this.currentTab = lng;
    this.currentLanguage = lng;

    this.lngData = this.data;
  }

  loadSetting(setting: Setting) {
    this.curSetting = setting;
    this.curSetting.key = this.curSetting.key.replace(this.currentLanguage + "_", "");
  }
  newSetting() {
    this.curSetting = new Setting(SettingType.Translation, "", "");
  }
  saveSetting() {
    const calls: Observable<any>[] = [];
    if (!this.curSetting.key.startsWith(this.currentLanguage + "_")) {
      this.curSetting.key = this.currentLanguage + "_" + this.curSetting.key;
    }
    calls.push(this.apiSettingsService.update(this.curSetting));

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.newSetting();
        this.loadData();
        this.loaderService.hideTransparentLoader();
      });
    });
  }
}
