import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loaderTransparentSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private stamp: Date;

  constructor() {}

  public showLoader(): void {
    this.stamp = new Date();
    if (Capacitor.isNativePlatform()) this.loaderTransparentSubject.next(true);
    else this.loaderSubject.next(true);
  }
  public showLoaderDelayed(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.showLoader();
      setTimeout(() => {
        observer.next(true);
        observer.complete();
      }, 250);
    });
  }
  public showTransparentLoader(): void {
    this.stamp = new Date();
    this.loaderTransparentSubject.next(true);
  }
  public showTransparentLoaderDelayed(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.showTransparentLoader();
      setTimeout(() => {
        observer.next(true);
        observer.complete();
      }, 250);
    });
  }

  public hideLoader(): void {
    if (Capacitor.isNativePlatform()) {
      // delayed close to prevent flicker
      let diff = new Date().getTime() - this.stamp.getTime();
      if (diff < 500) {
        setTimeout(() => {
          this.loaderTransparentSubject.next(false);
        }, 500 - diff);
      } else {
        this.loaderTransparentSubject.next(false);
      }
    } else this.loaderSubject.next(false);
  }
  public hideTransparentLoader(): void {
    this.loaderTransparentSubject.next(false);
  }
  /*
  public showTransparentLoader(show: boolean): void {
    if (Capacitor.isNativePlatform()) {
      this.showLoader(false);
    } else this.loaderTransparentSubject.next(show);
  }
  public showLoader(show: boolean): void {
    if (Capacitor.isNativePlatform()) {
      if (show) {
        this.stamp = new Date();
        this.loaderTransparentSubject.next(show);
      } else {
        // delayed close to prevent flicker
        let diff = new Date().getTime() - this.stamp.getTime();
        if (diff < 500) {
          setTimeout(() => {
            this.loaderTransparentSubject.next(false);
          }, 500 - diff);
        } else {
          this.loaderTransparentSubject.next(show);
        }
      }
    } else this.loaderSubject.next(show);
  }
  */
  public getTransparentLoaderObservable(): Observable<boolean> {
    return this.loaderTransparentSubject;
  }
  public getLoaderObservable(): Observable<boolean> {
    return <Observable<boolean>>this.loaderSubject;
  }
}
