import { Message } from "../../models/message";
import { OnInit } from "@angular/core";

import { Component } from "@angular/core";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit {
  message: Message;

  constructor(private messageService: MessageService) {}

  public ngOnInit(): void {
    this.messageService.getMessage().subscribe((message: Message) => {
      this.message = message;
      if (this.message) {
        setTimeout(
          () => {
            this.message = null;
          },
          this.message.type !== "error" ? 3000 : 10000,
        );
      }
    });
  }
  public close(): void {
    this.message = null;
  }
}
