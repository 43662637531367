import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-edit",
  templateUrl: "./admin-edit.component.html",
  styleUrls: ["./admin-edit.component.scss"],
})
export class AdminEditComponent implements OnInit {
  @Output() editFired: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Input() mobile: boolean;
  public enabled = false;

  constructor(private uiShareService: UiShareService) {
    this.enabled = uiShareService.isAdminEditAllowed();
  }

  ngOnInit(): void {}

  doEdit() {
    if (this.editFired) {
      this.editFired.emit(true);
    }
  }
}
