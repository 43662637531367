<div class="main-cat">Präambel</div>

Die Aldra Fenster und Türen GmbH, Marschstraße/Aldra-Gewerbepark, 25704 Meldorf (nachfolgend „Aldra“) ist ein Hersteller von Fenster und Türen.<br />
<br />
Aldra betreibt ein web-basiertes Portal mit dem Namen „Mein Aldra“ (nachfolgend „Mein Aldra“). Das Portal Mein Aldra dient der exklusiven Nutzung durch Händler
, die mit Aldra einen Aldra-Fachhandelspartnervertrag abgeschlossen haben (nachfolgend „Fachhändler“). <br />
<br />
Die Fachhändler verfügen im Hinblick auf den Vertrieb und/oder den Einbau bzw. die Montage von Fenstern und Türen über langjährige Erfahrungen. Die Fachhändler
kaufen und verkaufen die von Aldra produzierten und gehandelten Produkte im eigenen Namen und auf eigene Rechnung.<br />
<br />
Über das Portal Mein Aldra besteht u.a. die Möglichkeit der Angebotsverwaltung, der Auftragsverwaltung, der Nutzung eines Reklamationsmanagements, der
Gestellverwaltung sowie generell der Kommunikation zwischen den Fachhändlern und Aldra. Über Mein Aldra soll eine höhere Verfügbarkeit und eine effizientere
Abwicklung von Aufträgen, Angeboten und Reklamationen ermöglicht werden. <br />
<br />
Diese Allgemeinen Nutzungsbedingungen sind Grundlage für die Nutzung des Portals Mein Aldra durch die Fachhändler und regeln die Nutzung des Portals im
Einzelnen wie folgt:<br />
<br />

<div class="main-cat">1. Allgemeines</div>
1.1 Für die Nutzung von Mein Aldra unter der Webadresse www.aldra.de.... gelten die nachfolgenden Bedingungen. Änderungen dieser Nutzungsbedingungen wird Aldra
den Nutzern kommunizieren. Es gilt die jeweils aktuelle Fassung der Nutzungsbedingungen, vgl. Ziffer 10.1.<br />
<br />
1.2 Aldra ermöglicht dem Fachhändler, den Zugang zu Mein Aldra. Die Nutzung des Portals erfolgt ausschließlich auf Grundlage dieser allgemeinen
Nutzungsbedingungen, deren Geltung der jeweilige Fachhändler/Nutzer bei der erstmaligen Registrierung sowie jeder Anmeldung bestätigt.<br />
<br />
1.3 Diese Allgemeinen Nutzungsbedingungen gelten für sämtliche Inhalte und Produkte, die Aldra über Mein Aldra zur Verfügung stellt, sofern für Inhalte und
Produkte nicht gesonderte Nutzungsbedingungen existieren. Gelten für Inhalte und Produkte gesonderte Nutzungsbedingungen, haben diese Vorrang vor diesen
Allgemeinen Nutzungsbedingungen.<br />
<br />

<div class="main-cat">2. Registrierung</div>
2.1 Um Mein Aldra nutzen zu können, bedarf es zunächst der Registrierung des jeweiligen Fachhändlers durch einen Systemadministrator. Der Systemadministrator
ist dabei eine natürliche Person, die im Namen und für den Fachhändler handelt und bei diesem beschäftigt ist. Der Systemadministrator ist erster
Ansprechpartner für Aldra in Bezug auf das Portal Mein Aldra. Der Systemadministrator verwaltet die Rechte des Fachhändlers in Bezug auf Mein Aldra. Der
Systemadministrator kann insbesondere für den jeweiligen Fachhändler beliebig viele Einzelnutzer („Nutzer“) registrieren, deren Rechte verwalten und die von ihm
zugefügten Nutzer auch wieder aus Mein Aldra entfernen. Jeder Systemadministrator ist gleichzeitig auch Nutzer im Sinne dieser Allgemeinen Nutzungsbedingungen.
Für die Registrierung des Systemadministrators gelten die Ziffern 2.3 – 2.6. Das Registrierungsformular für den Systemadministrator stellt Aldra... bereit.<br />
<br />
2.2 Nach der erfolgreichen Registrierung eines Systemadministrators für den Fachhändler kann der Systemadministrator weitere Mitarbeiter des Fachhändlers als
vertretungsberechtigte Nutzer mittels Registrierung einrichten. Die Registrierung dient dazu, ein Nutzerkonto des jeweiligen Nutzers für das Portal zu
erstellen. Das Registrierungsformular stellt Aldra elektronisch im Portal Mein Aldra zur Verfügung. Der Systemadministrator kann die einzelnen für den Nutzer
zugänglichen Funktionen am Portal/Nutzungsrechte verwalten, freischalten bzw. einschränken.<br />
<br />
2.3 Bei der Registrierung ist jeder Nutzer mit seinem vollständigen Namen, seiner E-Mail-Adresse, der Firma des Fachhändlers und dessen Aldra-Kundennummer
anzugeben. Der Nutzer wird bei erster Anmeldung gleichfalls aufgefordert, die Geltung dieser Allgemeinen Nutzungsbedingungen zu bestätigen. <br />
<br />
2.5 Aldra wird die Registrierung zeitnah prüfen. Liegen keine Hindernisgründe vor, erhält der in der Registrierung angegebene Nutzer eine
E-Mail-Benachrichtigung an die von ihm angegebene E-Mail-Adresse, dass ein Zugang für ihn zu Mein Aldra eingerichtet ist.<br />
<br />

<div class="main-cat">3. Nutzung</div>
3.1 Nach vollständig abgeschlossener Registrierung ist der Fachhändler (durch seine Nutzer) berechtigt, die Inhalte und Produkte von Mein Aldra zum Zwecke der
Erleichterung der Arbeit beim Fachhändler auf Grundlage dieser Nutzungsbedingungen und des Fachhandelspartnervertrages zu nutzen. Jeder Nutzer handelt bei der
Nutzung von Mein Aldra als Vertreter und Erfüllungsgehilfe des Fachhändlers.<br />
<br />
3.2 Zweck der Nutzung der Inhalte und Produkte von Mein Aldra ist insbesondere die schnelle und effiziente Abwicklung von Aufträgen und Reklamationen, die
Verbesserung der Kommunikation, die Übersicht über aktuelle Bestellungen/Aufträge sowie die Freimeldung für offene Gestelle (nachstehend auch
„Gestellfreimeldung“). Im Einzelnen:<br />
• Auftragsabwicklung: Hier können Bestellungen eingesehen und bearbeitet werden, Freigaben erteilt werden und erteilte Aufträge und deren Bearbeitungsstatus
nachvollzogen werden.<br />
• Angebotsabwicklung: Der Fachhändler erhält über Mein Aldra eine aktuelle Übersicht über seine Angebote. Über die Kommunikationsfunktion kann er Änderungen
mitteilen und verschiedene Status setzen.<br />
• Reklamationsabwicklung: Mittels QR-Code und eines Formulars können Reklamationen eingestellt, übermittelt und im Nachgang deren Abwicklung nachverfolgt
werden<br />
• Gestellfreimeldung: Mittels QR-Code und eines Formulars können leere Gestelle gegenüber Aldra angezeigt werden, damit deren Abholung organisiert werden
kann.<br />
<br />
3.3 Aldra überträgt dem Fachhändler bzw. Nutzer das nicht ausschließliche, nicht übertragbare und jederzeit widerrufliche Recht, die Inhalte und Produkte zu den
in Ziffer 3 genannten Zwecken zu benutzen. <br />
<br />
3.4 Durch das Rechtemanagement in Mein Aldra kann der Systemadministrator den jeweiligen Umfang der Nutzung der für den Fachhändler in Mein Aldra registrierten
Nutzer festlegen. So kann jeder Nutzer seinem Aufgabenbereich individuell zugeordnete Rechte an dem Portal erhalten.<br />
<br />
3.5 Der Fachhändler und die entsprechenden Nutzer trägt/tragen dafür Sorge, dass Dritte keinen unberechtigten Zugriff auf die Inhalte und/oder Produkte von Mein
Aldra haben. <br />
<br />
3.6 Jede Umarbeitung, Veränderung oder sonstige Modifikation der Inhalte und Produkte ist unzulässig. Die Inhalte dürfen nicht veräußert, vermietet,
unterlizenziert oder auf andere Art und Weise entgeltlich Dritten zur Verfügung gestellt oder anderweitig als zu unter Ziffer 3 benannten Zweck kommerziell
verwendet werden. Ein Verstoß gegen diese Bestimmung berechtigt Aldra zur fristlosen Kündigung der Nutzung und ggf. zur Geltendmachung von Schadensersatz. Ein
derartiger Verstoß kann darüber hinaus strafbar sein.<br />
<br />

<div class="main-cat">4. Pflichten des Nutzers</div>
Damit Mein Aldra mit der Vielzahl seiner Nutzer funktionieren kann, müssen von den Fachhändlern bzw. den Nutzern bestimmte Regeln eingehalten werden. Die
Nutzung des Portals erfolgt ausschließlich auf Grundlage des Fachhandelspartnervertrages sowie dieser allgemeinen Nutzungsbedingungen, deren Geltung der
jeweilige Fachhändler bzw. die von ihm autorisierten Nutzer bei der erstmaligen Registrierung sowie jeder Anmeldung bestätigen. Jeder Fachhändler und die
einzelnen Nutzer ist/sind insbesondere verpflichtet, die nachfolgend aufgeführten Regeln zu beachten:<br />
<br />
4.1 Kompatibilität/Systemvoraussetzungen:<br />
Jeder Fachhändler und dessen Nutzer hat/haben vor Nutzung von Mein Aldra sicherzustellen, dass seine/ihre Systeme mit Mein Aldra kompatibel und nutzbar sind.
Für die Nutzung ist insbesondere ein Computer oder ein mobiles Endgerät mit Internetanschluss erforderlich.<br />
<br />
<div class="main-cat">4.2 Zugang:</div>
a) Der Zugang zu Mein Aldra hat ausschließlich durch den/die jeweils (für den Fachhändler) registrierten Nutzer zu erfolgen. <br />
<br />
b) Der Fachhändler/jeder Nutzer ist verpflichtet, den Zugang zu Mein Aldra durch geeignete Maßnahmen vor dem Zugriff durch unbefugte Dritte zu sichern.<br />
<br />
c) Jeder Nutzer hat insbesondere sämtliche Passwörter und Zugangsdaten an einem geschützten Ort zu verwahren, streng vertraulich zu behandeln und darf diese
nicht an Dritte weitergegeben. Im Falle von Verstößen gegen diese Verpflichtung und/oder dem Missbrauch von Passwörtern hat der Nutzer/der Fachhändler
unverzüglich nach Kenntniserlangung sein Passwort zu ändern und/oder Aldra in Textform zu informieren. Diese Maßnahmen erfolgen insbesondere im Interesse des
Fachhändlers, da durch den Missbrauch von Zugangsdaten ansonsten rechtsverbindliche Bestellungen durch Dritte ausgelöst werden können.<br />
<br />
<div class="main-cat">4.3 Nutzung:</div>
a) Die Nutzung von Mein Aldra darf zudem nur zu den in der Präambel bzw. Ziffer 3 genannten Zwecken erfolgen. Jede Nutzung über diese Zwecke hinaus ist
untersagt.<br />
<br />
b) Neben diesen Nutzungsbedingungen gelten die Regelungen des Fachhandelspartnervertrages.<br />
<br />
c) Jeder Nutzer verpflichtet sich dazu, seine hinterlegten Angaben regelmäßig zu kontrollieren, um die Richtigkeit der über ihn in Mein Aldra gespeicherten
Informationen zu gewährleisten. Die Nutzerdaten der einzelnen Nutzer des jeweiligen Fachhändlers sind nur für den vom Fachhändler benannten Systemadministrator
sowie für Aldra bzw. die Mein Aldra nutzenden Aldra-Mitarbeiter sichtbar. Andere Nutzer – gleich ob solche des betreffenden Fachhändlers oder solche anderer
Fachhändler – haben keinen Zugriff auf diese.<br />
<br />
d) Der Nutzer/der Fachhändler wird Aldra Mängel von Mein Aldra nach deren Entdeckung unverzüglich in Textform anzuzeigen. Bei Funktionseinschränkungen erfolgt
dies unter Beschreibung der Zeit des Auftretens der „Mängel“ und der näheren Umstände.<br />
<br />
e) Jedem Nutzer bzw. dem betreffenden Fachhändler ist es untersagt, Mein Aldra auf (s)einer Internetseite zu bewerben, zu verlinken bzw. anderen zur Verfügung
zu stellen. Es sei denn, es ist zwischen den Parteien etwas anderes geregelt.<br />
<br />
f) Es ist untersagt, Mein Aldra zu kopieren, zu verändern oder Eingriffe vorzunehmen.<br />
<br />
g) Elektronische Angriffe jeglicher Art auf Mein Aldra oder auf einzelne oder alle Nutzer sind verboten.<br />
<br />
h) Es ist untersagt, das Portal Mein Aldra zu entschlüsseln, zu dekompilieren, zu zerlegen, zu verändern, zu modifizieren, weiterzuentwickeln oder zu
übersetzen, auf den Quellcode zuzugreifen oder auf dem jeweiligen Programm aufbauend weitere oder andere Programme mit derselben oder ähnlicher Funktion zu
entwickeln. Auch die Nutzung von Mein Aldra in Test- oder Entwicklungsverfahren ist nicht gestattet.<br />
<br />
i) Es ist nicht erlaubt, einen Teil von Mein Aldra oder einzelne Komponenten davon allein oder in Verbindung mit anderer Software, anderen Daten oder
Komponenten zu verwenden. <br />
<br />
j) Der Fachhändler und/oder der Nutzer kann/können Dritten die vorstehenden untersagten Tätigkeiten auch nicht wirksam erlauben.<br />
<br />
k) Der Fachhändler/der Nutzer darf Dritten, insbesondere auch anderen Fachhändlern, kein Nutzungsrecht an Mein Aldra erteilen, Dritten nicht die Nutzung bzw.
den Zugang zu Mein Aldra gegen oder ohne eine Gegenleistung zur Verfügung stellen oder es ihnen sonst zugänglich machen. Eine kommerzielle Nutzung außerhalb
dieser Allgemeinen Nutzungsbedingungen/der Fachhändlerschaft ist nicht gestattet. <br />
<br />
l) Diese Nutzungsrechte können nur durch eine gesonderte Vereinbarung erweitert werden. <br />
<br />
m) Die Abgabe von strafrechtlich relevanten oder die Rechte Dritter verletzender Inhalte durch den Fachhändler/einen Nutzer in Mein Aldra sind unzulässig.
Ebenfalls unzulässig ist die Nutzung des Portals oder seiner Inhalte für wettbewerbs- und/oder kartellrechtliche Verstöße.<br />
<br />
n) Störende Eingriffe in Mein Aldra sind verboten. Insbesondere ist es untersagt, Maßnahmen zu ergreifen, die zu einer übermäßigen Belastung des Portals oder zu
einer unzumutbaren Belästigung anderer Fachhändler/Nutzer oder Aldras führen können.<br />
<br />
o) Jeder Nutzer bzw. jeder Fachhändler hat bei der Nutzung von Mein Aldra die Einhaltung geltender Datenschutzbestimmungen sicherzustellen. <br />
<br />

<div class="main-cat">5. Verstoß gegen Nutzerpflichten</div>
Jeder Verstoß gegen diese Nutzungsbedingungen, insbesondere die in Ziffern 3. und 4. genannten Pflichten berechtigt Aldra je nach Schwere des Verstoßes zur
Abmahnung und/oder ggf. zur Sperrung des Zuganges zum Portal Mein Aldra und/oder auch zur Kündigung des Nutzerkontos/der Nutzerkonten am Portal Mein Aldra und
ggf. zur Kündigung des Aldra-Fachhandelspartnervertrages. <bR />
<br />

<div class="main-cat">6. Rechte und Pflichten von Aldra</div>
6.1 Aldra leistet Gewähr dafür, dass einer vertragsgemäßen Nutzung von Mein Aldra keine Rechte Dritter entgegenstehen. Des Weiteren trägt Aldra dafür Sorge,
dass die bereitgestellten Unterlagen oder Programme keine Rechte Dritter verletzen und keine Persönlichkeits- oder sonstigen Rechte beeinträchtigen.<br />
<br />
6.2 Aldra wird auftretende Mängel an Mein Aldra selbst oder durch Dritte in angemessener Zeit beseitigen. <br />
<br />
6.3 Aldra wird nach Kräften sicherstellen, dass der Zugang zu Mein Aldra während der Nutzungsdauer möglich ist. Eine Pflicht Aldras zur Vorhaltung des Portals
besteht jedoch nicht.<br />
<br />
6.3 Installations- und Konfigurationsleistungen sind nicht Gegenstand der Pflichten von Aldra. <br />
<br />
6.4 Soweit Aldra telefonische Unterstützung im Zusammenhang mit der Einrichtung bzw. Verwendung von Mein Aldra leistet, erfolgt diese Leistung unentgeltlich und
unter Ausschluss jeglicher Gewährleistung und Haftung.<br />
<br />
6.5 Aldra behält sich vor, Inhalte von Mein Aldra und/oder das Portal selbst jederzeit zu ändern, zu löschen oder anzupassen.<br />
<br />

<div class="main-cat">7. Bestellungen/Freigaben/Reklamationsabwicklung – Allgemeine Geschäftsbedingungen der Aldra</div>
Über Mein Aldra getätigte Bestellungen, erteilte Freigaben und/oder gemeldete Reklamationen sind verbindlich. Neben diesen Nutzungsbedingungen gelten für
Bestellungen, Freigaben und Reklamationen unsere Allgemeinen Geschäftsbedingungen (Lieferbedingungen), abrufbar unter https://www.aldra.de/agb die diesen
Allgemeinen Nutzungsbedingungen als Anlage beigefügt sind.<br />
<br />
<div class="main-cat">8. Haftung</div>
8.1 Der Fachhändler bzw. der Nutzer verwendet Mein Aldra auf eigene Gefahr. Die Parteien stimmen darin überein, dass nach dem heutigen Stand der Technik auch
bei Anwendung größter Sorgfalt Fehler nicht ausgeschlossen werden können.<br />
<br />
8.2 Aldra haftet nicht für<br />
- Programmierungs- und Bedienungsfehler an Mein Aldra, insbesondere dessen Nichtausführbarkeit,<br />
- Schäden, Kosten, Aufwendungen o.ä., die aus der Verwendung von Mein Aldra entstehen (dazu zählen auch Schäden an den Geräten des Fachhändlers, Verlust von
Daten und/oder sonstige direkte oder indirekte Schäden),<br />
- Schäden auf Grund fehlerhafter Unterstützung oder Beratung, insb. entgangenen Gewinn,<br />
- Schäden aus Ansprüchen Dritter sowie sonstigen Folgeschäden.<br />
<br />
Aldras Haftung für <br />
- Vorsatz oder grobe Fahrlässigkeit von Aldra,<br />
- arglistiges Verhalten von Aldra,<br />
- die Verletzung von Leben, Leib oder Gesundheit,<br />
- eine Haftung nach den Vorschriften des Produkthaftungsgesetzes sowie<br />
- eine Haftung im Umfang einer von Aldra übernommenen Garantie<br />
bleiben hiervon unberührt.<br />
<br />
8.3 Bei leicht fahrlässiger Verletzung einer Pflicht, die wesentlich für die Erreichung des Vertragszwecks ist (Kardinalpflicht), ist die Haftung von Aldra der
Höhe nach begrenzt auf den Schaden, der nach der Art des fraglichen Geschäfts vorhersehbar und typisch ist.<br />
<br />
8.4 Eine weitergehende Haftung von Aldra besteht nicht. Insbesondere besteht keine Haftung von Aldra für anfängliche Mängel, soweit nicht die Voraussetzungen
der Ziffern 8.2 und/oder 8.3 vorliegen. <br />
<br />
8.5 Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung der Mitarbeiter, Vertreter und Organe von Aldra. <br />
<br />
8.6 Die Haftung für Datenverlust wird auf den typischen Wiederherstellungsaufwand beschränkt, der bei regelmäßiger und gefahrentsprechender Anfertigung von
Sicherungskopien eingetreten wäre. <br />
<br />

<div class="main-cat">9. Kündigung und Vertragsbeendigung</div>
9.1 Die Nutzung von Mein Aldra kann von ... mit einer Frist von ... (...) Wochen gekündigt werden. Das Recht zur Kündigung aus wichtigem Grund gemäß § 314 BGB
bleibt unberührt.<br />
<br />
9.2 Eine Kündigung des Fachhandelspartnervertrages führt automatisch zur Kündigung der Nutzung von Mein Aldra, ohne dass es hierzu einer gesonderten Erklärung
gegenüber dem jeweiligen für den Fachhändler agierenden Nutzer bedarf.<br />
<br />
9.3 Jede Kündigung bedarf zumindest der Textform (E-Mail).<br />
<br />
9.4 Jede Vertragsbeendigung führt zum Entzug der dem Fachhändler und den für ihn registrierten Nutzern eingeräumten Nutzungsrechte. Diese fallen automatisch an
Aldra zurück. In diesem Fall wird der Zugang des Fachhändlers und dessen einzelner Nutzers zu Mein Aldra gesperrt. Das Recht zur Nutzung der Inhalte und
Produkte des Portals endet damit. <br />
<br />

<div class="main-cat">10. Sonstiges</div>
10.1 Aldra behält sich vor, Bestimmungen dieser Nutzungsbedingungen jederzeit und ohne Nennung von Gründen zu ändern. Etwaige Änderungen wird Aldra dem Nutzer
selbst bzw. dem Fachhändler über dessen/seine Nutzer mitteilen und diesem die Fortsetzung der Nutzung zu den sich ändernden Bedingungen anbieten. Diese werden
dem Nutzer per E-Mail und/oder im Rahmen von Mein Aldra übermittelt. Widerspricht der Fachhändler/Nutzer der Geltung solcher geänderter Nutzungsbedingungen, ist
Aldra befugt, dem Nutzer/Fachhändler den Zugang zu Mein Aldra zu untersagen und die Nutzung des Angebotes zu sperren.<br />
<br />
10.2 Der Schutz Ihrer Daten ist für uns ein besonderes Anliegen. Die Datenschutzerklärung des Portals Mein Aldra finden Sie unter folgendem hier sowie auf
unserer Website abrufbar.<br />
<br />
10.3 Der Nutzer/Fachhändler darf Rechte und Pflichten aus oder im Zusammenhang mit diesem Vertrag nur nach schriftlicher Zustimmung von Aldra auf Dritte
übertragen.<br />
<br />
10.4 Eine Aufrechnung ist nur gegenüber unbestrittenen oder rechtskräftig festgestellten Forderungen von Aldra statthaft. <br />
<br />
10.5 Es gelten die Allgemeinen Geschäftsbedingungen der Aldra. Allgemeine Geschäftsbedingungen des Fachhändlers finden keine Anwendung. <br />
<br />
10.6 Diese Nutzungsbedingungen unterliegen deutschem Recht.<br />
<br />
10.7 Erfüllungsort ist Meldorf. Ausschließlicher Gerichtsstand ist Meldorf, sofern jede Partei Kaufmann oder juristische Person des öffentlichen Rechts ist.
<br />
<br />
10.8 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein, berührt dies die Gültigkeit der übrigen Bestimmungen grundsätzlich nicht. Die
Vertragsparteien werden sich bemühen, anstelle der unwirksamen Bestimmung eine solche zu finden, die dem Vertragsziel rechtlich und wirtschaftlich am ehesten
gerecht wird.<br />
<br />
<br />

Stand: Monat 2020
