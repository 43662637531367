<app-modal id="privacyModal" [show]="true" maxWidth="800px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.PRIVACY.TITLE' | translate }}"></app-content-header>
    <app-privacy-text></app-privacy-text>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: right" (click)="doClosePrivacyModal()">
      {{ "LOGIN.PRIVACY.CLOSE" | translate }}
    </button>
  </ng-template>
</app-modal>
