import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/services/loader.service";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { ResetPassword } from "src/app/models/user";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RoutingService } from "src/app/services/routing.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public password1 = "";
  public password2 = "";
  passwordValid = false;
  code = "";
  resetPasswordResultText = "";
  successState = false;

  constructor(
    private router: RoutingService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private apiUsersService: ApiUsersService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("code");
  }

  passwordValidated(evt: any) {
    this.passwordValid = evt;
  }

  doResetPassword() {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let data = new ResetPassword();
      data.code = this.code;
      data.password = this.password1;
      this.apiUsersService.resetPassword(data).subscribe({
        next: (result) => {
          this.successState = true;
          this.loaderService.hideLoader();
          this.resetPasswordResultText = this.translateService.instant("RESET_PASSWORD.REQUEST_SUCCESS_TEXT");
        },
        error: (error) => {
          this.successState = false;
          this.resetPasswordResultText = this.translateService.instant("RESET_PASSWORD.REQUEST_FAILED_TEXT");
          console.error(error);
          this.loaderService.hideLoader();
        },
      });
    });
  }

  doOpenLogin() {
    this.router.forwardByUrl("/login");
  }
  modalClosed() {
    if (this.successState) {
      this.router.forwardByUrl("/login");
    }
  }
}
