import { CurrencyPipe, DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild, effect } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import saveAs from "file-saver";
import { Observable, Subject, merge, of } from "rxjs";
import { catchError, startWith, switchMap, takeUntil } from "rxjs/operators";
import { ActionArchiveOrderDialogComponent } from "src/app/dialogs/action/action-archive-order-dialog/action-archive-order-dialog.component";
import { ActionBestaetigenDialogComponent } from "src/app/dialogs/action/action-bestaetigen-dialog/action-bestaetigen-dialog.component";
import { ActionChangeOrderDialogComponent } from "src/app/dialogs/action/action-change-order-dialog/action-change-order-dialog.component";
import { ActionStornierenDialogComponent } from "src/app/dialogs/action/action-stornieren-dialog/action-stornieren-dialog.component";
import { SelectedArchivesOrderDialogComponent } from "src/app/dialogs/selected-archives-order-dialog/selected-archives-order-dialog.component";
import { CustomIonHeaderCondenseComponent } from "src/app/header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "src/app/header/custom-ion-header/custom-ion-header.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { PagerComponent } from "src/app/includes/pager/pager.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { IOrder, IOrderStatus } from "src/app/models/order";
import { IOrderActionHistory } from "src/app/models/order-action-history";
import { IOrderPosition } from "src/app/models/order-position";
import { SortType } from "src/app/models/sort";
import { Tab, TabGroup } from "src/app/models/tab";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { NativeFileService } from "src/app/services/native-file.service";
import { OrderService } from "src/app/services/order.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public environment = environment;
  @ViewChild(CustomIonHeaderCondenseComponent)
  header1: CustomIonHeaderCondenseComponent;
  @ViewChild(CustomIonHeaderComponent) header2: CustomIonHeaderComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("pager1") pager1: PagerComponent;
  @ViewChild("pager2") pager2: PagerComponent;
  dataSource = new MatTableDataSource<IOrder>([]);
  expandedRows: IOrder[] = [];
  defaultSort: SortType = {
    type: "orderNumber",
    sort: "desc",
  };

  ngUnsubscribe = new Subject<void>();
  refresh = new Subject<void>();
  loadOrders = () => this.apiOrderService.getOrders();
  loadArchive = () => this.apiOrderService.getArchive();

  public tabGroups: TabGroup[] = [
    {
      dataLoader: this.loadOrders,
      cacheLoader: () => this.uiShareService.orders,
      cacheStorer: (data) => (this.uiShareService.orders = data),
      defaultColumns: [
        "expand",
        "select",
        "orderNumber",
        "createdDate",
        "customerReference",
        "calculatedStatus",
        "calculatedAmountGross",
        "discountPercentage",
        "calculatedAmountNet",
        "plannedDeliveryWeek",
        "confirmationPdf",
        "orderImagesPdf",
        "performanceStatementPdf",
        "actions",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["select", "actions", "contact"],
      defaultSublevelColumns: [
        "expand",
        "thumbnailFileName",
        "description",
        "quantity",
        "price",
        "amount",
        "qPos",
        "status",
        "plannedDeliveryWeek",
        "suggestedDeliveryDate",
        "performanceStatementPdf",
        "change",
        "adminEdit",
      ],
      defaultReadOnlyExcludedSublevelColumns: ["change"],
      tabs: [
        {
          title: "Bestätigung",
          active: true,
          id: 0,
          tabFilter: (o) => o.calculatedStatus == IOrderStatus.WaitForConfirmation,
          excludedColumns: ["select", "calculatedStatus"],
          excludedSublevelColumns: ["suggestedDeliveryDate"],
        },
        {
          title: "Zahlung",
          id: 1,
          tabFilter: (o) => o.calculatedStatus == IOrderStatus.WaitForPayment,
          excludedColumns: ["select", "actions"],
          excludedSublevelColumns: ["suggestedDeliveryDate", "change"],
        },
        {
          title: "Produktion",
          id: 2,
          tabFilter: (o) =>
            o.calculatedStatus == IOrderStatus.Production ||
            o.calculatedStatus == IOrderStatus.Delivering ||
            o.calculatedStatus == IOrderStatus.PartialyDelivered ||
            o.calculatedStatus == IOrderStatus.PartialyInDelivery,
          excludedColumns: ["select", "actions"],
          excludedSublevelColumns: ["suggestedDeliveryDate", "change"],
        },
        {
          title: "Geliefert",
          id: 3,
          tabFilter: (o) => o.calculatedStatus == IOrderStatus.Delivered || o.calculatedStatus == IOrderStatus.PartialyDelivered,
          excludedSublevelColumns: ["change"],
        },
        {
          title: "In Bearbeitung",
          id: 4,
          tabFilter: (o) => o.calculatedStatus == IOrderStatus.Processing,
          excludedColumns: ["select", "calculatedStatus", "actions"],
          excludedSublevelColumns: ["suggestedDeliveryDate", "change"],
        },
        {
          title: "Storniert",
          id: 5,
          tabFilter: (o) => o.calculatedStatus == IOrderStatus.Cancelled,
          excludedColumns: ["select", "calculatedStatus", "actions"],
          excludedSublevelColumns: ["suggestedDeliveryDate", "change"],
        },
      ],
    },
    {
      dataLoader: this.loadArchive,
      cacheLoader: () => this.uiShareService.ordersArchive,
      cacheStorer: (data) => (this.uiShareService.ordersArchive = data),
      defaultColumns: [
        "expand",
        "orderNumber",
        "createdDate",
        "customerReference",
        "calculatedAmountGross",
        "discountPercentage",
        "calculatedAmountNet",
        "plannedDeliveryWeek",
        "confirmationPdf",
        "orderImagesPdf",
        "performanceStatementPdf",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      defaultSublevelColumns: [
        "expand",
        "description",
        "quantity",
        "price",
        "amount",
        "qPos",
        "status",
        "plannedDeliveryWeek",
        "suggestedDeliveryDate",
        "performanceStatementPdf",
      ],
      tabs: [
        {
          title: "Im Archiv",
          id: 6,
        },
      ],
    },
  ];

  currentTabGroup = this.tabGroups[0];
  currentTab = this.currentTabGroup.tabs[0];
  displayedColumns: string[] = [];
  innerDisplayedColumns: string[] = [];
  currentData: IOrder[] = [];
  mobileData: IOrder[] = [];

  constructor(
    private loaderService: LoaderService,
    private apiOrderService: ApiOrderService,
    public orderService: OrderService,
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private router: RoutingService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private nativeFileService: NativeFileService,
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.ORDER"),
        page: null,
      },
    ];
    this.dataSource.filterPredicate = (data, filter) => {
      let term =
        data.orderNumber +
        this.getFixedDate(data.createdDate) +
        this.currencyPipe.transform(data.calculatedAmountNet, "EUR", "symbol", undefined, "de-DE") +
        this.currencyPipe.transform(data.calculatedAmountGross, "EUR", "symbol", undefined, "de-DE") +
        data.discountPercentage +
        data.customerReference +
        `KW${data.plannedDeliveryWeek}` +
        this.getFixedDate(data.lastPossibleConfirmDate);
      term = term.toLowerCase().trim();
      filter = filter.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };

    effect(() => {
      this.getColumnsToDisplay(this.currentTabGroup, this.currentTab);
      this.getInnerColumnsToDisplay(this.currentTabGroup, this.currentTab);
    });
  }

  ngOnDestroy(): void {
    try {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    merge(this.header1.onReloadClickedSubject, this.header2.onReloadClickedSubject, this.refresh)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          if (this.uiShareService.ordersNeedReload) {
            this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.needReload = true)));
          }
          return this.getData(this.currentTabGroup, this.currentTab).pipe(catchError(() => of(null)));
        }),
      )
      .subscribe((data: IOrder[]) => {
        this.loaderService.hideLoader();
        this.uiShareService.ordersNeedReload = false;
        if (data) {
          data = data.sort((a, b) => (b.createdDate as any).localeCompare(a.createdDate as any)); // sort for mobile
          this.storeData(this.currentTabGroup, this.currentTab, data);
          this.currentData = data;
          data.forEach((d) => {
            d.positionsDataSource = new MatTableDataSource<IOrderPosition>(d.positions);
            d.historyDataSource = new MatTableDataSource<IOrderActionHistory>();
          });
          this.currentTabGroup.tabs.forEach((tab) => {
            tab.needReload = false;
            tab.count = this.getTabData(tab).length;
          });
          const tabData = this.getCurrentTabData();
          this.dataSource.data = tabData;
          this.pager1.resetPage(this.dataSource.filteredData.length);
          this.updateSearchCount();
        }
      });

    this.globalSearchField.valueChanges.subscribe((term) => {
      this.dataSource.filter = term;
      this.pager1.resetPage(this.dataSource.filteredData.length);
      this.updateSearchCount();
    });

    this.sort.sortChange.subscribe((e) => {
      this.uiShareService.currentOrderSortTypes[this.currentTab.id] = {
        type: e.active,
        sort: e.direction,
      };
      this.uiShareService.saveViewOptions();
    });

    this.pager1.init(1, this.pager2);
    this.pager2.init(2, this.pager1);

    // restore sort
    if (!this.uiShareService.currentOrderSortTypes[this.currentTab.id]) {
      this.uiShareService.currentOrderSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentOrderSortTypes[this.currentTab.id]);

    // restore last tab
    for (let i = 0; i < this.tabGroups.length; i++) {
      for (let j = 0; j < this.tabGroups[i].tabs.length; j++) {
        if (this.tabGroups[i].tabs[j].id == this.uiShareService.currentOrderTabId) {
          this.switchTab(this.tabGroups[i], this.tabGroups[i].tabs[j]);
          break;
        }
      }
    }
  }

  private getData(tabGroup: TabGroup, tab: Tab): Observable<any> {
    if (!tab.needReload && tabGroup.cacheLoader && tabGroup.cacheLoader()) {
      return of(tabGroup.cacheLoader());
    } else {
      return tabGroup.dataLoader();
    }
  }

  private storeData(tabGroup: TabGroup, tab: Tab, data: IOrder[]) {
    if (tabGroup.cacheStorer) {
      tabGroup.cacheStorer(data);
    }
  }

  private getCurrentTabData() {
    return this.getTabData(this.currentTab);
  }

  private getTabData(tab: Tab) {
    if (tab.tabFilter) {
      return this.currentData.filter(tab.tabFilter);
    } else {
      return this.currentData;
    }
  }

  private getCachedTabData(tabGroup: TabGroup, tab: Tab): any[] {
    if (tabGroup.cacheLoader) {
      if (tab.tabFilter) {
        return tabGroup.cacheLoader().filter(tab.tabFilter);
      } else {
        return tabGroup.cacheLoader();
      }
    }
    return [];
  }

  private updateSearchCount() {
    this.tabGroups.forEach((tabGroup) => {
      tabGroup.tabs.forEach((tab) => {
        if (this.globalSearchField.value && tab.count > 0 && !tab.needReload) {
          const tempDataSource = new MatTableDataSource();
          tempDataSource.filterPredicate = this.dataSource.filterPredicate;
          tempDataSource.data = this.getCachedTabData(tabGroup, tab);
          tempDataSource.filter = this.dataSource.filter;
          tab.searchCount = tempDataSource.filteredData.length;
        } else {
          tab.searchCount = undefined;
        }
      });
    });
  }

  public openContactForm(mode, item): void {
    this.router.forwardByUrl("/" + mode + "/order/" + item.orderNumber + "/-");
  }

  public reloadData(force = false) {
    if (force) {
      this.uiShareService.ordersNeedReload = true;
    }
    this.refresh.next();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public switchTab(tabGroup: TabGroup, tab: Tab) {
    if (tab.active) {
      return;
    }
    this.expandedRows = [];
    this.getColumnsToDisplay(tabGroup, tab);
    this.getInnerColumnsToDisplay(tabGroup, tab);
    const previousLoader = this.currentTabGroup.dataLoader;
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.active = false)));
    tab.active = true;
    this.currentTab = tab;
    this.currentTabGroup = tabGroup;
    this.uiShareService.currentOrderTabId = this.currentTab.id;
    if (!this.uiShareService.currentOrderSortTypes[this.currentTab.id]) {
      this.uiShareService.currentOrderSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentOrderSortTypes[this.currentTab.id]);
    this.uiShareService.saveViewOptions();
    if (previousLoader !== tabGroup.dataLoader) {
      this.reloadData();
    } else {
      const tabData = this.getCurrentTabData();
      this.dataSource.data = tabData;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    }
  }

  public allSelected() {
    if (this.dataSource.filteredData.length == 0) {
      return false;
    }
    return this.dataSource.filteredData.every((o) => o.selected);
  }

  public selectAll(evt: any) {
    this.dataSource.filteredData.forEach((o) => (o.selected = evt?.currentTarget?.checked));
  }

  public selectedItemCount(): number {
    return this.dataSource.data?.filter((order) => order.selected).length;
  }

  public batchArchive() {
    const ordersToArchive = this.dataSource.data.filter((order) => order.selected);
    if (!ordersToArchive) {
      return;
    }
    let dialogRef = this.dialog.open(SelectedArchivesOrderDialogComponent, {
      height: "200px",
      width: "400px",
      data: {},
    });
    dialogRef.afterClosed().subscribe({
      next: async (res) => {
        if (res === true) {
          let successMessage = "Archivierung abgeschlossen";
          for await (const order of ordersToArchive) {
            await this.apiOrderService
              .archive(order.orderNumber)
              .toPromise()
              .then(
                (res) => {},
                (e) => {
                  console.debug(`${e}`);
                },
              );
          }
          this.reloadData(true);
          setTimeout(() => {
            this.messageService.success(successMessage);
          }, 1000);
        }
      },
      error: (e) => {
        console.debug(`${e}`);
      },
    });
  }

  public openActionChangeOrderDialog(order: IOrder) {
    let dialogRef = this.dialog.open(ActionChangeOrderDialogComponent, {
      height: "430px",
      width: "850px",
      data: { item: order },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("ORDER_DIALOG.CHANGE_SUCCESS");
      let errorMessage = this.translateService.instant("ORDER_DIALOG.CHANGE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public openActionStornierenDialog(order: IOrder) {
    let var_height;
    if (order.positions.filter((p) => p.changeMessage).length > 0) {
      var_height = "200px";
    } else {
      var_height = "170px";
    }
    let dialogRef = this.dialog.open(ActionStornierenDialogComponent, {
      height: var_height,
      width: "400px",
      data: { item: order },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("ORDER_DIALOG.CANCEL_SUCCESS");
      let errorMessage = this.translateService.instant("ORDER_DIALOG.CANCEL_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public openActionBestaetigenDialog(order: IOrder) {
    let dialogRef = this.dialog.open(ActionBestaetigenDialogComponent, {
      height: "350px",
      width: "450px",
      data: { item: order },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("ORDER_DIALOG.APPROVE_SUCCESS");
      let errorMessage = this.translateService.instant("ORDER_DIALOG.APPROVE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public openActionArchiveDialog(order: IOrder) {
    let dialogRef = this.dialog.open(ActionArchiveOrderDialogComponent, {
      height: "auto",
      width: "400px",
      data: { item: order },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let successMessage = this.translateService.instant("ORDER_DIALOG.ARCHIVE_SUCCESS");
      let errorMessage = this.translateService.instant("ORDER_DIALOG.ARCHIVE_ERROR");
      if (result == true) {
        this.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }

  public hasPerformaceStatementPdf(order: IOrder): boolean {
    return order.positions.some((pos) => pos.hasPerformanceStatementPdf);
  }

  public getOrderImagesPdf(order: IOrder) {
    let request;
    if (this.currentTab.id === 6) {
      request = this.orderService.getOrderArchiveImagesPdf(order.orderNumber);
    } else {
      request = this.orderService.getOrderImagesPdf(order.orderNumber);
    }
    request.subscribe({
      next: (res) => {
        if (Capacitor.isNativePlatform()) {
          this.nativeFileService.openPdf(res, {
            fileName: `Auftrag_${order.orderNumber}`,
          });
        } else {
          saveAs(res, `Auftrag_${order.orderNumber}`);
        }
      },
      error: (e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.messageService.warn("Daten werden aktuell noch verarbeitet, bitte versuchen Sie es später erneut.");
        } else {
          this.messageService.error("Zugehörige Bilder konnten nicht geladen werden.");
        }
      },
    });
  }

  public getColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.columns || tabGroup.defaultColumns;

    if (tabGroup.defaultReadOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedColumns.includes(c));
    }
    if (tab.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedColumns.includes(c));
    }
    if (!this.uiShareService.showPrices()) {
      columns = columns.filter((c) => !["calculatedAmountNet", "calculatedAmountGross", "discountPercentage"].includes(c));
    }
    if (tab.excludedColumns) {
      columns = columns.filter((c) => !tab.excludedColumns.includes(c));
    }
    this.displayedColumns = columns;
  }

  public getInnerColumnsToDisplay(tabGroup: TabGroup, tab: Tab) {
    let columns = tab.sublevelColumns || tabGroup.defaultSublevelColumns;

    if (tabGroup.defaultReadOnlyExcludedSublevelColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedSublevelColumns.includes(c));
    }
    if (tab.readOnlyExcludedSublevelColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedSublevelColumns.includes(c));
    }
    if (!this.uiShareService.showPrices()) {
      columns = columns.filter((c) => !["price", "amount"].includes(c));
    }
    if (tab.excludedSublevelColumns) {
      columns = columns.filter((c) => !tab.excludedSublevelColumns.includes(c));
    }
    if (!this.uiShareService.isAdminEditAllowed()) {
      columns = columns.filter((c) => "adminEdit" !== c);
    }
    this.innerDisplayedColumns = columns;
  }

  private doSort(sortType: SortType) {
    sortType.sort = sortType.sort.replace("ASC", "asc").replace("DESC", "desc").replace("DSC", "desc") as any; // for legacy support
    if (!sortType.sort) {
      sortType.sort = this.defaultSort.sort;
    }
    if (!sortType.type) {
      sortType.type = this.defaultSort.type;
    }
    this.sort.sort({ id: null, start: sortType.sort, disableClear: true });
    this.sort.sort({ id: sortType.type, start: sortType.sort, disableClear: true });
  }

  public onPageChanged(pagerData: any): void {
    this.mobileData = this.dataSource.filteredData.slice(pagerData.start, pagerData.end);
  }

  public isPastDate(date: string | Date) {
    if (!date) {
      return false;
    }
    return new Date(date) < new Date();
  }

  public hasImages(row: IOrder) {
    return row.positions.findIndex((pos) => pos.thumbnailFileName !== null) > -1;
  }
}
