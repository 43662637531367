import { Component, ElementRef, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/models/user";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";
import { LogService } from "src/app/services/log.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserCredentials } from "src/app/services/token.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-account-tools",
  templateUrl: "./account-tools.component.html",
  styleUrls: ["./account-tools.component.scss"],
})
export class AccountToolsComponent implements OnInit {
  showLostPasswordModal = false;
  showCreateAccountModal = false;
  addMerchantAccountMode = false;
  showChangePasswordModal = false;
  showPrivacyModal = false;
  showMultiLoginDialog = false;
  multiLoginDialogDefault = false;
  loginResultText = "";
  createAccountResultText = "";
  changePasswordResultText = "";
  passwordValid = false;
  privacyChecked = false;
  agbChecked = false;
  lostPasswordResultText = "";
  notificationsActivated = true;

  mailAddressPW = "";
  mailAddressAct = "";
  merchantId = "";
  password2 = "";
  password3 = "";
  multiLoginUsers: [User];
  tmpPassword = "";
  //firstName = "first";
  //lastName = "last";
  //companyName = "testcompany";

  showAutoLoginDialog = false;
  private timer;

  constructor(
    private router: RoutingService,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private apiUsersService: ApiUsersService,
    private apiSettingsService: ApiSettingsService,
    private translateService: TranslateService,
    private logService: LogService,
    private el: ElementRef
  ) {
    this.timer = setTimeout(() => {
      this.reloadImageConfig();
    }, 10 * 1000);
  }

  ngOnInit() {}

  public tryAutoLogin() {
    this.logService.debug("AccountTools.tryAutoLogin()");
    this.tryTokenLogin();
  }

  private tryTokenLogin() {
    const exists = this.uiShareService.tokenService.cryptionService.exists("jwtRefreshToken");
    if (exists) {
      // token set
      this.logService.debug("AccountTools.tryTokenLogin() found token");
      // try auto login by refreshing token
      this.loaderService.showLoaderDelayed().subscribe(() => {
        const storageData = this.uiShareService.tokenService.cryptionService.get("jwtEmail");
        this.uiShareService.tokenService.refresh(storageData).subscribe({
          next: (token) => {
            this.logService.debug("HomeCards.ngOnInit() Got new token");
            if (this.uiShareService.tokenService.cryptionService.exists("jwtAdminToken")) {
              this.uiShareService.adminToken = this.uiShareService.tokenService.cryptionService.get("jwtAdminToken");
            }
            this.uiShareService.loggedIn = true;
            this.uiShareService.loggedInChanged();
            this.uiShareService.multiMerchantLinks = token.multiMerchantLinks;
            this.loaderService.hideLoader();
            this.askForAutoLogin();
            this.router.forwardByUrl("/home");
          },
          error: (error) => {
            this.logService.error("HomeCards.ngOnInit() Failed to get new token");
            //this.loadRegistrationSettings();
            this.loaderService.hideLoader();
          },
        });
      });
    } else {
      this.logService.debug("AccountTools.tryTokenLogin() no token found");
    }
  }

  public switchAccount() {
    this.logService.debug("AccountTools.switchAccount()");
    this.uiShareService.commonModals.showSwitchAccountModal().subscribe((account) => {
      if (Array.isArray(account)) {
        this.logService.debug("AccountTools.switchAccount() is array");
        this.uiShareService.multiMerchantLinks = account;
        this.switchAccount();
      } else {
        this.logService.debug("AccountTools.switchAccount() single account");
        // get user data
        this.uiShareService.selectedMerchant = account.otherAccount;

        this.loaderService.showLoaderDelayed().subscribe(() => {
          let user = new UserCredentials();
          user.email = "-";
          user.uniqueID = account.otherAccount.uniqueID;
          user.password = "-";
          user.device = this.uiShareService.tokenService.deviceData;
          this.apiUsersService.switchMultiMerchant(user).subscribe((token) => {
            this.uiShareService.tokenService.setNewToken(token);
            this.uiShareService.loggedInUser = token.user;
            this.uiShareService.selectedMerchant = {
              merchantNumber: token.user.merchantNumber,
              companyName: token.user.companyName,
              email: token.user.email,
              uniqueID: token.user.uniqueID,
            };
            this.uiShareService.multiMerchantLinks = token.multiMerchantLinks;
            this.uiShareService.ordersNeedReload = true;
            this.uiShareService.offersNeedReload = true;
            this.uiShareService.reclamationsNeedReload = true;
            this.uiShareService.framesNeedReload = true;
            this.uiShareService.shipmentNotesNeedReload = true;
            this.loaderService.hideLoader();
            this.router.forwardByUrl("/home");
          });
        });
        //this.uiShareService.loggedInUser = account.otherAccount;
        //this.router.forwardByUrl("/home");
      }
    });
  }

  public switchMerchantAccess(hideClose?: boolean) {
    this.logService.debug("AccountTools.switchMerchantAccess()");
    this.uiShareService.commonModals.showSwitchMerchantContextModal(hideClose).subscribe((res) => {});
  }

  public closeMultiLoginDialog() {
    this.showMultiLoginDialog = false;
    this.tmpPassword = "";
    if (!this.multiLoginDialogDefault) {
      this.uiShareService.commonModals.switchAccountAddCheckLoginDataCanceled();
    }
  }
  public showMultiLoginDialogForMMAdd(accounts: [User]) {
    this.multiLoginUsers = accounts;
    this.tmpPassword = "";
    this.multiLoginDialogDefault = false;
    this.showMultiLoginDialog = true;
  }
  public selectLogin(user: User) {
    if (this.multiLoginDialogDefault) {
      this.doLoginById(user.uniqueID, this.tmpPassword);
    } else {
      this.showMultiLoginDialog = false;
      this.uiShareService.commonModals.switchAccountAddCheckLoginData(user.uniqueID);
    }
  }
  public doLoginById(uniqueId: string, password: string) {
    this.logService.debug("AccountTools.doLoginById()");
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.tmpPassword = "";
      let user = new User();
      user.email = "-";
      user.uniqueID = uniqueId.trim();
      user.password = password.trim();
      this.uiShareService.tokenService.login(user).subscribe({
        next: (result) => {
          this.logService.debug("AccountTools.doLoginById() logged in");
          this.showMultiLoginDialog = false;
          this.uiShareService.adminToken = null;
          this.setTokenFromLogin(result);
          this.uiShareService.setTouchIDData(uniqueId, password.trim());
        },
        error: (error) => {
          //TODO check, better error message
          this.logService.debug("AccountTools.doLoginById() failed to login");
          console.error(error);
          this.showMultiLoginDialog = false;
          this.processErrorFromLogin(error);
        },
      });
    });
  }
  doLogin(mailAddress: string, password: string) {
    this.logService.debug("AccountTools.doLogin()");
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let user = new User();
      user.email = mailAddress.trim();
      user.password = password.trim();
      this.uiShareService.tokenService.login(user).subscribe({
        next: (result) => {
          if (Array.isArray(result)) {
            // multiple users!
            this.multiLoginDialogDefault = true;
            this.multiLoginUsers = result as [User];
            this.tmpPassword = password.trim();
            this.showMultiLoginDialog = true;
            this.loaderService.hideLoader();
          } else {
            this.uiShareService.adminToken = null;
            // single token
            this.setTokenFromLogin(result);
            this.uiShareService.setTouchIDData(result.user.uniqueID, password.trim());
          }
        },
        error: (error) => {
          //TODO check, better error message
          console.error(error);
          this.processErrorFromLogin(error);
        },
      });
    });
  }
  private processErrorFromLogin(error: any) {
    if (error.error) {
      if (error.error == "ACCOUNT_NOT_CONFIRMED") {
        this.loginResultText = this.translateService.instant("LOGIN.LOGIN.REQUEST_ERROR_NOT_CONFIRMED_TEXT");
      } else if (error.error == "CREDENTIALS_INVALID") {
        this.loginResultText = this.translateService.instant("LOGIN.LOGIN.REQUEST_ERROR_CREDENTIALS_INVALID_TEXT");
      } else {
        this.loginResultText = this.translateService.instant("LOGIN.LOGIN.REQUEST_ERROR_TEXT");
      }
    } else {
      this.loginResultText = this.translateService.instant("LOGIN.LOGIN.REQUEST_ERROR_TEXT");
    }
    if (environment.LOG_LEVEL == 10) {
      this.loginResultText += "<br><br>" + JSON.stringify(error) + "<br><br>" + environment.BASE_URL;
    }
    this.loaderService.hideLoader();
  }
  private setTokenFromLogin(result: any) {
    this.uiShareService.tokenService.cryptionService.set("jwtEmail", result.user.email);
    this.uiShareService.loggedIn = true;
    this.uiShareService.loggedInUser = result.user;
    this.uiShareService.selectedMerchant = {
      merchantNumber: result.user.merchantNumber,
      companyName: result.user.companyName,
      email: result.user.email,
      uniqueID: result.user.uniqueID,
    };
    this.uiShareService.multiMerchantLinks = result.multiMerchantLinks;
    this.uiShareService.ordersNeedReload = true;
    this.uiShareService.offersNeedReload = true;
    this.uiShareService.reclamationsNeedReload = true;
    this.uiShareService.framesNeedReload = true;
    this.uiShareService.shipmentNotesNeedReload = true;
    this.uiShareService.loggedInChanged();
    this.uiShareService.handleLoginMessage();
    this.loaderService.hideLoader();
    this.reloadImageConfig();
    this.gotoHomeOrRedirect();
    this.askForAutoLogin();
  }
  private askForAutoLogin() {
    if (!localStorage.getItem("autoLogin")) {
      this.showAutoLoginDialog = true;
    }
  }
  public setAutoLoginDialog() {
    this.showAutoLoginDialog = false;
    localStorage.setItem("autoLogin", "true");
    this.uiShareService.gvlSelectionShowed = false;
    this.uiShareService.activeMerchantAccess$.next(this.uiShareService.userMerchantAccess);
  }
  public closeAutoLoginDialog() {
    this.showAutoLoginDialog = false;
    if (localStorage.getItem("autoLogin")) localStorage.removeItem("autoLogin");
    this.uiShareService.gvlSelectionShowed = false;
    this.uiShareService.activeMerchantAccess$.next(this.uiShareService.userMerchantAccess);
  }

  private attachRunning = false;
  attachLink() {
    if (!this.attachRunning) {
      this.attachRunning = true;
      let that = this;
      setTimeout(() => {
        this.attachRunning = false;
        if (this.el.nativeElement.querySelector(".privacyLink1") && !this.el.nativeElement.querySelector(".privacyLink1").getAttribute("attached")) {
          this.el.nativeElement.querySelector(".privacyLink1").setAttribute("attached", "1");
          this.el.nativeElement.querySelector(".privacyLink1").addEventListener("click", function () {
            that.uiShareService.accountTools.showPrivacyModal = true;
          });
          this.el.nativeElement.querySelector(".privacyLink2").addEventListener("click", function () {
            that.uiShareService.accountTools.showPrivacyModal = true;
          });
        }
      }, 500);
    }
  }

  gotoUnprotectedRedirect() {
    if (sessionStorage.getItem("redirect")) {
      let redirect = sessionStorage.getItem("redirect");
      this.logService.debug("AccountTools.gotoUnprotectedRedirect() " + redirect);
      console.warn(redirect);
      if (redirect.startsWith("articleDetails")) {
        // goto mydata
        sessionStorage.removeItem("redirect");
        const number = redirect.replace("articleDetails:", "");
        this.router.forwardByUrl("/article/" + number);
      }
    }
  }

  gotoHomeOrRedirect() {
    if (sessionStorage.getItem("redirect")) {
      let redirect = sessionStorage.getItem("redirect");
      this.logService.debug("AccountTools.gotoUnprotectedRedirect() " + redirect);
      if (redirect.startsWith("order")) {
        // goto order
        const number = redirect.replace("order:", "");
        sessionStorage.removeItem("redirect");
        this.router.forwardByUrl("/order", { state: { search: number } });
      } else if (redirect.startsWith("offer")) {
        // goto offer
        const number = redirect.replace("offer:", "");
        sessionStorage.removeItem("redirect");
        this.router.forwardByUrl("/offer", { state: { search: number } });
      } else if (redirect.startsWith("shipment")) {
        // goto offer
        const number = redirect.replace("shipment:", "");
        sessionStorage.removeItem("redirect");
        this.router.forwardByUrl("/shipment-notes", {
          state: { search: number },
        });
      } else if (redirect.startsWith("mydata")) {
        // goto mydata
        sessionStorage.removeItem("redirect");
        this.router.forwardByUrl("/mydata");
      } else if (redirect.startsWith("articleDetails")) {
        // goto article details
        sessionStorage.removeItem("redirect");
        const number = redirect.replace("articleDetails:", "");
        this.router.forwardByUrl("/article/" + number);
      } else {
        sessionStorage.removeItem("redirect");
        // this.router.forwardByUrl("/home");
        this.router.forwardByUrl(redirect);
      }
    } else {
      this.router.forwardByUrl("/home");
    }
  }

  /**
   * Called when a result modal has been closed. Modals must be resetted.
   */
  resultModalClosed() {
    this.logService.debug("AccountTools.resultModalClosed()");
    this.createAccountResultText = "";
    this.lostPasswordResultText = "";
    this.changePasswordResultText = "";
    this.loginResultText = "";
    this.showChangePasswordModal = false;
  }
  doClosePrivacyModal() {
    this.logService.debug("AccountTools.doClosePrivacyModal()");
    this.showPrivacyModal = false;
  }
  doRequestChangePassword() {
    this.logService.debug("AccountTools.doRequestChangePassword()");
    this.showChangePasswordModal = false;
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiUsersService
        .updatePassword({
          password: this.password2,
          id: this.uiShareService.loggedInUser.id,
        })
        .subscribe({
          next: (result) => {
            //TODO success message
            this.uiShareService.loggedInUser.needsPasswordChange = false;
            this.loaderService.hideLoader();
            this.changePasswordResultText = this.translateService.instant("LOGIN.CHANGE_PASSWORD.REQUEST_SUCCESS_TEXT");
          },
          error: (error) => {
            this.loaderService.hideLoader();
            if (error.error) {
              if (error.error == "PASSWORD_INVALID") {
                this.changePasswordResultText = this.translateService.instant("LOGIN.CHANGE_PASSWORD.REQUEST_ERROR_PASSWORD_INVALID_TEXT");
              } else {
                this.changePasswordResultText = this.translateService.instant("LOGIN.CHANGE_PASSWORD.REQUEST_ERROR_TEXT");
              }
            } else {
              this.changePasswordResultText = this.translateService.instant("LOGIN.CHANGE_PASSWORD.REQUEST_ERROR_TEXT");
            }
            if (environment.LOG_LEVEL == 10) {
              this.changePasswordResultText += "<br><br>" + JSON.stringify(error);
            }
          },
        });
    });
  }

  doRequestPasswordFinal() {
    this.logService.debug("AccountTools.doRequestPasswordFinal()");
    this.showLostPasswordModal = false;
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiUsersService.lostPassword({ email: this.mailAddressPW }).subscribe({
        next: (result) => {
          //TODO success message
          this.loaderService.hideLoader();
          this.lostPasswordResultText = this.translateService.instant("LOGIN.LOST_PASSWORD.REQUEST_SUCCESS_TEXT");
        },
        error: (error) => {
          this.loaderService.hideLoader();
          if (error.error) {
            if (error.error == "ACCOUNT_NOT_CONFIRMED") {
              this.lostPasswordResultText = this.translateService.instant("LOGIN.LOST_PASSWORD.REQUEST_ERROR_UNCONFIRMED_TEXT");
            } else {
              this.lostPasswordResultText = this.translateService.instant("LOGIN.LOST_PASSWORD.REQUEST_ERROR_TEXT");
            }
          } else {
            this.lostPasswordResultText = this.translateService.instant("LOGIN.LOST_PASSWORD.REQUEST_ERROR_TEXT");
          }
          if (environment.LOG_LEVEL == 10) {
            this.lostPasswordResultText += "<br><br>" + JSON.stringify(error);
          }
        },
      });
    });
  }

  passwordValidated(evt: any) {
    this.passwordValid = evt;
  }

  doRequestAccount() {
    this.logService.debug("AccountTools.doRequestAccount()");
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let user = new User();
      user.activateNotifications = this.notificationsActivated;
      user.merchantNumber = this.merchantId.trim();
      user.email = this.mailAddressAct.trim().toLowerCase();
      user.password = this.password2.trim();
      user.linkMerchantID = this.uiShareService.accountTools.addMerchantAccountMode ? this.uiShareService.loggedInUser.id : null;
      this.apiUsersService.register(user).subscribe({
        next: (result) => {
          this.showCreateAccountModal = false;
          this.notificationsActivated = true;
          this.loaderService.hideLoader();
          this.createAccountResultText = this.translateService.instant("LOGIN.CREATE_ACCOUNT.RESULT_SUCCESS_TEXT");
          this.uiShareService.accountTools.addMerchantAccountMode = false;
        },
        error: (error) => {
          if (error.error && !error.error.type) {
            if (error.error == "REGISTER_DATA_INVALID") {
              this.createAccountResultText = this.translateService.instant("LOGIN.CREATE_ACCOUNT.RESULT_INVALID_TEXT");
            } else if (error.error == "EMAIL_ADDRESS_IN_USE" || error.error == "MERCHANT_NUMBER_IN_USE") {
              this.createAccountResultText = this.translateService.instant("LOGIN.CREATE_ACCOUNT.RESULT_INUSE_TEXT");
            } else if (error.error == "ACCOUNT_NOT_OPENED") {
              this.createAccountResultText = this.translateService.instant("LOGIN.CREATE_ACCOUNT.RESULT_NOT_OPENED_TEXT");
            } else {
              this.createAccountResultText = error.error;
            }
          } else {
            this.createAccountResultText = this.translateService.instant("LOGIN.CREATE_ACCOUNT.RESULT_ERROR_TEXT");
          }
          if (environment.LOG_LEVEL == 10) {
            this.createAccountResultText += "<br><br>" + JSON.stringify(error);
          }
          console.error(error.error);
          this.loaderService.hideLoader();
        },
      });
    });
  }

  notificationsChanged(evt) {
    this.logService.debug("AccountTools.notificationsChanged()");
    this.notificationsActivated = evt;
  }
  privacyChanged(evt) {
    this.logService.debug("AccountTools.privacyChanged()");
    this.privacyChecked = evt;
  }
  agbChanged(evt) {
    this.logService.debug("AccountTools.agbChanged()");
    this.agbChecked = evt;
  }

  public reloadImageConfig(): void {
    if (this.uiShareService.loggedIn) {
      if (localStorage.getItem("imageConfigLoadedDate")) {
        const dt = new Date();
        dt.setTime(Number(localStorage.getItem("imageConfigLoadedDate")));
        const diff = new Date().getTime() - dt.getTime();
        const minutes = diff / 1000 / 60;
        if (minutes >= 10) {
          this.postReloadImageConfig();
        }
      } else {
        this.postReloadImageConfig();
      }
    }
  }
  private postReloadImageConfig() {
    this.apiSettingsService.getImageConfig().subscribe((next) => {
      localStorage.setItem("imageConfig", JSON.stringify(next));
      localStorage.setItem("imageConfigLoadedDate", "" + new Date().getTime());
    });
  }
}
