import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoaderService } from "../services/loader.service";
import { RoutingService } from "../services/routing.service";
import { UiShareService } from "../services/ui-share.service";

@Injectable({
  providedIn: "root",
})
export class RouteGuard {
  constructor(private router: RoutingService, private loaderService: LoaderService, private uiShareService: UiShareService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.uiShareService.tokenService.recordUserAction();
    this.uiShareService.routeChanged.next(true);
    if (next.routeConfig.path == "logout") {
      if (localStorage.getItem("lastLoginMessage")) localStorage.removeItem("lastLoginMessage");
      if (localStorage.getItem("autoLogin")) localStorage.removeItem("autoLogin");
      this.uiShareService.loginMessageShown = false;

      this.uiShareService.adminToken = null;

      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.uiShareService.tokenService.logout(this.uiShareService.loggedInUser).subscribe({
          next: (nextLogout) => {
            this.loaderService.hideLoader();
            this.uiShareService.tokenService.cryptionService.del("tid");
            this.uiShareService.loggedIn = false;
            this.uiShareService.loggedInUser = null;
            this.uiShareService.ordersNeedReload = true;
            this.uiShareService.offersNeedReload = true;
            this.uiShareService.reclamationsNeedReload = true;
            this.uiShareService.framesNeedReload = true;
            this.uiShareService.shipmentNotesNeedReload = true;
            this.uiShareService.loggedInChanged();
            this.router.forwardByUrl("/login");
          },
          error: (errorLogout) => {
            this.loaderService.hideLoader();
            this.router.forwardByUrl("/login");
          },
        });
      });
      return false;
    } else {
      if (next.routeConfig.path != "login") {
        if (next.routeConfig.path.startsWith("redirect")) {
          console.warn("SET REDIRECT");
          sessionStorage.setItem("redirect", next.params["data"]);
        }

        if (next.routeConfig.path.startsWith("article/")) {
          // allow root if no token available

          if (!this.uiShareService.tokenService.cryptionService.existsInstant("jwtRefreshToken")) return true;
          console.warn("SET REDIRECT TO ARTICLE");
          sessionStorage.setItem("redirect", "articleDetails:" + next.params["elementId"]);
          //http://localhost:4200/Article/90000012X02
        }

        //console.log(next.routeConfig.path);
        if (!this.uiShareService.loggedIn) {
          console.log("RouteGuard() need to route to login! (" + next.routeConfig.path + ")");
          if (!sessionStorage.getItem("redirect")) {
            sessionStorage.setItem("redirect", state.url);
          }
          this.router.forwardByUrl("/login");
          return false;
        } else {
          if (this.uiShareService.loggedInUser.needsPasswordChange) {
            console.warn("pass change");
            this.uiShareService.accountTools.showChangePasswordModal = true;
          } else {
            if (next.routeConfig.path == "user-administration") {
              if (this.uiShareService.loggedInUser.type != 4 && this.uiShareService.loggedInUser.type != 0) {
                this.router.forwardByUrl("/home");
                return false;
              }
            }
          }
        }
      }
      this.handleReloading(next.routeConfig.path);
      return true;
    }
  }

  private handleReloading(path: string): void {
    if (!path.startsWith("order")) this.uiShareService.ordersNeedReload = true;
    if (!path.startsWith("offer")) this.uiShareService.offersNeedReload = true;
    if (!path.startsWith("reclamation")) this.uiShareService.reclamationsNeedReload = true;
    if (!path.startsWith("frame")) this.uiShareService.framesNeedReload = true;
  }
}
