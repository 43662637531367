import { Component, OnInit } from "@angular/core";
import { ApiEventLogService } from "src/app/services/api/api-event-log.service";
import { EventLog, EventLogType, EventLogState, EventLogFilterVM, EventLogFilterType, EventLogFilterState } from "src/app/models/eventLog";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-admin-event-log",
  templateUrl: "./admin-event-log.component.html",
  styleUrls: ["./admin-event-log.component.scss"],
})
export class AdminEventLogComponent implements OnInit {
  public eventLog: EventLog[];
  public filter: EventLogFilterVM = {
    type: EventLogFilterType.All,
    state: EventLogFilterState.All,
    hours: 1,
    filter: "",
    withLoginLogout: false,
  };

  constructor(private apiEventLogService: ApiEventLogService, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.reload();
  }
  public loginLogoutChanged(evt: any) {
    this.filter.withLoginLogout = evt;
  }
  public reload(): void {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.apiEventLogService.getEventLog(this.filter).subscribe((data) => {
        this.eventLog = data;
        this.loaderService.hideTransparentLoader();
      });
    });
  }
  public showUserData(type: EventLogType): boolean {
    if (type == EventLogType.UserConfirmation) return true;
    if (type == EventLogType.UserLogin) return true;
    if (type == EventLogType.UserLostPassword) return true;
    if (type == EventLogType.UserRegistration) return true;
    if (type == EventLogType.UserResetPassword) return true;
    if (type == EventLogType.OfferApprove) return true;
    if (type == EventLogType.OrderApprove) return true;
    if (type == EventLogType.OfferArchive) return true;
    if (type == EventLogType.OrderArchive) return true;
    return false;
  }
  public getState(state: EventLogState): string {
    if (state == EventLogState.Error) return "Error";
    if (state == EventLogState.Warning) return "Warn";
    return "Info";
  }
  public getType(type: EventLogType): string {
    if (type == EventLogType.UserConfirmation) return "UserConfirmation";
    if (type == EventLogType.UserLogin) return "UserLogin";
    if (type == EventLogType.UserLostPassword) return "UserLostPassword";
    if (type == EventLogType.UserRegistration) return "UserRegistration";
    if (type == EventLogType.UserResetPassword) return "UserResetPassword";
    if (type == EventLogType.OfferAttachmentImport) return "OfferAttachmentImport";
    if (type == EventLogType.OfferImport) return "OfferImport";
    if (type == EventLogType.OfferPositionImport) return "OfferPositionImport";
    if (type == EventLogType.OrderAttachmentImport) return "OrderAttachmentImport";
    if (type == EventLogType.OrderImport) return "OrderImport";
    if (type == EventLogType.OrderPositionImport) return "OrderPositionImport";
    if (type == EventLogType.OfferApprove) return "OfferApprove";
    if (type == EventLogType.OrderApprove) return "OrderApprove";
    if (type == EventLogType.Mailing) return "Mailing";
    if (type == EventLogType.Cleanup) return "Cleanup";
    if (type == EventLogType.OfferArchive) return "OfferArchive";
    if (type == EventLogType.OrderArchive) return "OrderArchive";
    if (type == EventLogType.MultiMerchantAdd) return "MultiMerchantAdd";
    if (type == EventLogType.MultiMerchantSwitch) return "MultiMerchantSwitch";
    if (type == EventLogType.ShipmentImport) return "ShipmentImport";
    if (type == EventLogType.FrameImport) return "FrameImport";
    if (type == EventLogType.ReclamationImport) return "ReclamationImport";
    if (type == EventLogType.Import) return "Import";
    if (type == EventLogType.Export) return "Export";
    if (type == EventLogType.CustomerWhitelist) return "CustomerWhitelist";
    if (type == EventLogType.ShipmentAttachmentImport) return "ShipmentAttImport";
    if (type == EventLogType.ReclamationAttachmentImport) return "ReclamationAttImport";
    if (type == EventLogType.OfferParking) return "OfferParking";

    return "" + type;
  }
}
