import { MatTableDataSource } from "@angular/material/table";
import { IOrderActionHistory } from "./order-action-history";
import { IOrderPosition } from "./order-position";

export enum IOrderStatus {
  WaitForConfirmation, // 0
  WaitForInternalRelease, // 1
  WaitForPayment, // 2

  Production, // 3
  Delivering, // 4
  Delivered, // 5
  Cancelled, // 6

  Processing, // 7 internal
  PartialyDelivered, // 8 internal
  PartialyInDelivery, // 9 internal
}
export interface IOrder {
  id: string;
  merchantNumber: string;
  zdemId: string;
  orderNumber: string;
  calculatedStatus: IOrderStatus;
  positions: IOrderPosition[];
  lastPossibleConfirmDate: Date;
  firstPossibleDeliveryDate: Date;
  createdDate: Date;
  canChangeUntil: Date;
  calculatedAmountNet: number;
  calculatedAmountGross: number;
  discountPercentage: number;
  plannedDeliveryWeek: number;
  plannedDeliveryDate: Date;
  deliveryWeekDiffers: boolean;
  customerReference: string;
  longDescription: string;
  date: Date;

  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressZipCode: string;
  deliveryAddressHouseNumber: string;

  confirmationPdfType: number;
  HasDivergingDeliveryAddress: boolean;

  archived?: boolean;
  showDescription?: boolean;
  selected: boolean;
  expanded: boolean;
  historyDataSource: MatTableDataSource<IOrderActionHistory>;
  positionsDataSource: MatTableDataSource<IOrderPosition>;
}
