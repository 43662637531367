<div class="tabs">
  <div class="tab" [ngClass]="currentTab === lng ? 'active' : ''" *ngFor="let lng of availableLanguages" (click)="loadLng(lng)">
    {{ lng }}
  </div>
</div>

<div class="box">
  <div class="list">
    <div class="setting" [ngClass]="curSetting?.key === setting?.key ? 'active' : ''" *ngFor="let setting of lngData" (click)="loadSetting(setting)">
      {{ setting.dspKey }}
    </div>
    <div *ngIf="lngData.length === 0">No data</div>
  </div>
  <div class="code">
    <button (click)="newSetting()">
      {{ "ADMIN.TRANSLATION.NEW_SETTING" | translate }}</button
    ><br />
    <br />
    <form>
      <label class="field-title">{{ "ADMIN.TRANSLATION.KEY" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="key" [(ngModel)]="curSetting.key" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.TRANSLATION.VALUE" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="value" [(ngModel)]="curSetting.value" placeholder="" />
      </div>
      <br />
      <button (click)="saveSetting()" [disabled]="curSetting.value === '' || curSetting.key === ''">
        {{ "ADMIN.TRANSLATION.SAVE" | translate }}
      </button>
    </form>
  </div>
</div>
