<div class="container" *ngIf="!appMode">
  <app-home-logo></app-home-logo>
  <div class="root-box">
    <app-lined-header pageTitle="{{ 'LOGIN.TITLE' | translate }}"></app-lined-header>
    <br />
    <br />
    <p innerHtml="{{ 'LOGIN.WELCOME_TEXT' | translate }}"></p>
    <br />
    <form>
      <label class="field-title" (click)="preset()">{{ "LOGIN.MAIL_ADDRESS" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="mailAddress" [(ngModel)]="mailAddress" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "LOGIN.PASSWORD" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="password" [(ngModel)]="password" type="password" placeholder="" />
      </div>
      <br />
      <button (click)="uiShareService.accountTools.doLogin(mailAddress, password)" [disabled]="mailAddress == '' || password == ''">
        {{ "LOGIN.LOGIN.BUTTON" | translate }}
      </button>
    </form>
    <br />
    <a (click)="uiShareService.accountTools.showLostPasswordModal = true">{{ "LOGIN.LOST_PASSWORD.BUTTON" | translate }}</a
    ><br />
    <a (click)="uiShareService.accountTools.showCreateAccountModal = true">{{ "LOGIN.CREATE_ACCOUNT.BUTTON" | translate }}</a>
  </div>
</div>
<ion-content *ngIf="appMode">
  <div slot="fixed" class="home">
    <div id="container" class="container" [ngClass]="appMode ? 'appMode' : 'deskMode'">
      <app-home-logo></app-home-logo>
      <div class="root-box">
        <div class="cards-box">
          <div class="card login" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="appMode && !uiShareService.loggedIn">
            <img
              class="card-image"
              (click)="defaultUser()"
              id="login"
              [src]="!appMode ? './assets/icons/LoginWhite.png' : './assets/icons/LoginRed.png'"
              alt=""
            />
            <div class="card-label">
              <div class="login-text" [innerHtml]="'LOGIN.WELCOME_TEXT' | translate"></div>
              <form>
                <label class="field-title">{{ "LOGIN.MAIL_ADDRESS" | translate }}:</label>
                <div class="field">
                  <input class="form-control" name="mailAddress" [(ngModel)]="mailAddress" placeholder="" />
                </div>
                <label class="field-title">{{ "LOGIN.PASSWORD" | translate }}:</label>
                <div class="field">
                  <input class="form-control" name="password" [(ngModel)]="password" type="password" placeholder="" />
                </div>

                <div class="buttons">
                  <button
                    class="no-margin"
                    (click)="uiShareService.accountTools.doLogin(mailAddress, password)"
                    [disabled]="mailAddress == '' || password == ''"
                  >
                    {{ "LOGIN.LOGIN.BUTTON" | translate }}
                  </button>
                  <button style="float: right" class="plain small no-margin" (click)="uiShareService.accountTools.showLostPasswordModal = true">
                    {{ "LOGIN.LOST_PASSWORD.BUTTON" | translate }}
                  </button>
                </div>
              </form>
              <div class="card-options" *ngIf="!uiShareService.loggedIn && appMode">
                <div class="option light-gray">{{ uiShareService.uiVersion }} ({{ uiShareService.backendVersion }})</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
