<ion-footer class="wrapper-options">
  <ion-toolbar>
    <div *ngIf="multiMerchantEnabled && (uiShareService.selectedMerchant$ | async) !== null">
      <span>
        Händler: {{ (uiShareService.selectedMerchant$ | async).merchantNumber }},
        {{ (uiShareService.selectedMerchant$ | async).companyName }}
      </span>
      &nbsp;
      <span
        class="deviating-merchant"
        *ngIf="(uiShareService.loggedInUser$ | async) !== null && (uiShareService.loggedInUser$ | async).userMerchantAccess?.activeMerchantId"
        >ANSICHT: {{ (uiShareService.loggedInUser$ | async).userMerchantAccess.activeMerchantNumber }},
        {{ (uiShareService.loggedInUser$ | async).userMerchantAccess.activeMerchantName }}</span
      >
    </div>
    <div class="option light-gray">{{ uiShareService.uiVersion }} ({{ uiShareService.backendVersion }})</div>
  </ion-toolbar>
</ion-footer>
