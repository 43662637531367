<div class="expandedContent">
  <div class="description">
    <div>
      <p class="text" *ngIf="offer.deliveryAddressCity">
        <strong>{{ "OFFER.DELIVERY_ADRESS" | translate }}:</strong><br />
        {{ offer.deliveryAddressStreet }} {{ offer.deliveryAddressHouseNumber }}<br />
        {{ offer.deliveryAddressZipCode }} {{ offer.deliveryAddressCity }}
      </p>
      <p class="text" *ngIf="offer.hasDivergentDeliveryAddress">
        <img src="./assets/icons/MobileWarn.png" alt="" />{{ "OFFER.DIVERGENT_DELIVERY_ADDRESS" | translate }}
      </p>
    </div>
    <div class="admin-edit-container">
      <app-admin-edit (editFired)="adminEdit(offer)" *ngIf="parentTab.id !== 4"></app-admin-edit>
    </div>
  </div>
  <div class="tab" (click)="switchTab(tab); updateInnerSort()" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabs">

    <ng-container *ngIf="tab.count; else nocount2"> {{ tab.title }} ({{ tab.count }}) </ng-container>
    <ng-template #nocount2>
      {{ tab.title }}
    </ng-template>
  </div>
  <br />
  <br />
  <div *ngIf="currentTab.id == 0">
    <app-content-header [contentTitle]="'OFFER.POSITIONS_TITLE' | translate"></app-content-header>
    <div class="inner-table">
      <div class="table">
        <div *ngIf="isChangeMessagesSet() && !uiShareService.readOnlyMode()" class="submit-change" style="float: none !important">
          <button (click)="openActionChangeDialog(offer)">
            {{ "OFFER.CHANGE_SUBMIT" | translate }}
          </button>
        </div>
        <table #table mat-table #sort="matSort" [dataSource]="offer.positionsDataSource" matSort matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" (click)="addOrRemove(detailExpandedRows, row); $event.stopPropagation()">
              <button class="button-table">
                <span *ngIf="!detailExpandedRows.includes(row)" class="add"></span>
                <span *ngIf="detailExpandedRows.includes(row)" class="minus"></span>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="thumbnailFileName">
            <th mat-header-cell *matHeaderCellDef>Bild</th>
            <td mat-cell *matCellDef="let row">
              <img *ngIf="row.thumbnailFileBase64" id="image{{ row.thumbnailFileName }}" width="50" src="data:image/gif;base64,{{ row.thumbnailFileBase64 }}" alt="{{ row.thumbnailFileName }}" (click)="previewImage(row)" />
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
            <td mat-cell *matCellDef="let row; let i = index;">
              <b>{{ row.description }}</b><br />
              <span [innerHtml]="'OFFER.ARTICLE_NUMBER' | translate"></span>: {{ row.articleNumber }}<br />
              {{ "OFFER.ELEMENT_ID" | translate }}: {{ row.elementId }}<br />
              <div class="change-message" *ngIf="row.changeMessage">
                <span [innerHtml]="'OFFER_DIALOG.CHANGE_MESSAGE' | translate"></span>:
                {{ row.changeMessage }}
              </div>
              <div class="last-change-message" *ngIf="lastChangeHistory && positionMessages[i]">
                <span>{{ "OFFER.LAST_CHANGE_MESSAGE" | translate }}:</span>
                {{ positionMessages[i] }} ({{ lastChangeHistoryDate | date : "dd.MM.yyyy" }})
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Anzahl</th>
            <td mat-cell *matCellDef="let row">{{ row.quantity }}</td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Stückpreis</th>
            <td mat-cell *matCellDef="let row">{{ row.price | currency : "EUR" : "symbol" : undefined : "de-DE"}}</td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Preis</th>
            <td mat-cell *matCellDef="let row">{{ row.amount | currency : "EUR" : "symbol" : undefined : "de-DE"}}</td>
          </ng-container>
          <ng-container matColumnDef="qPos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ku-Pos</th>
            <td mat-cell *matCellDef="let row">{{ row.qPos }}</td>
          </ng-container>
          <ng-container matColumnDef="validTo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gültig bis</th>
            <td mat-cell [ngClass]="isPastDate(offer.validTo) ? 'past-date' : ''" #highlightCell *matCellDef="let row">{{ getFixedDate(offer.validTo) }}</td>
          </ng-container>
          <ng-container matColumnDef="change">
            <th mat-header-cell *matHeaderCellDef>Ändern</th>
            <td mat-cell *matCellDef="let row">
              <a class="edit" (click)="openEditIconDialog(row)"><img src="./assets/icons/edit-red.png" alt="" height="20px" /></a>
            </td>
          </ng-container>
          <ng-container matColumnDef="adminEdit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <app-admin-edit (editFired)="adminEdit(offer, row)"></app-admin-edit>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!detailExpandedRows.includes(row)">
              <ng-container *ngIf="detailExpandedRows.includes(row)">
                <div class="expandedContent">
                  <span class="text">
                    <span [innerHtml]="row.longDescription"></span>
                  </span>
                  <p class="text" *ngIf="row.deliveryAddressCity">
                    {{ "OFFER.DELIVERY_ADRESS" | translate }}:
                    {{ row.deliveryAddressStreet }}
                    {{ row.deliveryAddressHouseNumber }}
                    {{ row.deliveryAddressZipCode }}
                    {{ row.deliveryAddressCity }}
                  </p>
                  <p class="text" *ngIf="offer.hasDivergentDeliveryAddress">
                    <img src="./assets/icons/MobileWarn.png" alt="" />{{ "OFFER.DIVERGENT_DELIVERY_ADDRESS" | translate }}
                  </p>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">Keine Positionen vorhanden</td>
          </tr>
        </table>
      </div>
      <div class="table-mobil">
        <div *ngIf="isChangeMessagesSet() && !uiShareService.readOnlyMode()" class="submit-change padded">
          <button class="mat-button" (click)="openActionChangeDialog(offer)">
            <mat-icon>cloud_upload</mat-icon>
          </button>
        </div>
        <div class="card" *ngFor="let item of offer.positions; let i = index">
          <div class="card-content" style="width: 100% !important">
            <span class="header-cell-mobil">
              {{ item?.articleNumber }}
              <span class="date-mobil" *ngIf="item.elementId !== null">({{ item?.elementId }})</span>
            </span>
            <span class="cell-mobil">{{ "OFFER.STATUS" | translate }}: {{ "OFFER_STATUS." + offer.calculatedStatus | translate }}</span>
            <span class="cell-mobil">{{ "OFFER.DESCRIPTION" | translate }}: {{ item.description }}</span>
            <span class="cell-mobil">{{ "OFFER.QUANTITY" | translate }}: {{ item.quantity }}</span>
            <span *ngIf="uiShareService.showPrices()" class="cell-mobil"><span [innerHtml]="'OFFER.PRICE' | translate"></span>: {{ item.price | currency : "EUR"
              :
              "symbol" : undefined : "de-DE" }}</span>
            <span *ngIf="uiShareService.showPrices()" class="cell-mobil">{{ "OFFER.AMOUNT" | translate }}: {{ item.amount | currency : "EUR" : "symbol" :
              undefined
              : "de-DE" }}</span>
            <span class="cell-mobil">{{ "OFFER.DELIVERY_WEEK" | translate }}: KW {{ item.plannedDeliveryWeek }}</span>
            <span class="cell-mobil" *ngIf="item.qPos != ''">{{ "OFFER.QPOS" | translate }}: {{ item.qPos }}</span>

            <span class="cell-mobil" *ngIf="item.changeMessage">
              <div class="change-message"><span [innerHtml]="'OFFER_DIALOG.CHANGE_MESSAGE' | translate"></span>: {{ item.changeMessage }}</div>
            </span>
            <div class="cell-mobil last-change-message" *ngIf="lastChangeHistory && positionMessages[i]">
              <span>{{ "OFFER.LAST_CHANGE_MESSAGE" | translate }}: </span>
              {{ positionMessages[i] }} ({{ lastChangeHistoryDate | date : "dd.MM.yyyy" }})
            </div>
            <br />
            <span class="cell-mobil-button" *ngIf="item.showDescription">
              <button (click)="item.showDescription = !item.showDescription">Beschreibung ausblenden</button>
              <span class="cell-mobil-button">
                <button *ngIf="offer.calculatedStatus == 1 && parentTab.id !== 4 && !uiShareService.readOnlyMode()" (click)="openEditIconDialog(item)">
                  Bearbeiten
                </button>
              </span>
              <br />
              <span [innerHtml]="item.longDescription"></span>
            </span>
            <span class="cell-mobil-button" *ngIf="!item.showDescription">
              <button (click)="item.showDescription = !item.showDescription">Beschreibung anzeigen</button>
              <span class="cell-mobil-button">
                <button *ngIf="offer.calculatedStatus == 1 && parentTab.id !== 4 && !uiShareService.readOnlyMode()" (click)="openEditIconDialog(item)">
                  Bearbeiten
                </button>
              </span>
            </span>
            <span class="cell-mobil" *ngIf="uiShareService.isAdminEditAllowed() && parentTab.id !== 4">
              <app-admin-edit [mobile]="true" (editFired)="adminEdit(offer, item)"></app-admin-edit>
            </span>
          </div>
          <div class="card-image" *ngIf="item.thumbnailFileBase64">
            <img width="50" *ngIf="item.thumbnailFileBase64" id="image{{ item.thumbnailFileName }}" width="50" src="data:image/gif;base64,{{ item.thumbnailFileBase64 }}" alt="{{ item.thumbnailFileName }}" (click)="previewImage(item)" />
          </div>
        </div>
        <div *ngIf="offer.positions && offer.positions.length == 0">Keine Positionen vorhanden</div>
        <div *ngIf="isChangeMessagesSet() && !uiShareService.readOnlyMode() && offer.positions.length > 10" class="submit-change padded">
          <button class="mat-button" (click)="openActionChangeDialog(offer)">
            <mat-icon>cloud_upload</mat-icon>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="currentTab.id == 1">
    <div class="inner-table">
      <table class="table history-table" #table mat-table #sort="matSort" [dataSource]="offer.historyDataSource" matSort matSortActive="createdDate" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let row">{{ row.createdDate | date : "dd.MM.yyyy" }}</td>
        </ng-container>
        <ng-container matColumnDef="historyType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktionen</th>
          <td mat-cell *matCellDef="let row">{{ "OFFER_HISTORY_TYPE." + row.historyType | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let row">
            <span [innerHtml]="row.convertedMessage"></span>
            <div *ngIf="row.userData && row.userData != ''">
              von <span class="user">{{ row.userData }}</span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['createdDate', 'historyType', 'details']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['createdDate', 'historyType', 'details']"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="3">Keine Historie gefunden</td>
        </tr>
      </table>
      <div class="table-mobil">
        <div class="card" *ngFor="let item of offer.historyDataSource.data; let i = index">
          <div class="card-content">
            <span class="header-cell-mobil">
              {{ "OFFER_HISTORY_TYPE." + item.historyType | translate }}
              <span class="date-mobil">({{ item?.createdDate | date : "dd.MM.yyyy" }})</span>
            </span>
            <span class="cell-mobil breakall">
              <span [innerHtml]="item.convertedMessage"></span>
              <div *ngIf="item?.userData && item?.userData != ''">
                von <span class="user">{{ item?.userData }}</span>
              </div>
            </span>
          </div>
        </div>
        <div *ngIf="offer.historyDataSource.data && offer.historyDataSource.data.length == 0">Keine Historie gefunden</div>
      </div>
    </div>
  </div>
</div>
