import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IReclamationEdit } from "src/app/models/edit";
import { ApiReclamationService } from "src/app/services/api/api-reclamation.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-reclamation-dialog",
  templateUrl: "./admin-reclamation-dialog.component.html",
  styleUrls: ["./admin-reclamation-dialog.component.scss"],
})
export class AdminReclamationDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminReclamationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private formBuilder: FormBuilder,
    private apiReclamationService: ApiReclamationService,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.form = this.formBuilder.group({
      status: [1],
    });
    this.dialogRef.afterClosed().subscribe(() => {
      // anti-flicker
      this.loaderService.hideLoader();
    });
  }

  setNewStatus() {
    const formValue = this.form.value;

    const edit: IReclamationEdit = {
      id: this.data.id,
      status: formValue.status,
      token: this.uiShareService.adminToken,
    };

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiReclamationService.editReclamation(edit).subscribe({
        next: (data: any) => {
          this.uiShareService.reclamationsNeedReload = true;
          this.messageService.success("Successfully changed");
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.messageService.error("Failed to change!");
          this.dialogRef.close();
        },
      });
    });
  }
}
