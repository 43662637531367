<app-modal id="approve-dialog" [show]="isOpenUser" minWidth="350px" maxWidth="800px">
  <ng-template #modalContent>
    <app-content-header *ngIf="!isEditUser" contentTitle="{{ 'USER.DIALOG_TITLE' | translate }}"></app-content-header>
    <app-content-header *ngIf="isEditUser" contentTitle="{{ 'USER.EDIT_DIALOG_TITLE' | translate }}"></app-content-header>
    <div class="root-merchant" *ngIf="user && user.isMainMerchantUser">
      {{ "USER.MAIN_MERCHANT_USER" | translate }}
    </div>
    <div>
      <form class="form" [formGroup]="form">
        <div class="table">
          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.EMAIL" | translate }}*</label>
              <label class="error" *ngIf="form.get('email').invalid">{{ getFormControl("email") }}</label>
            </div>
            <div class="field">
              <input [readonly]="isEditUser" class="form-control" formControlName="email" />
            </div>
          </div>
          <div class="cell-50">
            <div class="cell-100">
              <div>
                <label class="field-title">{{ "USER.USER_TYPE" | translate }}*</label>
                <label class="error" *ngIf="form.get('userType').invalid">{{ getFormControl("userType") }}</label>
              </div>
              <select class="form-control" formControlName="userType" [ngClass]="user && user.isMainMerchantUser ? 'disabled' : ''">
                <option value="" disabled selected>
                  {{ "USER.CHOOSE" | translate }}
                </option>
                <option value="4" [disabled]="user && user.isMainMerchantUser">
                  {{ "USER.MERCHANT_ADMIN" | translate }}
                </option>
                <option value="1" [disabled]="user && user.isMainMerchantUser">
                  {{ "USER.SALES" | translate }}
                </option>
                <option value="2" [disabled]="user && user.isMainMerchantUser">
                  {{ "USER.USER" | translate }}
                </option>
                <option value="5" [disabled]="user && user.isMainMerchantUser">Monteur (Gestelle)</option>
              </select>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.FIRST_NAME" | translate }}*</label>
              <label class="error" *ngIf="form.get('firstName').invalid">{{ getFormControl("firstName") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="firstName" />
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.LAST_NAME" | translate }}*</label>
              <label class="error" *ngIf="form.get('lastName').invalid">{{ getFormControl("lastName") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="lastName" />
            </div>
          </div>
          <div class="cell-75">
            <div>
              <label class="field-title">{{ "USER.STREET" | translate }}</label>
              <label class="error" *ngIf="form.get('street').invalid">{{ getFormControl("street") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="street" />
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">{{ "USER.NUMBER" | translate }}</label>
              <label class="error" *ngIf="form.get('houseNumber').invalid">{{ getFormControl("houseNumber") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="houseNumber" />
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">{{ "USER.PLZ" | translate }}</label>
              <label class="error" *ngIf="form.get('zipCode').invalid">{{ getFormControl("zipCode") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="zipCode" />
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">{{ "USER.CITY" | translate }}</label>
              <label class="error" *ngIf="form.get('city').invalid">{{ getFormControl("city") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="city" />
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.COUNTRY" | translate }}</label>
              <label class="form-error" *ngIf="form.get('country').invalid">{{ getFormControl("country") }}</label>
            </div>
            <div class="field">
              <select class="form-control" formControlName="country">
                <option *ngFor="let c of countryOptions" [value]="c.code">
                  {{ c.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.PHONE" | translate }}</label>
              <label class="error" *ngIf="form.get('phone').invalid">{{ getFormControl("phone") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="phone" />
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">{{ "USER.MOBILE" | translate }}</label>
              <label class="error" *ngIf="form.get('mobile').invalid">{{ getFormControl("mobile") }}</label>
            </div>
            <div class="field">
              <input class="form-control" formControlName="mobile" />
            </div>
          </div>
        </div>
        <br />
      </form>

      <br />
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button style="float: right" [disabled]="form.invalid" (click)="onSubmit()">
      {{ "USER.BUTTON_SEND" | translate }}
    </button>
    <button style="float: left" (click)="closeDialog()">
      {{ "USER.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>
<app-modal id="delete-dialog" [show]="isOpenDeleteDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'USER.DELETE_TITLE' | translate }}"></app-content-header>
    <div>
      <span>{{ "USER.DELETE_REQUEST" | translate : { email: user?.email } }}</span>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "USER.NO" | translate }}
    </button>
    <button style="float: right" (click)="deleteUser()">
      {{ "USER.YES" | translate }}
    </button>
  </ng-template>
</app-modal>
