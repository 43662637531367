export enum IFrameStatus {
  Delivered,
  InDelivery,
  InPickup,
  PickedUp,
  Processing,
  PrePickup,
  Impediment,
}
export interface IFrame {
  id: string;
  status: IFrameStatus;
  statusText: string;
  merchantNumber: string;
  zdemid: string;
  createdDate: Date;
  number: string;
  orderNumber: string;
  merchantReference: string;
  deliverStreet: string;
  deliverHouseNumber: string;
  deliverCity: string;
  deliverZipCode: string;
  deliverCountry: string;
  pickupLongitude: number;
  pickupLatitude: number;
  pickupStreet: string;
  pickupHouseNumber: string;
  pickupCity: string;
  pickupZipCode: string;
  pickupCountry: string;
  pickupAddressType: string;
  selected: boolean;
}

export interface Defect {
  name: string;
  amount: number;
  mm: number;
  area: string;
}
