<app-custom-ion-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-custom-ion-condense-header>
  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'MYDATA.TITLE' | translate"></app-content-header>
        <div class="root-merchant" *ngIf="user.isMainMerchantUser">
          {{ "MYDATA.MAIN_MERCHANT_USER" | translate }}
        </div>

        <form class="form" [formGroup]="dataForm" (ngSubmit)="onSubmit()">
          <div class="table">
            <div class="cell-100">
              <label class="field-title">{{ "MYDATA.EMAIL" | translate }}</label>
              <div class="field">
                <input class="form-control" formControlName="email" readonly />
              </div>
            </div>
            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.MERCHANT_NUMBER" | translate }}</label>
                <div class="field">
                  <input class="form-control" formControlName="merchantNumber" readonly />
                </div>
              </div>
            </div>
            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.MERCHANT" | translate }}*</label>
                <div class="field">
                  <input class="form-control" formControlName="companyName" readonly />
                </div>
              </div>
            </div>

            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.FIRST_NAME" | translate }}*</label>
                <label class="form-error" *ngIf="dataForm.get('firstName').invalid">{{ getFormControl("firstName") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="firstName" />
              </div>
            </div>
            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.LAST_NAME" | translate }}*</label>
                <label class="form-error" *ngIf="dataForm.get('lastName').invalid">{{ getFormControl("lastName") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="lastName" />
              </div>
            </div>
            <div class="cell-75">
              <label class="field-title">{{ "MYDATA.STREET" | translate }}</label>
              <div class="field">
                <input class="form-control" formControlName="street" />
              </div>
            </div>
            <div class="cell-25">
              <label class="field-title">{{ "MYDATA.NUMBER" | translate }}</label>
              <div class="field">
                <input class="form-control" formControlName="houseNumber" />
              </div>
            </div>
            <div class="cell-25">
              <div>
                <label class="field-title">{{ "MYDATA.PLZ" | translate }}</label>
                <label class="form-error" *ngIf="dataForm.get('zipCode').invalid">{{ getFormControl("zipCode") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="zipCode" />
              </div>
            </div>
            <div class="cell-25">
              <div>
                <label class="field-title">{{ "MYDATA.CITY" | translate }}</label>
                <label class="form-error" *ngIf="dataForm.get('city').invalid">{{ getFormControl("city") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="city" />
              </div>
            </div>
            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.COUNTRY" | translate }}</label>
                <label class="form-error" *ngIf="dataForm.get('country').invalid">{{ getFormControl("country") }}</label>
              </div>
              <div class="field">
                <div class="country-select" (click)="selectCountry()">
                  {{ dataForm.get("country").value }}
                </div>
              </div>
            </div>

            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.PHONE" | translate }}</label>
                <label class="form-error" *ngIf="dataForm.get('phone').invalid">{{ getFormControl("phone") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="phone" />
              </div>
            </div>
            <div class="cell-50">
              <div>
                <label class="field-title">{{ "MYDATA.MOBILE" | translate }}</label>
                <label class="form-error" *ngIf="dataForm.get('mobile').invalid">{{ getFormControl("mobile") }}</label>
              </div>
              <div class="field">
                <input class="form-control" formControlName="mobile" />
              </div>
            </div>
          </div>
          <br />
          <button type="submit" [disabled]="dataForm.invalid">
            {{ "MYDATA.SEND" | translate }}
          </button>
        </form>

        <br />
        <br />
        <span class="sub-header">{{ "MYDATA.PASSWORD" | translate }}</span>

        <form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()">
          <div class="table">
            <div class="cell-100">
              <label class="field-title">{{ "MYDATA.PASSWORD" | translate }}*</label>
              <div class="field">
                <input class="form-control" formControlName="password" type="password" />
              </div>
              <app-password-validator
                password="{{ passwordForm.get('password').value }}"
                [successLevel]="5"
                (onPasswordValidated)="passwordValidated($event)"
              ></app-password-validator>
            </div>
            <div class="cell-100">
              <label class="field-title">{{ "MYDATA.PASSWORD_REPEAT" | translate }}*:</label>
              <label class="form-error" *ngIf="passwordForm.get('password') && passwordForm.get('password').value != passwordForm.get('password2').value">{{
                "MYDATA.PASSWORD_INVALID" | translate
                }}</label>
              <div class="field">
                <input class="form-control" autocomplete="new-password" name="password2" formControlName="password2" type="password" placeholder="" />
              </div>
            </div>
          </div>
          <div class="form-error" style="float: left; margin-top: 10px" *ngIf="!passwordValid">
            {{ "LOGIN.CREATE_ACCOUNT.PASSWORD_INVALID" | translate }}<br />
          </div>
          <br /><br />
          <button
            type="submit"
            [disabled]="!passwordValid || passwordForm.invalid || passwordForm.get('password').value != passwordForm.get('password2').value"
          >
            {{ "MYDATA.SEND" | translate }}
          </button>
        </form>

        <br />
        <br />
        <span class="sub-header">{{ "MYDATA.NOTIFICATIONS" | translate }}</span
        ><br />
        <br />
        {{ "MYDATA.NOTIFICATION_INFOS" | translate }}<br />
        <br />
        <button type="button" (click)="switchNotifications(true)">
          {{ "MYDATA.ACTIVATE_ALL" | translate }}
        </button>
        <button type="button" (click)="switchNotifications(false)">
          {{ "MYDATA.DEACTIVATE_ALL" | translate }}
        </button>

        <form class="form" [formGroup]="infoForm" (ngSubmit)="onSubmitInfos()">
          <div class="table">
            <div class="cell-50" *ngIf="uiShareService.loggedInUser.type != 2">
              <label class="field-title">{{ "MYDATA.INFO_ORDER" | translate }}</label>
              <div class="table">
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnNewOrder">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_NEW" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnChangedOrder">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_CHANGED" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnChangedOrderDelivery">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_CHANGED_DELIVERY" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOrderToConfirm">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_CONFIRM" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOrderInProduction">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_PRODUCTION" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOrderInPayment">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_PAYMENT" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOrderDelivered">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_ORDER_DELIVERED" | translate }}</span>
                  </div>
                </div>
                <!-- <div class="cell-100">
                                    <div style="display: inline;">
                                        <div class="checkbox">
                                            <img alt=""
                                                [src]="orderPDFwithPrices ? '/assets/icons/SwitchOn.png':'/assets/icons/SwitchOff.png'"
                                                (click)="orderPDFwithPrices = !orderPDFwithPrices"
                                                style="height: 30px; vertical-align: middle;">
                                            <label style="vertical-align: bottom;">Preise in PDF zeigen</label>
                                        </div>

                                    </div>
                                </div> -->
              </div>

              <br />
              <label class="field-title">{{ "MYDATA.INFO_OFFER" | translate }}</label>
              <div class="table">
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnNewOffer">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_OFFER_NEW" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnChangedOffer">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_OFFER_CHANGED" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOfferToConfirm">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_OFFER_CONFIRM" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnOfferExpiring">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">In Kürze ablaufende Angebote</span>
                  </div>
                </div>
                <!-- <div class="cell-100">
                                    <div style="display: inline;">
                                        <div class="checkbox">
                                            <img alt=""
                                                [src]="offerPDFwithPrices ? '/assets/icons/SwitchOn.png':'/assets/icons/SwitchOff.png'"
                                                (click)="offerPDFwithPrices = !offerPDFwithPrices"
                                                style="height: 30px; vertical-align: middle;">
                                            <label style="vertical-align: bottom;">Preise in PDF zeigen</label>
                                        </div>

                                    </div>
                                </div> -->
              </div>
            </div>
            <div class="cell-50">
              <label class="field-title">{{ "MYDATA.INFO_FRAME" | translate }}</label>
              <div class="table">
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnDeliveredFrame">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_FRAME_DELIVERED" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnPickedUpFrame">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_FRAME_PICKEDUP" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnFrameInPickup">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_FRAME_IN_PICKUP" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnFrameInDelivery">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_FRAME_IN_DELIVERY" | translate }}</span>
                  </div>
                </div>
              </div>

              <br />
              <label class="field-title">{{ "MYDATA.INFO_RECLAMATION" | translate }}</label>
              <div class="table">
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnChangedReclamation">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_RECLAMATION_CHANGED" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnDoneReclamation">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_RECLAMATION_DONE" | translate }}</span>
                  </div>
                </div>
              </div>

              <br />
              <label class="field-title">{{ "MYDATA.INFO_SHIPMENT" | translate }}</label>
              <div class="table">
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnChangedShipmentNote">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_SHIPMENT_CHANGED" | translate }}</span>
                  </div>
                </div>
                <div class="cell-100">
                  <div style="display: inline">
                    <select class="form-control" formControlName="infoOnNewShipmentNote">
                      <option *ngFor="let info of infoOptions" value="{{ info.value }}">
                        {{ info.label }}
                      </option>
                    </select>
                    &nbsp;
                    <span class="field-title plain small">{{ "MYDATA.INFO_SHIPMENT_NEW" | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div *ngIf="uiShareService.loggedInUser.type != 2">
            <span class="sub-header">{{ "MYDATA.SUMMARY_MAIL" | translate }}</span
            ><br />
            <br />
            {{ "MYDATA.NOTIFICATION_INFOS2" | translate }}<br />
            <br />
            <label class="field-title">{{ "MYDATA.INFO_SUMMARY_INTERVAL" | translate }}</label
            ><br />
            <div>
              <select class="form-control" formControlName="infoOnSummaryIntervalInDays">
                <option *ngFor="let info of intervalOptions" value="{{ info.value }}">
                  {{ info.label }}
                </option>
              </select>
            </div>
            <div class="table" style="padding-top: 5px">
              <div class="cell-cb">
                <app-checkbox [checked]="summaryDoubleChecked" (onChanged)="summaryDoubleChanged($event)"></app-checkbox>
              </div>
              <div class="cell-cb-text">
                <span class="field-title plain small">{{ "MYDATA.INFO_SUMMARY_DOUBLE" | translate }}</span>
              </div>
            </div>
            <br />
            <label class="field-title">{{ "MYDATA.INFO_SUMMARY_CONTENT" | translate }}</label
            ><br />
            <div class="table" style="padding-top: 5px">
              <div class="cell-cb">
                <app-checkbox [checked]="summaryAddOrdersChecked" (onChanged)="summaryAddOrdersChanged($event)"></app-checkbox>
              </div>
              <div class="cell-cb-text">
                <span class="field-title plain small">{{ "MYDATA.INFO_SUMMARY_ADD_ORDERS" | translate }}</span>
              </div>
            </div>
            <div class="table" style="padding-top: 5px">
              <div class="cell-cb">
                <app-checkbox [checked]="summaryAddOffersChecked" (onChanged)="summaryAddOffersChanged($event)"></app-checkbox>
              </div>
              <div class="cell-cb-text">
                <span class="field-title plain small">{{ "MYDATA.INFO_SUMMARY_ADD_OFFERS" | translate }}</span>
              </div>
            </div>
          </div>
          <br />
          <br />
          <button type="submit" [disabled]="infoForm.invalid">
            {{ "MYDATA.SEND" | translate }}
          </button>
        </form>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>