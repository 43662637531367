import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { CryptedStorageService } from "src/app/services/crypted-storage.service";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";
import { MockData } from "../models/mock";
import { Token } from "../models/token";
import { User } from "../models/user";
import { LogService } from "./log.service";

export class AldraClaimTypes {
  static AccessMerchantSwitch = "aldra.gvl.access.switch";
  static AccessMerchantReader = "aldra.gvl.access.read";
  static AccessMerchantWriter = "aldra.gvl.access.write";
  static ActiveAccessMerchant = "aldra.gvl.access.active";
  static ActiveAccessMerchantNumber = "aldra.gvl.access.active.number";
  static ActiveAccessCompanyName = "aldra.gvl.access.active.name";
  static MainUserMerchantId = "aldra.user.merchant.main.id";
  static MainUserMerchantNumber = "aldra.user.merchant.main.number";
  static Role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
}

export interface UserMerchantAccess {
  switchAccess: boolean;
  readAccess: boolean;
  writeAccess: boolean;
  activeMerchantId: string;
  activeMerchantNumber: string;
  activeMerchantName: string;
  mainMerchantId: string;
  mainMerchantNumber: string;
  isGVL: boolean;
  activeMerchantAccessType: MerchantAccessType;
}

export enum MerchantAccessType {
  READ,
  WRITE,
}
export enum TokenRefreshMode {
  Automatic,
  Manual,
  None,
}
export class DeviceData {
  token: string;
  model: string;
  manufacturer: string;
  type: DeviceType;
}
export enum DeviceType {
  iOS,
  Android,
  Browser,
}
export class UserCredentials {
  email: string;
  password: string;
  device: DeviceData;
  uniqueID: string;
}
export class RefreshCredentials {
  token: string;
  useremail: string;
  device: DeviceData;
}
@Injectable({
  providedIn: "root",
})
export class TokenService {
  public timedoutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public refreshedSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  private userUrl = environment.BASE_URL + "Users";
  private refreshMode = TokenRefreshMode.None;
  private timer;
  private timerDelayInSeconds = 10;
  private timerRefreshOffset = 5 * 60 * 1000; // 5 minutes
  private tokenTimeout;
  private refreshRunning = false;

  public deviceData: DeviceData;

  public currentToken: Token;
  public currentToken$ = new BehaviorSubject<Token>(null);

  constructor(private http: HttpService, private logService: LogService, public cryptionService: CryptedStorageService) {
    logService.debug("TokenService.init()");

    // load token from storage
    /*
    //TODO CHECK!!!
    if (localStorage.getItem("jwtToken")) {
      // and parse expiration datetime
      this.parseTokenExpiration(true);
    }
    */
    // load refresh mode from storage
    if (localStorage.getItem("jwtRefreshMode")) {
      // parse fresh mode
      this.setRefreshMode(JSON.parse(localStorage.getItem("jwtRefreshMode")));
      // auto start refresh if set
      if (this.refreshMode == TokenRefreshMode.Automatic) this.startAutoRefreshTimer();
    }
  }
  setSharedMerchant(merchant: any) {
    this.cryptionService.set("sharedMerchantAccess", merchant.id);
  }
  resetSharedMerchant() {
    this.cryptionService.del("sharedMerchantAccess");
  }

  public isTokenValid(): boolean {
    if (this.currentToken) {
      return this.tokenTimeout > new Date();
    }
    return false;
  }

  /**
   * If refresh mode is set to manual, this method auto refreshes the token by user action
   */
  public recordUserAction(): void {
    if (this.refreshMode == TokenRefreshMode.Manual) {
      this.checkTokenRefreshTimeout();
    }
  }

  /**
   * Sets the token refresh mode
   * @param mode Refresh mode
   */
  public setRefreshMode(mode: TokenRefreshMode): void {
    this.logService.debug("TokenService.setRefreshMode() " + mode);
    this.refreshMode = mode;
    localStorage.setItem("jwtRefreshMode", JSON.stringify(mode));

    if (mode != TokenRefreshMode.Automatic) {
      // stop auto timer
      if (this.timer) clearTimeout(this.timer);
    }
  }

  /**
   * Automatically refreshes the jwt token, when the refresh mode is set to automatic
   */
  private checkTokenRefreshTimeout(): void {
    if (this.tokenTimeout) {
      const secondsLeft = this.tokenTimeout.getTime() - new Date().getTime();
      this.logService.debug("TokenService.checkTokenRefreshTimeout() token is valid for: " + secondsLeft + ", offset: " + this.timerRefreshOffset);
      if (secondsLeft < this.timerRefreshOffset) {
        // token needs to be refreshed

        if (!this.refreshRunning) {
          const exists = this.cryptionService.exists("jwtEmail");
          if (exists) {
            this.refreshRunning = true;
            if (this.timer) clearTimeout(this.timer);

            const storageData = this.cryptionService.get("jwtEmail");
            // request new token
            this.refresh(storageData).subscribe({
              next: (next) => {
                // success
                this.logService.debug("TokenService.checkTokenRefreshTimeout() success");
                this.refreshRunning = false;
                this.refreshedSubject.next(next);
              },
              error: (error) => {
                // refresh failed, stop auto refresh
                this.logService.error("TokenService.checkTokenRefreshTimeout() failed: " + error);
                this.refreshRunning = false;
              },
            });
          }
        }
      }
    }
    if (this.refreshMode == TokenRefreshMode.Automatic) this.startAutoRefreshTimer();
  }
  /**
   * Starts the auto refresh timer
   */
  public startAutoRefreshTimer(): void {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.checkTokenRefreshTimeout();
    }, this.timerDelayInSeconds * 1000);
  }
  /**
   * Parses the expiration date from token response
   */
  private parseTokenExpiration(tryRefresh: boolean): void {
    this.logService.debug("TokenService.parseTokenExpiration()");
    this.tokenTimeout = new Date(this.currentToken.expiration);
    this.logService.debug("TokenService.parseTokenExpiration() " + this.tokenTimeout);

    if (this.currentToken.accessToken == new MockData().validTokenMock) {
      // testing mode
      return;
    }

    if (this.tokenTimeout < new Date()) {
      this.logService.debug("TokenService.parseTokenExpiration() Token expired already!");

      if (tryRefresh) {
        this.logService.debug("TokenService.parseTokenExpiration() Trying to refresh!");

        const storageData = this.cryptionService.get("jwtEmail");
        this.refresh(storageData).subscribe({
          next: (refreshToken) => {
            this.logService.debug("TokenService.parseTokenExpiration() Got new token");
            this.refreshedSubject.next(refreshToken);
          },
          error: (error) => {
            this.logService.error("TokenService.parseTokenExpiration() Failed to get new token");
            this.internalLogout();
            this.timedoutSubject.next(true);
          },
        });
      } else {
        this.internalLogout();
        this.timedoutSubject.next(true);
      }
    }
  }

  /**
   * Refresh token
   * @param email User email address
   */
  public refresh(email: string): Observable<any> {
    this.logService.debug("TokenService.refresh()");
    if (!this.cryptionService.exists("jwtRefreshToken")) {
      // not token?
      this.logService.debug("TokenService.refresh() No token available!");
      return new Observable((observer: Observer<any>) => {
        observer.error(null);
        observer.complete();
      });
    }
    this.logService.debug("TokenService.refresh() Found token");

    return new Observable((observer: Observer<any>) => {
      const storageData = this.cryptionService.get("jwtRefreshToken");
      const credentials: RefreshCredentials = {
        useremail: email,
        token: storageData,
        device: this.deviceData,
      };
      this.http.post(this.userUrl + "/refresh", JSON.stringify(credentials)).subscribe({
        next: (next) => {
          // token got refreshed
          this.logService.debug("TokenService.refresh() Got refresh token");
          this.currentToken = next;
          this.currentToken$.next(next);
          this.http.currentToken = next;
          this.cryptionService.set("jwtRefreshToken", next.refreshToken);
          this.parseTokenExpiration(false);
          if (this.refreshMode == TokenRefreshMode.Automatic) this.startAutoRefreshTimer();
          this.refreshedSubject.next(next);
          observer.next(next);
          observer.complete();
        },
        error: (err) => {
          // on error remove storage stuff
          this.logService.error("TokenService.refresh() Failed to get refresh token");
          try {
            this.internalLogout();

            // post timed out
            this.timedoutSubject.next(true);
          } catch (e) {
            this.logService.error("TokenService.refresh() Some error occured while cleaning up");
          }
          observer.error(err);
          observer.complete();
        },
        complete: () => {
          this.logService.debug("finished");
        },
      });
    });
  }

  public internalLogout(): void {
    this.currentToken = null;
    this.currentToken$.next(null);
    this.cryptionService.del("jwtRefreshToken");
    this.cryptionService.del("jwtEmail");
    this.cryptionService.del("jwtAdminToken");
    this.tokenTimeout = null;
  }

  public logout(user: User): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const credentials: UserCredentials = {
        email: user ? user.email : null,
        password: user ? user.password : null,
        device: this.deviceData,
        uniqueID: user ? user.uniqueID : null,
      };
      if (this.timer) clearTimeout(this.timer);

      this.http.post(this.userUrl + "/logout", JSON.stringify(credentials)).subscribe({
        next: (next) => {
          this.internalLogout();
          this.timedoutSubject.next(true);
          observer.next("");
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          this.internalLogout();
          this.timedoutSubject.next(true);
          observer.next("");
          observer.complete();
        },
      });
    });
  }

  public setNewToken(token: Token): void {
    this.currentToken = token;
    this.currentToken$.next(token);
    this.http.currentToken = token;
    this.cryptionService.set("jwtRefreshToken", token.refreshToken);
    this.cryptionService.set("jwtEmail", token.user.email);
    this.parseTokenExpiration(false);
    if (this.refreshMode == TokenRefreshMode.Automatic) this.startAutoRefreshTimer();
  }

  public login(user: User): Observable<any> {
    const credentials: UserCredentials = {
      email: user ? user.email : null,
      password: user ? user.password : null,
      uniqueID: user ? user.uniqueID : null,
      device: this.deviceData,
    };
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.userUrl + "/login", JSON.stringify(credentials)).subscribe({
        next: (next) => {
          this.currentToken = next;
          this.currentToken$.next(next);
          this.http.currentToken = next;
          this.cryptionService.set("jwtRefreshToken", next.refreshToken);
          this.parseTokenExpiration(false);
          if (this.refreshMode == TokenRefreshMode.Automatic) this.startAutoRefreshTimer();

          observer.next(next);
          observer.complete();
        },
        error: (error) => {
          this.internalLogout();
          observer.error(error);
          observer.complete();
        },
      });
    });
  }

  public getUserMerchantAccess(token?: string): UserMerchantAccess {
    let switchAccess = false;
    let readAccess = false;
    let writeAccess = false;
    let activeMerchantId: string;
    let activeMerchantNumber: string;
    let activeMerchantName: string;
    let mainMerchantId: string;
    let mainMerchantNumber: string;
    let isGVL: boolean;
    let activeMerchantAccessType: MerchantAccessType;
    const accessToken = token || this.currentToken?.accessToken;
    if (accessToken) {
      const decodedTokenBody = atob(accessToken.split(".")[1]);
      if (decodedTokenBody) {
        try {
          const tokenObject = JSON.parse(decodedTokenBody);
          const tokenSwitchAccess = tokenObject[AldraClaimTypes.AccessMerchantSwitch];
          const tokenRead = tokenObject[AldraClaimTypes.AccessMerchantReader];
          const tokenWrite = tokenObject[AldraClaimTypes.AccessMerchantWriter];
          const tokenActiveId = tokenObject[AldraClaimTypes.ActiveAccessMerchant];
          const tokenActiveNumber = tokenObject[AldraClaimTypes.ActiveAccessMerchantNumber];
          const tokenActiveName = tokenObject[AldraClaimTypes.ActiveAccessCompanyName];
          const tokenMainMerchantId = tokenObject[AldraClaimTypes.MainUserMerchantId];
          const tokenMainMerchantNumber = tokenObject[AldraClaimTypes.MainUserMerchantNumber];
          const tokenRole = tokenObject[AldraClaimTypes.Role];

          if (tokenSwitchAccess) {
            switchAccess = tokenSwitchAccess;
          }
          if (tokenRead) {
            readAccess = true;
          }
          if (tokenWrite) {
            writeAccess = true;
          }
          if (tokenActiveId) {
            activeMerchantId = tokenActiveId;
          }
          if (tokenActiveNumber) {
            activeMerchantNumber = tokenActiveNumber;
          }
          if (tokenActiveName) {
            activeMerchantName = tokenActiveName;
          }
          if (tokenMainMerchantId) {
            mainMerchantId = tokenMainMerchantId;
          }
          if (tokenMainMerchantNumber) {
            mainMerchantNumber = tokenMainMerchantNumber;
          }
          if (tokenRole) {
            if (tokenRole instanceof Array) {
              isGVL = tokenRole.includes("GVL");
            } else {
              isGVL = tokenRole === "GVL";
            }
          }
          activeMerchantAccessType = MerchantAccessType.READ;
          if (activeMerchantId) {
            if (readAccess == true) {
              activeMerchantAccessType = MerchantAccessType.READ;
            }
            if (writeAccess == true) {
              activeMerchantAccessType = MerchantAccessType.WRITE;
            }
          } else {
            // user does not have any claims set fall back to normal mode
            activeMerchantAccessType = MerchantAccessType.WRITE;
          }
          return {
            switchAccess,
            readAccess,
            writeAccess,
            activeMerchantId,
            activeMerchantNumber,
            activeMerchantName,
            mainMerchantId,
            mainMerchantNumber,
            isGVL,
            activeMerchantAccessType,
          };
        } catch (error) {
          this.logService.error(error);
        }
      }
    }
    return {
      switchAccess,
      readAccess,
      writeAccess,
      activeMerchantId,
      activeMerchantNumber,
      activeMerchantName,
      mainMerchantId,
      mainMerchantNumber,
      isGVL,
      activeMerchantAccessType,
    };
  }
}
