<select class="form-control" [(ngModel)]="filter.state">
  <option value="0">All</option>
  <option value="1">Info</option>
  <option value="2">Warning</option>
  <option value="3">Error</option>
</select>
<select class="form-control" [(ngModel)]="filter.type">
  <option value="0">All</option>
  <option value="1">User</option>
  <option value="2">Import</option>
  <option value="3">Approval</option>
  <option value="4">Mailing</option>
  <option value="5">Cleanup</option>
  <option value="6">Archive</option>
</select>
<select class="form-control" [(ngModel)]="filter.hours">
  <option value="1">1 Hours</option>
  <option value="2">2 Hours</option>
  <option value="3">3 Hours</option>
  <option value="6">6 Hours</option>
  <option value="12">12 Hours</option>
  <option value="24">1 Day</option>
  <option value="48">2 Days</option>
  <option value="72">3 Days</option>
  <option value="96">4 Days</option>
  <option value="120">5 Days</option>
</select>
<div class="checkbox">
  <app-checkbox [checked]="filter.withLoginLogout" (onChanged)="loginLogoutChanged($event)"></app-checkbox>
</div>
<div class="checkbox-text">With login/logout</div>
<input type="text" class="form-control" [(ngModel)]="filter.filter" />
<button (click)="reload()">Ok</button><br />
<br />
<div class="table">
  <div class="table-row" [ngClass]="{ warning: log.state == 1, error: log.state == 2 }" *ngFor="let log of eventLog">
    <div class="cell date">
      {{ log.createdDate | date : "dd.MM.yyyy HH:mm:ss" }}
    </div>
    <div class="cell state">{{ getState(log.state) }}</div>
    <div class="cell type">{{ getType(log.type) }}</div>
    <div class="cell merchant" *ngIf="showUserData(log.type)">
      {{ log.merchantNumber }}
    </div>
    <div class="cell email" *ngIf="showUserData(log.type)">
      {{ log.userEmail }}
    </div>

    <div class="cell fileName" *ngIf="!showUserData(log.type)">
      {{ log.fileName }}
    </div>

    <div class="cell big">
      {{ log.message }}
      <div *ngIf="log.stacktrace && log.stacktrace != ''">
        <div class="toggle" (click)="log.stacktraceVisible = !log.stacktraceVisible">Toggle stacktrace</div>
        <span class="stacktrace" *ngIf="log.stacktraceVisible" [innerHtml]="log.stacktrace"></span>
      </div>
    </div>
  </div>
</div>
