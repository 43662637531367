import { Component, ContentChild, ElementRef, EventEmitter, Output, TemplateRef } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { FrameService } from "src/app/services/frame.service";

@Component({
  selector: "app-frame-wrapper",
  templateUrl: "./frame-wrapper.component.html",
  styleUrls: ["./frame-wrapper.component.scss"],
})
export class FrameWrapperComponent {
  @ContentChild("wrapperFrame")
  wrapperFrame: TemplateRef<ElementRef>;
  public appMode = Capacitor.isNativePlatform();
  @Output() frameNumber: EventEmitter<string> = new EventEmitter<string>();
  constructor(private frameService: FrameService) {}

  public scanBarCode(): void {
    this.frameService.scanBarCode().subscribe((next) => {
      this.frameNumber.emit(next);
    });
  }
}
