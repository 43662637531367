<app-custom-ion-header [selected]="'MENU.ORDER'"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.ORDER'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.ORDER'"></app-custom-ion-condense-header>
  <app-custom-content-wrapper [breadcrumb]="breadcrumb">
    <ng-template #wrapperContent>
      <div class="data-card">
        <span class="position" *ngIf="position && position?.order && uiShareService.loggedIn">
          <app-content-header [contentTitle]="'PRODUCTS.DETAILS' | translate"></app-content-header><br />
          <span class="key">{{ "PRODUCTS.NAME" | translate }}:</span>
          {{ position?.position?.description }}<br />
          <span class="key">{{ "PRODUCTS.NUMBER" | translate }}:</span>
          {{ position?.position?.articleNumber }}

          <div>
            <span class="key">{{ "PRODUCTS.DESCRIPTION" | translate }}:</span>&nbsp;

            <button ion-button (click)="toggleDescription()">
              <span *ngIf="!showDescription">{{ "PRODUCTS.DESCRIPTION_SHOW" | translate }}</span>
              <span *ngIf="showDescription">{{ "PRODUCTS.DESCRIPTION_HIDE" | translate }}</span>
            </button>
          </div>
          <div *ngIf="showDescription">
            <span [innerHtml]="position?.position?.longDescription"></span>
          </div>
          <br />

          <div>
            <br />
            <app-content-header [contentTitle]="'PRODUCTS.ORDER_SECTION' | translate"></app-content-header><br />
            <span class="key" [innerHtml]="'ORDER.ORDER_NUMBER' | translate">:</span>
            {{ position?.order?.orderNumber }}<br />
            <span class="key">{{ "ORDER.DATE" | translate }}:</span>
            {{ position?.order?.createdDate | date : "dd.MM.yyyy" }}<br />
            <span *ngIf="showPrices && position?.order?.discountPercentage > 0"
              ><span class="key">{{ "ORDER.AMOUNT_NET" | translate }}:</span>
              {{ position?.order?.calculatedAmountNet | currency : "EUR" : "symbol" : undefined : "de-DE" }}<br
            /></span>
            <span *ngIf="showPrices"
              ><span class="key">{{ "ORDER.AMOUNT_GROSS" | translate }}:</span>
              {{ position?.order?.calculatedAmountGross | currency : "EUR" : "symbol" : undefined : "de-DE" }}<br
            /></span>
            <span *ngIf="showPrices && position?.order?.discountPercentage > 0"
              ><span class="key">{{ "ORDER.DISCOUNT" | translate }}:</span> {{ position?.order?.discountPercentage }}%<br
            /></span>
            <span class="key">{{ "ORDER.REFERENZ_CUSTOMER" | translate }}:</span>
            {{ position?.order?.customerReference }}<br />
          </div>
        </span>
        <span class="position">
          <app-content-header [contentTitle]="'PRODUCTS.DOCUMENTS' | translate"></app-content-header><br />

          <div class="table">
            <div class="table-row" *ngFor="let document of position?.documents" (click)="orderService.getDocumentPdf($event, document)">
              <div class="table-cell">
                <div class="card-label" *ngIf="document?.translations">
                  <div>{{ document?.translations[language] }}</div>
                </div>
              </div>
              <div class="table-cell">
                <img src="/assets/icons/DownloadRed.png" width="16" alt="" />
              </div>
            </div>
          </div>
          <div class="table-mobil">
            <div class="card" *ngFor="let document of position?.documents" (click)="orderService.getDocumentPdf($event, document)">
              <div class="card-content">
                <img src="/assets/icons/DownloadRed.png" width="16" alt="" />
                <span class="header-cell-mobil" *ngIf="document?.translations">{{ document?.translations[language] }}</span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </ng-template>
  </app-custom-content-wrapper>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
