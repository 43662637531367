import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import saveAs from "file-saver";
import { NativeFileService } from "src/app/services/native-file.service";
import { IReclamationDb, IReclamationWriting, IReclamationWritingType } from "../models/reclamation";
import { ApiReclamationService } from "../services/api/api-reclamation.service";
import { LoaderService } from "./loader.service";
import { MessageService } from "./message.service";
import { UiShareService } from "./ui-share.service";

@Injectable({
  providedIn: "root",
})
export class ReclamationService {
  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private nativeFileService: NativeFileService,
    private uiShareService: UiShareService,
    private apiReclamationService: ApiReclamationService,
  ) {}

  public getPdf(evt: any, reclamation: IReclamationDb, item: IReclamationWriting): void {
    evt.stopPropagation();

    const object = Object.assign({});
    object.id = item.id;
    object.reclamationNumber = reclamation.reclamationNumber;
    if (item.type == IReclamationWritingType.Initial) {
      object.finalFileName = "Eingangsschreiben_" + reclamation.reclamationNumber + ".pdf";
    } else if (item.type == IReclamationWritingType.Intermediate) {
      object.finalFileName = "Zwischenbescheid_" + reclamation.reclamationNumber + ".pdf";
    } else if (item.type == IReclamationWritingType.Closing) {
      object.finalFileName = "Erledigungsschreiben_" + reclamation.reclamationNumber + ".pdf";
    }
    object.fileName = object.finalFileName;
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.apiReclamationService.pdf(object).subscribe({
        next: (data) => {
          if (Capacitor.isNativePlatform()) {
            this.openPdfInApp(data, object);
          } else {
            this.loaderService.hideTransparentLoader();
            let filename: string = data.headers.get("content-disposition");
            filename = filename.substring(filename.indexOf("filename=") + "filename=".length);
            saveAs(data.body, filename);
          }
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("RECLAMATION.PDF_ERROR"));
          this.loaderService.hideTransparentLoader();
        },
      });
    });
  }

  private openPdfInApp(data: any, object: any) {
    this.nativeFileService.openPdf(data, object);
  }
}
