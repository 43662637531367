<div class="dialog-content">
  <img
    *ngIf="data.item.thumbnailFileBase64"
    id="image{{ data.item.thumbnailFileName }}"
    src="data:image/gif;base64,{{ data.item.thumbnailFileBase64 }}"
    alt="{{ data.item.thumbnailFileName }}"
  />
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Abbrechen</button>
</div>
