import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { BehaviorSubject, Subject } from "rxjs";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { MessageService } from "src/app/services/message.service";
export enum AccessType {
  NONE = 0,
  ACTIVE = 1,
  READ = 2,
  WRITE = 4,
}
@Component({
  selector: "app-admin-manage-access",
  templateUrl: "./admin-manage-access.component.html",
  styleUrls: ["./admin-manage-access.component.scss"],
})
export class AdminManageAccessComponent implements OnInit {
  @ViewChild("userList") userList: MatSelectionList;
  public userFilterValue: string;
  public merchantFilterValue: string;
  public updating = new Subject<boolean>();
  selection = new SelectionModel(true);
  users = [];
  selectedUser;
  merchants = [];
  filteredUsers: Subject<any> = new Subject();
  filteredMerchants: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(private apiAdminService: ApiAdminService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.apiAdminService.getUsers().subscribe((users) => {
      this.users = users;
      this.filteredUsers.next(users);
    });
    this.apiAdminService.getCustomers().subscribe((merchants) => {
      this.merchants = merchants;
      this.filteredMerchants.next(merchants);
    });
  }

  filterUsers(value: string[]) {
    if (this.users) {
      this.filteredUsers.next(
        this.users.filter((m) =>
          value.every(
            (e) =>
              m.firstName?.toLowerCase()?.includes(e) ||
              m.lastName?.toLowerCase()?.includes(e) ||
              m.typeString?.toLowerCase()?.includes(e) ||
              m.email?.toLowerCase()?.includes(e)
          )
        )
      );
    }
  }
  userSelectionChange(change: MatSelectionListChange) {
    if (!change.options[0]?.value) {
      return;
    }
    // resolve user access rights
    this.apiAdminService.getUserAccess(change.options[0]?.value?.id).subscribe((userAccessList: any[]) => {
      console.log(userAccessList);
      this.merchants.forEach((m) => {
        m.read = false;
        m.write = false;
        m.active = false;
        userAccessList?.forEach((access) => {
          if (access.customerId == m.id) {
            m.read = (access.accessType & AccessType.READ) === AccessType.READ;
            m.write = (access.accessType & AccessType.WRITE) === AccessType.WRITE;
            m.active = (access.accessType & AccessType.ACTIVE) === AccessType.ACTIVE;
          }
        });
      });
    });
  }

  filterMerchants(value: string[]) {
    if (this.merchants) {
      this.filteredMerchants.next(
        this.merchants?.filter((m) =>
          value.every(
            (e) =>
              m.merchantNumber?.toLowerCase()?.includes(e) ||
              m.companyName?.toLowerCase()?.includes(e) ||
              m.region?.toLowerCase()?.includes(e) ||
              m.regionName?.toLowerCase()?.includes(e) ||
              m.email?.toLowerCase()?.includes(e)
          )
        )
      );
    }
  }
  onUserChange(newValue: string) {
    this.userFilterValue = newValue;
    this.filterUsers(newValue?.toLowerCase()?.split(" "));
  }
  onMerchantChange(newValue: string) {
    this.merchantFilterValue = newValue;
    this.filterMerchants(newValue?.toLowerCase()?.split(" "));
  }
  onAccountTypeChange(event: any, user: any, type: number) {
    this.apiAdminService.updateAccountType(user.id, type, event.checked).subscribe({
      next: (res) => {
        this.messageService.success("Berechtigung gesetzt");
      },
      error: (e) => {
        event.source.checked = !event.checked;
        this.messageService.error("Berechtigung konnte nicht gesetzt werden");
      },
    });
  }

  checkBoxChange(event: any, merchant: any, type: number) {
    this.apiAdminService.updateAccess(this.userList.selectedOptions.selected[0]?.value?.id, merchant, type, event.checked).subscribe({
      next: (res) => {
        this.messageService.success("Berechtigung gesetzt");
        this.merchants.map((m) => {
          if (m.id == merchant.id) {
            switch (type) {
              case AccessType.READ:
                m.read = event.checked;
                break;
              case AccessType.WRITE:
                m.write = event.checked;
                break;
              case AccessType.ACTIVE:
                m.active = event.checked;
                break;
              default:
                break;
            }
          }
        });
      },
      error: (e) => {
        event.source.checked = !event.checked;
        this.messageService.error("Berechtigung konnte nicht gesetzt werden");
      },
    });
  }

  setAll(event: any, type: number) {
    this.updating.next(true);
    this.apiAdminService.updateBatchAccess(this.userList.selectedOptions.selected[0]?.value?.id, this.filteredMerchants.value, type, true).subscribe({
      next: (res) => {
        this.messageService.success(`Berechtigungen gesetzt`);
      },
      error: (e) => {
        event.source.checked = !event.checked;
        this.messageService.error("Berechtigung konnte nicht gesetzt werden");
      },
      complete: () => {
        this.apiAdminService.getCustomers().subscribe((merchants) => {
          this.merchants = merchants;
          this.filteredMerchants.next(merchants);
          this.apiAdminService.getUserAccess(this.userList.selectedOptions.selected[0]?.value?.id).subscribe({
            next: (userAccessList: any[]) => {
              console.log(userAccessList);
              this.merchants.forEach((m) => {
                m.read = false;
                m.write = false;
                m.active = false;
                userAccessList?.forEach((access) => {
                  if (access.customerId == m.id) {
                    m.read = (access.accessType & AccessType.READ) === AccessType.READ;
                    m.write = (access.accessType & AccessType.WRITE) === AccessType.WRITE;
                    m.active = (access.accessType & AccessType.ACTIVE) === AccessType.ACTIVE;
                  }
                });
              });
            },
            error: (e) => {},
            complete: () => {
              if (this.merchantFilterValue) {
                this.filterMerchants([this.merchantFilterValue]);
              } else {
                this.onMerchantChange("");
              }
              this.updating.next(false);
            },
          });
        });
      },
    });
  }

  removeAll(event: any, type: number) {
    this.updating.next(true);
    this.apiAdminService.updateBatchAccess(this.userList.selectedOptions.selected[0]?.value?.id, this.filteredMerchants.value, type, false).subscribe({
      next: (res) => {
        this.messageService.success(`Berechtigungen entfernt`);
      },
      error: (e) => {
        event.source.checked = !event.checked;
        this.messageService.error("Berechtigung konnte nicht gesetzt werden");
      },
      complete: () => {
        this.apiAdminService.getCustomers().subscribe((merchants) => {
          this.merchants = merchants;
          this.filteredMerchants.next(merchants);
          this.apiAdminService.getUserAccess(this.userList.selectedOptions.selected[0]?.value?.id).subscribe({
            next: (userAccessList: any[]) => {
              console.log(userAccessList);
              this.merchants.forEach((m) => {
                m.read = false;
                m.write = false;
                m.active = false;
                userAccessList?.forEach((access) => {
                  if (access.customerId == m.id) {
                    m.read = (access.accessType & AccessType.READ) === AccessType.READ;
                    m.write = (access.accessType & AccessType.WRITE) === AccessType.WRITE;
                    m.active = (access.accessType & AccessType.ACTIVE) === AccessType.ACTIVE;
                  }
                });
              });
            },
            error: (e) => {},
            complete: () => {
              if (this.merchantFilterValue) {
                this.filterMerchants([this.merchantFilterValue]);
              } else {
                this.onMerchantChange("");
              }
              this.updating.next(false);
            },
          });
        });
      },
    });
  }
}
