<app-custom-ion-header [selected]="'MENU.' + (type ? type : '').toUpperCase()" [homeIcon]="homeIcon"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.' + (type ? type : '').toUpperCase()" [homeIcon]="homeIcon"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.' + (type ? type : '').toUpperCase()" [homeIcon]="homeIcon"></app-custom-ion-condense-header>
  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header contentTitle="Kontaktanfrage" [ngClass]="mode !== 'contact' ? 'grey' : ''"  (click)="setMode('contact')"></app-content-header>
        <span class="separator">&nbsp;|&nbsp;</span>
        <app-content-header contentTitle="Rückrufanfrage" [ngClass]="mode !== 'callback' ? 'grey' : ''" (click)="setMode('callback')"></app-content-header>
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div>
            <label class="field-title contact-title" *ngIf="type == 'order'">{{ "CONTACT_FORM.ORDER_NUMBER" | translate }}*</label>
            <label class="field-title contact-title" *ngIf="type == 'shipment-notes'">{{ "CONTACT_FORM.SHIPMENT_NUMBER" | translate }}*</label>
            <label class="field-title contact-title" *ngIf="type == 'offer'">{{ "CONTACT_FORM.OFFER_NUMBER" | translate }}*</label>
            <label class="field-title contact-title" *ngIf="type == 'reclamation'">{{ "CONTACT_FORM.RECLAMATION_NUMBER" | translate }}*</label>
            <label class="field-title contact-title" *ngIf="type == 'frame'">{{ "CONTACT_FORM.FRAME_NUMBER" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="typeNumber" />
            </div>
          </div>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.NAME" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="contactName" />
            </div>
          </div>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.EMAIL" | translate }}*</label>
            <div class="field">
              <input class="form-control" formControlName="email" />
            </div>
          </div>
          <ng-container *ngIf="mode === 'callback'">
            <div>
              <label class="field-title contact-title">Telefonnummer*</label>
              <div class="field">
                <input class="form-control" formControlName="tel" />
              </div>
            </div>
            <div>
              <label class="field-title contact-title">Ansprechpartner</label>
              <div class="field">
                <input class="form-control" formControlName="contact" />
              </div>
            </div>
            <div>
              <label class="field-title contact-title">Datum*</label>
              <div class="field">
                <input type="date" [min]="getStartDate()" class="form-control" formControlName="date" />
              </div>
            </div>
            <div>
              <label class="field-title contact-title">Uhrzeit* (Betriebszeiten Mo-Do 07:00 - 17:00, Fr 07:00 - 14:00) </label>
              <div class="field">
                <input
                  type="time"
                  [min]="getStartingHourFormatted()"
                  [max]="getClosingHourFormatted()"
                  step="3600"
                  class="form-control"
                  formControlName="time"
                />
              </div>
              <label *ngIf="!form.errors" class="info">Zeitfenster: {{ form.get("time").value }} - {{ getTimeframeEnd() }}</label>
              <label *ngIf="form.errors?.format" class="warn">Bitte wählen Sie ein gültiges Datum</label>
              <label *ngIf="form.errors?.past" class="warn">Das Datum darf nicht in der Vergangenheit liegen</label>
              <label *ngIf="form.errors?.max || form.errors?.min" class="warn">Bitte wählen Sie ein Datum innerhalb der Öffnungszeiten</label>
              <label *ngIf="form.errors?.holiday" class="warn">Geschlossen ({{ form.errors?.holiday }})</label>
            </div>
          </ng-container>
          <div>
            <label class="field-title contact-title">{{ "CONTACT_FORM.MESSAGE" | translate }}<ng-container *ngIf="mode === 'contact'">*</ng-container></label>
            <div class="field">
              <textarea class="form-control" formControlName="message"></textarea>
            </div>
          </div>
          <div class="privacy">
            <label>{{ "PRIVACY.DATA_PRIVACY_PART_1" | translate }}</label>
            <a class="link" (click)="uiShareService.accountTools.showPrivacyModal = true">{{ "PRIVACY.DATA_PRIVACY_PART_2" | translate }}</a>
            <label>{{ "PRIVACY.DATA_PRIVACY_PART_3" | translate }}</label>
          </div>
          <div>
            <div class="field-checkbox">
              <input id="acceptPrivacy" class="form-control" type="checkbox" formControlName="acceptPrivacy" />
              <label for="acceptPrivacy" class="cell-text-checkbox">{{ "PRIVACY.ACCEPT_PRIVACY" | translate }}</label>
            </div>
            <div class="field-checkbox">
              <input id="sendCopy" class="form-control" type="checkbox" formControlName="sendCopy" />
              <label for="sendCopy" class="cell-text-checkbox">Kopie dieser Anfrage an {{ form?.get("email")?.value }}</label>
            </div>
          </div>
          <div>
            <button type="submit" [disabled]="form.invalid">
              {{ "CONTACT_FORM.SEND" | translate }}
            </button>
          </div>
        </form>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
