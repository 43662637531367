import { Component, OnInit, ContentChild, TemplateRef, ElementRef, Input, HostListener } from "@angular/core";
import { fromEvent } from "rxjs";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-custom-content-wrapper",
  templateUrl: "./custom-content-wrapper.component.html",
  styleUrls: ["./custom-content-wrapper.component.scss"],
})
export class CustomContentWrapperComponent implements OnInit {
  @ContentChild("wrapperContent")
  wrapperContent: TemplateRef<ElementRef>;
  @Input() breadcrumb: any;
  public multiMerchantEnabled: boolean;

  constructor(private router: RoutingService, public uiShareService: UiShareService) {
    this.multiMerchantEnabled = environment.MULTIMERCHANT;
  }

  ngOnInit() {}

  openBreadcrumb(crumb: Breadcrumb) {
    if (crumb.page) {
      if (crumb.page.name) {
        if (crumb.page.name == "offer-details") {
          this.router.forwardByUrl("/" + crumb.page.name, {
            state: { offer: crumb.page.item },
          });
        } else if (crumb.page.name == "reclamation-details") {
          this.router.forwardByUrl("/" + crumb.page.name, {
            state: { reclamation: crumb.page.item },
          });
        } else if (crumb.page.name == "frame-details") {
          this.router.forwardByUrl("/" + crumb.page.name, {
            state: { frame: crumb.page.item },
          });
        } else {
          this.router.forwardByUrl("/" + crumb.page.name, {
            state: { order: crumb.page.item },
          });
        }
      } else {
        this.router.forwardByUrl(crumb.page);
      }
    }
  }
}
