export enum EventLogType {
  UserLogin,
  UserRegistration,
  UserConfirmation,
  UserLostPassword,
  UserResetPassword,
  OrderImport,
  OrderAttachmentImport,
  OrderPositionImport,
  OfferImport,
  OfferAttachmentImport,
  OfferPositionImport,
  OrderApprove,
  OfferApprove,
  Mailing,
  Cleanup,
  OrderArchive,
  OfferArchive,
  MultiMerchantAdd,
  MultiMerchantSwitch,
  ShipmentImport,
  FrameImport,
  ReclamationImport,
  Import,
  Export,
  CustomerWhitelist,
  ShipmentAttachmentImport,
  ReclamationAttachmentImport,
  OfferParking,
}
export enum EventLogState {
  Info,
  Warning,
  Error,
}
export interface EventLog {
  createdDate: Date;
  type: EventLogType;
  state: EventLogState;
  merchantNumber: string;
  fileName: string;
  message: string;
  userEmail: string;
  stacktrace: string;
  stacktraceVisible?: boolean;
}
export enum EventLogFilterType {
  All,
  User,
  Import,
  Approval,
  Mailing,
  Export,
}
export enum EventLogFilterState {
  All,
  Info,
  Warning,
  Error,
}
export interface EventLogFilterVM {
  type: EventLogFilterType;
  state: EventLogFilterState;
  hours: number;
  filter: string;
  withLoginLogout: boolean;
}
