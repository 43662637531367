import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";
import { UiShareService } from "./../../services/ui-share.service";

@Component({
  selector: "app-privacy-dialog",
  templateUrl: "./privacy-dialog.component.html",
  styleUrls: ["./privacy-dialog.component.scss"],
})
export class PrivacyDialogComponent {
  public form;
  public errorMessage = "";

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PrivacyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uiShareService: UiShareService,
    private apiUsersService: ApiUsersService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
  ) {
    this.form = this.formBuilder.group({
      privacy: [false, Validators.requiredTrue],
      agb: [false, Validators.requiredTrue],
      activateNotifications: [true],
    });
  }

  public linkClicked() {
    this.uiShareService.accountTools.showPrivacyModal = true;
  }

  public logout() {
    this.uiShareService.logout();
  }

  public confirm() {
    const formValue = this.form.value;
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.apiUsersService.confirm({ activateNotifications: formValue.activateNotifications }).subscribe({
        next: (user) => {
          this.loaderService.hideTransparentLoader();
          this.uiShareService.setLoggedInUser(user);
          this.dialogRef.close();
        },
        error: (error) => {
          this.loaderService.hideTransparentLoader();
          console.error(error);
          if (error.error && !error.error.type) {
            if (error.error == "ACCOUNT_DEACTIVATED") {
              this.errorMessage = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_ACCOUNT_DEACTIVATED_TEXT");
            } else {
              this.errorMessage = error.error;
            }
          } else {
            this.errorMessage = this.translateService.instant("CONFIRM_ACCOUNT.REQUEST_FAILED_TEXT");
          }
        },
      });
    });
  }

  public retry() {
    this.errorMessage = "";
    this.confirm();
  }
}
