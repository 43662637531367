export class Customer {
  id: string;
  createdDate: Date;
  modifiedDate: Date;
  merchantNumber: string;
  email: string;
  companyName: string;
  firstName: string;
  lastName: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  mobile: string;
  fax: string;
  webPage: string;
  region: string;
  regionName: string;
  portalClient: string;
  backOffice: string;
  portalAdminAccount: string;
  portalValidFrom: string;
  portalValidTo: string;
}
