<app-content-header mat-dialog-title contentTitle="Admin-Edit"></app-content-header>
<div mat-dialog-content>
  <form [formGroup]="form">
    <span>New status:</span>
    <select class="form-control" formControlName="status">
      <option value="0">Delivered</option>
      <option value="1">InDelivery</option>
      <option value="2">InPickup</option>
      <option value="3">PickedUp</option>
      <option value="4">Processing</option>
    </select>
  </form>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-button (click)="setNewStatus()">Set data</button>
</div>
