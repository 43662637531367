import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Geolocation } from "@capacitor/geolocation";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { UiShareService } from "./ui-share.service";
import { Camera } from "@capacitor/camera";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private diagnostic: Diagnostic,
    private uiShareService: UiShareService
  ) {}

  public async askForGeoUsage(): Promise<void> {
    const premission = await Geolocation.checkPermissions();
    if (premission.location === "granted") {
      return;
    }
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.translate.instant("FRAME.ASK_FOR_GEOUSAGE_TITLE"),
      message: this.translate.instant("FRAME.ASK_FOR_GEOUSAGE_TEXT"),
      buttons: [
        {
          text: this.translate.instant("FRAME.ASK_FOR_GEOUSAGE_NO"),
          role: "cancel",
          cssClass: "alert-button",
          handler: () => {},
        },
        {
          text: this.translate.instant("FRAME.ASK_FOR_GEOUSAGE_YES"),
          cssClass: "alert-button",
          handler: async () => {
            await Geolocation.requestPermissions();
          },
        },
      ],
    });
    await alert?.present();
  }

  public checkCamera() {
    this.diagnostic
      .isCameraAuthorized()
      .then((data) => {
        if (!data) {
          Camera.requestPermissions({ permissions: ["camera"] }).then((status) => {
            console.log("status: " + status);
          });
        }
      })
      .catch((error) => {});
  }

  private async showError() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: "Fehler",
      message: "Für diese Funktion benötigen wir Zugriff auf Ihre Kamera.",
      buttons: [
        {
          text: "Ablehnen",
          role: "cancel",
          cssClass: "alert-button",
          handler: () => {},
        },
        {
          text: "OK",
          cssClass: "alert-button",
          handler: () => {
            this.uiShareService.cameraOrScanMode = 1;
            this.diagnostic.switchToSettings();
          },
        },
      ],
    });

    await alert.present();
  }
}
