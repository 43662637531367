import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { AdminReclamationDialogComponent } from "src/app/dialogs/admin/admin-reclamation-dialog/admin-reclamation-dialog.component";
import { ImageDialogComponent } from "src/app/dialogs/image-dialog/image-dialog.component";
import { Defect } from "src/app/models/frame";
import { RoutingService } from "src/app/services/routing.service";
import { BaseComponent } from "../../includes/base/base.component";
import { IReclamationDb, IReclamationWriting } from "../../models/reclamation";
import { ApiReclamationService } from "../../services/api/api-reclamation.service";
import { LoaderService } from "../../services/loader.service";
import { MessageService } from "../../services/message.service";
import { ReclamationService } from "../../services/reclamation.service";
import { ReclamationsComponent } from "./../reclamations.component";

@Component({
  selector: "app-reclamation-detail",
  templateUrl: "./reclamation-detail.component.html",
  styleUrls: ["./reclamation-detail.component.scss"],
})
export class ReclamationDetailComponent extends BaseComponent {
  @Input() reclamation: IReclamationDb;
  public appMode = Capacitor.isNativePlatform();
  public reasons: string;
  public defects = new MatTableDataSource<Defect>([]);
  public writings = new MatTableDataSource<IReclamationWriting>([]);
  public twoSided;
  public threeSided;
  public images;
  public delivery;

  constructor(
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: RoutingService,
    public reclamationService: ReclamationService,
    private reclamationApiService: ApiReclamationService,
    private dialog: MatDialog,
    private reclamationsComponent: ReclamationsComponent
  ) {
    super();
  }

  public ngOnInit(): void {
    try {
      if (this.reclamation) {
        if (this.reclamation.delivery == "delivery") {
          this.delivery = this.translateService.instant("RECLAMATION.REPLACEMENT_DELIVERY");
        } else if (this.reclamation.delivery == "byService") {
          this.delivery = this.translateService.instant("RECLAMATION.ALDRA_SERVICE");
        }

        if (this.getFixedValue(this.reclamation.reasons)) {
          const tmp = JSON.parse(this.getFixedValue(this.reclamation.reasons));
          this.reasons = "";
          for (let i = 0; i < tmp.length; i++) {
            if (this.reasons != "") this.reasons += ", ";
            this.reasons += this.translateService.instant("RECLAMATION_MATTER." + tmp[i]);
          }
        }
        if (this.getFixedValue(this.reclamation.positions2SidedGlas)) {
          this.twoSided = [];
          const tmp = JSON.parse(this.getFixedValue(this.reclamation.positions2SidedGlas));
          for (let i = 0; i < tmp.length; i++) this.twoSided.push("" + tmp[i]);
        }
        if (this.getFixedValue(this.reclamation.positions3SidedGlas)) {
          this.threeSided = [];
          const tmp = JSON.parse(this.getFixedValue(this.reclamation.positions3SidedGlas));
          for (let i = 0; i < tmp.length; i++) this.threeSided.push("" + tmp[i]);
        }
        if (this.getFixedValue(this.reclamation.defects)) {
          const defects = JSON.parse(this.getFixedValue(this.reclamation.defects));
          const keys = Object.keys(defects);
          for (const key of keys) {
            if (defects[key]) {
              this.defects.data.push({
                name: this.translateService.instant("RECLAMATION." + key.toUpperCase()),
                amount: defects[key]["Amount"],
                area: defects[key]["Area"],
                mm: defects[key]["MM"],
              });
            }
          }
        }

        this.writings.data = this.reclamation.writings;

        this.reclamationApiService
          .getPreviewImages(this.reclamation.reclamationNumber ? this.reclamation.reclamationNumber : this.reclamation.portalNumber)
          .subscribe((images) => {
            this.images = images.images;
          });
      }
    } catch (e) {
      console.error(e);
      this.messageService.error(this.translateService.instant("RECLAMATION.LOADING_FAIL"));
    }
  }

  public adminEdit() {
    let dialogRef = this.dialog.open(AdminReclamationDialogComponent, {
      width: "450px",
      data: { id: this.reclamation.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reclamationsComponent.reloadData(true);
      }
    });
  }
  private getFixedValue(value): string {
    if (value && value != "null") return value;
    return null;
  }

  public previewImage(item: any): void {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.reclamationApiService
        .getImageById(this.reclamation.reclamationNumber ? this.reclamation.reclamationNumber : this.reclamation.portalNumber, item.fileId)
        .subscribe({
          next: (image) => {
            let dialogRef = this.dialog.open(ImageDialogComponent, {
              width: "450px",
              data: { item: { thumbnailFileBase64: image, thumbnailFileName: item.fileId } },
            });
            this.loaderService.hideTransparentLoader();
          },
          error: (error) => {
            this.messageService.error("Es ist ein Fehler beim Laden des Bildes aufgetreten.");
            this.loaderService.hideTransparentLoader();
          },
        });
    });
  }
}
