<app-custom-ion-header [selected]="'MENU.DASHBOARD'"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.DASHBOARD'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.DASHBOARD'"></app-custom-ion-condense-header>
  <app-custom-content-wrapper [breadcrumb]="breadcrumb">
    <ng-template #wrapperContent>
      <div class="table">
        <div class="cell-50">
          <app-content-header [contentTitle]="'DASHBOARD.ORDERS_TITLE' | translate"></app-content-header>
          <div class="sub-title">
            <a (click)="openOrdersTab(0)"
              >{{ "DASHBOARD.ORDERS_INFO" | translate }} <span class="cnt">{{ ordersCnt1 }}</span></a
            >
          </div>
          <span class="no-data" *ngIf="!orders1 || orders1.length === 0">{{ "DASHBOARD.ORDERS_NO_DATA" | translate }}<br /></span>
          <div class="card" *ngFor="let item of orders1" (click)="openOrder(item)">
            <div class="card-content">
              <span class="header"
                >{{ item?.orderNumber }} <span class="date" *ngIf="item.date !== null">({{ getFixedDate(item.createdDate) }})</span></span
              >

              <span class="cell">
                <img src="./assets/icons/MobileDeliveryGray.png" alt="Delivery week" /><span
                  >{{ "ORDER.DELIVERY_WEEK" | translate }}: KW {{ item.plannedDeliveryWeek }}</span
                >
              </span>

              <span class="cell">{{ item.customerReference }}</span>
            </div>
            <div class="card-arrow">
              <img src="./assets/icons/arrow.png" alt="Arrow" />
            </div>
          </div>
          <a class="more" (click)="openOrdersTab(0)"> {{ "DASHBOARD.ORDERS_MORE" | translate }}</a>
          <br />
          <br />
          <div class="sub-title">
            <a (click)="openOrdersTab(1)"
              >{{ "DASHBOARD.ORDERS_PAY_INFO" | translate }} <span class="cnt">{{ ordersCnt2 }}</span></a
            >
          </div>
          <span class="no-data" *ngIf="!orders2 || orders2.length === 0">{{ "DASHBOARD.ORDERS_NO_DATA2" | translate }}<br /></span>
          <div class="card" *ngFor="let item of orders2" (click)="openOrderToPay(item)">
            <div class="card-content">
              <span class="header"
                >{{ item?.orderNumber }} <span class="date" *ngIf="item.date !== null">({{ getFixedDate(item.createdDate) }})</span></span
              >
              <span class="cell">
                <img src="./assets/icons/MobileDeliveryGray.png" alt="Delivery week" />
                <span>{{ "OFFER.DELIVERY_WEEK" | translate }}: KW {{ item.plannedDeliveryWeek }}</span>
              </span>
              <span class="cell">{{ item.customerReference }}</span>
            </div>
            <div class="card-arrow">
              <img src="./assets/icons/arrow.png" alt="Arrow" />
            </div>
          </div>
          <a class="more" (click)="openOrdersTab(1)"> {{ "DASHBOARD.ORDERS_PAY_MORE" | translate }}</a>
        </div>
        <div class="cell-50">
          <app-content-header [contentTitle]="'DASHBOARD.OFFERS_TITLE' | translate"></app-content-header>
          <div class="sub-title">
            <a (click)="openOffer()">
              {{ "DASHBOARD.OFFERS_INFO" | translate }}
              <span class="cnt">{{ offersCnt1 }}</span></a
            >
          </div>
          <span class="no-data" *ngIf="!offers || offers.length === 0">{{ "DASHBOARD.OFFERS_NO_DATA" | translate }}<br /></span>
          <div class="card" *ngFor="let item of offers" (click)="openOffer(item)">
            <div class="card-content">
              <span class="header"
                >{{ item?.offerNumber }} <span class="date" *ngIf="item.date !== null">({{ getFixedDate(item.createdDate) }})</span></span
              >

              <span class="cell">
                <img src="./assets/icons/MobileDeliveryGray.png" alt="Delivery week" />
                <span>{{ "ORDER.DELIVERY_WEEK" | translate }}: KW {{ item.plannedDeliveryWeek }}</span>
              </span>
              <span class="cell">{{ item.customerReference }}</span>
            </div>
            <div class="card-arrow">
              <img src="./assets/icons/arrow.png" alt="Arrow" />
            </div>
          </div>
          <a class="more" (click)="openOffer()"> {{ "DASHBOARD.OFFERS_MORE" | translate }}</a>
        </div>
      </div>
    </ng-template>
  </app-custom-content-wrapper>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
