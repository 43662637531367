import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { LoaderService } from "src/app/services/loader.service";
import { LogService } from "src/app/services/log.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  mailAddress = "";
  password = "";
  appMode = Capacitor.isNativePlatform();

  constructor(private router: Router, public uiShareService: UiShareService, private loaderService: LoaderService, private logService: LogService) {}

  ngOnInit() {
    /*
    setTimeout(() => {
      this.showCreateAccountModal = true;
    }, 1000);*/
    // check for existing token

    if (this.uiShareService.tokenService.cryptionService.existsInstant("tid") && localStorage.getItem("autoLogin")) {
      // use saved login data
      this.logService.debug("Login.ngOnInit() use saved login data");
      const storageData = this.uiShareService.tokenService.cryptionService.get("tid");
      const tid = JSON.parse(storageData);
      this.uiShareService.accountTools.doLoginById(tid.uid, tid.passwd);
    } else {
      // try token login
      const exists = this.uiShareService.tokenService.cryptionService.exists("jwtRefreshToken");
      if (exists) {
        this.logService.debug("Login.ngOnInit() found token");

        // try auto login by refreshing token
        this.loaderService.showLoaderDelayed().subscribe(() => {
          const storageData = this.uiShareService.tokenService.cryptionService.get("jwtEmail");
          this.uiShareService.tokenService.refresh(storageData).subscribe({
            next: (token) => {
              this.logService.debug("Login.ngOnInit() Got new token");
              if (this.uiShareService.tokenService.cryptionService.exists("jwtAdminToken")) {
                this.uiShareService.adminToken = this.uiShareService.tokenService.cryptionService.get("jwtAdminToken");
              }
              this.uiShareService.loggedIn = true;
              this.uiShareService.loggedInUser = token.user;
              this.uiShareService.multiMerchantLinks = token.multiMerchantLinks;
              this.uiShareService.selectedMerchant = {
                merchantNumber: token.user.merchantNumber,
                companyName: token.user.companyName,
                email: token.user.email,
                uniqueID: token.user.uniqueID,
              };
              this.uiShareService.loggedInChanged();
              this.loaderService.hideLoader();
              this.uiShareService.handleLoginMessage();
              this.uiShareService.accountTools.reloadImageConfig();
              this.uiShareService.accountTools.gotoHomeOrRedirect();
            },
            error: (error) => {
              this.logService.error("Login.ngOnInit() Failed to get new token");
              //this.loadRegistrationSettings();
              this.loaderService.hideLoader();
              this.uiShareService.accountTools.gotoUnprotectedRedirect();
            },
          });
        });
      }
    }
  }
  public preset() {
    if (environment.LOG_LEVEL == 10) {
    }
  }

  public defaultUser() {
    if (environment.LOG_LEVEL == 10) {
      this.mailAddress = "";
      this.password = "";
    }
  }
}
