import { Component, NgZone, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { Approve } from "src/app/models/approve";
import { IOrder } from "src/app/models/order";
import { IOrderPosition } from "src/app/models/order-position";
import { Setting } from "src/app/models/setting";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-order-modals",
  templateUrl: "./order-modals.component.html",
  styleUrls: ["./order-modals.component.scss"],
})
export class OrderModalsComponent implements OnInit {
  public isOpenApproveDialog: boolean;
  public isOpenChangeDialog: boolean;
  public isOpenCancelDialog: boolean;
  public isOpenPreviewImageDialog: boolean;
  public isOpenConfirmDialog: boolean;
  public showConfirmOrderBatchArchiveDialog: boolean;
  public confirmText: string;
  public confirmMode: number;
  public previewImage: string;
  public isOpenChangePerPositionDialog: boolean;
  public wishDeliveryYears = [];
  public wishDeliveryWeeks = [];
  public wishDeliveryDateWeek: number;
  public wishDeliveryDateYear: number;
  public wishDeliveryDateDelay?: number;
  public wishDeliveryDateMinWeek?: number;
  public wishDeliveryDateMinYear?: number;
  public wishDeliveryDateMaxYear?: number;
  public holidayDelay: number = 0;
  public approve: Approve = Object.assign({});
  public item: IOrder;
  public itemPosition: IOrderPosition;
  public requestDoneSubject: BehaviorSubject<IOrder> = new BehaviorSubject(null);
  public approveOnChange: boolean;
  public approveOnChangeEnabled: boolean;
  //HolidaySettings
  private holidaySettings: Setting[] = [];
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private orderService: ApiOrderService,
    private uiShareService: UiShareService,
    private settingsService: ApiSettingsService,
    private ngZone: NgZone
  ) {
    this.approveOnChangeEnabled = environment.APPROVEAFTERCHANGE;
  }

  ngOnInit() {
    this.settingsService.getHolidaySettings().subscribe(
      (res) => {
        this.holidaySettings = res;
      },
      (e) => {
        console.log(e);
      }
    );
  }
  public closeDialog() {
    this.isOpenChangeDialog = false;
    this.isOpenApproveDialog = false;
    this.isOpenCancelDialog = false;
    this.isOpenConfirmDialog = false;
    this.isOpenPreviewImageDialog = false;
    this.isOpenChangePerPositionDialog = false;
    this.approve = Object.assign({});
  }

  public changeChangeMessageOfPosition(evt: any, item: IOrderPosition) {
    evt.stopPropagation();
    this.itemPosition = item;
    this.isOpenChangePerPositionDialog = true;
    this.approve.changeMessage = item.changeMessage;
  }
  public openDialog(evt: any, type: string, item: IOrder): void {
    evt.stopPropagation();
    if (type == "ARCHIVE") {
      this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_ARCHIVE", { orderNumber: item.orderNumber });
      this.confirmMode = 4;
      this.approve.orderNumber = item.orderNumber;
      this.isOpenConfirmDialog = true;
    }
    if (type == "CHANGE") {
      this.approve.positions = item.positions.filter((p) => p.changeMessage);
      this.isOpenChangeDialog = true;
    }
    if (type == "ACCEPT") {
      const debug = false;
      moment.locale("de");
      // calculate time between today and last possible confirm date
      const diffTodayToLastPossibleDate = new Date().getTime() - new Date(item.lastPossibleConfirmDate).getTime();
      const minutesPast = Math.round(diffTodayToLastPossibleDate / 1000 / 60);
      const daysPast = Math.round(diffTodayToLastPossibleDate / 1000 / 60 / 60 / 24);
      if (debug) console.log("lastPossibleConfirmDate", item.lastPossibleConfirmDate);
      if (debug) console.log("plannedDeliveryDate", item.plannedDeliveryDate);
      if (debug) console.log("daysPastUntilConfirmDate", daysPast);
      if (debug) console.log("--------------------");

      // shift planned date if needed
      let plannedDeliveryDate = moment(item.plannedDeliveryDate);
      if (minutesPast >= 0) {
        // last possible date in past!
        const dd = moment(item.lastPossibleConfirmDate);
        const delta = moment().diff(dd, "days");
        plannedDeliveryDate = moment(item.lastPossibleConfirmDate).add(delta, "days");
        this.wishDeliveryDateDelay = daysPast;
        if (debug) console.warn("lastPosConfDateInPast", delta, plannedDeliveryDate.toString());
      } else {
        this.wishDeliveryDateDelay = 0;
      }
      let newPlannedDeliveryDate = plannedDeliveryDate;
      if (debug) console.log("newPlanDelDate", newPlannedDeliveryDate.toString());
      if (debug)
        console.log("newPlanDelDate", newPlannedDeliveryDate.format("w") + ", " + newPlannedDeliveryDate.format("Y") + ", " + newPlannedDeliveryDate.weekday());
      if (debug) console.log("--------------------");

      // minimum date for selection
      const diffDeliveryDateToLastPossibleDate = new Date(item.plannedDeliveryDate).getTime() - new Date(item.lastPossibleConfirmDate).getTime();
      const plannedDeliveryDateDayOfWeek = new Date(item.plannedDeliveryDate).getDay();

      const productionDays = Math.round(diffDeliveryDateToLastPossibleDate / 1000 / 60 / 60 / 24);
      if (debug) console.log("calculatedProductionDays", productionDays);
      if (debug) console.log("--------------------");

      let minSelectionDate = this.addBusinessDays(moment(), productionDays); //moment().add(productionDays, "days");

      if (debug) console.log("minSelctionDate", minSelectionDate);
      if (debug) console.log("minSelectionWeekDay", minSelectionDate.day());
      if (minSelectionDate.day() == 0) {
        minSelectionDate = minSelectionDate.add(1, "days");
      }
      if (minSelectionDate.day() == 6) {
        minSelectionDate = minSelectionDate.add(2, "days");
      }
      // overriding minsSelectiondate with firstPossibleDeliveryDate if available
      const firstPossibleDeliveryDate = moment(item?.firstPossibleDeliveryDate);
      if (firstPossibleDeliveryDate.year() != 1) {
        minSelectionDate = moment(item.firstPossibleDeliveryDate);
      }

      minSelectionDate = this.checkForValidDayOfWeek(plannedDeliveryDateDayOfWeek, minSelectionDate);
      //evaluate holiday settings
      this.holidayDelay = 0;
      const holidaySettingsValues = this.holidaySettings;
      if (holidaySettingsValues && holidaySettingsValues.length > 0) {
        for (let index = 0; index < holidaySettingsValues.length; index++) {
          let minDateChanged = false;
          const setting = holidaySettingsValues[index];
          if (moment(setting.key).isSame(minSelectionDate, "day")) {
            minDateChanged = true;
            const newDate = minSelectionDate.clone();
            newDate.add(1, "weeks");
            this.holidayDelay++;
            minSelectionDate = newDate;
          }
          if (minDateChanged) {
            index = -1;
          }
        }
      }
      if (debug) console.log("--------------------");

      if (newPlannedDeliveryDate < minSelectionDate) {
        if (debug) console.warn("new<min");
        newPlannedDeliveryDate = minSelectionDate;
      }

      if (debug) console.log("--------------------");

      // maximum date
      this.wishDeliveryDateMinWeek = Number(minSelectionDate.format("w"));
      this.wishDeliveryDateMinYear = Number(minSelectionDate.format("Y"));
      this.wishDeliveryDateMaxYear = this.wishDeliveryDateMinYear + 1;
      if (debug) console.log("minWishYear: " + this.wishDeliveryDateMinYear);
      if (debug) console.log("minWishWeek: " + this.wishDeliveryDateMinWeek);
      if (debug) console.log("maxWishYear: " + this.wishDeliveryDateMaxYear);
      if (newPlannedDeliveryDate.year() > this.wishDeliveryDateMaxYear) {
        this.wishDeliveryDateMaxYear = newPlannedDeliveryDate.year() + 1;
      }
      if (debug) console.log("planned: " + newPlannedDeliveryDate.year(), item.plannedDeliveryDate);
      if (debug) console.log(item);

      // defaults
      this.wishDeliveryDateWeek = Number(newPlannedDeliveryDate.format("w")); //this.wishDeliveryDateMinWeek;
      this.wishDeliveryDateYear = Number(newPlannedDeliveryDate.format("Y")); //this.wishDeliveryDateMinYear;
      if (debug) console.log("presetWishWeek: " + this.wishDeliveryDateWeek);
      if (debug) console.log("presetWishYear: " + this.wishDeliveryDateYear);

      this.loadDateFieldOptions();

      if (this.wishDeliveryDateWeek < this.wishDeliveryWeeks[0]) {
        if (debug) console.log("FIX WEEK");
        this.wishDeliveryDateWeek = this.wishDeliveryWeeks[0];
      }

      if (item.positions.filter((p) => p.changeMessage).length > 0) {
        // messages set!
        this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_ELSE_DROP_MESSAGES");
        this.confirmMode = 2;
        this.isOpenConfirmDialog = true;
      } else {
        this.postOpenDialog(type, item);
      }
    }
    if (type == "CANCEL") {
      if (item.positions.filter((p) => p.changeMessage).length > 0) {
        // messages set!
        this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_ELSE_DROP_MESSAGES");
        this.confirmMode = 3;
        this.isOpenConfirmDialog = true;
      } else {
        this.postOpenDialog(type, item);
      }
    }
    this.item = item;
    this.approve.orderNumber = this.item.orderNumber;
  }
  checkForValidDayOfWeek(plannedDeliveryDateDayOfWeek: number, minSelectionDate: any): any {
    const minSelectionDateDayOfWeek = minSelectionDate.day();
    if (minSelectionDateDayOfWeek > plannedDeliveryDateDayOfWeek) {
      const newDate = minSelectionDate.clone();
      newDate.add(1, "weeks");
      return newDate;
    }
    return minSelectionDate;
  }
  public wishDeliveryDateYearChanged(event: any) {
    this.wishDeliveryWeeks = [];
    if (event == this.wishDeliveryDateMinYear) {
      for (let i = this.wishDeliveryDateMinWeek; i <= 52; i++) {
        this.wishDeliveryWeeks.push(i);
      }
      this.wishDeliveryDateWeek = this.wishDeliveryDateMinWeek;
    } else {
      for (let i = 1; i <= 52; i++) {
        this.wishDeliveryWeeks.push(i);
      }
      this.wishDeliveryDateWeek = 1;
    }
  }
  private addBusinessDays(originalDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;
    const newDate = originalDate.clone();
    while (daysRemaining > 0) {
      newDate.add(1, "days");
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--;
      }
    }
    return newDate;
  }
  private loadDateFieldOptions() {
    this.wishDeliveryWeeks = [];
    this.wishDeliveryYears = [];

    let weekStart = 1;
    if (this.wishDeliveryDateYear == this.wishDeliveryDateMinYear) {
      // selected year = min year
      weekStart = this.wishDeliveryDateMinWeek;
    }

    for (let i = weekStart; i <= 52; i++) {
      this.wishDeliveryWeeks.push(i);
    }

    for (let i = this.wishDeliveryDateMinYear; i <= this.wishDeliveryDateMaxYear; i++) {
      this.wishDeliveryYears.push(i);
    }
  }
  public postOpenDialog(type: string, item: IOrder): void {
    if (type == "ACCEPT") {
      this.isOpenApproveDialog = true;
    }
    if (type == "CANCEL") this.isOpenCancelDialog = true;
  }
  public setChangeToPosition(): void {
    this.itemPosition.changeMessage = this.approve.changeMessage;
    this.isOpenChangePerPositionDialog = false;
    this.approve = Object.assign({});
  }
  public approveAfterChangeChanged(evt: any) {
    this.approveOnChange = evt;
  }
  public closeConfirm(): void {
    this.isOpenConfirmDialog = false;
  }
  public confirmOrder(): void {
    this.closeConfirm();
    if (this.confirmMode == 1) {
      if (this.approveOnChange) {
        // double ask for approve on change!
        this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_APPROVE_ON_CHANGE");
        this.confirmMode = 10;
        this.isOpenConfirmDialog = true;
      } else {
        this.postChangeOrder();
      }
    } else if (this.confirmMode == 10) {
      this.postChangeOrder();
    } else if (this.confirmMode == 2) {
      this.postOpenDialog("ACCEPT", this.item);
    } else if (this.confirmMode == 3) {
      this.postOpenDialog("CANCEL", this.item);
    } else if (this.confirmMode == 4) {
      this.postArchiveOrder();
    }
  }

  public changeOrder(): void {
    if (this.approve.positions.length == 0) {
      // double ask for positions!
      this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_APPROVE_NO_ARTICLES");
      this.confirmMode = 1;
      this.isOpenConfirmDialog = true;
    } else {
      if (this.approveOnChange) {
        // double ask for approve on change!
        this.confirmText = this.translateService.instant("ORDER_DIALOG.CONFIRM_APPROVE_ON_CHANGE");
        this.confirmMode = 10;
        this.isOpenConfirmDialog = true;
      } else {
        this.postChangeOrder();
      }
    }
  }
  private postArchiveOrder(): void {
    let successMessage = this.translateService.instant("ORDER_DIALOG.ARCHIVE_SUCCESS");
    let errorMessage = this.translateService.instant("ORDER_DIALOG.ARCHIVE_ERROR");

    this.loaderService.showLoaderDelayed().subscribe(null, null, () => {
      this.orderService.archive(this.approve.orderNumber).subscribe(
        (data) => {
          this.uiShareService.ordersNeedReload = true;
          this.requestDoneSubject.next(data);
          this.loaderService.hideLoader();
          this.approve = Object.assign({});
          this.messageService.success(successMessage);
        },
        (error) => {
          this.loaderService.hideLoader();
          this.messageService.error(errorMessage);
        }
      );
    });
  }
  private postChangeOrder(): void {
    this.approve.changeMessagesPerPosition = Object.assign({});
    this.approve.positions.forEach((p) => {
      this.approve.changeMessagesPerPosition[p.positionIndex] = p.changeMessage;
    });
    this.approve.type = 1;
    this.approve.approveOnChange = this.approveOnChange;
    let success = this.translateService.instant("ORDER_DIALOG.CHANGE_SUCCESS");
    let error = this.translateService.instant("ORDER_DIALOG.CHANGE_ERROR");
    this.organizeRequest(success, error);
  }
  public acceptOrder(): void {
    this.approve.type = 0;
    this.approve.wishDeliveryDate = this.wishDeliveryDateYear + "-W" + this.wishDeliveryDateWeek;
    let success = this.translateService.instant("ORDER_DIALOG.APPROVE_SUCCESS");
    let error = this.translateService.instant("ORDER_DIALOG.APPROVE_ERROR");
    this.organizeRequest(success, error);
  }
  public cancelOrder(): void {
    this.approve.type = 2;
    let success = this.translateService.instant("ORDER_DIALOG.CANCEL_SUCCESS");
    let error = this.translateService.instant("ORDER_DIALOG.CANCEL_ERROR");
    this.organizeRequest(success, error);
  }
  public organizeRequest(successMessage: string, errorMessage): void {
    this.loaderService.showLoaderDelayed().subscribe(null, null, () => {
      this.orderService.approve(this.approve).subscribe(
        (data) => {
          this.uiShareService.ordersNeedReload = true;
          this.requestDoneSubject.next(data);
          this.isOpenApproveDialog = false;
          this.isOpenChangeDialog = false;
          this.isOpenCancelDialog = false;

          this.loaderService.hideLoader();
          this.approve = Object.assign({});
          this.messageService.success(successMessage);
        },
        (error) => {
          this.loaderService.hideLoader();
          this.messageService.error(errorMessage);
        }
      );
    });
  }

  batchRunning = false;
  batchCompleted = false;
  public confirmOrderBatchArchiveDialog$: Subject<boolean>;
  public openOrderBatchArchiveModal(text: string) {
    this.confirmText = text;
    this.showConfirmOrderBatchArchiveDialog = true;
    this.confirmOrderBatchArchiveDialog$ = new Subject<boolean>();
    return this;
  }
  public closeBatchModal() {
    this.confirmOrderBatchArchiveDialog$.next(false);
    this.confirmOrderBatchArchiveDialog$.complete();
    this.showConfirmOrderBatchArchiveDialog = false;
    this.confirmOrderBatchArchiveDialog$ = null;
    this.batchCompleted = false;
    this.batchRunning = false;
  }
  public confirmBatchModal() {
    this.confirmOrderBatchArchiveDialog$.next(true);
    this.confirmOrderBatchArchiveDialog$.complete();
    if (this.batchCompleted === true) {
      this.confirmOrderBatchArchiveDialog$ = null;
      this.showConfirmOrderBatchArchiveDialog = false;
      this.batchCompleted = false;
      this.batchRunning = false;
    }
  }
}
