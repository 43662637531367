import { AfterViewInit, Component, OnDestroy, ViewChild, effect } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, merge, of } from "rxjs";
import { catchError, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { SelectedArchivesDialogComponent } from "src/app/dialogs/selected-archives-dialog/selected-archives-dialog.component";
import { CustomIonHeaderCondenseComponent } from "src/app/header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "src/app/header/custom-ion-header/custom-ion-header.component";
import { BaseComponent } from "src/app/includes/base/base.component";
import { PagerComponent } from "src/app/includes/pager/pager.component";
import { Breadcrumb } from "src/app/models/breadcrumb";
import { IReclamationDb, IReclamationStatus, IReclamationType } from "src/app/models/reclamation";
import { SortType } from "src/app/models/sort";
import { Tab, TabGroup } from "src/app/models/tab";
import { ApiReclamationService } from "src/app/services/api/api-reclamation.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reclamations",
  templateUrl: "./reclamations.component.html",
  styleUrls: ["./reclamations.component.scss"],
})
export class ReclamationsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public environment = environment;
  @ViewChild(CustomIonHeaderCondenseComponent)
  header1: CustomIonHeaderCondenseComponent;
  @ViewChild(CustomIonHeaderComponent) header2: CustomIonHeaderComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("pager1") pager1: PagerComponent;
  @ViewChild("pager2") pager2: PagerComponent;
  dataSource = new MatTableDataSource<IReclamationDb>([]);
  expandedRows: IReclamationDb[] = [];
  defaultSort: SortType = {
    type: "createdDate",
    sort: "desc",
  };

  ngUnsubscribe = new Subject<void>();
  refresh = new Subject<void>();
  loadReclamations = () => this.apiReclamationService.getReclamations();
  loadReclamationsArchive = () => this.apiReclamationService.getArchive();

  public tabGroups: TabGroup[] = [
    {
      dataLoader: this.loadReclamations,
      cacheLoader: () => this.uiShareService.reclamations,
      cacheStorer: (data) => (this.uiShareService.reclamations = data),
      defaultColumns: [
        "expand",
        "select",
        "reclamationNumber",
        "createdDate",
        "status",
        "orderNumber",
        "customerName",
        "contactPerson",
        "product",
        "errorDescription",
        "orderNumberInfo",
        "positions2SidedGlasStr",
        "positions3SidedGlasStr",
        "photosCount",
        "writings",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      tabs: [
        {
          title: "Standard",
          id: 0,
          active: true,
          tabFilter: (o) => o.reclamationType == IReclamationType.Default,
          excludedColumns: ["orderNumberInfo", "positions2SidedGlasStr", "positions3SidedGlasStr"],
          subTabs: [
            {
              title: "Neu",
              id: 0,
              tabFilter: (o) => o.status == IReclamationStatus.New,
            },
            {
              title: "In Bearbeitung",
              id: 1,
              tabFilter: (o) => o.status == IReclamationStatus.Processing,
            },
            {
              title: "Abgeschlossen",
              id: 2,
              tabFilter: (o) => o.status == IReclamationStatus.Done,
            },
          ],
        },
        {
          title: "Glas",
          id: 1,
          tabFilter: (o) => o.reclamationType == IReclamationType.Glas,
          excludedColumns: ["product", "errorDescription"],
          subTabs: [
            {
              title: "Neu",
              id: 0,
              tabFilter: (o) => o.status == IReclamationStatus.New,
            },
            {
              title: "In Bearbeitung",
              id: 1,
              tabFilter: (o) => o.status == IReclamationStatus.Processing,
            },
            {
              title: "Abgeschlossen",
              id: 2,
              tabFilter: (o) => o.status == IReclamationStatus.Done,
            },
          ],
        },
      ],
    },
    {
      dataLoader: this.loadReclamationsArchive,
      cacheLoader: () => this.uiShareService.reclamationsArchive,
      cacheStorer: (data) => (this.uiShareService.reclamationsArchive = data),
      defaultColumns: [
        "expand",
        "reclamationNumber",
        "createdDate",
        "status",
        "orderNumber",
        "customerName",
        "contactPerson",
        "product",
        "errorDescription",
        "orderNumberInfo",
        "positions2SidedGlasStr",
        "positions3SidedGlasStr",
        "photosCount",
        "writings",
        "contact",
      ],
      defaultReadOnlyExcludedColumns: ["contact"],
      tabs: [
        {
          title: "Archiv",
          id: 2,
          subTabs: [
            {
              title: "Standard",
              id: 0,
              tabFilter: (o) => o.reclamationType == IReclamationType.Default,
              excludedColumns: ["orderNumberInfo", "positions2SidedGlasStr", "positions3SidedGlasStr"],
            },
            {
              title: "Glas",
              id: 1,
              tabFilter: (o) => o.reclamationType == IReclamationType.Glas,
              excludedColumns: ["product", "errorDescription"],
            },
          ],
        },
      ],
    },
  ];

  currentTabGroup = this.tabGroups[0];
  currentTab = this.currentTabGroup.tabs[0];
  currentSubTab: Tab;
  displayedColumns: string[] = [];
  currentData: IReclamationDb[] = [];
  mobileData: IReclamationDb[] = [];

  constructor(
    private loaderService: LoaderService,
    private apiReclamationService: ApiReclamationService,
    private translateService: TranslateService,
    public uiShareService: UiShareService,
    private router: RoutingService,
    private messageService: MessageService,
    private dialog: MatDialog,
  ) {
    super();
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.RECLAMATION_LIST"),
        page: null,
      },
    ];
    this.dataSource.filterPredicate = (data, filter) => {
      let term =
        data.reclamationNumber +
        this.getFixedDate(data.createdDate) +
        data.orderNumber +
        data.customerName +
        data.contactPerson +
        data.product +
        data.errorDescription +
        data.orderNumberInfo;
      term = term.toLowerCase().trim();
      filter = filter.toLowerCase().trim();
      return filter.split(" ").every((e) => term.includes(e));
    };
    effect(() => {
      this.getColumnsToDisplay(this.currentTabGroup, this.currentTab);
    });
  }

  ngOnDestroy(): void {
    try {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    merge(this.header1.onReloadClickedSubject, this.header2.onReloadClickedSubject, this.refresh)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith({}),
        switchMap(() => {
          this.loaderService.showLoader();
          if (this.uiShareService.reclamationsNeedReload) {
            this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.needReload = true)));
          }
          return this.getData(this.currentTabGroup, this.currentTab).pipe(catchError(() => of(null)));
        }),
      )
      .subscribe((data: IReclamationDb[]) => {
        this.loaderService.hideLoader();
        this.uiShareService.reclamationsNeedReload = false;
        if (data) {
          data.forEach((o) => {
            this.positionsToStr(o);
          });
          data = data.sort((a, b) => (b.createdDate as any).localeCompare(a.createdDate as any)); // sort for mobile
          this.storeData(this.currentTabGroup, this.currentTab, data);
          this.currentData = data;
          this.currentTabGroup.tabs.forEach((tab) => {
            tab.needReload = false;
            tab.count = this.getTabData(tab).length;
            tab.subTabs.forEach((subTab) => {
              subTab.count = this.getTabData(tab, subTab).length;
            });
          });
          const tabData = this.getCurrentTabData();
          this.dataSource.data = tabData;
          this.pager1.resetPage(this.dataSource.filteredData.length);
        }
      });

    this.globalSearchField.valueChanges.subscribe((term) => {
      this.dataSource.filter = term;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    });

    this.sort.sortChange.subscribe((e) => {
      this.uiShareService.currentReclamationSortTypes[this.currentTab.id] = {
        type: e.active,
        sort: e.direction,
      };
      this.uiShareService.saveViewOptions();
    });

    this.pager1.init(1, this.pager2);
    this.pager2.init(2, this.pager1);

    // restore sort
    if (!this.uiShareService.currentReclamationSortTypes[this.currentTab.id]) {
      this.uiShareService.currentReclamationSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentReclamationSortTypes[this.currentTab.id]);

    // restore last tab
    for (let i = 0; i < this.tabGroups.length; i++) {
      for (let j = 0; j < this.tabGroups[i].tabs.length; j++) {
        if (this.tabGroups[i].tabs[j].id == this.uiShareService.currentReclamationTabId) {
          this.switchTab(this.tabGroups[i], this.tabGroups[i].tabs[j]);
          break;
        }
      }
    }
  }

  private getData(tabGroup: TabGroup, tab: Tab): Observable<any> {
    if (!tab.needReload && tabGroup.cacheLoader && tabGroup.cacheLoader()) {
      return of(tabGroup.cacheLoader());
    } else {
      return tabGroup.dataLoader();
    }
  }

  private storeData(tabGroup: TabGroup, tab: Tab, data: any) {
    if (tabGroup.cacheStorer) {
      tabGroup.cacheStorer(data);
    }
  }

  private getCurrentTabData() {
    return this.getTabData(this.currentTab, this.currentSubTab);
  }

  private getTabData(tab: Tab, subTab?: Tab) {
    let data = this.currentData;
    if (tab.tabFilter) {
      data = data.filter(tab.tabFilter);
    }
    if (subTab?.tabFilter) {
      data = data.filter(subTab.tabFilter);
    }
    return data;
  }

  public openContactForm(mode, item): void {
    this.router.forwardByUrl("/" + mode + "/reclamation/" + (item.reclamationNumber ? item.reclamationNumber : item.portalNumber) + "/-");
  }

  public reloadData(force = false) {
    if (force) {
      this.uiShareService.reclamationsNeedReload = true;
    }
    this.refresh.next();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public allSelected() {
    if (this.dataSource.filteredData.length == 0) {
      return false;
    }
    return this.dataSource.filteredData.every((o) => o.selected);
  }

  public selectAll(evt: any) {
    this.dataSource.filteredData.forEach((o) => (o.selected = evt?.currentTarget?.checked));
  }

  public selectedItemCount(): number {
    return this.dataSource.data?.filter((order) => order.selected).length;
  }

  public switchTab(tabGroup: TabGroup, tab: Tab) {
    if (tab.active) {
      if (this.currentTab.subTabs) {
        if (this.currentTab.id == 2) {
          this.switchSubTab(this.currentTab.subTabs[this.uiShareService.currentReclamationArchiveTabId]);
        } else {
          this.switchSubTab(this.currentTab.subTabs[this.uiShareService.currentReclamationSubTabId]);
        }
      }
      return;
    }
    this.expandedRows = [];
    this.getColumnsToDisplay(tabGroup, tab);
    const previousLoader = this.currentTabGroup.dataLoader;
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => (tab.active = false)));
    tab.active = true;
    this.currentTab = tab;
    this.currentTabGroup = tabGroup;
    this.uiShareService.currentReclamationTabId = this.currentTab.id;
    if (!this.uiShareService.currentReclamationSortTypes[this.currentTab.id]) {
      this.uiShareService.currentReclamationSortTypes[this.currentTab.id] = { type: this.defaultSort.type, sort: this.defaultSort.sort };
    }
    this.doSort(this.uiShareService.currentReclamationSortTypes[this.currentTab.id]);
    this.uiShareService.saveViewOptions();
    if (previousLoader !== tabGroup.dataLoader) {
      this.reloadData();
    } else {
      const tabData = this.getCurrentTabData();
      this.dataSource.data = tabData;
      this.pager1.resetPage(this.dataSource.filteredData.length);
    }

    if (this.currentTab.subTabs) {
      if (this.currentTab.id == 2) {
        this.switchSubTab(this.currentTab.subTabs[this.uiShareService.currentReclamationArchiveTabId]);
      } else {
        this.switchSubTab(this.currentTab.subTabs[this.uiShareService.currentReclamationSubTabId]);
      }
    }
  }

  public switchSubTab(tab: Tab) {
    if (tab.active) {
      return;
    }
    this.getColumnsToDisplay(this.currentTabGroup, this.currentTab, tab);
    this.tabGroups.forEach((group) => group.tabs.forEach((tab) => tab.subTabs.forEach((subTab) => (subTab.active = false))));
    tab.active = true;
    this.currentSubTab = tab;
    if (this.currentTab.id == 2) {
      this.uiShareService.currentReclamationArchiveTabId = this.currentSubTab.id;
    } else {
      this.uiShareService.currentReclamationSubTabId = this.currentSubTab.id;
    }
    this.uiShareService.saveViewOptions();
    const tabData = this.getCurrentTabData();
    this.dataSource.data = tabData;
    this.pager1.resetPage(this.dataSource.filteredData.length);
  }

  public getColumnsToDisplay(tabGroup: TabGroup, tab: Tab, subTab?: Tab) {
    let columns = subTab?.columns || tab.columns || tabGroup.defaultColumns;

    if (tabGroup.defaultReadOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tabGroup.defaultReadOnlyExcludedColumns.includes(c));
    }
    if (tab.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !tab.readOnlyExcludedColumns.includes(c));
    }
    if (tab.excludedColumns) {
      columns = columns.filter((c) => !tab.excludedColumns.includes(c));
    }
    if (subTab?.readOnlyExcludedColumns && this.uiShareService.readOnlyMode()) {
      columns = columns.filter((c) => !subTab?.readOnlyExcludedColumns.includes(c));
    }
    if (subTab?.excludedColumns) {
      columns = columns.filter((c) => !subTab?.excludedColumns.includes(c));
    }
    this.displayedColumns = columns;
  }

  public batchArchive() {
    const reclamationsToArchive = this.dataSource.data.filter((reclamation) => reclamation.selected === true);
    if (!reclamationsToArchive) {
      return;
    }
    const dialogRef = this.dialog.open(SelectedArchivesDialogComponent, {
      height: "auto",
      width: "400px",
      data: {
        itemName: "Reklamationen",
      },
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res == true) {
        const successMessage = "Archivierung abgeschlossen";
        const reclamationIds = reclamationsToArchive.map((reclamation) => reclamation.id);
        this.apiReclamationService
          .archive(reclamationIds)
          .pipe(take(1))
          .subscribe({
            next: (data) => {
              this.messageService.success(successMessage);
            },
            error: (error) => {
              this.messageService.error(error.message);
            },
            complete: () => {
              this.reloadData(true);
            },
          });
      }
    });
  }

  public createReclamation(subinfo?: string): void {
    if (Capacitor.isNativePlatform()) {
      if (subinfo) {
        this.router.forward(["/reclamation-scan", subinfo]);
      } else {
        this.router.forward(["/reclamation-scan", ""]);
      }
    } else {
      if (subinfo) {
        this.router.forward(["/reclamation-create", subinfo]);
      } else {
        this.router.forward(["/reclamation-create"]);
      }
    }
  }

  private doSort(sortType: SortType) {
    sortType.sort = sortType.sort.replace("ASC", "asc").replace("DESC", "desc").replace("DSC", "desc") as any; // for legacy support
    if (!sortType.sort) {
      sortType.sort = this.defaultSort.sort;
    }
    if (!sortType.type) {
      sortType.type = this.defaultSort.type;
    }
    this.sort.sort({ id: null, start: sortType.sort, disableClear: true });
    this.sort.sort({ id: sortType.type, start: sortType.sort, disableClear: true });
  }

  public onPageChanged(pagerData: any): void {
    this.mobileData = this.dataSource.filteredData.slice(pagerData.start, pagerData.end);
  }

  private positionsToStr(data: IReclamationDb) {
    data.positions2SidedGlasStr = "";
    if (data.positions2SidedGlas && data.positions2SidedGlas != "null") {
      const tmp = JSON.parse(data.positions2SidedGlas);
      for (let i = 0; i < tmp.length; i++) {
        if (data.positions2SidedGlasStr != "") data.positions2SidedGlasStr += ", ";
        data.positions2SidedGlasStr += tmp[i] + 1;
      }
    }
    data.positions3SidedGlasStr = "";
    if (data.positions3SidedGlas && data.positions3SidedGlas != "null") {
      const tmp = JSON.parse(data.positions3SidedGlas);
      for (let i = 0; i < tmp.length; i++) {
        if (data.positions3SidedGlasStr != "") data.positions3SidedGlasStr += ", ";
        data.positions3SidedGlasStr += tmp[i] + 1;
      }
    }
  }
}
