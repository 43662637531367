<ion-menu *ngIf="!appMode" side="end" type="push" contentId="menuContent">
  <div class="sidebar-right">
    <div class="logo">
      <img src="./assets/images/MeinAldra_Logo.png" width="160" alt="" />
    </div>
    <div class="sidebar">
      <ul class="sidebar-header">
        <ng-template [ngTemplateOutlet]="defaultMenu"></ng-template>
      </ul>
    </div>
  </div>
</ion-menu>
<ng-template #defaultMenu>
  <div class="nav-item scroll right-menu-hide" (click)="closeMenu()" [routerLink]="['/home']">
    <div class="home"></div>
  </div>

  <li class="nav-item right-menu" (click)="closeMenu()" [routerLink]="['/home']">
    <a>{{ "MENU.START" | translate }}</a>
  </li>
  <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (click)="closeMenu()"
    [routerLink]="['/dashboard']" [ngClass]="{ selected: isRouteHighlighed('MENU.DASHBOARD') }">
    <a>{{ "MENU.DASHBOARD" | translate }}</a>
  </li>
  <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (click)="closeMenu()"
    [routerLink]="['/offer']" [ngClass]="{ selected: isRouteHighlighed('MENU.OFFER') }">
    <a>{{ "MENU.OFFER" | translate }}</a>
  </li>
  <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (click)="closeMenu()"
    [routerLink]="['/order']" [ngClass]="{ selected: isRouteHighlighed('MENU.ORDER') }">
    <a>{{ "MENU.ORDER" | translate }}</a>
  </li>
  <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" (click)="closeMenu()" [routerLink]="['/shipment-notes']"
    [ngClass]="{ selected: isRouteHighlighed('MENU.SHIPMENT-NOTES') }">
    <a>{{ "MENU.SHIPMENT-NOTES" | translate }}</a>
  </li>
  <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" (click)="closeMenu()" [routerLink]="['/invoices']"
    [ngClass]="{ selected: selected === 'Rechnungen' }">
    <a>Rechnungen</a>
  </li>
  <li class="nav-item has-sub default-menu reclamations" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [ngClass]="{
      selected: isRouteHighlighed('MENU.RECLAMATION')
    }">
    <a (click)="closeMenu()" [routerLink]="['/reclamation']">{{ "MENU.RECLAMATION" | translate }}</a>
    <div class="whiteline"></div>
    <ul class="submenu">
      <li (click)="closeMenu()" [routerLink]="['/reclamation']">
        {{ "MENU.RECLAMATION_LIST" | translate }}
      </li>
      <li *ngIf="!uiShareService.readOnlyMode()" (click)="closeMenu()" [routerLink]="['/reclamation-create', 'reclamation-glass']">
        {{ "MENU.RECLAMATION_GLASS" | translate }}
      </li>
      <li *ngIf="!uiShareService.readOnlyMode()" (click)="closeMenu()" [routerLink]="['/reclamation-create']">
        {{ "MENU.RECLAMATION_DEFAULT" | translate }}
      </li>
    </ul>
  </li>
  <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [ngClass]="{
      selected: isRouteHighlighed('MENU.RECLAMATION')
    }" (click)="toggleReclamationMenu()">
    <a>{{ "MENU.RECLAMATION" | translate }}</a>
    <div class="icon">
      <img
        [src]="reclamationMenuVisible || isRouteHighlighed('MENU.RECLAMATION') ? './assets/icons/MenuIconDownRed.png' : './assets/icons/MenuIconDownGray.png'"
        alt="" />
    </div>
  </li>
  <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
    [ngClass]="{ selected: selected === 'MENU.RECLAMATION_LIST' }" (click)="closeMenu()" [routerLink]="['/reclamation']">
    <a>{{ "MENU.RECLAMATION_LIST" | translate }}</a>
  </li>
  <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
    [ngClass]="{ selected: selected === 'MENU.RECLAMATION_GLASS' }" (click)="closeMenu()" [routerLink]="['/reclamation-create', 'reclamation-glass']">
    <a>{{ "MENU.RECLAMATION_GLASS" | translate }}</a>
  </li>
  <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
    [ngClass]="{ selected: selected === 'MENU.RECLAMATION_DEFAULT' }" (click)="closeMenu()" [routerLink]="['/reclamation-create']">
    <a>{{ "MENU.RECLAMATION_DEFAULT" | translate }}</a>
  </li>

  <li class="nav-item has-sub default-menu" *ngIf="uiShareService.loggedIn" [ngClass]="{
      selected: isRouteHighlighed('MENU.FRAME')
    }">
    <a (click)="closeMenu()" [routerLink]="['/frame']">{{ "MENU.FRAME" | translate }}</a>
    <div class="whiteline"></div>
    <ul class="submenu">
      <li (click)="closeMenu()" [routerLink]="['/frame']">{{ "MENU.FRAME_LIST" | translate }}</li>
      <li *ngIf="!uiShareService.readOnlyMode()" (click)="closeMenu()" [routerLink]="['/frame-create']">
        {{ "MENU.FRAME_CREATE" | translate }}
      </li>
    </ul>
  </li>
  <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn" [ngClass]="{
      selected: isRouteHighlighed('MENU.FRAME')
    }" (click)="toggleFrameMenu()">
    <a>{{ "MENU.FRAME" | translate }}</a>
    <div class="icon">
      <img [src]="frameMenuVisible || isRouteHighlighed('MENU.FRAME') ? './assets/icons/MenuIconDownRed.png' : './assets/icons/MenuIconDownGray.png'" alt="" />
    </div>
  </li>
  <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && frameMenuVisible" [ngClass]="{ selected: selected === 'MENU.FRAME_LIST' }"
    (click)="closeMenu()" [routerLink]="['/frame']">
    <a>{{ "MENU.FRAME_LIST" | translate }}</a>
  </li>
  <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && frameMenuVisible" [ngClass]="{ selected: selected === 'MENU.FRAME_CREATE' }"
    (click)="closeMenu()" [routerLink]="['/frame-create']">
    <a>{{ "MENU.FRAME_CREATE" | translate }}</a>
  </li>

  <li class="nav-item" *ngIf="uiShareService.loggedIn" [ngClass]="{ selected: selected === 'MENU.EXPERTHEK' }" (click)="openExperthek()">
    <a>{{ "MENU.EXPERTHEK" | translate }}</a>
  </li>

  <div class="nav-item scroll"
    *ngIf="uiShareService.loggedIn && !isMenuSelected && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (click)="closeMenu()"
    [routerLink]="['/user-administration']">
    <div class="avatar-plus"></div>
  </div>
  <div class="nav-item scroll" *ngIf="uiShareService.loggedIn && !isMenuSelected" (click)="closeMenu()" [routerLink]="['/mydata']">
    <div class="avatar"></div>
  </div>
  <div class="nav-item scroll" *ngIf="uiShareService.loggedIn && !isMenuSelected" (click)="closeMenu()" [routerLink]="['/logout']">
    <div class="logout"></div>
  </div>

  <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn" (click)="closeMenu()" [routerLink]="['/logout']">
    <a>{{ "MENU.LOGOUT" | translate }}</a>
  </li>
</ng-template>