import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() checked: boolean;
  @Output() onChanged = new EventEmitter<boolean>();
  isChecked = false;
  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;

  constructor() {}

  writeValue(obj: any): void {
    this.isChecked = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isChecked = changes.checked.currentValue;
    this.checked = this.isChecked;
    this.onChange(this.isChecked);
  }

  ngOnInit() {
    this.isChecked = this.checked == true;
    this.onChange(this.isChecked);
  }

  toggle() {
    this.isChecked = !this.isChecked;
    this.onChanged.emit(this.isChecked);
    this.onChange(this.isChecked);
  }
}
