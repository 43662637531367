export class User {
  id: string;
  email: string;
  password: string;
  type: UserType;
  firstName: string;
  lastName: string;
  city: string;
  companyName: string;
  houseNumber: string;
  mobile: string;
  phone: string;
  street: string;
  zipCode: string;
  country: string;
  merchantNumber: string;
  infoOnNewOrder: UserNotificationState;
  infoOnOrderToConfirm: UserNotificationState;
  infoOnChangedOrder: UserNotificationState;
  infoOnChangedOrderDelivery: UserNotificationState;
  infoOnOrderInPayment: UserNotificationState;
  infoOnOrderInProduction: UserNotificationState;
  infoOnOrderDelivered: UserNotificationState;
  infoOnNewOffer: UserNotificationState;
  infoOnOfferToConfirm: UserNotificationState;
  infoOnOfferExpiring: UserNotificationState;
  infoOnChangedOffer: UserNotificationState;
  infoOnNewReclamation: UserNotificationState;
  infoOnChangedReclamation: UserNotificationState;
  infoOnDoneReclamation: UserNotificationState;
  infoOnDeliveredFrame: UserNotificationState;
  infoOnPickedUpFrame: UserNotificationState;
  infoOnFrameInPickup: UserNotificationState;
  infoOnFrameInDelivery: UserNotificationState;
  infoOnChangedShipmentNote: UserNotificationState;
  infoOnNewShipmentNote: UserNotificationState;
  infoOnSummaryIntervalInDays: number;
  infoOnSummaryTwiceADay: boolean;
  infoOnSummaryAddOffers: boolean;
  infoOnSummaryAddOrders: boolean;
  needsPasswordChange: boolean;
  isMainMerchantUser: boolean;
  merchantLinkAfterApprove: string;
  linkMerchantID: string;
  workflowState: UserWorkflowState;
  uniqueID: string;
  accountType: number;
  activateNotifications?: boolean;
  orderPDFwithPrices: boolean;
  offerPDFwithPrices: boolean;
}
export interface MerchantAccount {
  merchantNumber: string;
  companyName: string;
  email: string;
  uniqueID: string;
}
export enum UserWorkflowState {
  New,
  Verified,
  Confirmed,
  Deactivated,
}
export enum UserType {
  Administrator,
  Sales,
  User, //NOSONAR
  Anonymous,
  MerchantAdmin,
  None,
}
export enum UserNotificationState {
  Both,
  Email,
  Push,
  None,
}
export class LostPassword {
  email: string;
}
export class ConfirmAccount {
  code: string;
  activateNotifications?: boolean;
}
export class ResetPassword {
  password: string;
  code: string;
}
export class UpdatePassword {
  password: string;
  id: string;
}
export class MyData {
  id: string;
  firstName: string;
  lastName: string;
  city: string;
  companyName: string;
  houseNumber: string;
  mobile: string;
  phone: string;
  street: string;
  zipCode: string;
  country: string;
  merchantNumber: string;
}
export class MyDataInfos {
  id: string;
  infoOnNewOrder: UserNotificationState;
  infoOnOrderToConfirm: UserNotificationState;
  infoOnChangedOrder: UserNotificationState;
  infoOnChangedOrderDelivery: UserNotificationState;
  infoOnNewOffer: UserNotificationState;
  infoOnOfferToConfirm: UserNotificationState;
  infoOnChangedOffer: UserNotificationState;
}
