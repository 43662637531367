import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-privacy-text",
  templateUrl: "./privacy-text.component.html",
  styleUrls: ["./privacy-text.component.scss"],
})
export class PrivacyTextComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
