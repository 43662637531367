import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoaderService } from "src/app/services/loader.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { RoutingService } from "src/app/services/routing.service";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-home-logo",
  templateUrl: "./home-logo.component.html",
  styleUrls: ["./home-logo.component.scss"],
})
export class HomeLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
