<app-modal id="commonErrorModal" [show]="showCommonErrorModal" maxWidth="400px" (modalClosed)="errorModalClosed()">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ title }}"></app-content-header>
    <p innerHtml="{{ text }}" style="word-wrap: break-word"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button [style]="{ float: extraButtonTitle !== '' ? 'left' : 'right' }" (click)="errorModalClosed()">
      {{ "COMMON.OK" | translate }}
    </button>
    <button style="float: right" *ngIf="extraButtonTitle !== ''" (click)="errorModalExtraClicked()">
      {{ extraButtonTitle }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="country-dialog" [show]="isOpenCountryDialog" minWidth="350px" maxWidth="400px" height="80%">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'COUNTRY_DIALOG.TITLE' | translate }}"></app-content-header>
    <div class="dialog-row" style="padding-bottom: 10px">
      <div class="cell-100">
        <input class="form-control" [(ngModel)]="countrySearchText" (keyup)="countrySearchChanged()" />
      </div>
    </div>
    <div class="dialog-row" *ngFor="let c of countryOptions">
      <div class="cell-75-fixed">
        {{ c.title }}
      </div>
      <div class="cell-25-fixed">
        <button class="dialog-add-button" (click)="selectCountry(c)">wählen</button>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeCountryDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="sort-dialog" [show]="isOpenSortOptionDialog" minWidth="350px" maxWidth="400px" height="80%">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'SORT_DIALOG.TITLE' | translate }}"></app-content-header>
    <div class="dialog-row" *ngFor="let c of sortOptions">
      <div class="cell-75-fixed">
        {{ c.name }}
      </div>
      <div class="cell-25-fixed">
        <button class="dialog-add-button" (click)="selectSortOption(c)">wählen</button>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeSortOptionDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="login-message-dialog" [show]="isOpenLoginMessageDialog" minWidth="350px" maxWidth="400px" height="80%">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN_MESSAGE.TITLE' | translate }}"></app-content-header>
    <br />
    {{ loginMessage }}
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeLoginMessageDialog()">
      {{ "COMMON.OK" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="switch-merchant-dialog" [show]="isOpenSwitchMerchantContextDialog" minWidth="350px" maxWidth="400px" height="80%">
  <ng-template #modalContent>
    <div id="modal-header">
      <app-content-header contentTitle="Händler Ansicht wechseln"></app-content-header>
      <input type="text" class="cell-header-search" placeholder="Suche" [formControl]="merchantSearchCtrl" autofocus />
    </div>
    <div *ngIf="merchantsRead?.length > 0" class="dialog-row">
      <div class="cell-75-fixed gray">Zugriff Lesend ({{ merchantsReadFiltered?.length }}/{{ merchantsRead?.length }})</div>
    </div>
    <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
      <div class="dialog-row" *cdkVirtualFor="let access of merchantsReadFiltered">
        <div class="cell-75-fixed">
          {{ access.merchantNumber }} | {{ access.companyName }} |
          {{ access.email }}
        </div>
        <div class="cell-25-fixed">
          <button class="dialog-add-button" (click)="switchMerchantContext(access)">wechseln</button>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <div *ngIf="merchantsWrite?.length > 0" class="dialog-row">
      <div class="cell-75-fixed gray">Zugriff Schreibend ({{ merchantsWriteFiltered?.length }}/{{ merchantsWrite?.length }})</div>
    </div>
    <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
      <div class="dialog-row" *cdkVirtualFor="let access of merchantsWriteFiltered">
        <div class="cell-75-fixed">
          {{ access.merchantNumber }} | {{ access.companyName }} |
          {{ access.email }}
        </div>
        <div class="cell-25-fixed">
          <button class="dialog-add-button" (click)="switchMerchantContext(access)">wechseln</button>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-template>
  <ng-template #modalFooter>
    <button *ngIf="!(uiShareService.loggedInUser$ | async).userMerchantAccess.isGVL" style="float: left" (click)="closeSwitchMerchantContextDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button *ngIf="!(uiShareService.loggedInUser$ | async).userMerchantAccess.isGVL" style="float: right" (click)="leaveMerchantContext()">
      Zurück zu eigenem Konto
    </button>
  </ng-template>
</app-modal>
