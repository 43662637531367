<app-modal id="confirmAccountModal" [show]="confirmAccountModalShow" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'CONFIRM_ACCOUNT.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ 'CONFIRM_ACCOUNT.CONFIRM_INFO' | translate }}"></p>

    <div class="table">
      <div class="cell-100">
        <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.DATA_PRIVACY' | translate }}"></app-content-header>
        <br />
        <br />
        <div class="table">
          <div class="cell-cb">
            <app-checkbox [checked]="privacyChecked" (onChanged)="privacyChanged($event)"></app-checkbox>
          </div>
          <div class="cell-cb-text">
            <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.DATA_PRIVACY_TEXT' | translate }}" style="word-wrap: break-word"></div>
            {{ attachLink() }}
          </div>
        </div>
        <br />
      </div>
      <div class="cell-100">
        <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.AGB' | translate }}"></app-content-header>
        <br />
        <br />
        <div class="table">
          <div class="cell-cb">
            <app-checkbox [checked]="agbChecked" (onChanged)="agbChanged($event)"></app-checkbox>
          </div>
          <div class="cell-cb-text">
            <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.AGB_TEXT' | translate }}" style="word-wrap: break-word"></div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.NOTIFICATIONS' | translate }}"></app-content-header>
    <br />
    <br />
    <div class="table">
      <div class="cell-cb">
        <app-checkbox [checked]="notificationsActivated" (onChanged)="notificationsChanged($event)"></app-checkbox>
      </div>
      <div class="cell-cb-text">
        <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.NOTIFICATIONS_ACTIVATED' | translate }}" style="word-wrap: break-word"></div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="doOpenLogin()">
      {{ "CONFIRM_ACCOUNT.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="doConfirmAccount()" [disabled]="!privacyChecked || !agbChecked">
      {{ "CONFIRM_ACCOUNT.CONFIRM" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="confirmAccountResultModal" [show]="confirmAccountResultText != ''" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'CONFIRM_ACCOUNT.TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ confirmAccountResultText }}"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" *ngIf="!successState" (click)="doRetry()">
      {{ "CONFIRM_ACCOUNT.RETRY" | translate }}
    </button>
    <button style="float: right" (click)="doOpenLogin()">
      {{ "CONFIRM_ACCOUNT.GOTO_LOGIN" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="privacyModal" [show]="showPrivacyModal" maxWidth="800px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'LOGIN.PRIVACY.TITLE' | translate }}"></app-content-header>
    <app-privacy-text></app-privacy-text>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: right" (click)="doClosePrivacyModal()">
      {{ "LOGIN.PRIVACY.CLOSE" | translate }}
    </button>
  </ng-template>
</app-modal>
