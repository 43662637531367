import { MatTableDataSource } from "@angular/material/table";

export interface IInvoice {
  id: string;
  createdDate: Date;
  invoiceDate: Date;
  invoiceDueDate: Date;
  dateEarly: Date;
  invoiceType: string;
  invoiceNumber: string;
  invoiceCreatedDate: Date;
  orders: IInvoicedOrder[];
  invoiceAmount: number;
  amountEarly: number;
  attachmentPath: string;
}
export interface IInvoiceDTO {
  id: string;
  createdDate: Date;
  invoiceDate: Date;
  invoiceDueDate: Date;
  dateEarly: Date;
  invoiceType: string;
  invoiceNumber: string;
  invoiceCreatedDate: Date;
  invoiceAmount: number;
  amountEarly: number;
  attachmentPath: string;
}
export interface IInvoicedOrder {
  id: string;
  orderNumber: string;
  customerReference: string;
  positions: IInvoicedOrderPosition[];

  positionsDataSource: MatTableDataSource<IInvoicedOrderPosition>;
}

export interface IInvoicedOrderPosition {}

export function mapInvoiceToInvoiceDTO(invoice: IInvoice): IInvoiceDTO {
  return {
    id: invoice.id,
    createdDate: invoice.createdDate,
    invoiceDate: invoice.invoiceDate,
    invoiceDueDate: invoice.invoiceDueDate,
    dateEarly: invoice.dateEarly,
    invoiceType: invoice.invoiceType,
    invoiceNumber: invoice.invoiceNumber,
    invoiceCreatedDate: invoice.invoiceCreatedDate,
    invoiceAmount: invoice.invoiceAmount,
    amountEarly: invoice.amountEarly,
    attachmentPath: invoice.attachmentPath,
  };
}