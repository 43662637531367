import buildInfo from "../../buildInfo.json";
export const environment = {
  production: true,
  LOG_LEVEL: 10,
  ENTRIES_PER_PAGE: 25,
  MULTIMERCHANT: true,
  APPROVEAFTERCHANGE: true,
  BUILD_NUMBER: buildInfo.buildNumber,
  msalConfig: {
    auth: {
      clientId: "98637542-ee4d-4e9c-aa25-73ebecbc7b35",
    },
    redirectUri: "https://dev01.aldra.multitrade.hosting/",
    postLogoutRedirectUri: "https://dev01.aldra.multitrade.hosting/",
  },
  apiConfig: {
    scopes: ["https://gcaldratest.onmicrosoft.com/d3635322-9d14-4d01-a26b-98a328bf4be8/TestScope"],
    uri: "https://dev01.aldra.multitrade.hosting/api/",
  },
  b2cPolicies: {
    names: {
      signIn: "B2C_1_sign_in",
      editProfile: "B2C_1_edit_profile",
    },
    authorities: {
      signIn: {
        authority: "https://gcaldratest.b2clogin.com/gcaldratest.onmicrosoft.com/B2C_1_sign_in",
      },
      editProfile: {
        authority: "https://gcaldratest.b2clogin.com/gcaldratest.onmicrosoft.com/B2C_1_edit_profile",
      },
    },
    authorityDomain: "gcaldratest.b2clogin.com",
  },
};
