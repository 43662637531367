<app-custom-ion-header [selected]="'MENU.FRAME'"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.FRAME'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.FRAME'"></app-custom-ion-condense-header>
  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'FRAME.TITLE' | translate"></app-content-header>
        <app-frame-wrapper (frameNumber)="addFrameNumber($event, true)">
          <ng-template #wrapperFrame>
            <form [formGroup]="form">
              <div class="cell-100">
                <div>
                  <label class="field-title">{{ "FRAME.FRAME_NUMBERS" | translate }}*</label>
                </div>
                <div class="field" *ngFor="let value of frames; let idx = index">
                  <div class="frame-number">{{ value }}</div>
                  <button class="remove-button" (click)="removeFrame(idx)">entfernen</button>
                </div>
              </div>
              <br />
              <button class="add-button" (click)="isOpenAddDialog = true">Gestell hinzufügen</button>

              <!--   <div *ngIf="latitude!=-1 && !noGeo">
                          <br>
                          {{'FRAME.RESOLVED_GEOS'|translate}}:<br>
                          <br>
                          Latitude: {{latitude}}<br>
                          Longitude: {{longitude}}<br>
                          <div style="padding-top:5px;padding-bottom:10px">
                              <button type="button" (click)="updateGeoCoordinates(false)">{{'FRAME.GEO_UPDATE_POSITION'|translate}}</button>
                          </div>
                          <br>
                          <div>
                              <label class="switch">
                                  <input type="checkbox" [(checked)]="noGeo" (click)="emptyAdress()">
                                  <span class="slider round"></span>
                              </label>
                              <label>{{'FRAME.USE_ADDRESS_INSTEADOF_GEO'|translate}}</label>
                          </div>
                          <br>
                          <button type="submit"(click)="onSubmit()"  [disabled]="form.invalid ">{{'FRAME.SEND' | translate}}</button>
                      </div> -->
              <div [formGroup]="formAdress">
                <div class="cell-100" *ngIf="appMode">
                  <div class="checkbox">
                    <div><img alt="" [src]="noGeo ? '/assets/icons/SwitchOn.png' : '/assets/icons/SwitchOff.png'" (click)="useGeoCoordinates()" /></div>
                    <label>{{ "FRAME.USE_ADDRESS_INSTEADOF_GEO" | translate }}</label>
                  </div>
                </div>
                <div class="cell-100">
                  <div class="checkbox">
                    <div>
                      <img alt="" [src]="useMerchantAddress ? '/assets/icons/SwitchOn.png' : '/assets/icons/SwitchOff.png'" (click)="fillMerchantAdress()" />
                    </div>
                    <label>{{ "FRAME.FILL_MERCHANT_ADRESS" | translate }}</label>
                  </div>
                </div>
                <div class="cell-100">
                  <div class="checkbox">
                    <div>
                      <img alt="" [src]="useDeliverAddress ? '/assets/icons/SwitchOn.png' : '/assets/icons/SwitchOff.png'" (click)="fillDeliveryAdress()" />
                    </div>
                    <label>{{ "FRAME.FILL_DELIVERY_ADRESS" | translate }}</label>
                  </div>
                </div>
                <div *ngIf="appMode && !useMerchantAddress && !this.useDeliverAddress" class="cell-100">
                  <div class="checkbox">
                    <button type="button" (click)="updateGeoCoordinates(false)">Adresse per Geo Koordinaten ermitteln</button>
                  </div>
                </div>
                <div class="cell-100" *ngIf="!useDeliverAddress">
                  <div class="table">
                    <div class="cell-75">
                      <div>
                        <label class="field-title">{{ "FRAME.STREET" | translate }}*</label>
                        <label class="form-error" *ngIf="formAdress.get('street').invalid">{{ getFormControl("street", formAdress) }}</label>
                      </div>
                      <div class="field">
                        <input [readonly]="useMerchantAddress" class="form-control" formControlName="street" />
                      </div>
                    </div>
                    <div class="cell-25">
                      <div>
                        <label class="field-title">{{ "FRAME.NUMBER" | translate }}*</label>
                        <label class="form-error" *ngIf="formAdress.get('number').invalid">{{ getFormControl("number", formAdress) }}</label>
                      </div>
                      <div class="field">
                        <input [readonly]="useMerchantAddress" class="form-control" formControlName="number" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cell-100" *ngIf="!useDeliverAddress">
                  <div class="table">
                    <div class="cell-25">
                      <div>
                        <label class="field-title">{{ "FRAME.PLZ" | translate }}*</label>
                        <label class="form-error" *ngIf="formAdress.get('zipCode').invalid">{{ getFormControl("zipCode", formAdress) }}</label>
                      </div>
                      <div class="field">
                        <input [readonly]="useMerchantAddress" class="form-control" formControlName="zipCode" />
                      </div>
                    </div>
                    <div class="cell-25">
                      <div>
                        <label class="field-title">{{ "FRAME.PLACE" | translate }}*</label>
                        <label class="form-error" *ngIf="formAdress.get('city').invalid">{{ getFormControl("city", formAdress) }}</label>
                      </div>
                      <div class="field">
                        <input [readonly]="useMerchantAddress" class="form-control" formControlName="city" />
                      </div>
                    </div>
                    <div class="cell-50">
                      <div>
                        <label class="field-title">{{ "MYDATA.COUNTRY" | translate }}*</label>
                        <label class="form-error" *ngIf="formAdress.get('country').invalid">{{ getFormControl("country", formAdress) }}</label>
                      </div>
                      <div class="field">
                        <input [readonly]="useMerchantAddress" class="form-control" formControlName="country" />
                      </div>
                    </div>
                  </div>
                </div>
                <br /><br />
                <button type="submit" (click)="onSubmit()" [disabled]="form.invalid || !frames || frames.length == 0 || (formAdress && formAdress.invalid)">
                  {{ "FRAME.SEND" | translate }}</button
                ><br />

                <!-- <button type="button" (click)="enableLocationService()" *ngIf="locationState == 'declined'">
                    {{ "FRAME.RECHECK_LOCATION_SERVICE" | translate }}</button> --><br />
              </div>
              <br />
            </form>
          </ng-template>
        </app-frame-wrapper>
      </ng-template>
    </app-custom-content-wrapper>

    <app-modal id="add-dialog" [show]="isOpenAddDialog" minWidth="350px" maxWidth="400px" height="80%">
      <ng-template #modalContent>
        <app-content-header contentTitle="{{ 'FRAME_DIALOG.TITLE' | translate }}"></app-content-header>
        <div class="dialog-row" style="padding-bottom: 10px">
          {{ "FRAME.CUSTOM_FRAME_NUMBER" | translate }}<br />
          <div class="cell-70-dialog">
            <input class="form-control" [(ngModel)]="customFrameNumber" />
          </div>
          <div class="cell-30-dialog">
            <button class="dialog-add-button" (click)="addFrameNumberDirect(customFrameNumber)">hinzufügen</button>
          </div>
        </div>
        <div class="dialog-row" *ngFor="let item of framesSrc">
          <div class="cell-70-dialog">
            {{ item?.number }}<bR />
            <div class="address">
              {{ item?.deliverStreet }} {{ item?.deliverHouseNumber }}<br />
              {{ item?.deliverZipCode }} {{ item?.deliverCity }}<br />
            </div>
          </div>
          <div class="cell-30-dialog">
            <button class="dialog-add-button" (click)="addFrameNumber(item.number)">hinzufügen</button>
          </div>
        </div>
      </ng-template>
      <ng-template #modalFooter>
        <button style="float: left" (click)="closeDialog()">
          {{ "COMMON.CANCEL" | translate }}
        </button>
      </ng-template>
    </app-modal>

    <app-modal id="addresstype-invalid-dialog" [show]="isOpenAdTypeInvalidDialog" minWidth="350px" maxWidth="400px">
      <ng-template #modalContent>
        <app-content-header contentTitle="{{ 'FRAME_DIALOG.ADDRESS_TYPE_INVALID_TITLE' | translate }}"></app-content-header>
        <p style="word-wrap: break-word">
          {{ "FRAME_DIALOG.ADDRESS_TYPE_INVALID_TEXT" | translate }}
        </p>
      </ng-template>
      <ng-template #modalFooter>
        <button style="float: left" (click)="closeDialog()">
          {{ "COMMON.OK" | translate }}
        </button>
      </ng-template>
    </app-modal>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
