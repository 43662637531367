<div class="dialog-modal-content">
  <app-content-header contentTitle="Ändern/In Auftrag überführen"></app-content-header>
  <div>
    <label class="field-title plain">{{ "OFFER_DIALOG.OFFER_NUMBER" | translate }}: {{ data.item?.offerNumber }}</label>
    <br />
    <div class="table">
      <div class="cell-50">
        <label class="field-title plain">{{ "OFFER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
        <div class="field">
          <textarea class="form-control" name="changeMessage" [(ngModel)]="approve.changeMessage" placeholder=""></textarea>
        </div>
      </div>
      <div class="cell-50">
        <div *ngFor="let position of approve.positions; let i = index" class="field-container">
          <label class="field-title plain"
            >{{ "OFFER_DIALOG.CHANGE_MESSAGE_FOR_ARTICLE" | translate }}
            {{ position?.articleNumber }}
          </label>
          <div class="field">{{ position.changeMessage }}</div>
        </div>
        <div class="no-articles" *ngIf="!approve.positions || approve.positions.length === 0">
          {{ "OFFER_DIALOG.NO_CHANGE_ARTICLES_SET" | translate }}
        </div>
      </div>
    </div>
    <div class="table" style="padding-top: 5px">
      <div class="cell-cb">
        <app-checkbox [checked]="releaseOnChange" (onChanged)="releaseOnChange = $event"> </app-checkbox>
      </div>
      <div class="cell-cb-text">
        <span class="field-title plain small">Angebot nach Änderung in Auftrag überführen</span>
      </div>
    </div>
  </div>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="onCancel()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="changeOffer()" [disabled]="getChangeDialogDisabledState()">
    {{ changeDialogSaveStateText }}
  </button>
  <div></div>
</div>
