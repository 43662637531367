import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Approve } from "../../models/approve";
import { IOfferPosition } from "../../models/offer-position";
import { OfferModalsComponent } from "src/app/includes/offer-modals/offer-modals.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-edit-icon-dialog",
  templateUrl: "./edit-icon-dialog.component.html",
  styleUrls: ["./edit-icon-dialog.component.scss"],
})
export class EditIconDialogComponent implements OnInit {
  @ViewChild("offerModals") offerModals: OfferModalsComponent;
  public itemPosition: IOfferPosition;
  public approve: Approve = Object.assign({});
  constructor(
    public dialogRef: MatDialogRef<EditIconDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onCancel(): void {
    // this.itemPosition = null;
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.itemPosition = this.data.item;
    this.approve.changeMessage = this.data.item.changeMessage;
  }
  public setChangeToPosition(): void {
    this.data.item.changeMessage = this.approve.changeMessage;
    this.approve = Object.assign({});
    this.dialogRef.close();
  }
}
