export class MockData {
  validTokenMock =
    "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNyc2Etc2hhMjU2IiwidHlwIjoiSldUIn0.eyJqdGkiOiJkNTAzNGM2MS03OWQyLTQ0ZTMtYjJjYy0zOWE1N2Q2NTg5YWEiLCJzdWIiOiJhZG1pbkBhZG1pbi5kZSIsIm5iZiI6MTU3MTIzMjU1MCwiZXhwIjoxNTcxMjM2MTUwLCJpc3MiOiJNeUFsZHJhUG9ydGFsQVBJIiwiYXVkIjoiTXlBbGRyYVBvcnRhbCJ9.i4BfEv-ng6THcsS7v6aNnaRk2aOUTYWpP2XymWsrdjZ7gLZ4HOQpO-B4OxY_4CFDeMSjkvjs1WhN4ufSQLY1PXyOeEtjNGaP5WWHGs2PJh17w9leCDoRuTqDh-l4v-eL6yIANG-8PpSzUvYgEJRW_maCNdgDhDEzhUgJrBa-Y5TEyLbb0_ByV67huqh6HTXt4bqKYYWRAN0yQaE91xWCBxIqOrGslGaHl74RLoVXgE0UlIgwNqJ50LN6BhxCvTh7oVZwlC1ClepWiIhx4bie2cWuz8QLdVBGcvdYtnP_sou17oBYkIclpDVkyqIAm31Ii4wYnQtvAyeECaED3vulXQ";
  validUserMock = {
    accessToken:
      "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNyc2Etc2hhMjU2IiwidHlwIjoiSldUIn0.eyJqdGkiOiJkNTAzNGM2MS03OWQyLTQ0ZTMtYjJjYy0zOWE1N2Q2NTg5YWEiLCJzdWIiOiJhZG1pbkBhZG1pbi5kZSIsIm5iZiI6MTU3MTIzMjU1MCwiZXhwIjoxNTcxMjM2MTUwLCJpc3MiOiJNeUFsZHJhUG9ydGFsQVBJIiwiYXVkIjoiTXlBbGRyYVBvcnRhbCJ9.i4BfEv-ng6THcsS7v6aNnaRk2aOUTYWpP2XymWsrdjZ7gLZ4HOQpO-B4OxY_4CFDeMSjkvjs1WhN4ufSQLY1PXyOeEtjNGaP5WWHGs2PJh17w9leCDoRuTqDh-l4v-eL6yIANG-8PpSzUvYgEJRW_maCNdgDhDEzhUgJrBa-Y5TEyLbb0_ByV67huqh6HTXt4bqKYYWRAN0yQaE91xWCBxIqOrGslGaHl74RLoVXgE0UlIgwNqJ50LN6BhxCvTh7oVZwlC1ClepWiIhx4bie2cWuz8QLdVBGcvdYtnP_sou17oBYkIclpDVkyqIAm31Ii4wYnQtvAyeECaED3vulXQ",
    refreshToken: "AB3KIjgrWreR7+oGh5iHXMNoqjr89TKZgymX7q0DBmhvDGSXtIO3k/CbEmx29TCHCg==",
    expiration: 1571236150717,
    user: {
      id: "4676429e-abde-4e49-f1f9-08d752278214",
      email: "admin@admin.de",
      type: 0,
      firstName: "Administrator",
      lastName: "Administrator",
      phone: null,
      mobile: null,
      street: null,
      houseNumber: null,
      zipCode: null,
      city: null,
      companyName: null,
    },
  };
  validLostPasswordMock = {
    id: "a3b16646-83e8-47d0-a3d7-3670e1a97ff0",
    userId: "4676429e-abde-4e49-f1f9-08d752278214",
    timeStamp: "2019-10-22T15:09:23.1463389+02:00",
  };
}
