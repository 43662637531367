export class Contact {
  type: ContactType;
  body: string;
  sendCopy: boolean;
  number: string;
}

export class Callback extends Contact {
  phone: string;
  contactPerson: string;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  message: string;
}

export enum ContactType {
  Order = 0,
  Offer = 1,
  Reclamation = 2,
  Frame = 3,
  ShipmentNote = 4,
}
