<app-custom-ion-header [selected]="'MENU.USER'"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.USER'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.USER'"></app-custom-ion-condense-header>
  <app-custom-content-wrapper [breadcrumb]="breadcrumb">
    <ng-template #wrapperContent>
      <app-content-header [contentTitle]="'USER.TITLE' | translate"></app-content-header>
      <button *ngIf="!uiShareService.readOnlyMode()" class="user-button" (click)="userAdminModals.openDialogNewUser()">
        {{ "USER.BUTTON_TEXT" | translate }}
      </button>
      <div class="table">
        <div class="table-row row-header row-background">
          <span class="cell normal cell-header">{{ "USER.EMAIL" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.COMPANY_NAME" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.FIRST_NAME" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.LAST_NAME" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.USER_TYPE" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.PHONE" | translate }}</span>
          <span class="cell normal cell-header">{{ "USER.MOBILE" | translate }}</span>
          <span *ngIf="!uiShareService.readOnlyMode()" class="cell small cell-header">{{ "USER.DELETE" | translate }}</span>
          <span *ngIf="!uiShareService.readOnlyMode()" class="cell small cell-header">{{ "USER.CHANGE" | translate }}</span>
        </div>
        <div class="table-row" *ngFor="let user of users; let i = index">
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.email }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.companyName }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.firstName }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.lastName }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ "USER.TYPE." + user.type | translate }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.phone }}</span>
          <span class="cell normal" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">{{ user.mobile }}</span>
          <span *ngIf="!uiShareService.readOnlyMode()" class="cell small" [ngClass]="user.isMainMerchantUser ? 'locked' : ''">
            <a class="delete" [ngClass]="user.isMainMerchantUser ? 'disabled' : ''" (click)="userAdminModals.openDeleteUser(user)"
              ><img src="./assets/icons/trash-red.png" alt="" height="20px"
            /></a>
          </span>
          <span *ngIf="!uiShareService.readOnlyMode()" class="cell small">
            <a class="edit" (click)="userAdminModals.editUser(user)"><img src="./assets/icons/edit-red.png" alt="" height="20px" /></a>
          </span>
        </div>
      </div>
    </ng-template>
  </app-custom-content-wrapper>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>

<app-user-admin-modals #userAdminModals (refresh)="ngOnInit()"></app-user-admin-modals>
