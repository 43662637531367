import { Component, OnInit } from "@angular/core";
import { SettingType, Setting } from "src/app/models/setting";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { LoaderService } from "src/app/services/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable, forkJoin } from "rxjs";

@Component({
  selector: "app-admin-system",
  templateUrl: "./admin-system.component.html",
  styleUrls: ["./admin-system.component.scss"],
})
export class AdminSystemComponent implements OnInit {
  availableLanguages: Array<string> = ["DE"];
  currentLanguage = "DE";
  currentTab = "common";
  data: Setting[];
  loginMessage: Setting = new Setting(SettingType.System, "LOGIN_MESSAGE_DE", "");
  loginMessageEnabled: Setting = new Setting(SettingType.System, "LOGIN_MESSAGE_ENABLED", "false");
  importLogsEnabled: Setting = new Setting(SettingType.System, "IMPORT_LOGS_ENABLED", "false");
  frameMailsEnabled: Setting = new Setting(SettingType.System, "FRAME_IMPORT_MAILS_ENABLED", "false");
  reclamationMailsEnabled: Setting = new Setting(SettingType.System, "RECLAMATION_IMPORT_MAILS_ENABLED", "false");
  shipmentMailsEnabled: Setting = new Setting(SettingType.System, "SHIPMENT_IMPORT_MAILS_ENABLED", "false");
  orderMailsEnabled: Setting = new Setting(SettingType.System, "ORDER_IMPORT_MAILS_ENABLED", "false");
  offerMailsEnabled: Setting = new Setting(SettingType.System, "OFFER_IMPORT_MAILS_ENABLED", "false");

  constructor(
    private apiSettingsService: ApiSettingsService,
    private loaderService: LoaderService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.loadData();
  }
  loadLng(lng: string) {
    console.log("loadLng()", this.data);
    this.currentTab = lng;
    this.currentLanguage = lng;
    this.loginMessage = new Setting(SettingType.System, "LOGIN_MESSAGE_" + lng.toUpperCase(), "");
    this.data.forEach((d) => {
      if (d.key.startsWith("LOGIN_MESSAGE_" + lng)) {
        this.loginMessage = d;
      }
    });
  }
  loadCommon() {
    this.currentTab = "common";
    this.data.forEach((d) => {
      console.log(d.key + " -> " + d.value);
      if (d.key == "LOGIN_MESSAGE_ENABLED") this.loginMessageEnabled = d;
      if (d.key == "IMPORT_LOGS_ENABLED") this.importLogsEnabled = d;
      if (d.key == "FRAME_IMPORT_MAILS_ENABLED") this.frameMailsEnabled = d;
      if (d.key == "ORDER_IMPORT_MAILS_ENABLED") this.orderMailsEnabled = d;
      if (d.key == "OFFER_IMPORT_MAILS_ENABLED") this.offerMailsEnabled = d;
      if (d.key == "RECLAMATION_IMPORT_MAILS_ENABLED") this.reclamationMailsEnabled = d;
      if (d.key == "SHIPMENT_IMPORT_MAILS_ENABLED") this.shipmentMailsEnabled = d;
    });
  }
  loadData() {
    this.apiSettingsService.getAll().subscribe((data) => {
      this.data = data.filter((d) => d.type == SettingType.System);
      if (this.currentTab == "common") this.loadCommon();
      else this.loadLng(this.currentTab);
    });
  }
  loginMessageEnabledChanged(event: any) {
    this.loginMessageEnabled.value = event ? "true" : "false";
  }
  importLogsEnabledChanged(event: any) {
    this.importLogsEnabled.value = event ? "true" : "false";
  }
  frameMailsEnabledChanged(event: any) {
    this.frameMailsEnabled.value = event ? "true" : "false";
  }
  orderMailsEnabledChanged(event: any) {
    this.orderMailsEnabled.value = event ? "true" : "false";
  }
  offerMailsEnabledChanged(event: any) {
    this.offerMailsEnabled.value = event ? "true" : "false";
  }
  shipmentMailsEnabledChanged(event: any) {
    this.shipmentMailsEnabled.value = event ? "true" : "false";
  }
  reclamationMailsEnabledChanged(event: any) {
    this.reclamationMailsEnabled.value = event ? "true" : "false";
  }
  saveSetting() {
    const calls: Observable<any>[] = [];
    if (this.currentTab == "common") {
      calls.push(this.apiSettingsService.update(this.loginMessageEnabled));
      calls.push(this.apiSettingsService.update(this.frameMailsEnabled));
      calls.push(this.apiSettingsService.update(this.orderMailsEnabled));
      calls.push(this.apiSettingsService.update(this.offerMailsEnabled));
      calls.push(this.apiSettingsService.update(this.reclamationMailsEnabled));
      calls.push(this.apiSettingsService.update(this.shipmentMailsEnabled));
      calls.push(this.apiSettingsService.update(this.importLogsEnabled));
    } else {
      calls.push(this.apiSettingsService.update(this.loginMessage));
    }

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.loadData();
        this.loaderService.hideTransparentLoader();
      });
    });
  }
}
