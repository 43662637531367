export interface LoginMessage {
  enabled: boolean;
  messageDE: string;
}

export interface UserMerchantAccess {
  switchAccess: boolean;
  readAccess: boolean;
  writeAccess: boolean;
  activeMerchantId: string;
  activeMerchantNumber: string;
  activeMerchantName: string;
  mainMerchantId: string;
  mainMerchantNumber: string;
  isGVL: boolean;
  activeMerchantAccessType: MerchantAccessType;
}

export enum MerchantAccessType {
  READ,
  WRITE,
}
