<app-custom-ion-header *ngIf="!isGlas" [selected]="'MENU.RECLAMATION'"></app-custom-ion-header>
<app-custom-ion-header *ngIf="isGlas" [selected]="'MENU.RECLAMATION_GLASS'"></app-custom-ion-header>
<app-mobile-ion-header *ngIf="!isGlas" [selected]="'MENU.RECLAMATION'"></app-mobile-ion-header>
<app-mobile-ion-header *ngIf="isGlas" [selected]="'MENU.RECLAMATION_GLASS'"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header *ngIf="!isGlas" [selected]="'MENU.RECLAMATION'"></app-custom-ion-condense-header>
  <app-custom-ion-condense-header *ngIf="isGlas" [selected]="'MENU.RECLAMATION_GLASS'"></app-custom-ion-condense-header>

  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'RECLAMATION.CREATE_TITLE' | translate"></app-content-header>

        <form class="form" [formGroup]="reclamationForm" (ngSubmit)="onSubmit()">
          <label class="field-title reclamation-title">{{ "RECLAMATION.CONTACT_PERSON" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('contactPerson').invalid">{{ getFormControl("contactPerson") }}</label>
          <div class="field">
            <input class="form-control" formControlName="contactPerson" />
          </div>
          <br />
          <label class="field-title reclamation-title">{{ "RECLAMATION.PHONE" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('phone').invalid">{{ getFormControl("phone") }}</label>
          <div class="field">
            <input class="form-control" formControlName="phone" />
          </div>
          <br />
          <label class="field-title reclamation-title">{{ "RECLAMATION.CUSTOMER_NAME" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('customerName').invalid">{{ getFormControl("customerName") }}</label>
          <div class="field">
            <input class="form-control" formControlName="customerName" />
          </div>
          <br />
          <label class="field-title reclamation-title">{{ "RECLAMATION.CUSTOMER_ADRESS" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('customerAdress').invalid">{{ getFormControl("customerAdress") }}</label>
          <div class="field">
            <textarea type="text" class="form-control" formControlName="customerAdress"></textarea>
          </div>
          <br />
          <label class="field-title reclamation-title">{{ "RECLAMATION.CUSTOMER_PHONE" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('customerPhone').invalid">{{ getFormControl("customerPhone") }}</label>
          <div class="field">
            <input class="form-control" formControlName="customerPhone" />
          </div>
          <br />
          <br />
          <label class="field-title reclamation-title">{{ "Email Endkunde" | translate }}*</label>
          <label class="form-error" *ngIf="reclamationForm.get('customerEmail').invalid">Bitte gültige Email eingeben</label>
          <div class="field">
            <input class="form-control" formControlName="customerEmail" />
          </div>
          <br />
          <div class="table">
            <div class="cell number1">
              <label class="field-title reclamation-title">{{ "RECLAMATION.ORDER_NUMBER" | translate }}*</label>
              <label class="form-error" *ngIf="reclamationForm.get('orderNumber').invalid">{{ getFormControl("orderNumber") }}</label>
              <div class="field" *ngIf="reclamationForm.get('orderNumber').valid">
                {{ reclamationForm.get("orderNumber").value }}
              </div>
              <div class="field" *ngIf="reclamationForm.get('orderNumber').invalid">-</div>
              <button *ngIf="reclamationForm.get('orderNumber').valid" type="button" (click)="clearOrder()">
                {{ "RECLAMATION.CLEAR_ORDER_NUMBER" | translate }}
              </button>
              <input class="form-control" formControlName="orderNumber" style="visibility: hidden" />
            </div>
            <div class="cell number2">
              <button type="button" (click)="chooseOrder()">
                {{ "RECLAMATION.CHOOSE_SET_ORDER_NUMBER" | translate }}
              </button>
            </div>
          </div>
          <div class="table">
            <div class="cell number1">
              <label class="field-title reclamation-title">{{ "RECLAMATION.ELEMENT_IDS" | translate }}*</label>
              <label class="form-error" *ngIf="reclamationForm.get('elementIds').invalid">{{ getFormControl("elementIds") }}</label>
              <div class="field" *ngIf="reclamationForm.get('elementIds').valid">
                {{ reclamationForm.get("elementIds").value.split(";").join(", ") }}
              </div>
              <div class="field" *ngIf="reclamationForm.get('elementIds').invalid">-</div>
              <input class="form-control" formControlName="elementIds" style="visibility: hidden" />
            </div>
            <div class="cell number2">
              <button type="button" (click)="chooseOrderPositions()" [disabled]="reclamationForm.get('orderNumber').invalid">
                {{ "RECLAMATION.CHOOSE_SET_ELEMENT_IDS" | translate }}
              </button>
            </div>
          </div>
          <br />
          <div *ngIf="!isGlas; else glas">
            <label class="field-title reclamation-title">{{ "RECLAMATION.REASON" | translate }}</label>
            <div formGroupName="reason">
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input id="surface" class="form-control" type="checkbox" formControlName="surface" (change)="checkValidation()" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.0" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="pane" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.1" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="sprouted" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.2" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="shutters" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.3" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="malfunction" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.4" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="transport" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.5" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="delivery" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.6" | translate }}</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="other" />
                </div>
                <span class="cell-text-checkbox">{{ "RECLAMATION_MATTER.7" | translate }}</span>
              </div>
            </div>
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.ERROR_DESCRIPTION" | translate }}*</label>
            <label class="form-error" *ngIf="reclamationForm.get('errorDescription').invalid">{{ getFormControl("errorDescription") }}</label>
            <div class="field">
              <input class="form-control" formControlName="errorDescription" />
            </div>
            <br />
          </div>
          <ng-template #glas>
            <label class="field-title reclamation-title">{{ "RECLAMATION.ORDER_NUMBER_INFO" | translate }}</label>
            <div class="field">
              <input class="form-control" formControlName="orderNumberInfo" />
            </div>
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.EDGE_COMPOSITE_NAME" | translate }}</label>
            <div class="field">
              <input class="form-control" formControlName="distanceName" />
            </div>
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.GLASS_DIMENSIONS" | translate }}</label>
            <div class="field">
              <input class="form-control" formControlName="glassDimensions" />
            </div>
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.HEIGHT_GLASS_DIMENSIONS" | translate }}</label>
            <div class="field">
              <input class="form-control" formControlName="heightGlassDimensions" />
            </div>
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.DEFECT" | translate }}</label>
            <div class="table-defect">
              <div class="table-row-defect">
                <span class="table-cell-defect"></span>
                <span class="table-cell-defect">{{ "RECLAMATION.SLICE" | translate }}</span>
                <span class="table-cell-defect">mm</span>
                <span class="table-cell-defect">{{ "RECLAMATION.AREA" | translate }}</span>
              </div>
              <div class="table-row-defect" formGroupName="defect1">
                <div class="table-cell-defect">
                  <label class="field-title reclamation-title">{{ "RECLAMATION.DEFECT1" | translate }}</label>
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="amount"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect1').get('amount').invalid
                    }"
                    (change)="checkDefectsValidation('defect1')"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="mm"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect1').get('mm').invalid
                    }"
                    (change)="checkDefectsValidation('defect1')"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="text"
                    formControlName="area"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect1').get('area').invalid
                    }"
                    (change)="checkDefectsValidation('defect1')"
                  />
                </div>
              </div>
              <div class="table-row-defect" formGroupName="defect2">
                <div class="table-cell-defect">
                  <label class="field-title reclamation-title"
                    >{{ "RECLAMATION.DEFECT2" | translate }}
                    <span class="error-inline" *ngIf="reclamationForm.get('defect2').invalid">Pflichtfeld</span></label
                  ><br />
                </div>

                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="amount"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect2').get('amount').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="mm"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect2').get('mm').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="text"
                    formControlName="area"
                    class="form-control"
                    [ngClass]="{
                      required: reclamationForm.get('defect2').get('area').invalid
                    }"
                  />
                </div>
              </div>
              <div class="table-row-defect" formGroupName="defect3">
                <div class="table-cell-defect">
                  <label class="field-title reclamation-title">{{ "RECLAMATION.DEFECT3" | translate }}</label>
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="amount"
                    class="form-control"
                    (change)="checkDefectsValidation('defect3')"
                    [ngClass]="{
                      required: reclamationForm.get('defect3').get('amount').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="mm"
                    class="form-control"
                    (change)="checkDefectsValidation('defect3')"
                    [ngClass]="{
                      required: reclamationForm.get('defect3').get('mm').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="text"
                    formControlName="area"
                    class="form-control"
                    (change)="checkDefectsValidation('defect3')"
                    [ngClass]="{
                      required: reclamationForm.get('defect3').get('area').invalid
                    }"
                  />
                </div>
              </div>
              <div class="table-row-defect" formGroupName="defect4">
                <div class="table-cell-defect">
                  <label class="field-title reclamation-title">{{ "RECLAMATION.DEFECT4" | translate }}</label>
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="amount"
                    class="form-control"
                    (change)="checkDefectsValidation('defect4')"
                    [ngClass]="{
                      required: reclamationForm.get('defect4').get('amount').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="mm"
                    class="form-control"
                    (change)="checkDefectsValidation('defect4')"
                    [ngClass]="{
                      required: reclamationForm.get('defect4').get('mm').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="text"
                    formControlName="area"
                    class="form-control"
                    (change)="checkDefectsValidation('defect4')"
                    [ngClass]="{
                      required: reclamationForm.get('defect4').get('area').invalid
                    }"
                  />
                </div>
              </div>

              <div formGroupName="defect5">
                <div class="table-cell-defect">
                  <label class="field-title reclamation-title">{{ "RECLAMATION.DEFECT5" | translate }}</label>
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="amount"
                    class="form-control"
                    (change)="checkDefectsValidation('defect5')"
                    [ngClass]="{
                      required: reclamationForm.get('defect5').get('amount').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="number"
                    formControlName="mm"
                    class="form-control"
                    (change)="checkDefectsValidation('defect5')"
                    [ngClass]="{
                      required: reclamationForm.get('defect5').get('mm').invalid
                    }"
                  />
                </div>
                <div class="table-cell-defect">
                  <input
                    type="text"
                    formControlName="area"
                    class="form-control"
                    (change)="checkDefectsValidation('defect5')"
                    [ngClass]="{
                      required: reclamationForm.get('defect1').get('area').invalid
                    }"
                  />
                </div>
              </div>
            </div>
            <br />
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.MARKER_LABEL" | translate }}*</label><br />
            <div id="crossWindow" (click)="setPosition($event)">
              <img class="window" src="./assets/images/aldra_fenster.png" alt="" />
            </div>
            <br />
            <a class="deleteMarker" (click)="deleteMarker()">{{ "RECLAMATION.DELETE_MARKER" | translate }}</a
            ><br />
            <br />
            <label class="field-title reclamation-title">{{ "RECLAMATION.POSITION_2" | translate }}</label>
            <div formGroupName="position2">
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="0" />
                </div>
                <label class="cell-text-checkbox">1 {{ "RECLAMATION.INSIDE" | translate }}</label>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="1" />
                </div>
                <span class="cell-text-checkbox">2</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="2" />
                </div>
                <span class="cell-text-checkbox">3</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="3" />
                </div>
                <span class="cell-text-checkbox">4 {{ "RECLAMATION.OUTSIDE" | translate }}</span>
              </div>
            </div>
            <br />
            <div formGroupName="position3">
              <label class="field-title reclamation-title">{{ "RECLAMATION.POSITION_3" | translate }}</label>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="0" />
                </div>
                <label class="cell-text-checkbox">1 {{ "RECLAMATION.INSIDE" | translate }}</label>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="1" />
                </div>
                <span class="cell-text-checkbox">2</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="2" />
                </div>
                <span class="cell-text-checkbox">3</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="3" />
                </div>
                <span class="cell-text-checkbox">4</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="4" />
                </div>
                <span class="cell-text-checkbox">5</span>
              </div>
              <div class="field-checkbox">
                <div class="cell-checkbox">
                  <input class="form-control" type="checkbox" formControlName="5" />
                </div>
                <span class="cell-text-checkbox">6 {{ "RECLAMATION.OUTSIDE" | translate }}</span>
              </div>
            </div>
          </ng-template>
          <br />
          <div>
            <label class="field-title reclamation-title">{{ "RECLAMATION.PHOTO" | translate }}</label>
            <br />
            Sie können maximal 5 Fotos hinzufügen.<br />
            <label class="error-photo" *ngIf="!isGlas && reclamationForm.get('photo').invalid">{{ getFormControl("photo") }}</label>
          </div>
          <br />
          <div *ngIf="!photoSrc || photoSrc.length < 5">
            <div *ngIf="appMode; else portalPhoto">
              <app-mobile-photo (fileEvent)="addFile($event)"></app-mobile-photo>
            </div>
            <ng-template #portalPhoto>
              <input class="file" type="file" formControlName="photo" accept="image/*" #uploadInput multiple />
            </ng-template>
          </div>
          <div>
            <div class="file-card" [ngClass]="file.valid ? '' : 'invalid'" *ngFor="let file of photoSrc; let i = index">
              <div class="loader" *ngIf="file.loading">
                {{ "RECLAMATION.ANALYZING_IMAGE" | translate }}
              </div>
              <div class="invalid" *ngIf="!file.valid">
                {{ "RECLAMATION.IMAGE_TO_BIG" | translate }}
              </div>
              <div><img [src]="file.b64" width="140" alt="" /></div>
              <div class="name" *ngIf="!appMode">{{ file.file.name }}</div>
              <div class="size">
                {{ file.file.size }}
                <span class="compressed" *ngIf="file.compressed">(komprimiert)</span>
              </div>
              <div *ngIf="!file.loading" (click)="removeFile(i)">
                {{ "RECLAMATION.DELETE_IMAGE" | translate }}
              </div>
            </div>
          </div>
          <br />

          <br />
          <div class="radio-button-container">
            <label class="field-title reclamation-title">{{ "RECLAMATION.DELIVERY" | translate }}</label>
            <br />
            <div class="radio-inline">
              <div class="field-radio">
                <input class="form-control radio-button" type="radio" name="delivery" formControlName="delivery" value="delivery" />&nbsp;
                <span *ngIf="!isGlas; else alternateLabel"> {{ "RECLAMATION.REPLACEMENT_DELIVERY" | translate }}</span>
                <ng-template #alternateLabel
                  ><span>{{ "RECLAMATION.REPLACEMENT_GLASS" | translate }}</span></ng-template
                >
              </div>
              <br />
              <div class="field-radio">
                <input class="form-control radio-button" type="radio" name="delivery" formControlName="delivery" value="byService" />&nbsp;
                <span>{{ "RECLAMATION.ALDRA_SERVICE" | translate }}</span>
              </div>
            </div>
          </div>
          <br />
          <label class="field-title reclamation-title">{{ "RECLAMATION.COPY_EMAIL" | translate }}</label>
          <label class="form-error" *ngIf="reclamationForm && reclamationForm.get('email').invalid">{{ getFormControl("email") }}</label>
          <div class="field">
            <input class="form-control" type="email" formControlName="email" />
          </div>
          <br />
          <label>{{ "PRIVACY.DATA_PRIVACY_PART_1" | translate }}</label>
          <a class="link" href="#">{{ "PRIVACY.DATA_PRIVACY_PART_2" | translate }}</a>
          <label>{{ "PRIVACY.DATA_PRIVACY_PART_3" | translate }}</label>
          <div class="field-checkbox">
            <div class="cell-checkbox">
              <input class="form-control" type="checkbox" formControlName="acceptPrivacy" />
            </div>
            <span class="cell-text-checkbox">{{ "PRIVACY.ACCEPT_PRIVACY" | translate }}</span>
          </div>
          <br />
          <button type="submit" [disabled]="reclamationForm.invalid">
            {{ "RECLAMATION.SEND" | translate }}
          </button>
        </form>
      </ng-template>
    </app-custom-content-wrapper>
    <app-reclamation-modals #reclamationModals></app-reclamation-modals>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
