<app-custom-ion-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header [selected]="'MENU.MY_DATA'" [homeIcon]="true"></app-custom-ion-condense-header>
  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'MASTER_DATA.TITLE' | translate"></app-content-header>
        <br />
        <div class="tab" (click)="switchTab(tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabs">
          {{ tab.title }}
        </div>
        <br /><br />
        <form *ngIf="currentTabId === 0" class="form" [formGroup]="masterData" (ngSubmit)="onSubmit()">
          <div class="cell-50">
            <div>
              <label class="field-title">Handelspartnernummer</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.merchantNumber" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Handelspartner</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.companyName" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Straße + Nummer</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.street + ' ' + merchantToShow?.houseNumber" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">PLZ</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.zipCode" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">Stadt</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.city" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Telefon</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.phone" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Fax</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.fax" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Emailadresse</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.email" readonly />
              </div>
            </div>
          </div>
          <div class="cell-50">
            <div>
              <label class="field-title">Webseite</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.webPage" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">Region</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.region" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">Region Name</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.regionName" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">Portalkunde</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.portalClient" readonly />
              </div>
            </div>
          </div>
          <div class="cell-25">
            <div>
              <label class="field-title">Backoffice</label>
              <div class="field">
                <input class="form-control" [value]="merchantToShow?.backOffice" readonly />
              </div>
            </div>
          </div>
        </form>
        <form [hidden]="currentTabId !== 1" class="form" [formGroup]="contacts" (ngSubmit)="onSubmit()">
          <div class="table">
            <table mat-table #contactsSort="matSort" [dataSource]="contactsData" matSort class="mat-elevation-z1">
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.firstName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.lastName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="function">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Funktion</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.function }}
                </td>
              </ng-container>
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
                <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
              </ng-container>
              <ng-container matColumnDef="mobile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobil</th>
                <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedContactColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedContactColumns"></tr>
            </table>
          </div>
          <div class="table-mobil">
            <mat-card *ngFor="let contact of merchantToShow?.contacts">
              <mat-card-title-group>
                <mat-card-title>{{ contact.firstName }} {{ contact.lastName }}</mat-card-title>
                <mat-card-subtitle>{{ contact.function }}</mat-card-subtitle>
              </mat-card-title-group>
              <mat-card-content>
                <span *ngIf="contact.phone">
                  <label><u>Telefon</u></label>
                  <div>{{ contact.phone }}</div>
                </span>
                <span *ngIf="contact.mobile">
                  <label><u>Mobil</u></label>
                  <div>{{ contact.mobile }}</div>
                </span>
                <span *ngIf="contact.email">
                  <label><u>Email</u></label>
                  <div>{{ contact.email }}</div>
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
        <form [hidden]="currentTabId !== 2" class="form" [formGroup]="deliveries" (ngSubmit)="onSubmit()">
          <div class="table">
            <table mat-table #deliveriesSort="matSort" [dataSource]="deliveriesData" matSort class="mat-elevation-z1">
              <ng-container matColumnDef="deliveryCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Liefer Nr.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.deliveryCode }}
                </td>
              </ng-container>
              <ng-container matColumnDef="street">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Straße</th>
                <td mat-cell *matCellDef="let element">{{ element.street }}</td>
              </ng-container>
              <ng-container matColumnDef="houseNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.houseNumber }}
                </td>
              </ng-container>
              <ng-container matColumnDef="zipCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>PLZ</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.zipCode }}
                </td>
              </ng-container>
              <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Stadt</th>
                <td mat-cell *matCellDef="let element">{{ element.city }}</td>
              </ng-container>
              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedDeliveryColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedDeliveryColumns"></tr>
            </table>
          </div>
          <div class="table-mobil">
            <mat-card *ngFor="let delivery of merchantToShow?.deliveries">
              <mat-card-title-group>
                <mat-card-title>{{ delivery.deliveryCode }}</mat-card-title>
                <mat-card-subtitle>{{ delivery.country }}</mat-card-subtitle>
              </mat-card-title-group>
              <mat-card-content>
                <div>{{ delivery.street }} {{ delivery.houseNumber }}</div>
                <div>{{ delivery.zipCode }} {{ delivery.city }}</div>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
        <form [hidden]="currentTabId !== 3" class="form" [formGroup]="discounts" (ngSubmit)="onSubmit()">
          <div class="table">
            <table mat-table #discountsSort="matSort" [dataSource]="discountsData" matSort class="mat-elevation-z1">
              <ng-container matColumnDef="orderType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Bestelltyp</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.orderType }}
                </td>
              </ng-container>
              <ng-container matColumnDef="orderName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Bestellname</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.orderName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Wert</th>
                <td mat-cell *matCellDef="let element">{{ element.value }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedDiscountColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedDiscountColumns"></tr>
            </table>
          </div>
          <div class="table-mobil">
            <mat-card *ngFor="let discount of merchantToShow?.discounts">
              <mat-card-title-group>
                <mat-card-title>{{ discount.orderType }}</mat-card-title>
                <mat-card-subtitle>{{ discount.orderName }}</mat-card-subtitle>
              </mat-card-title-group>
              <mat-card-content>
                <label><u>Wert</u></label>
                <div>{{ discount.value }}</div>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
        <form [hidden]="currentTabId !== 4" class="form" [formGroup]="documents" (ngSubmit)="onSubmit()">
          <div class="table">
            <table mat-table #documentsSort="matSort" [dataSource]="documentsData" matSort class="mat-elevation-z1">
              <ng-container matColumnDef="documentCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Dokument Nr.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.documentCode }}
                </td>
              </ng-container>
              <ng-container matColumnDef="receiver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Empfänger</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.receiver }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns"></tr>
            </table>
          </div>
          <div class="table-mobil">
            <mat-card *ngFor="let document of merchantToShow?.documents">
              <mat-card-title-group>
                <mat-card-title>{{ document.documentCode }}</mat-card-title>
              </mat-card-title-group>
              <mat-card-content>
                <span *ngIf="document.receiver">
                  <label><u>Empfänger</u></label>
                  <div>{{ document.receiver }}</div>
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>