<div class="expandedContent">
  <div class="admin-edit-container">
    <app-admin-edit (editFired)="adminEdit()"></app-admin-edit>
  </div>
  <div class="details">
    <div class="grid" *ngIf="reclamation?.contactPerson">
      <div>Ansprechpartner:</div>
      <div>{{ reclamation?.contactPerson }}</div>
      <div>Telefon:</div>
      <div>{{ reclamation?.phone }}</div>
      <div>Name Endkunde:</div>
      <div>{{ reclamation?.customerName }}</div>
      <div>Adresse Endkunde:</div>
      <div class="pre">{{ reclamation?.customerAddress?.trim() }}</div>
      <div>Telefonnumer Endkunde:</div>
      <div>{{ reclamation?.customerPhone }}</div>
    </div>
    <div class="grid">
      <ng-container *ngIf="reclamation?.product">
        <div>Produkt:</div>
        <div>{{ reclamation?.product }}</div>
      </ng-container>
      <ng-container *ngIf="reasons">
        <div>Reklamationsgrund:</div>
        <div>{{ reasons }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.errorDescription">
        <div>Fehlerbeschreibung:</div>
        <div>{{ reclamation?.errorDescription }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.delivery">
        <div>Lieferung:</div>
        <div>{{ delivery }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.orderNumberInfo">
        <div>Auftragsnummer Info:</div>
        <div>{{ reclamation?.orderNumberInfo }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.distanceName">
        <div>Bezeichnung im Randverbund:</div>
        <div>{{ reclamation?.distanceName }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.glassDimensions">
        <div>Lichtes Glasmaß in mm:</div>
        <div>{{ reclamation?.glassDimensions }}</div>
      </ng-container>
      <ng-container *ngIf="reclamation?.heightGlassDimensions">
        <div>Glasmaß Höhe:</div>
        <div>{{ reclamation?.heightGlassDimensions }}</div>
      </ng-container>
      <ng-container *ngIf="twoSided">
        <div>Lage/Position bei 2-fach Verglasung:</div>
        <div>
          <span class="glasposition" [ngClass]="twoSided.indexOf('0') != -1 ? 'active' : ''">1</span>
          <span class="glasposition">({{ "RECLAMATION.INSIDE" | translate }})</span>
          <span class="glasposition" [ngClass]="twoSided.indexOf('1') != -1 ? 'active' : ''">2</span>
          <span class="glasposition" [ngClass]="twoSided.indexOf('2') != -1 ? 'active' : ''">3</span>
          <span class="glasposition" [ngClass]="twoSided.indexOf('3') != -1 ? 'active' : ''">4</span>
          <span class="glasposition">({{ "RECLAMATION.OUTSIDE" | translate }})</span>
        </div>
      </ng-container>
      <ng-container *ngIf="threeSided">
        <div>Lage/Position bei 3-fach Verglasung:</div>
        <div>
          <span class="glasposition" [ngClass]="threeSided.indexOf('0') != -1 ? 'active' : ''">1</span>
          <span class="glasposition">({{ "RECLAMATION.INSIDE" | translate }})</span>
          <span class="glasposition" [ngClass]="threeSided.indexOf('1') != -1 ? 'active' : ''">2</span>
          <span class="glasposition" [ngClass]="threeSided.indexOf('2') != -1 ? 'active' : ''">3</span>
          <span class="glasposition" [ngClass]="threeSided.indexOf('3') != -1 ? 'active' : ''">4</span>
          <span class="glasposition" [ngClass]="threeSided.indexOf('4') != -1 ? 'active' : ''">5</span>
          <span class="glasposition" [ngClass]="threeSided.indexOf('5') != -1 ? 'active' : ''">6</span>
          <span class="glasposition">({{ "RECLAMATION.OUTSIDE" | translate }})</span>
        </div>
      </ng-container>
    </div>

    <div class="inner-table" *ngIf="defects.data.length > 0">
      <table class="table" #defectsTable mat-table #defectsSort="matSort" [dataSource]="defects" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mangel</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Stück</th>
          <td mat-cell *matCellDef="let row">{{ row.amount }}</td>
        </ng-container>
        <ng-container matColumnDef="mm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>mm</th>
          <td mat-cell *matCellDef="let row">{{ row.mm }}</td>
        </ng-container>
        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Bereich</th>
          <td mat-cell *matCellDef="let row">{{ row.area }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['name', 'amount', 'mm', 'area']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'amount', 'mm', 'area']"></tr>
      </table>
    </div>

    <div class="inner-table" *ngIf="writings.data.length > 0">
      <table class="table" #writingsTable mat-table #writingsSort="matSort" [dataSource]="writings" matSort matSortActive="createdDate" matSortDirection="desc">
        <ng-container matColumnDef="pdf">
          <th mat-header-cell *matHeaderCellDef>Bericht</th>
          <td mat-cell *matCellDef="let row" (click)="reclamationService.getPdf($event, reclamation, row)">
            <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
          </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let row">{{ row.createdDate | date : "dd.MM.yyyy HH:mm" }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Art</th>
          <td mat-cell *matCellDef="let row">{{ "RECLAMATION_WRITING_TYPE." + row.type | translate }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['pdf', 'createdDate', 'type']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['pdf', 'createdDate', 'type']"></tr>
      </table>
    </div>

    <span class="position-big" *ngIf="images && images.length > 0">
      <div style="width: 100%">
        <span class="key bold">{{ "RECLAMATION.IMAGES" | translate }}:</span>
        <br />
        <div class="file-card" *ngFor="let image of images">
          <img width="140" [src]="'data:image/jpg;base64,' + image.base64" alt="" (click)="previewImage(image)" />
        </div>
      </div>
    </span>
  </div>
</div>
