import { Component, ElementRef, EventEmitter, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-pager",
  templateUrl: "./pager.component.html",
  styleUrls: ["./pager.component.scss"],
})
export class PagerComponent implements OnChanges {
  private entryCount: number = 0;
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

  public curPage = 1;
  public maxPages = 1;
  public pageEnd = 1;
  public pageStart = 1;

  public pages = [];
  public pageSelectMenuVisible = false;
  public appMode = Capacitor.isNativePlatform();
  private idx: number;
  private otherPager: PagerComponent;
  @ViewChild("pageSelectMenu") pageSelectMenu: ElementRef<any>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
  }
  public showPageDrowDown(evt: any): void {
    const el = evt.srcElement;

    this.pageSelectMenu.nativeElement.style.top = el.offsetTop + 28 + "px";
    this.pageSelectMenu.nativeElement.style.left = el.offsetLeft + "px";
    this.pageSelectMenu.nativeElement.style.display = "block";
    this.pageSelectMenuVisible = true;
  }

  public init(idx: number, otherPager: PagerComponent): void {
    this.idx = idx;
    this.otherPager = otherPager;
  }

  public resetPage(count: number, mainInit: boolean = true): void {
    this.entryCount = count;
    this.curPage = 1;
    this.switchPage(0, mainInit);
    if (mainInit && this.otherPager) {
      // init other pager
      this.otherPager.resetPage(count, false);
    }
  }
  public switchPage(direction: number, mainInit: boolean = true): void {
    if (direction == -999) {
      this.curPage--;
    } else if (direction == 999) {
      this.curPage++;
    } else if (direction == 1000) {
      this.curPage = this.maxPages;
    } else if (direction == 1001) {
      this.curPage = 1;
    } else if (direction != 0) {
      this.curPage = direction;
    }
    const cnt = environment.ENTRIES_PER_PAGE;

    const start = cnt * (this.curPage - 1);
    let end = start + cnt;
    //console.log(start + " - " + end + ", " + this.curTab.dataFiltered.length);
    if (start + cnt > this.entryCount) {
      end = this.entryCount;
    }
    //console.log("fin> start=" + start + ", end=" + end);
    this.pageStart = start;
    this.pageEnd = end;
    this.maxPages = Math.ceil(this.entryCount / cnt);
    if (this.pageChanged && mainInit) this.pageChanged.emit({ start: start, end: end, pagerIdx: this.idx });

    this.pages = [];
    for (let i = 1; i <= this.maxPages; i++) {
      this.pages.push({
        dir: i,
        title: "Seite " + i,
      });
    }

    this.pageSelectMenu.nativeElement.style.display = "none";
    this.pageSelectMenuVisible = false;

    if (mainInit && this.otherPager) this.otherPager.switchPage(direction, false);
  }
}
