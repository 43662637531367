export interface IOrderActionHistory {
  id: string;
  userId: string;
  createdDate: Date;
  merchantNumber: string;
  orderNumber: string;
  wishDeliveryDate: string;
  message: string;
  historyType: IOrderActionHistoryType;
  convertedMessage?: string;
  userData?: string;
}
export enum IOrderActionHistoryType {
  Approve,
  Change,
  Cancel,
  Archive,
  StateChange,
  Contact,
  Callback,
  Create,
}
export interface IOrderActionHistoryMessage {
  Message: string;
  MessagesByPosition: IOrderActionHistoryMessageByPosition[];
}
export interface IOrderActionHistoryMessageByPosition {
  Message: string;
  Index: string;
}
