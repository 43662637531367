import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Customer } from "src/app/models/customer";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-customer-edit-dialog",
  templateUrl: "./admin-customer-edit-dialog.component.html",
  styleUrls: ["./admin-customer-edit-dialog.component.scss"],
})
export class AdminCustomerEditDialogComponent implements OnInit {
  public form: FormGroup;
  public countryOptions = [];

  constructor(
    public dialogRef: MatDialogRef<AdminCustomerEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public customer: Customer,
    private fromBuilder: FormBuilder,
    private uiShareService: UiShareService,
    private adminService: ApiAdminService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    try {
      this.countryOptions = this.uiShareService.getCountryOptions("");
    } catch (e) {}

    this.form = this.fromBuilder.group({
      merchantNumber: [{ value: this.customer.merchantNumber, disabled: true }],
      email: [{ value: this.customer.email, disabled: true }],
      firstName: [this.customer.firstName],
      lastName: [this.customer.lastName],
      city: [this.customer.city],
      companyName: [this.customer.companyName],
      houseNumber: [this.customer.houseNumber],
      mobile: [this.customer.mobile],
      phone: [this.customer.phone],
      street: [this.customer.street],
      zipCode: [this.customer.zipCode, Validators.pattern("^[0-9]+$")],
      country: [this.customer.country],
      fax: [this.customer.fax],
      webPage: [this.customer.webPage],
      region: [this.customer.region],
      regionName: [this.customer.regionName],
      portalClient: [this.customer.portalClient],
      backOffice: [this.customer.backOffice],
      portalAdminAccount: [this.customer.portalAdminAccount],
      portalValidFrom: [this.customer.portalValidFrom],
      portalValidTo: [this.customer.portalValidTo],
    });
  }

  save() {
    const formValue = this.form.value;
    const customer: Partial<Customer> = {};
    customer.id = this.customer.id;
    customer.merchantNumber = this.customer.merchantNumber;
    customer.email = this.customer.email;
    customer.firstName = formValue.firstName;
    customer.lastName = formValue.lastName;
    customer.city = formValue.city;
    customer.companyName = formValue.companyName;
    customer.houseNumber = formValue.houseNumber;
    customer.mobile = formValue.mobile;
    customer.phone = formValue.phone;
    customer.street = formValue.street;
    customer.zipCode = formValue.zipCode;
    customer.country = formValue.country;
    customer.fax = formValue.fax;
    customer.webPage = formValue.webPage;
    customer.region = formValue.region;
    customer.regionName = formValue.regionName;
    customer.portalClient = formValue.portalClient;
    customer.backOffice = formValue.backOffice;
    customer.portalAdminAccount = formValue.portalAdminAccount;
    customer.portalValidFrom = formValue.portalValidFrom;
    customer.portalValidTo = formValue.portalValidTo;

    this.adminService.updateCustomer(customer as Customer).subscribe({
      next: (data) => {
        this.messageService.success("Händler konnte erfolgreich geändert werden");
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.messageService.error("Beim Ändern des Händlers ist ein Fehler aufgetreten.");
      },
    });
  }
}
