import { Component, OnInit } from "@angular/core";
import { Setting, SettingType } from "src/app/models/setting";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-admin-push",
  templateUrl: "./admin-push.component.html",
  styleUrls: ["./admin-push.component.scss"],
})
export class AdminPushComponent implements OnInit {
  availableLanguages: Array<string> = ["DE"];
  currentLanguage = "DE";
  data: Setting[];
  lngData: Setting[];

  currentTab = "settings";

  currentSubject: Setting;
  currentBody: Setting;
  key = "";
  body = "";
  subject = "";
  password = new Setting(SettingType.Push, "PUSH_PASSWORD", "");
  stTestPush = new Setting(SettingType.Push, "SENDTO_TEST_PUSH", "");
  public testPushModalShow: boolean = false;

  constructor(
    private apiSettingsService: ApiSettingsService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {
    this.lngData = [];
  }

  ngOnInit() {
    this.loadData();
  }
  public doCloseModal(): void {
    this.testPushModalShow = false;
  }
  sendTest() {
    this.data.forEach((d) => {
      if (d.key == "SENDTO_TEST_PUSH") this.stTestPush = d;
    });
    this.testPushModalShow = true;
  }
  doSendTest() {
    const calls = [this.apiSettingsService.update(this.stTestPush)];

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.apiSettingsService.testPush().subscribe((done) => {
          this.testPushModalShow = false;
          this.loaderService.hideTransparentLoader();
          this.messageService.success("Push sent");
        });
      });
    });
  }

  loadData() {
    this.apiSettingsService.getAll().subscribe((data) => {
      this.data = data.filter((d) => d.type == SettingType.Push);
      if (this.currentTab == "settings") this.loadSettings();
      else this.loadLng(this.currentTab);
    });
  }
  loadSettings() {
    this.currentTab = "settings";
    this.data.forEach((d) => {
      if (d.key == "PUSH_PASSWORD") this.password = d;
    });
  }
  saveSettings() {
    const calls = [this.apiSettingsService.update(this.password)];

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.loadData();
        this.loaderService.hideTransparentLoader();
      });
    });
  }
  loadLng(lng: string) {
    this.currentTab = lng;
    this.currentLanguage = lng;

    this.lngData = this.data
      .filter((d) => {
        if (d.key.startsWith(lng + "_") && d.key.endsWith("_SUBJECT")) {
          d.dspKey = this.translate.instant("ADMIN.PUSH." + d.key.replace(lng + "_", "").replace("_SUBJECT", ""));
          return d;
        }
      })
      .sort((l1: Setting, l2: Setting) => {
        if (l1.key > l2.key) return 1;
        else if (l1.key < l2.key) return -1;
        return 0;
      });
  }
  loadSetting(setting: Setting) {
    const key = setting.key.replace("_SUBJECT", "").replace("_BODY", "");
    const tmp = this.data.filter((d) => d.key.startsWith(key + "_BODY") || d.key.startsWith(key + "_SUBJECT"));
    tmp.forEach((t) => {
      if (t.key.endsWith("_BODY")) this.currentBody = t;
      else if (t.key.endsWith("_SUBJECT")) this.currentSubject = t;
    });
    this.key = key.replace(this.currentLanguage + "_", "");
    this.subject = this.currentSubject.value;
    this.body = this.currentBody.value;
  }
  newSetting() {
    this.currentSubject = null;
    this.subject = "";
    this.body = "";
  }
  savePushSetting() {
    if (this.currentSubject == null) {
      // new
      this.currentSubject = new Setting(SettingType.Push, this.currentLanguage + "_" + this.key + "_SUBJECT", this.subject);

      this.currentBody = new Setting(SettingType.Push, this.currentLanguage + "_" + this.key + "_BODY", this.body);
    } else {
      this.currentSubject.value = this.subject;
      this.currentBody.value = this.body;
    }

    const calls = [this.apiSettingsService.update(this.currentSubject), this.apiSettingsService.update(this.currentBody)];

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.loadData();
        this.loaderService.hideTransparentLoader();
      });
    });
  }
}
