import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class ApiContactService {
  private dataUrl = environment.apiConfig.uri + "Contact";

  constructor(private http: HttpService) {}

  public sendContact(body: any): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/ContactInquiry", body);
  }

  public sendCallback(body: any): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/CallbackInquiry", body);
  }
}
