<ion-footer class="wrapper-options">
  <ion-toolbar>
    <div *ngIf="uiShareService.selectedMerchant && multiMerchantEnabled">
      <span>
        Händler: {{ uiShareService.selectedMerchant.merchantNumber }},
        {{ uiShareService.selectedMerchant.companyName }}
      </span>
      &nbsp;
      <span class="deviating-merchant" *ngIf="userMerchantAccess?.activeMerchantId">ANSICHT: {{userMerchantAccess.activeMerchantNumber}}, {{userMerchantAccess.activeMerchantName}}</span>
    </div>
    <div class="option light-gray">{{ uiShareService.uiVersion }} ({{ uiShareService.backendVersion }})</div>
  </ion-toolbar>
</ion-footer>
