<app-content-header mat-dialog-title contentTitle="{{ 'LOGIN.PRIVACY.TITLE' | translate }}"></app-content-header>
<div mat-dialog-content>
  @if (errorMessage) {
    <p innerHtml="{{ errorMessage }}"></p>
  } @else {
    <p style="word-wrap: break-word">{{ "CONFIRM_ACCOUNT.CONFIRM_INFO" | translate }}</p>

    <form [formGroup]="form">
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.DATA_PRIVACY' | translate }}"></app-content-header>
      <div class="section">
        <app-checkbox formControlName="privacy"></app-checkbox>
        <div>
          <span style="word-wrap: break-word">Ja, ich habe die </span>
          <a (click)="linkClicked()">Datenschutzerklärung</a>
          <span style="word-wrap: break-word">
            für die durch Log-in geschützten Bereiche der „MeinAldra“ gelesen. Mit der Verwendung meiner personenbezogenen Daten zur Erstellung eines Zugangs
            für den Handelspartner zur MeinAldra und Verwaltung des Benutzerstatus bin ich einverstanden. Rechtsgrundlage für die Datenverarbeitung ist Art. 6
            Abs. 1 Satz 1 f) DSGVO. Unser berechtigtes Interesse besteht darin, dass wir Ihre Daten benötigen, um Ihre Anmeldung bearbeiten und Ihren
            Benutzerstatus verwalten zu können. Rechtsgrundlage ist zudem die Erfüllung des Vertrages gem. Art. 6 Abs. 1 Satz 1 b) DSGVO.</span
          >
        </div>
      </div>
      <br />
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.AGB' | translate }}"></app-content-header>
      <div class="section">
        <app-checkbox formControlName="agb"></app-checkbox>
        <div>
          <span style="word-wrap: break-word">Die </span>
          <a (click)="linkClicked()">Nutzungsbedingungen</a>
          <span style="word-wrap: break-word"> von MeinAldra habe ich gelesen und akzeptiere ich.</span>
        </div>
      </div>
      <br />
      <app-content-header contentTitle="{{ 'LOGIN.CREATE_ACCOUNT.NOTIFICATIONS' | translate }}"></app-content-header>
      <div class="section">
        <app-checkbox formControlName="activateNotifications"></app-checkbox>
        <div innerHtml="{{ 'LOGIN.CREATE_ACCOUNT.NOTIFICATIONS_ACTIVATED' | translate }}" style="word-wrap: break-word"></div>
      </div>
    </form>
  }
</div>
<div mat-dialog-actions class="button-footer">
  <button type="button" (click)="logout()">
    {{ "CONFIRM_ACCOUNT.CANCEL" | translate }}
  </button>
  @if (errorMessage) {
    <button type="button" [disabled]="form.invalid" (click)="retry()">
      {{ "CONFIRM_ACCOUNT.RETRY" | translate }}
    </button>
  } @else {
    <button type="button" [disabled]="form.invalid" (click)="confirm()">
      {{ "CONFIRM_ACCOUNT.CONFIRM" | translate }}
    </button>
  }
</div>
