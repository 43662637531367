import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IInvoiceDTO } from "src/app/models/invoice";
import { HttpService } from "src/app/services/http.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiInvoiceService {
  private dataUrl = environment.apiConfig.uri + "Invoices";

  constructor(private http: HttpService) {}
  public getInvoices(): Observable<any> {
    return this.http.get(`${this.dataUrl}`);
  }
  getInvoiceAttachment(invoice: IInvoiceDTO): Observable<any> {
    return this.http.postBlob(`${this.dataUrl}/PDF`, invoice);
  }
  getInvoiceOrderPositions(orderId: string, orderNumber: string): Observable<any> {
    return this.http.get(`${this.dataUrl}/positions/${orderId}/${orderNumber}`);
  }
}
