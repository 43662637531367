import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RoutingService {
  constructor(private router: Router) {}

  public forward(route: any[]) {
    this.router.navigate(route);
  }
  public forwardByUrl(url: string, data?: any) {
    this.router.navigateByUrl(url, data);
  }
}
