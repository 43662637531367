import { Component, OnInit } from "@angular/core";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";
import { Setting, SettingType } from "src/app/models/setting";
import { forkJoin } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { User } from "src/app/models/user";
import { ApiAdminService } from "src/app/services/api/api-admin.service";

@Component({
  selector: "app-admin-mail",
  templateUrl: "./admin-mail.component.html",
  styleUrls: ["./admin-mail.component.scss"],
})
export class AdminMailComponent implements OnInit {
  availableLanguages: Array<string> = ["DE"];
  currentLanguage = "DE";
  data: Setting[];
  lngData: Setting[];
  selectedUser: string;
  users: User[];
  selectedMailType: number;
  confirmType: number;
  confirmText: string;

  currentTab = "smtp";

  currentSubject: Setting;
  currentBody: Setting;
  key = "";
  body = "";
  subject = "";
  from = new Setting(SettingType.Mail, "SMTP_FROM", "");
  ssl = new Setting(SettingType.Mail, "SMTP_SSL", "");
  host = new Setting(SettingType.Mail, "SMTP_HOST", "");
  port = new Setting(SettingType.Mail, "SMTP_PORT", "");
  user = new Setting(SettingType.Mail, "SMTP_USER", "");
  password = new Setting(SettingType.Mail, "SMTP_PASSWORD", "");
  stContactOrder = new Setting(SettingType.Mail, "SENDTO_CONTACT_ORDER", "");
  stContactOffer = new Setting(SettingType.Mail, "SENDTO_CONTACT_OFFER", "");
  stContactFrame = new Setting(SettingType.Mail, "SENDTO_CONTACT_FRAME", "");
  stContactShipmentNote = new Setting(SettingType.Mail, "SENDTO_CONTACT_SHIPMENTNOTE", "");
  stContactReclamation = new Setting(SettingType.Mail, "SENDTO_CONTACT_RECLAMATION", "");
  stInvalidImport = new Setting(SettingType.Mail, "SENDTO_INVALID_IMPORT", "");
  stImporterError = new Setting(SettingType.Mail, "SENDTO_IMPORTER_ERROR", "");
  stTestMail = new Setting(SettingType.Mail, "SENDTO_TEST_MAIL", "");
  public confirmMailModalShow: boolean = false;
  public testMailModalShow: boolean = false;

  constructor(
    private apiAdminService: ApiAdminService,
    private apiUsersService: ApiUsersService,
    private apiSettingsService: ApiSettingsService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
    this.lngData = [];
  }

  ngOnInit() {
    this.loadData();
  }
  getWorkflowState(state: number): string {
    if (state == 0) return "New";
    if (state == 1) return "Verified";
    if (state == 2) return "Confirmed";
    return "Deactivated";
  }

  sendTest() {
    this.data.forEach((d) => {
      if (d.key == "SENDTO_TEST_MAIL") this.stTestMail = d;
    });
    this.testMailModalShow = true;
  }
  doSendTest() {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      this.apiSettingsService.update(this.stTestMail).subscribe((next) => {
        this.apiSettingsService.testMail().subscribe((done) => {
          this.testMailModalShow = false;
          this.loaderService.hideTransparentLoader();
          this.messageService.success("Mail sent");
        });
      });
    });
  }
  loadResend() {
    this.currentTab = "resend";
    this.apiUsersService.getAllUser().subscribe((data) => {
      this.users = data.sort((u1, u2) => {
        if (u1.merchantNumber > u2.merchantNumber) return 1;
        if (u1.merchantNumber < u2.merchantNumber) return -1;
        return 0;
      });
    });
  }
  resendMail() {
    this.confirmText = this.translate.instant("ADMIN.MAIL.CONFIRM_RESEND_TEXT");
    this.confirmType = 2;
    this.confirmMailModalShow = true;
  }
  loadData() {
    this.apiSettingsService.getAll().subscribe((data) => {
      this.data = data.filter((d) => d.type == SettingType.Mail);
      if (this.currentTab == "smtp") this.loadSmtp();
      else if (this.currentTab == "sendto") this.loadSendTo();
      else this.loadLng(this.currentTab);
    });
  }
  loadSmtp() {
    this.currentTab = "smtp";
    this.data.forEach((d) => {
      if (d.key == "SMTP_HOST") this.host = d;
      if (d.key == "SMTP_PORT") this.port = d;
      if (d.key == "SMTP_USER") this.user = d;
      if (d.key == "SMTP_PASSWORD") this.password = d;
      if (d.key == "SMTP_FROM") this.from = d;
      if (d.key == "SMTP_SSL") this.ssl = d;
    });
    if (this.ssl) {
      this.ssl.boolValue = this.ssl.value == "true";
    }
  }
  loadSendTo() {
    this.currentTab = "sendto";
    this.data.forEach((d) => {
      if (d.key == "SENDTO_CONTACT_ORDER") this.stContactOrder = d;
      if (d.key == "SENDTO_CONTACT_OFFER") this.stContactOffer = d;
      if (d.key == "SENDTO_CONTACT_RECLAMATION") this.stContactReclamation = d;
      if (d.key == "SENDTO_CONTACT_FRAME") this.stContactFrame = d;
      if (d.key == "SENDTO_CONTACT_SHIPMENTNOTE") this.stContactShipmentNote = d;
      if (d.key == "SENDTO_INVALID_IMPORT") this.stInvalidImport = d;
      if (d.key == "SENDTO_IMPORTER_ERROR") this.stImporterError = d;
    });
  }
  saveSendTo() {
    const calls = [
      this.apiSettingsService.update(this.stContactOrder),
      this.apiSettingsService.update(this.stContactOffer),
      this.apiSettingsService.update(this.stContactReclamation),
      this.apiSettingsService.update(this.stContactFrame),
      this.apiSettingsService.update(this.stContactShipmentNote),
      this.apiSettingsService.update(this.stInvalidImport),
      this.apiSettingsService.update(this.stImporterError),
    ];

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.loadData();
        this.loaderService.hideTransparentLoader();
        this.messageService.success("Saved");
      });
    });
  }
  saveSmtp() {
    this.confirmText = this.translate.instant("ADMIN.MAIL.CONFIRM_SMTP_TEXT");
    this.confirmType = 1;
    this.confirmMailModalShow = true;
  }
  public doCloseModal(): void {
    this.confirmMailModalShow = false;
    this.testMailModalShow = false;
  }
  public doConfirmModal(): void {
    this.confirmMailModalShow = false;

    if (this.confirmType == 2) {
      this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
        this.apiAdminService.resendMail(this.selectedMailType, this.selectedUser).subscribe({
          next: () => {
            this.messageService.success("Saved");
            this.loaderService.hideTransparentLoader();
          },
          error: (error) => {
            this.loaderService.hideTransparentLoader();
          },
        });
      });
    }
    if (this.confirmType == 1) {
      this.ssl.value = "" + this.ssl.boolValue;
      const calls = [
        this.apiSettingsService.update(this.host),
        this.apiSettingsService.update(this.port),
        this.apiSettingsService.update(this.user),
        this.apiSettingsService.update(this.password),
        this.apiSettingsService.update(this.from),
        this.apiSettingsService.update(this.ssl),
      ];

      this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
        forkJoin(calls).subscribe((next) => {
          this.loadData();
          this.loaderService.hideTransparentLoader();
          this.messageService.success("Saved");
        });
      });
    }
  }
  loadLng(lng: string) {
    this.currentTab = lng;
    this.currentLanguage = lng;

    this.lngData = this.data
      .filter((d) => {
        if (d.key.startsWith(lng + "_") && d.key.endsWith("_SUBJECT")) {
          d.dspKey = this.translate.instant("ADMIN.MAIL." + d.key.replace(lng + "_", "").replace("_SUBJECT", ""));
          return d;
        }
      })
      .sort((l1: Setting, l2: Setting) => {
        if (l1.key > l2.key) return 1;
        else if (l1.key < l2.key) return -1;
        return 0;
      });
  }
  loadSetting(setting: Setting) {
    if (setting.key != "TEMPLATE") {
      const key = setting.key.replace("_SUBJECT", "").replace("_BODY", "");
      const tmp = this.data.filter((d) => d.key.startsWith(key + "_BODY") || d.key.startsWith(key + "_SUBJECT"));
      tmp.forEach((t) => {
        if (t.key.endsWith("_BODY")) this.currentBody = t;
        else if (t.key.endsWith("_SUBJECT")) this.currentSubject = t;
      });
      this.key = key.replace(this.currentLanguage + "_", "");
      this.subject = this.currentSubject.value;
      this.body = this.currentBody.value;
    }
  }
  newSetting() {
    this.currentSubject = null;
    this.subject = "";
    this.body = "";
  }
  saveMailSetting() {
    if (this.currentSubject == null) {
      // new
      this.currentSubject = new Setting(SettingType.Mail, this.currentLanguage + "_" + this.key + "_SUBJECT", this.subject);

      this.currentBody = new Setting(SettingType.Mail, this.currentLanguage + "_" + this.key + "_BODY", this.body);
    } else {
      this.currentSubject.value = this.subject;
      this.currentBody.value = this.body;
    }

    const calls = [this.apiSettingsService.update(this.currentSubject), this.apiSettingsService.update(this.currentBody)];

    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      forkJoin(calls).subscribe((next) => {
        this.loadData();
        this.loaderService.hideTransparentLoader();
        this.messageService.success("Saved");
      });
    });
  }
}
