import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { ITab } from "src/app/models/tab";
import { Breadcrumb } from "../../models/breadcrumb";
import { ApiUsersService } from "../../services/api/api-users.service";
import { TABS } from "./master-data.config";
@Component({
  selector: "app-master-data",
  templateUrl: "./master-data.component.html",
  styleUrls: ["./master-data.component.scss"],
})
export class MasterDataComponent implements OnInit {
  @ViewChild("contactsSort") contactsSort: MatSort;
  @ViewChild("discountsSort") discountsSort: MatSort;
  @ViewChild("documentsSort") documentsSort: MatSort;
  @ViewChild("deliveriesSort") deliveriesSort: MatSort;
  public breadcrumb: [Breadcrumb];
  public tabs: ITab[] = TABS as ITab[];
  currentTabId = 0;

  public masterData: UntypedFormGroup = new UntypedFormGroup({});
  contactsData;
  displayedContactColumns: string[] = ["firstName", "lastName", "function", "phone", "mobile", "email"];
  public contacts: UntypedFormGroup = new UntypedFormGroup({});
  deliveriesData;
  displayedDeliveryColumns: string[] = ["deliveryCode", "street", "houseNumber", "zipCode", "city", "country"];
  public deliveries: UntypedFormGroup = new UntypedFormGroup({});
  discountsData;
  displayedDiscountColumns: string[] = ["orderType", "orderName", "value"];
  public discounts: UntypedFormGroup = new UntypedFormGroup({});
  documentsData;
  displayedDocumentColumns: string[] = ["documentCode", "receiver"];
  public documents: UntypedFormGroup = new UntypedFormGroup({});
  public DSGVO: UntypedFormGroup;

  public merchantToShow;

  constructor(
    private translateService: TranslateService,
    private userService: ApiUsersService,
  ) {
    this.breadcrumb = [{ title: this.translateService.instant("MASTER_DATA.TITLE"), page: null }];
  }

  ngOnInit(): void {
    this.userService.getMerchantMasterData().subscribe((res) => {
      this.merchantToShow = res;
      this.contactsData = new MatTableDataSource(this.merchantToShow.contacts);
      this.contactsData.sort = this.contactsSort;
      this.documentsData = new MatTableDataSource(this.merchantToShow.documents);
      this.documentsData.sort = this.documentsSort;
      this.discountsData = new MatTableDataSource(this.merchantToShow.discounts);
      this.discountsData.sort = this.discountsSort;
      this.deliveriesData = new MatTableDataSource(this.merchantToShow.deliveries);
      this.deliveriesData.sort = this.deliveriesSort;
    });
  }

  public switchTab(tab: ITab): void {
    this.tabs.forEach((t) => (t.active = false));
    tab.active = true;
    this.currentTabId = tab?.id;
  }

  onSubmit() {}
}
