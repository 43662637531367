<app-message></app-message>
<app-header-side-menu></app-header-side-menu>
<ion-router-outlet id="menuContent" [animated]="false"></ion-router-outlet>
<app-loader></app-loader>
<app-account-tools #accountTools></app-account-tools>
<app-common-modals #commonModals></app-common-modals>

<div class="qr-code-container">
  <div class="aim-square"></div>
  <div class="qr-code"></div>
  <button class="qr-close" mat-icon-button (click)="closeView()">
    <mat-icon>close</mat-icon>
  </button>
</div>
