<div class="dialog-modal-content">
  <app-content-header contentTitle="{{ 'ORDER_DIALOG.ORDER_CANCEL' | translate }}"></app-content-header>
  <div>
    <span>{{ "ORDER_DIALOG.CANCEL_REQUEST" | translate: { orderNumber: data.item?.orderNumber } }}</span
    ><br />{{ confirmText }}
  </div>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="onCancel()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="cancelOrder()">
    {{ "ORDER_DIALOG.CANCEL" | translate }}
  </button>
</div>
