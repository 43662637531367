import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { Capacitor } from "@capacitor/core";
import { Observable, ObservableInput, Observer } from "rxjs";
import { LogService } from "./log.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private logService: LogService,
    private httpApp: HTTP,
    private httpWeb: HttpClient,
  ) {}

  private setAuthHeaderMobile(withHeaders: boolean = true): any {
    this.logService.debug("HttpClient setAuthHeaderMobile() start");
    try {
      if (Capacitor.isNativePlatform()) {
        this.httpApp.setRequestTimeout(30);
      }
    } catch (e) {
      /* empty */
    }

    if (withHeaders) {
      this.httpApp.setHeader("*", "Accept", "application/json");
      this.httpApp.setHeader("*", "Content-Type", "application/json");
      this.httpApp.setDataSerializer("json");
    }
    return {};
  }

  private setAuthHeaderMobileNoType(method: string, data: any, asMultiPart: boolean = false): any {
    try {
      if (Capacitor.isNativePlatform()) {
        this.httpApp.setRequestTimeout(30);
      }
    } catch (e) {
      /* empty */
    }

    this.logService.debug("HttpClient setAuthHeaderMobileNoType() start");
    if (asMultiPart) {
      this.logService.debug("HttpClient setAuthHeaderMobileNoType() post as multipart");
      this.httpApp.setDataSerializer("multipart");
    }
    return { method: method, data: data };
  }
  private setAuthHeaderBinaryMobile(method: string, data: any): any {
    try {
      if (Capacitor.isNativePlatform()) {
        this.httpApp.setRequestTimeout(30);
      }
    } catch (e) {
      /* empty */
    }

    this.logService.debug("HttpClient setAuthHeaderBinary() start");
    return { method: method, responseType: "blob", data: data };
  }

  private setAuthHeaderWeb(withHeaders: boolean = true): any {
    this.logService.debug("HttpClient setAuthHeader() start");
    let headers = new HttpHeaders();
    const options = <any>{};
    if (withHeaders) {
      headers = headers.append("Accept", "application/json");
      headers = headers.append("Content-Type", "application/json");
    }
    options.headers = headers;
    return options;
  }

  private setAuthHeaderBinaryWeb(_options?: any): any {
    this.logService.debug("HttpClient setAuthHeaderBinary() start");
    const headers = new HttpHeaders();
    let options = <any>{};
    options.headers = headers;
    options.body = "";
    options.responseType = "blob";

    if (_options) {
      options = { ...options, ..._options };
    }

    return options;
  }

  public get(url: string): Observable<any> {
    this.logService.debug("HttpClient.get() called with " + url);
    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.get(url, this.setAuthHeaderWeb());
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .get(url, {}, this.setAuthHeaderMobile())
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public getBinary(url: string, fileExtension?: string): Observable<any> {
    this.logService.debug("HttpClient getBinary() start");
    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.get(url, this.setAuthHeaderBinaryWeb());
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .sendRequest(url, this.setAuthHeaderBinaryMobile("get", null))
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public postBlob(url: string, content: any, options?: any): Observable<any> {
    this.logService.debug("HttpClient.postBlob() called with " + url);
    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.post(url, content, this.setAuthHeaderBinaryWeb(options));
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .sendRequest(url, this.setAuthHeaderBinaryMobile("post", content))
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          this.logService.log(error);
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public post(url: string, content: any): Observable<any> {
    this.logService.debug("HttpClient.post() called with " + url);
    if (!Capacitor.isNativePlatform()) {
      return new Observable((observer: Observer<any>) => {
        this.httpWeb.post(url, content, this.setAuthHeaderWeb()).subscribe({
          next: (data: any) => {
            try {
              observer.next(JSON.parse(data.data));
            } catch (e) {
              try {
                if (data.data) {
                  observer.next(data.data);
                } else {
                  observer.next(JSON.parse(data));
                }
              } catch (e2) {
                observer.next(data);
              }
            }
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          },
        });
      });
    }
    if (typeof content === "string") {
      content = JSON.parse(content);
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .post(url, content, this.setAuthHeaderMobile())
        .then((data: any) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              if (data.data) {
                observer.next(data.data);
              } else {
                observer.next(JSON.parse(data));
              }
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          this.logService.log("failed");
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public postNoContentType(url: string, content: any, multiPartOnMobile: boolean = false): Observable<any> {
    this.logService.debug("HttpClient.post() NoContentType called with " + url);

    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.post(url, content, this.setAuthHeaderWeb(false));
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .sendRequest(url, this.setAuthHeaderMobileNoType("post", content, multiPartOnMobile))
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          this.logService.log(error);
          observer.error(error);
          this.handleError(error);
        });
    });
  }
  public put(url: string, content: any): Observable<any> {
    this.logService.debug("HttpClient.put() called with " + url);
    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.put(url, content, this.setAuthHeaderWeb());
    }
    if (typeof content === "string") {
      content = JSON.parse(content);
    }
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .put(url, content, this.setAuthHeaderMobile())
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public putNoContentType(url: string, content: any): Observable<any> {
    this.logService.debug("HttpClient.post() NoContentType called with " + url);
    if (!Capacitor.isNativePlatform()) {
      return this.httpWeb.put(url, content, this.setAuthHeaderWeb(false));
    }

    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .sendRequest(url, this.setAuthHeaderMobileNoType("put", content))
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  public delete(url: string, body?: any): Observable<any> {
    //TODO POST BODY! ?
    //return this.httpApp.request("delete", url, { body: opt });

    if (!Capacitor.isNativePlatform()) {
      const opt = this.setAuthHeaderWeb();
      if (body) {
        opt.body = body;
      }
      return this.httpWeb.delete(url, opt);
    }

    this.logService.debug("HttpClient.delete() called with " + url);
    return new Observable((observer: Observer<any>) => {
      this.httpApp
        .delete(url, body, this.setAuthHeaderMobile())
        .then((data) => {
          try {
            observer.next(JSON.parse(data.data));
          } catch (e) {
            try {
              observer.next(data.data);
            } catch (e2) {
              observer.next(data);
            }
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          this.handleError(error);
        });
    });
  }

  private handleError(error: any): ObservableInput<any> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    if (error) {
      const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : "Server error";
      console.error(errMsg); // log to console instead

      if (error.status == 401) {
        //this.tokenInvalid();
      }
    }
    return error;
  }
}
