import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenService } from "../services/token.service";
import { CryptedStorageService } from "../services/crypted-storage.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private storageService: CryptedStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sharedAccess = this.storageService.get("sharedMerchantAccess");
    this.tokenService.recordUserAction();

    let request: HttpRequest<any>;

    if (this.tokenService.currentToken) {
      console.log("HttpInterceptor() Adding jwt token");
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokenService.currentToken.accessToken}`,
          "Shared-Merchant-Access": sharedAccess || "",
        },
      });
    } else {
      console.log("HttpInterceptor() Cannot add jwt token");
      request = req.clone({
        setHeaders: {
          "Shared-Merchant-Access": sharedAccess || "",
        },
      });
    }
    return next.handle(request);
  }
}
