import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { AdminFrameDialogComponent } from "src/app/dialogs/admin/admin-frame-dialog/admin-frame-dialog.component";
import { RoutingService } from "src/app/services/routing.service";
import { BaseComponent } from "../../includes/base/base.component";
import { Breadcrumb } from "../../models/breadcrumb";
import { IFrame } from "../../models/frame";
import { MessageService } from "../../services/message.service";
import { FramesComponent } from "./../frames.component";

@Component({
  selector: "app-frame-detail",
  templateUrl: "./frame-detail.component.html",
  styleUrls: ["./frame-detail.component.scss"],
})
export class FrameDetailComponent extends BaseComponent implements OnInit {
  @Input() frame: IFrame;
  public appMode = Capacitor.isNativePlatform();
  public breadcrumb: [Breadcrumb];

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: RoutingService,
    private dialog: MatDialog,
    private framesComponent: FramesComponent,
  ) {
    super();
  }

  public ngOnInit(): void {}
  public adminEdit() {
    const dialogRef = this.dialog.open(AdminFrameDialogComponent, {
      width: "450px",
      data: { frameNumber: this.frame.number },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.framesComponent.reloadData(true);
      }
    });
  }
  public getCountryName(code: string) {
    return i18nIsoCountries.getName(code, this.translateService.currentLang);
  }
}
