import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(private translateService: TranslateService) {}

  public getFormControl(form: UntypedFormGroup, formControlName: string): string {
    let err: string = "";
    if (!form.get(formControlName)) {
      return;
    }

    if (form.get(formControlName).hasError("required")) {
      if (formControlName == "photo") {
        err = this.translateService.instant("ERROR.PHOTO_REQUIRED");
      } else {
        err = this.translateService.instant("ERROR.REQUIRED");
      }
    } else if (form.get(formControlName).hasError("minlength")) {
      err += this.translateService.instant("ERROR.MINLENGTH", {
        minlength: form.get(formControlName).getError("minlength").requiredLength,
      });
    } else if (form.get(formControlName).hasError("maxlength")) {
      err += this.translateService.instant("ERROR.MAXLENGTH", {
        maxlength: form.get(formControlName).getError("maxlength").requiredLength,
      });
    } else if (form.get(formControlName).hasError("pattern")) {
      if (
        form.get(formControlName).getError("pattern").requiredPattern ===
        "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
      ) {
        err += this.translateService.instant("ERROR.EMAIL");
      } else if (form.get(formControlName).getError("pattern").requiredPattern === "^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{5,}$") {
        let value = form.get(formControlName).value;
        let symbole = "+, 0-9";
        err += this.translateService.instant("ERROR.PHONE_NUMBER");
        err +=
          value.length > 5
            ? ". " +
              this.translateService.instant("ERROR.ALLOWED_SYMBOL", {
                symbole: symbole,
              })
            : this.translateService.instant("ERROR.PHONE_NUMBER_MINLENGTH", {
                minlength: 5,
              });
      } else if (form.get(formControlName).getError("pattern").requiredPattern === "^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{10,}$") {
        let value = form.get(formControlName).value.length;
        let symbole = "+, 0-9";
        err += this.translateService.instant("ERROR.MOBILE_NUMBER");
        err +=
          value.length > 10
            ? this.translateService.instant("ERROR.ALLOWED_SYMBOL", {
                symbole: symbole,
              })
            : this.translateService.instant("ERROR.PHONE_NUMBER_MINLENGTH", {
                minlength: 10,
              });
      } else if (form.get(formControlName).getError("pattern").requiredPattern === "^[0-9]+$") {
        err += this.translateService.instant("ERROR.ONLY_NUMBERS");
      }
    }
    return err;
  }
}
