/**
 * MyAldra API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReclamationReason } from "./reclamationReason";

export interface Reclamation {
  reclamationType: Reclamation.ReclamationTypeEnum;
  contactPerson: string;
  phone: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  orderNumber: string;
  product?: string;
  errorDescription: string;
  delivery?: string;
  reason?: ReclamationReason;
}
export interface IReclamationDb {
  id: string;
  status: IReclamationStatus;
  reclamationNumber: string;
  portalNumber: string;
  merchantNumber: string;
  zdemid: string;
  createdDate: Date;
  reclamationType: IReclamationType;
  contactPerson: string;
  phone: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  orderNumber: string;
  orderNumberInfo: string;
  product?: string;
  errorDescription: string;
  delivery?: string;
  reasons?: string;
  window?: string;
  defects?: string;
  heightGlassDimensions?: string;
  glassDimensions?: string;
  distanceName?: string;
  photosCount?: number;
  positions2SidedGlas?: string;
  positions3SidedGlas?: string;
  positions2SidedGlasStr?: string;
  positions3SidedGlasStr?: string;
  writings?: IReclamationWriting[];

  selected?: boolean;
}
export enum IReclamationWritingType {
  Initial,
  Intermediate,
  Closing,
}
export interface IReclamationWriting {
  id: string;
  createdDate: Date;
  type: IReclamationWritingType;
  fileName: string;
}
export enum IReclamationStatus {
  Done,
  Processing,
  New,
}
export enum IReclamationType {
  Default,
  Glas,
}
export namespace Reclamation {
  export type ReclamationTypeEnum = 0 | 1;
  export const ReclamationTypeEnum = {
    NUMBER_0: 0 as ReclamationTypeEnum,
    NUMBER_1: 1 as ReclamationTypeEnum,
  };
}
