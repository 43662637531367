import { Component } from "@angular/core";
import { UserMerchantAccess } from "src/app/services/token.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-custom-content-footer",
  templateUrl: "./custom-content-footer.component.html",
  styleUrls: ["./custom-content-footer.component.scss"],
})
export class CustomContentFooterComponent {
  public multiMerchantEnabled: boolean = environment.MULTIMERCHANT;
  userMerchantAccess?: UserMerchantAccess;

  constructor(public uiShareService: UiShareService) {
    this.uiShareService.activeMerchantAccess$.subscribe((access: UserMerchantAccess) => {
      this.userMerchantAccess = access;
    });
  }
}
