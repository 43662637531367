import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { DashboardComponent } from "./dashboard/dashboard.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { ScrollingModule } from "@angular/cdk/scrolling";
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { NativeGeocoder } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { IonicModule } from "@ionic/angular";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ConfirmAccountComponent } from "./account/confirm-account/confirm-account.component";
import { ContactFormularComponent } from "./account/contact-formular/contact-formular.component";
import { LoginComponent } from "./account/login/login.component";
import { LogoutComponent } from "./account/logout/logout.component";
import { PrivacyComponent } from "./account/privacy/privacy.component";
import { ResetPasswordComponent } from "./account/reset-password/reset-password.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminCustomersComponent } from "./components/admin/admin-customers/admin-customers.component";
import { AdminEventLogComponent } from "./components/admin/admin-event-log/admin-event-log.component";
import { AdminHolidayComponent } from "./components/admin/admin-holiday/admin-holiday.component";
import { AdminMailComponent } from "./components/admin/admin-mail/admin-mail.component";
import { AdminManageAccessComponent } from "./components/admin/admin-manage-access/admin-manage-access.component";
import { AdminPushComponent } from "./components/admin/admin-push/admin-push.component";
import { AdminSystemComponent } from "./components/admin/admin-system/admin-system.component";
import { AdminTranslationsComponent } from "./components/admin/admin-translations/admin-translations.component";
import { AdminUsersComponent } from "./components/admin/admin-users/admin-users.component";
import { AdminFrameComponent } from "./components/admin/includes/admin-frame/admin-frame.component";
import { CustomContentFooterComponent } from "./components/custom-content-footer/custom-content-footer.component";
import { CustomContentWrapperComponent } from "./components/custom-content-wrapper/custom-content-wrapper.component";
import { UserAdminModalsComponent } from "./components/includes/user-admin-modals/user-admin-modals.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MessageComponent } from "./components/message/message.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { UserAdministrationComponent } from "./components/user-administration/user-administration.component";
import { HomeCardsComponent } from "./dashboard/home-cards/home-cards.component";
import { MasterDataComponent } from "./data/master-data/master-data.component";
import { MyDataComponent } from "./data/my-data/my-data.component";
import { ActionArchiveDialogComponent } from "./dialogs/action/action-archive-dialog/action-archive-dialog.component";
import { ActionArchiveOrderDialogComponent } from "./dialogs/action/action-archive-order-dialog/action-archive-order-dialog.component";
import { ActionBestaetigenDialogComponent } from "./dialogs/action/action-bestaetigen-dialog/action-bestaetigen-dialog.component";
import { ActionChangeDialogComponent } from "./dialogs/action/action-change-dialog/action-change-dialog.component";
import { ActionChangeOrderDialogComponent } from "./dialogs/action/action-change-order-dialog/action-change-order-dialog.component";
import { ActionParkDialogComponent } from "./dialogs/action/action-park-dialog/action-park-dialog.component";
import { ActionStornierenDialogComponent } from "./dialogs/action/action-stornieren-dialog/action-stornieren-dialog.component";
import { AdminCustomerEditDialogComponent } from "./dialogs/admin/admin-customer-edit-dialog/admin-customer-edit-dialog.component";
import { AdminFrameDialogComponent } from "./dialogs/admin/admin-frame-dialog/admin-frame-dialog.component";
import { AdminOfferDialogComponent } from "./dialogs/admin/admin-offer-dialog/admin-offer-dialog.component";
import { AdminOrderDialogComponent } from "./dialogs/admin/admin-order-dialog/admin-order-dialog.component";
import { AdminReclamationDialogComponent } from "./dialogs/admin/admin-reclamation-dialog/admin-reclamation-dialog.component";
import { AdminSwitchUserComponent } from "./dialogs/admin/admin-switch-user/admin-switch-user.component";
import { AdminUserEditDialogComponent } from "./dialogs/admin/admin-user-edit-dialog/admin-user-edit-dialog.component";
import { BarcodePremissionDialogComponent } from "./dialogs/barcode-premission-dialog/barcode-premission-dialog.component";
import { ConfirmationDialogComponent } from "./dialogs/confirmation-dialog/confirmation-dialog.component";
import { EditIconDialogComponent } from "./dialogs/edit-icon-dialog/edit-icon-dialog.component";
import { ImageDialogComponent } from "./dialogs/image-dialog/image-dialog.component";
import { SelectedArchivesDialogComponent } from "./dialogs/selected-archives-dialog/selected-archives-dialog.component";
import { SelectedArchivesOrderDialogComponent } from "./dialogs/selected-archives-order-dialog/selected-archives-order-dialog.component";
import { ShipmentOrderPositionsDialogComponent } from "./dialogs/shipment-order-positions-dialog/shipment-order-positions-dialog.component";
import { FrameCreateComponent } from "./frames/frame-create/frame-create.component";
import { FrameDetailComponent } from "./frames/frame-detail/frame-detail.component";
import { FrameWrapperComponent } from "./frames/frame-wrapper/frame-wrapper.component";
import { FramesComponent } from "./frames/frames.component";
import { CustomIonHeaderCondenseComponent } from "./header/custom-ion-condense-header/custom-ion-condense-header.component";
import { CustomIonHeaderComponent } from "./header/custom-ion-header/custom-ion-header.component";
import { HeaderSideMenuComponent } from "./header/header-side-menu/header-side-menu.component";
import { MobileHeaderIonComponent } from "./header/mobile-header/mobile-header.component";
import { AccountToolsComponent } from "./includes/account-tools/account-tools.component";
import { AdminEditComponent } from "./includes/admin-edit/admin-edit.component";
import { BaseComponent } from "./includes/base/base.component";
import { CheckboxComponent } from "./includes/checkbox/checkbox.component";
import { CommonModalsComponent } from "./includes/common-modals/common-modals.component";
import { ContentHeaderComponent } from "./includes/content-header/content-header.component";
import { HomeLogoComponent } from "./includes/home-logo/home-logo.component";
import { LinedHeaderComponent } from "./includes/lined-header/lined-header.component";
import { ModalComponent, ModalContent } from "./includes/modal/modal.component";
import { OfferModalsComponent } from "./includes/offer-modals/offer-modals.component";
import { OrderModalsComponent } from "./includes/order-modals/order-modals.component";
import { PagerComponent } from "./includes/pager/pager.component";
import { PasswordValidatorComponent } from "./includes/password-validator/password-validator.component";
import { PrivacyTextComponent } from "./includes/privacy-text/privacy-text.component";
import { ReclamationModalsComponent } from "./includes/reclamation-modals/reclamation-modals.component";
import { SearchBoxComponent } from "./includes/search-box/search-box.component";
import { AuthHttpInterceptor } from "./interceptors/auth-http.interceptor";
import { InvoicesComponent } from "./invoices/invoices.component";
import { OfferDetailsComponent } from "./offer/offer-details/offer-details.component";
import { OfferRequestAgainComponent } from "./offer/offer-request-again/offer-request-again.component";
import { OffersComponent } from "./offer/offers.component";
import { OrderDetailsComponent } from "./order/order-details/order-details.component";
import { OrderComponent } from "./order/order.component";
import { SafePipe } from "./pipes/safe.pipe";
import { ProductDetailsComponent } from "./product/product-details/product-details.component";
import { ProductSearchComponent } from "./product/product-search/product-search.component";
import { ReclamationCreateComponent } from "./reclamations/reclamation-create/reclamation-create.component";
import { ReclamationDetailComponent } from "./reclamations/reclamation-detail/reclamation-detail.component";
import { ReclamationScanComponent } from "./reclamations/reclamation-scan/reclamation-scan.component";
import { ReclamationsComponent } from "./reclamations/reclamations.component";
import { LogService } from "./services/log.service";
import { ShipmentNotesComponent } from "./shipment/shipment-notes/shipment-notes.component";

registerLocaleData(localeDe); // de-DE

const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, `./assets/i18n/`, ".json");

@NgModule({ declarations: [
        AppComponent,
        HomeCardsComponent,
        LoginComponent,
        LinedHeaderComponent,
        HomeLogoComponent,
        LoaderComponent,
        ContentHeaderComponent,
        ModalComponent,
        ModalContent,
        ResetPasswordComponent,
        PasswordValidatorComponent,
        ConfirmAccountComponent,
        CheckboxComponent,
        DashboardComponent,
        LogoutComponent,
        AccountToolsComponent,
        AdminMailComponent,
        AdminFrameComponent,
        OrderComponent,
        ContactFormularComponent,
        MessageComponent,
        AdminTranslationsComponent,
        MyDataComponent,
        AdminPushComponent,
        OffersComponent,
        OrderDetailsComponent,
        FrameCreateComponent,
        OrderModalsComponent,
        FrameWrapperComponent,
        UserAdministrationComponent,
        UserAdminModalsComponent,
        RedirectComponent,
        PrivacyTextComponent,
        OfferDetailsComponent,
        OfferModalsComponent,
        CommonModalsComponent,
        ProductDetailsComponent,
        SafePipe,
        ReclamationDetailComponent,
        ReclamationsComponent,
        ReclamationCreateComponent,
        FramesComponent,
        FrameDetailComponent,
        PrivacyComponent,
        ShipmentNotesComponent,
        AdminEventLogComponent,
        AdminEditComponent,
        ReclamationModalsComponent,
        AdminSystemComponent,
        PagerComponent,
        BaseComponent,
        AdminHolidayComponent,
        MasterDataComponent,
        AdminManageAccessComponent,
        InvoicesComponent,
        ImageDialogComponent,
        EditIconDialogComponent,
        ActionChangeDialogComponent,
        ActionParkDialogComponent,
        ActionArchiveDialogComponent,
        SelectedArchivesDialogComponent,
        ActionStornierenDialogComponent,
        ActionBestaetigenDialogComponent,
        ActionArchiveOrderDialogComponent,
        ActionChangeOrderDialogComponent,
        SelectedArchivesOrderDialogComponent,
        BarcodePremissionDialogComponent,
        ProductSearchComponent,
        ReclamationScanComponent,
        CustomIonHeaderComponent,
        CustomIonHeaderCondenseComponent,
        CustomContentWrapperComponent,
        CustomContentFooterComponent,
        MobileHeaderIonComponent,
        HeaderSideMenuComponent,
        ConfirmationDialogComponent,
        AdminOrderDialogComponent,
        AdminOfferDialogComponent,
        AdminSwitchUserComponent,
        AdminUsersComponent,
        AdminCustomersComponent,
        AdminUserEditDialogComponent,
        AdminCustomerEditDialogComponent,
        AdminFrameDialogComponent,
        AdminReclamationDialogComponent,
        ShipmentOrderPositionsDialogComponent,
        SearchBoxComponent,
        OfferRequestAgainComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatListModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        ScrollingModule,
        MatDialogModule,
        MatTabsModule,
        MatSelectModule,
        MatMenuModule,
        MatButtonModule,
        IonicModule.forRoot({ mode: "ios" })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        LogService,
        InAppBrowser,
        CurrencyPipe,
        DatePipe,
        Geolocation,
        NativeGeocoder,
        {
            provide: LOCALE_ID,
            useValue: "de-DE", // 'de-DE' for Germany, 'fr-FR' for France ...
        },
        HTTP,
        Diagnostic,
        File,
        FileOpener,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
