import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Message, MessageType } from "../models/message";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  private subject = new Subject<Message>();
  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  public success(message: string) {
    this.setMessage(MessageType.Success, message);
  }

  public error(message: string) {
    this.setMessage(MessageType.Error, message);
  }

  public info(message: string) {
    this.setMessage(MessageType.Info, message);
  }

  public warn(message: string) {
    this.setMessage(MessageType.Warning, message);
  }

  public notification(title: string, message: string, handler: any) {
    this.setMessage(MessageType.Info, message, title, handler);
  }

  public setMessage(type: MessageType, message: string, title: string = null, handler: any = null) {
    const alertObject: Message = new Message();
    alertObject.type = type;
    alertObject.message = message;
    alertObject.title = title;
    alertObject.handler = handler;
    this.subject.next(alertObject);
  }
}
