import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Breadcrumb, BreadcrumbWithKey } from "src/app/models/breadcrumb";
import { ISearchPosition } from "src/app/models/searchOrder";
import { UserType } from "src/app/models/user";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { OrderService } from "src/app/services/order.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  public breadcrumb: [Breadcrumb];
  private subscription: any = null;
  public position: ISearchPosition = null;
  public language: string = "DE";
  public showDescription = false;
  public showPrices = false;

  constructor(
    private route: ActivatedRoute,
    public orderService: OrderService,
    private orderApiService: ApiOrderService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    public uiShareService: UiShareService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    try {
      this.language = this.translateService.getDefaultLang().toUpperCase();
      this.breadcrumb = [
        <BreadcrumbWithKey>{
          title: "PRODUCTS.DETAILS",
          page: null,
          withKey: true,
        },
      ];
      this.subscription = this.route.params.subscribe({
        next: (param) => {
          if (param["elementId"]) {
            this.searchArticle(param["elementId"]);
          }

          this.showPrices = false;
          this.uiShareService.loggedInUser$.subscribe((user) => {
            if (user && (user.type == UserType.Administrator || user.type == UserType.MerchantAdmin || user.type == UserType.Sales)) {
              this.showPrices = true;
            }
          });
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("ARTICLE.LOADING_FAIL"));
        },
      });
    } catch (e) {
      this.messageService.error(this.translateService.instant("ARTICLE.LOADING_FAIL"));
    }
    if (sessionStorage.getItem("redirect")) {
      sessionStorage.removeItem("redirect");
    }
  }
  public ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
    } catch (e) {}
  }

  public toggleDescription() {
    this.showDescription = !this.showDescription;
  }
  private searchArticle(id) {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.orderApiService.findByElementId(id).subscribe({
        next: (data) => {
          if (data) {
            data.documents.forEach((document) => {
              if (document.type == 0) {
                // leistungsbeschreibung
                document.translations = {};
                document.translations[this.translateService.defaultLang.toUpperCase()] = this.translateService.instant(
                  "PRODUCTS.DOCUMENT_TYPES.SERVICE_STATEMENT",
                );
              }
            });
            this.position = data;
          }
          this.loaderService.hideLoader();
        },
        error: (error) => {
          console.error(error);
          this.uiShareService.commonModals.showErrorModal(null, this.translateService.instant("PRODUCTS.WRONG_ELEMENT_ID")).subscribe();
          this.loaderService.hideLoader();
        },
      });
    });
  }
}
