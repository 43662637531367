import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import moment from "moment";

declare let noop: any;
declare let Error: any;
//declare let moment: any;

const methods = ["error", "warn", "log", "info", "debug", "trace"];
const console = window.console || { log: Function };

@Injectable()
export class LogService {
  debug(...args: any[]) {
    this.consoleLog("debug", args);
  }

  error(...args: any[]) {
    this.consoleLog("error", args);
  }

  info(...args: any[]) {
    this.consoleLog("info", args);
  }

  log(...args: any[]) {
    this.consoleLog("log", args);
  }

  trace(...args: any[]) {
    this.consoleLog("trace", args);
  }

  warn(...args: any[]) {
    this.consoleLog("warn", args);
  }

  private formatError(arg: any) {
    if (arg instanceof Error) {
      if (arg.stack) {
        arg = arg.message && arg.stack.indexOf(arg.message) === -1 ? "Error: " + arg.message + "\n" + arg.stack : arg.stack;
      } else if (arg.sourceURL) {
        arg = arg.message + "\n" + arg.sourceURL + ":" + arg.line;
      }
    } else if (typeof arg === "string") {
      return arg;
    } else if (typeof arg === "object") {
      if (Array.isArray(arg)) {
        arg = Array.prototype.join.call(arg);
      } else {
        arg = JSON.stringify(arg);
      }
    } else if (typeof arg !== "string") {
      arg = JSON.stringify(arg);
    }
    return arg;
  }
  public printBuffer(): void {
    this.buffer.forEach((b) => {
      console.log("BUFFER> " + b);
    });
  }
  private buffer: any[] = [];
  private consoleLog(type: string, args: any[]) {
    if (environment.LOG_LEVEL < methods.indexOf(type)) {
      return;
    }

    const logFn = (<any>console)[type] || console.log || noop;
    let hasApply = false;
    // Note: reading logFn.apply throws an error in IE11 in IE8 document mode.
    // The reason behind this is that console.log has type "object" in IE8...
    try {
      hasApply = !!logFn.apply;
    } catch (e) {
      //
    }
    const _args = [];
    const time = moment(new Date()).format("LTS");
    args.forEach((arg: any, index: number) => {
      if (index === 0) {
        _args.push(time + " " + type + " - " + this.formatError(arg));
        while (this.buffer.length > 250) this.buffer.splice(0, 1);
        this.buffer.push(time + " " + type + " - " + this.formatError(arg));
      } else {
        _args.push(this.formatError(arg));
      }
    });
    if (hasApply) {
      return logFn.apply(console, _args);
    } else {
      // we are IE which either doesn't have window.console => this is noop and we do nothing,
      // or we are IE where console.log doesn't have apply so we log at least first 2 args

      logFn(_args[0], _args.length <= 1 || _args[1] === null ? "" : _args[1]);
    }
  }
}
