<ion-header *ngIf="appMode">
  <ion-toolbar class="app-header">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="" icon="{{ icon }}" (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title center>{{ selected | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="secondary" (click)="secondaryClicked()">
        <ion-icon slot="icon-only" name="{{ secondaryIcon }}" *ngIf="secondaryIcon"></ion-icon>
      </ion-button>
      <ion-button color="secondary" (click)="reloadClicked()">
        <ion-icon slot="icon-only" name="refresh-outline" *ngIf="reloadIcon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
