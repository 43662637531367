export class Message {
  type: MessageType;
  message: string;
  title: string;
  handler: any;
  noAutoClose: boolean;
}

export enum MessageType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}
