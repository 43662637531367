<div class="tabs">
  <div class="tab active">{{ "ADMIN.HOLIDAY.MANAGE" | translate }}</div>
</div>
<div class="box">
  <div class="list">
    <div class="list-item" *ngFor="let setting of holidaySettings" (click)="loadSetting(setting)">
      <span>{{ setting.key + " - " + setting.value }}</span>
    </div>
    <div *ngIf="holidaySettings.length === 0">No data</div>
  </div>
  <div class="code">
    <button (click)="newSetting()">
      {{ "ADMIN.HOLIDAY.NEW_SETTING" | translate }}</button
    ><br />
    <br />
    <form>
      <label class="field-title">{{ "ADMIN.HOLIDAY.KEY" | translate }}:</label>
      <div class="field">
        <input class="form-control" type="date" name="key" [(ngModel)]="curSetting.key" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.HOLIDAY.VALUE" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="value" [(ngModel)]="curSetting.value" placeholder="" />
      </div>
      <br />
      <button (click)="saveSetting()" [disabled]="curSetting.value === '' || curSetting.key === ''">
        {{ "ADMIN.HOLIDAY.SAVE" | translate }}
      </button>
      <button (click)="deleteSetting()" [disabled]="!selected">
        {{ "ADMIN.HOLIDAY.DELETE" | translate }}
      </button>
    </form>
  </div>
</div>
