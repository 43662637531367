import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import moment from "moment";
import { BehaviorSubject } from "rxjs";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { Approve } from "../../../models/approve";
import { IOrder } from "../../../models/order";
import { Setting } from "../../../models/setting";
@Component({
  selector: "app-action-bestaetigen-dialog",
  templateUrl: "./action-bestaetigen-dialog.component.html",
  styleUrls: ["./action-bestaetigen-dialog.component.scss"],
})
export class ActionBestaetigenDialogComponent implements OnInit {
  public text: string;
  public approve: Approve = Object.assign({});
  public wishDeliveryDateDelay?: number;
  public holidayDelay: number = 0;
  private holidaySettings: Setting[] = [];
  public wishDeliveryDateMinWeek?: number;
  public wishDeliveryDateMinYear?: number;
  public wishDeliveryDateMaxYear?: number;
  public wishDeliveryDateWeek: number;
  public wishDeliveryDateYear: number;
  public wishDeliveryYears = [];
  public wishDeliveryWeeks = [];
  public confirmText: string;
  public requestDoneSubject: BehaviorSubject<IOrder> = new BehaviorSubject(null);
  constructor(
    public dialogRef: MatDialogRef<ActionBestaetigenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: ApiOrderService,
  ) {}

  ngOnInit(): void {
    const debug = false;
    moment.locale("de");
    // calculate time between today and last possible confirm date
    const diffTodayToLastPossibleDate = new Date().getTime() - new Date(this.data.item.lastPossibleConfirmDate).getTime();
    const minutesPast = Math.round(diffTodayToLastPossibleDate / 1000 / 60);
    const daysPast = Math.round(diffTodayToLastPossibleDate / 1000 / 60 / 60 / 24);
    if (debug) console.log("lastPossibleConfirmDate", this.data.item.lastPossibleConfirmDate);
    if (debug) console.log("plannedDeliveryDate", this.data.item.plannedDeliveryDate);
    if (debug) console.log("daysPastUntilConfirmDate", daysPast);
    if (debug) console.log("--------------------");

    // shift planned date if needed
    let plannedDeliveryDate = moment(this.data.item.plannedDeliveryDate);
    if (minutesPast >= 0) {
      // last possible date in past!
      const dd = moment(this.data.item.lastPossibleConfirmDate);
      const delta = moment().diff(dd, "days");
      plannedDeliveryDate = moment(this.data.item.lastPossibleConfirmDate).add(delta, "days");
      this.wishDeliveryDateDelay = daysPast;
      if (debug) console.warn("lastPosConfDateInPast", delta, plannedDeliveryDate.toString());
    } else {
      this.wishDeliveryDateDelay = 0;
    }
    let newPlannedDeliveryDate = plannedDeliveryDate;
    if (debug) console.log("newPlanDelDate", newPlannedDeliveryDate.toString());
    if (debug)
      console.log("newPlanDelDate", newPlannedDeliveryDate.format("w") + ", " + newPlannedDeliveryDate.format("Y") + ", " + newPlannedDeliveryDate.weekday());
    if (debug) console.log("--------------------");

    // minimum date for selection
    const diffDeliveryDateToLastPossibleDate =
      new Date(this.data.item.plannedDeliveryDate).getTime() - new Date(this.data.item.lastPossibleConfirmDate).getTime();
    const plannedDeliveryDateDayOfWeek = new Date(this.data.item.plannedDeliveryDate).getDay();

    const productionDays = Math.round(diffDeliveryDateToLastPossibleDate / 1000 / 60 / 60 / 24);
    if (debug) console.log("calculatedProductionDays", productionDays);
    if (debug) console.log("--------------------");

    let minSelectionDate = this.addBusinessDays(moment(), productionDays); //moment().add(productionDays, "days");

    if (debug) console.log("minSelctionDate", minSelectionDate);
    if (debug) console.log("minSelectionWeekDay", minSelectionDate.day());
    if (minSelectionDate.day() == 0) {
      minSelectionDate = minSelectionDate.add(1, "days");
    }
    if (minSelectionDate.day() == 6) {
      minSelectionDate = minSelectionDate.add(2, "days");
    }
    // overriding minsSelectiondate with firstPossibleDeliveryDate if available
    const firstPossibleDeliveryDate = moment(this.data.item?.firstPossibleDeliveryDate);
    if (firstPossibleDeliveryDate.year() != 1) {
      minSelectionDate = moment(this.data.item.firstPossibleDeliveryDate);
    }

    minSelectionDate = this.checkForValidDayOfWeek(plannedDeliveryDateDayOfWeek, minSelectionDate);
    //evaluate holiday settings
    this.holidayDelay = 0;
    const holidaySettingsValues = this.holidaySettings;
    if (holidaySettingsValues && holidaySettingsValues.length > 0) {
      for (let index = 0; index < holidaySettingsValues.length; index++) {
        let minDateChanged = false;
        const setting = holidaySettingsValues[index];
        if (moment(setting.key).isSame(minSelectionDate, "day")) {
          minDateChanged = true;
          const newDate = minSelectionDate.clone();
          newDate.add(1, "weeks");
          this.holidayDelay++;
          minSelectionDate = newDate;
        }
        if (minDateChanged) {
          index = -1;
        }
      }
    }
    if (debug) console.log("--------------------");

    if (newPlannedDeliveryDate < minSelectionDate) {
      if (debug) console.warn("new<min");
      newPlannedDeliveryDate = minSelectionDate;
    }

    if (debug) console.log("--------------------");

    // maximum date
    this.wishDeliveryDateMinWeek = Number(minSelectionDate.format("w"));
    this.wishDeliveryDateMinYear = Number(minSelectionDate.format("Y"));
    this.wishDeliveryDateMaxYear = this.wishDeliveryDateMinYear + 1;
    if (debug) console.log("minWishYear: " + this.wishDeliveryDateMinYear);
    if (debug) console.log("minWishWeek: " + this.wishDeliveryDateMinWeek);
    if (debug) console.log("maxWishYear: " + this.wishDeliveryDateMaxYear);
    if (newPlannedDeliveryDate.year() > this.wishDeliveryDateMaxYear) {
      this.wishDeliveryDateMaxYear = newPlannedDeliveryDate.year() + 1;
    }
    if (debug) console.log("planned: " + newPlannedDeliveryDate.year(), this.data.item.plannedDeliveryDate);
    if (debug) console.log(this.data.item);

    // defaults
    this.wishDeliveryDateWeek = Number(newPlannedDeliveryDate.format("w")); //this.wishDeliveryDateMinWeek;
    this.wishDeliveryDateYear = Number(newPlannedDeliveryDate.format("Y")); //this.wishDeliveryDateMinYear;
    if (debug) console.log("presetWishWeek: " + this.wishDeliveryDateWeek);
    if (debug) console.log("presetWishYear: " + this.wishDeliveryDateYear);

    this.loadDateFieldOptions();

    if (this.wishDeliveryDateWeek < this.wishDeliveryWeeks[0]) {
      if (debug) console.log("FIX WEEK");
      this.wishDeliveryDateWeek = this.wishDeliveryWeeks[0];
    }

    if (this.data.item.positions.filter((p) => p.changeMessage).length > 0) {
      // messages set!
      this.confirmText = "(Achtung: Sie haben Änderungsnachrichten auf Positionsebene.)";
    }
    this.approve.orderNumber = this.data.item.orderNumber;
  }
  private addBusinessDays(originalDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;
    const newDate = originalDate.clone();
    while (daysRemaining > 0) {
      newDate.add(1, "days");
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--;
      }
    }
    return newDate;
  }
  checkForValidDayOfWeek(plannedDeliveryDateDayOfWeek: number, minSelectionDate: any): any {
    const minSelectionDateDayOfWeek = minSelectionDate.day();
    if (minSelectionDateDayOfWeek > plannedDeliveryDateDayOfWeek) {
      const newDate = minSelectionDate.clone();
      newDate.add(1, "weeks");
      return newDate;
    }
    return minSelectionDate;
  }
  private loadDateFieldOptions() {
    this.wishDeliveryWeeks = [];
    this.wishDeliveryYears = [];

    let weekStart = 1;
    if (this.wishDeliveryDateYear == this.wishDeliveryDateMinYear) {
      // selected year = min year
      weekStart = this.wishDeliveryDateMinWeek;
    }

    for (let i = weekStart; i <= 52; i++) {
      this.wishDeliveryWeeks.push(i);
    }

    for (let i = this.wishDeliveryDateMinYear; i <= this.wishDeliveryDateMaxYear; i++) {
      this.wishDeliveryYears.push(i);
    }
  }
  public wishDeliveryDateYearChanged(event: any) {
    this.wishDeliveryWeeks = [];
    if (event == this.wishDeliveryDateMinYear) {
      for (let i = this.wishDeliveryDateMinWeek; i <= 52; i++) {
        this.wishDeliveryWeeks.push(i);
      }
      this.wishDeliveryDateWeek = this.wishDeliveryDateMinWeek;
    } else {
      for (let i = 1; i <= 52; i++) {
        this.wishDeliveryWeeks.push(i);
      }
      this.wishDeliveryDateWeek = 1;
    }
  }
  onCancel() {
    this.approve = Object.assign({});
    this.dialogRef.close();
  }
  public acceptOrder(): void {
    this.approve.type = 0;
    this.approve.wishDeliveryDate = this.wishDeliveryDateYear + "-W" + this.wishDeliveryDateWeek;
    this.organizeRequest();
  }
  public organizeRequest(): void {
    this.orderService.approve(this.approve).subscribe(
      (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      (error) => {
        this.dialogRef.close(false);
      },
    );
  }
}
