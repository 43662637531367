<ion-content>
  <div slot="fixed" class="home">
    <div id="container" class="container" [ngClass]="appMode ? 'appMode' : 'deskMode'">
      <app-home-logo></app-home-logo>

      <div class="root-box">
        <div class="cards-box">
          <div class="card login" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="appMode && !uiShareService.loggedIn">
            <img class="card-image" (click)="defaultUser()" id="login"
              [src]="loginHover && !appMode ? './assets/icons/LoginWhite.png' : './assets/icons/LoginRed.png'" alt="" />
            <div class="card-label">
              <div class="login-text" [innerHtml]="'LOGIN.WELCOME_TEXT' | translate"></div>
              <form>
                <label class="field-title">{{ "LOGIN.MAIL_ADDRESS" | translate }}:</label>
                <div class="field">
                  <input class="form-control" name="mailAddress" [(ngModel)]="mailAddress" placeholder="" />
                </div>
                <label class="field-title">{{ "LOGIN.PASSWORD" | translate }}:</label>
                <div class="field">
                  <input class="form-control" name="password" [(ngModel)]="password" type="password" placeholder="" />
                </div>

                <div class="buttons">
                  <button class="no-margin" (click)="uiShareService.accountTools.doLogin(mailAddress, password)"
                    [disabled]="mailAddress == '' || password == ''">
                    {{ "LOGIN.LOGIN.BUTTON" | translate }}
                  </button>
                  <button style="float: right" class="plain small no-margin" (click)="uiShareService.accountTools.showLostPasswordModal = true">
                    {{ "LOGIN.LOST_PASSWORD.BUTTON" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="appMode" (mouseenter)="searchHover = true" (mouseleave)="searchHover = false"
            (click)="openPage('search')">
            <img class="card-image" id="exit" [src]="searchHover && !appMode ? './assets/icons/searchWhite.png' : '../assets/icons/searchRed.png'" alt="" />
            <span class="card-label">{{ "MENU.PRODUCT_SEARCH" | translate }}</span>
          </div>
          <div class="card" *ngIf="!appMode && uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5"
            (mouseenter)="dashboardHover = true" (mouseleave)="dashboardHover = false" (click)="openPage('dashboard')">
            <img class="card-image" id="dashboard" [src]="dashboardHover && !appMode ? './assets/icons/DashboardWhite.png' : './assets/icons/DashboardRed.png'"
              alt="" />
            <span class="card-label">{{ "MENU.DASHBOARD" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''"
            *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (mouseenter)="offerHover = true"
            (mouseleave)="offerHover = false" (click)="openPage('offer')">
            <img class="card-image" id="offer" [src]="offerHover && !appMode ? './assets/icons/OffersWhite.png' : './assets/icons/OffersRed.png'" alt="" />
            <span class="card-label">{{ "MENU.OFFER" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''"
            *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5" (mouseenter)="orderHover = true"
            (mouseleave)="orderHover = false" (click)="openPage('order')">
            <img class="card-image" id="order" [src]="orderHover && !appMode ? './assets/icons/OrdersWhite.png' : './assets/icons/OrdersRed.png'" alt="" />
            <span class="card-label">{{ "MENU.ORDER" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5"
            (mouseenter)="orderHover2 = true" (mouseleave)="orderHover2 = false" (click)="openPage('shipment-notes')">
            <img class="card-image" id="order" [src]="orderHover2 && !appMode ? './assets/icons/DeliveringWhite.png' : './assets/icons/DeliveringRed.png'"
              alt="" />
            <span class="card-label">{{ "MENU.SHIPMENT-NOTES" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="
              uiShareService.loggedIn &&
              (uiShareService.loggedInUser.type == 4 || uiShareService.loggedInUser.type == 0 || uiShareService.loggedInUser.type == 1)
            " (mouseenter)="invoicesHover = true" (mouseleave)="invoicesHover = false" (click)="openPage('invoices')">
            <img class="card-image" id="rechnungen"
              [src]="invoicesHover && !appMode ? './assets/icons/MeinAldra_Rechnungen_war.png' : './assets/icons/MeinAldra_Rechnungen_raw.png'" alt="" />
            <span class="card-label">{{ "Rechnungen" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5"
            (mouseenter)="reclamation1Hover = true" (mouseleave)="reclamation1Hover = false" (click)="openPage('reclamation')">
            <img class="card-image" id="reclamation"
              [src]="reclamation1Hover && !appMode ? './assets/icons/ReclamationWhite.png' : './assets/icons/ReclamationRed.png'" alt="" />
            <span class="card-label">{{ "MENU.RECLAMATION" | translate }}</span>
          </div>

          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn" (mouseenter)="frameHover = true"
            (mouseleave)="frameHover = false" (click)="openPage('frame')">
            <img class="card-image" id="frame" [src]="frameHover && !appMode ? './assets/icons/FrameWhite.png' : './assets/icons/FrameRed.png'" alt="" />
            <span class="card-label">{{ "MENU.FRAME" | translate }}</span>
          </div>

          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn" (mouseenter)="experthekHover = true"
            (mouseleave)="experthekHover = false" (click)="openExperthek()">
            <img class="card-image" id="frame" [src]="experthekHover && !appMode ? './assets/icons/ExperthekWhite.png' : './assets/icons/ExperthekRed.png'"
              alt="" />
            <span class="card-label">{{ "MENU.EXPERTHEK" | translate }}</span>
          </div>

          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn" (mouseenter)="mydataHover = true"
            (mouseleave)="mydataHover = false" (click)="openPage('mydata')">
            <img class="card-image" id="mydata" [src]="mydataHover && !appMode ? './assets/icons/MyDataWhite.png' : './assets/icons/MyDataRed.png'" alt="" />
            <span class="card-label">{{ "MENU.MY_DATA" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''"
            *ngIf="uiShareService.loggedIn && !appMode && (uiShareService.loggedInUser.type == 4 || uiShareService.loggedInUser.type == 0)"
            (mouseenter)="userHover = true" (mouseleave)="userHover = false" (click)="openPage('user-administration')">
            <img class="card-image" id="user" [src]="userHover && !appMode ? './assets/icons/UsersWhite.png' : './assets/icons/UsersRed.png'" alt="" />
            <span class="card-label">{{ "MENU.USER" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn && !appMode && uiShareService.loggedInUser.type == 0"
            (mouseenter)="adminHover = true" (mouseleave)="adminHover = false" (click)="openPage('admin/eventlog')">
            <img class="card-image" id="admin" [src]="adminHover && !appMode ? './assets/icons/AdminWhite.png' : './assets/icons/AdminRed.png'" alt="" />
            <span class="card-label">{{ "MENU.ADMIN" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="userMerchantAccess && userMerchantAccess.switchAccess"
            (click)="uiShareService.accountTools.switchMerchantAccess()" (mouseenter)="gvlHover = true" (mouseleave)="gvlHover = false">
            <img class="card-image" [src]="gvlHover && !appMode ? './assets/icons/GVL_weiss.png' : './assets/icons/GVL_rot.png'" alt="" />
            <span class="card-label" *ngIf="userMerchantAccess && userMerchantAccess?.activeMerchantId">Händler: {{ userMerchantAccess.activeMerchantNumber
              }}</span>
            <span *ngIf="!(userMerchantAccess && userMerchantAccess?.activeMerchantId)" class="card-label">Händler wechseln</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="
              uiShareService.loggedIn &&
              (uiShareService.loggedInUser.type == 4 || uiShareService.loggedInUser.type == 0 || uiShareService.loggedInUser.type == 1)
            " (mouseenter)="stammdatenHover = true" (mouseleave)="stammdatenHover = false" (click)="openPage('master-data')">
            <img class="card-image" id="stammdaten"
              [src]="stammdatenHover && !appMode ? './assets/icons/Stammdaten_weiss.png' : './assets/icons/Stammdaten_rot.png'" alt="" />
            <span class="card-label">{{ "MASTER_DATA.TITLE" | translate }}</span>
          </div>
          <div class="card" [ngClass]="appMode ? 'no-hover' : ''" *ngIf="uiShareService.loggedIn" (mouseenter)="exitHover = true"
            (mouseleave)="exitHover = false" (click)="openPage('logout')">
            <img class="card-image" id="exit" [src]="exitHover && !appMode ? './assets/icons/ExitWhite.png' : './assets/icons/ExitRed.png'" alt="" />
            <span class="card-label">{{ "MENU.LOGOUT" | translate }}</span>
          </div>
        </div>
        <div class="card-options">
          <div class="option" (click)="toggleShowPrices()">
            <span *ngIf="uiShareService.showPrices()">{{ "MENU.HIDE_PRICES" | translate }}</span>
            <span *ngIf="!uiShareService.showPrices()">{{ "MENU.SHOW_PRICES" | translate }}</span>
          </div>

          <div *ngIf="multiMerchantEnabled && uiShareService.selectedMerchant" >
            <span class="option gray"> Händler: {{ uiShareService.selectedMerchant.merchantNumber }}, {{ uiShareService.selectedMerchant.companyName }} </span>&nbsp;
            <span class="deviating-merchant" *ngIf="userMerchantAccess?.activeMerchantId">
              ANSICHT: {{ userMerchantAccess.activeMerchantNumber }}, {{ userMerchantAccess.activeMerchantName }}</span
            >
          </div>

          <div class="option light-gray">{{ uiShareService.uiVersion }} ({{ uiShareService.backendVersion }})</div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
