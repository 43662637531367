import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const switchUserId = localStorage.getItem("SwitchUser");
    if (switchUserId) {
      req = req.clone({ headers: req.headers.set("Switch-User", switchUserId) });
    }
    return next.handle(req);
  }
}
