import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { ContactFormularComponent } from "./account/contact-formular/contact-formular.component";
import { PrivacyComponent } from "./account/privacy/privacy.component";
import { AdminCustomersComponent } from "./components/admin/admin-customers/admin-customers.component";
import { AdminEventLogComponent } from "./components/admin/admin-event-log/admin-event-log.component";
import { AdminHolidayComponent } from "./components/admin/admin-holiday/admin-holiday.component";
import { AdminMailComponent } from "./components/admin/admin-mail/admin-mail.component";
import { AdminManageAccessComponent } from "./components/admin/admin-manage-access/admin-manage-access.component";
import { AdminPushComponent } from "./components/admin/admin-push/admin-push.component";
import { AdminSystemComponent } from "./components/admin/admin-system/admin-system.component";
import { AdminTranslationsComponent } from "./components/admin/admin-translations/admin-translations.component";
import { AdminUsersComponent } from "./components/admin/admin-users/admin-users.component";
import { AdminFrameComponent } from "./components/admin/includes/admin-frame/admin-frame.component";
import { LoginFailedComponent } from "./components/login-failed/login-failed.component";
import { UserAdministrationComponent } from "./components/user-administration/user-administration.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeCardsComponent } from "./dashboard/home-cards/home-cards.component";
import { MasterDataComponent } from "./data/master-data/master-data.component";
import { MyDataComponent } from "./data/my-data/my-data.component";
import { FrameCreateComponent } from "./frames/frame-create/frame-create.component";
import { FramesComponent } from "./frames/frames.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { OfferDetailsComponent } from "./offer/offer-details/offer-details.component";
import { OffersComponent } from "./offer/offers.component";
import { OrderDetailsComponent } from "./order/order-details/order-details.component";
import { OrderComponent } from "./order/order.component";
import { ProductDetailsComponent } from "./product/product-details/product-details.component";
import { ProductSearchComponent } from "./product/product-search/product-search.component";
import { ReclamationCreateComponent } from "./reclamations/reclamation-create/reclamation-create.component";
import { ReclamationScanComponent } from "./reclamations/reclamation-scan/reclamation-scan.component";
import { ReclamationsComponent } from "./reclamations/reclamations.component";
import { ShipmentNotesComponent } from "./shipment/shipment-notes/shipment-notes.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "login-failed", component: LoginFailedComponent },
  { path: "home", component: HomeCardsComponent, canActivate: [MsalGuard] },
  {
    path: "article/:elementId",
    component: ProductDetailsComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "admin",
    component: AdminFrameComponent,
    children: [
      {
        path: "",
        redirectTo: "eventlog",
        pathMatch: "full",
      },
      {
        path: "mail",
        component: AdminMailComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "push",
        component: AdminPushComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "translations",
        component: AdminTranslationsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "eventlog",
        component: AdminEventLogComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "system",
        component: AdminSystemComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "holiday",
        component: AdminHolidayComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "manage-access",
        component: AdminManageAccessComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "users",
        component: AdminUsersComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "customers",
        component: AdminCustomersComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: "order",
    component: OrderComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "search",
    component: ProductSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "shipment-notes",
    component: ShipmentNotesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "order-details",
    component: OrderDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "reclamation-create",
    component: ReclamationCreateComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "reclamation-create/:id",
    component: ReclamationCreateComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "reclamation",
    component: ReclamationsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "contact/:type/:id/:info",
    component: ContactFormularComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "callback/:type/:id/:info",
    component: ContactFormularComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "offer",
    component: OffersComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "offer-details",
    component: OfferDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "mydata",
    component: MyDataComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "frame",
    component: FramesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "frame-create",
    component: FrameCreateComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "user-administration",
    component: UserAdministrationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "master-data",
    component: MasterDataComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "invoices",
    component: InvoicesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "reclamation-scan/:type",
    component: ReclamationScanComponent,
    canActivate: [MsalGuard],
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabledNonBlocking" : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
