import { Component, OnInit } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Capacitor } from "@capacitor/core";
import { User } from "src/app/models/user";
import { LogService } from "src/app/services/log.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserMerchantAccess } from "src/app/services/token.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home-cards",
  templateUrl: "./home-cards.component.html",
  styleUrls: ["./home-cards.component.scss"],
})
export class HomeCardsComponent implements OnInit {
  private isPopupOpen: boolean = false;
  public appMode = Capacitor.isNativePlatform();
  public mailAddress = "";
  public password = "";
  public adminHover: boolean;
  public loginHover: boolean;
  public searchHover: boolean;
  public dashboardHover: boolean;
  public offerHover: boolean;
  public experthekHover: boolean;
  public reclamation1Hover: boolean;
  public frameHover: boolean;
  public mydataHover: boolean;
  public userHover: boolean;
  public exitHover: boolean;
  public orderHover: boolean;
  public orderHover2: boolean;
  public invoicesHover: boolean;
  public togglePricesHover: boolean;
  public toggleMultiMerchantsHover: boolean;
  public gvlHover: boolean;
  public stammdatenHover: boolean;
  public user: User;
  public multiMerchantEnabled: boolean = environment.MULTIMERCHANT;
  public userMerchantAccess: UserMerchantAccess;
  public activeMerchantNumber: string;

  constructor(public uiShareService: UiShareService, private router: RoutingService, private iab: InAppBrowser, private logService: LogService) {
    this.loginHover = false;
    this.searchHover = false;
    this.dashboardHover = false;
    this.offerHover = false;
    this.experthekHover = false;
    this.reclamation1Hover = false;
    this.frameHover = false;
    this.mydataHover = false;
    this.userHover = false;
    this.exitHover = false;
    this.orderHover = false;
    this.orderHover2 = false;
    this.invoicesHover = false;
    this.togglePricesHover = false;
    this.toggleMultiMerchantsHover = false;
  }
  public ngOnInit(): void {
    this.logService.debug("HomeCardsComponent.ngOnInit()");
    if (Capacitor.isNativePlatform()) {
      this.uiShareService.appReady.subscribe((next) => {
        this.logService.debug("HomeCardsComponent.ngOnInit() platform ready: " + next);
        if (next) {
          this.logService.debug("HomeCardsComponent.ngOnInit() init auto login");
          this.uiShareService.accountTools.tryAutoLogin();
        }
      });
    } else {
      //this.logService.debug("HomeCardsComponent.ngOnInit() direct init");
      //this.uiShareService.accountTools.tryAutoLogin();
    }

    this.uiShareService.activeMerchantAccess$.subscribe((access: UserMerchantAccess) => {
      this.userMerchantAccess = access;
      if (this.userMerchantAccess?.isGVL && !this.uiShareService.gvlSelectionShowed && !this.userMerchantAccess?.activeMerchantId) {
        this.uiShareService.accountTools.switchMerchantAccess(true);
        this.uiShareService.gvlSelectionShowed = true;
      }
    });
    this.uiShareService.routeChanged.subscribe((changed) => {
      this.mailAddress = "";
      this.password = "";
    });
  }

  public toggleShowPrices() {
    this.uiShareService.showPrices.update((prev) => !prev);
  }

  public defaultUser() {
    if (environment.LOG_LEVEL == 10) {
      this.mailAddress = "";
      this.password = "";
    }
  }

  public openPage(page) {
    if (page == "logout") {
      this.uiShareService.tokenService.resetSharedMerchant();
    }
    this.router.forwardByUrl(page);
  }

  public openExperthek() {
    if (Capacitor.isNativePlatform()) {
      this.iab.create("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", "_system");
    } else {
      window.open("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", "_blank");
    }
  }
}
