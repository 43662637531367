import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { Setting, SettingType } from "src/app/models/setting";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";

@Component({
  selector: "app-admin-holiday",
  templateUrl: "./admin-holiday.component.html",
  styleUrls: ["./admin-holiday.component.scss"],
})
export class AdminHolidayComponent implements OnInit {
  holidaySettings: Setting[] = [];
  selected = false;
  curSetting: Setting = new Setting(SettingType.Holiday, "", "");
  constructor(private apiSettingsService: ApiSettingsService) {}

  ngOnInit(): void {
    this.apiSettingsService.getByType(SettingType.Holiday).subscribe({
      next: (res) => {
        this.holidaySettings = res;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  newSetting() {
    this.curSetting = new Setting(SettingType.Holiday, "", "");
    this.selected = false;
  }
  loadSetting(setting: Setting) {
    this.curSetting = Object.assign({}, setting);
    this.selected = true;
  }
  saveSetting() {
    this.apiSettingsService
      .createOrUpdate(this.curSetting)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          this.apiSettingsService.getByType(SettingType.Holiday).subscribe({
            next: (res) => {
              this.holidaySettings = res;
            },
            error: (e) => {
              console.log(e);
            },
            complete: () => {
              this.curSetting = new Setting(SettingType.Holiday, "", "");
              this.selected = false;
            },
          });
        },
      });
  }
  deleteSetting() {
    this.apiSettingsService.delete(this.curSetting.id).subscribe({
      complete: () => {
        this.apiSettingsService.getByType(SettingType.Holiday).subscribe({
          next: (res) => {
            this.holidaySettings = res;
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
            this.curSetting = new Setting(SettingType.Holiday, "", "");
            this.selected = false;
          },
        });
      },
    });
  }
}
