import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { IReclamationEdit } from "src/app/models/edit";
import { IOrder } from "src/app/models/order";
import { IOrderPosition } from "src/app/models/order-position";
import { IReclamationDb } from "src/app/models/reclamation";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { ApiReclamationService } from "src/app/services/api/api-reclamation.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-reclamation-modals",
  templateUrl: "./reclamation-modals.component.html",
  styleUrls: ["./reclamation-modals.component.scss"],
})
export class ReclamationModalsComponent implements OnInit {
  public isOpenPreviewImageDialog = false;
  public isOpenAdminDialog = false;
  public isOpenSelectOrderDialog = false;
  public isOpenSelectOrderPositionDialog = false;
  public selectOrderSearchText = "";
  public selectOrderTabId = 0;
  public orderPosOptions = [];
  private orderPosOptionsSrc: IOrderPosition[] = [];
  public orderOptions = [];
  private orderOptionsSrc = [];
  private orderOptionsArchiveSrc = [];
  public freeOrderNumber = "";
  public freeElementId = "";
  public elementIds = [];
  private selectedOrder;
  private selectedPosition;
  public previewImageData = "";
  public editNewStatus;
  public requestDoneSubject: BehaviorSubject<IReclamationDb> = new BehaviorSubject(null);
  public item: IReclamationDb;
  private orderSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private orderPositionSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private archiveLoaded = false;

  constructor(
    private messageService: MessageService,
    private uiShareService: UiShareService,
    private apiReclService: ApiReclamationService,
    private loaderService: LoaderService,
    private apiOrderService: ApiOrderService,
  ) {}

  ngOnInit(): void {}

  public closeDialog() {
    this.isOpenPreviewImageDialog = false;
    this.isOpenAdminDialog = false;
    this.isOpenSelectOrderDialog = false;
  }

  public openAdminDialog(reclamation: IReclamationDb) {
    this.item = reclamation;
    this.editNewStatus = 1; // default is processing
    this.isOpenAdminDialog = true;
  }

  public selectOrder(order: IOrder): void {
    this.selectedOrder = order;
    this.orderSelectedSubject.next(true);
  }
  public selectFreeOrder(): void {
    this.selectedOrder = this.freeOrderNumber;
    this.orderSelectedSubject.next(true);
    this.freeOrderNumber = "";
  }
  public closeSelectOrderDialog(): void {
    this.selectedOrder = null;
    this.orderSelectedSubject.next(true);
    this.selectOrderTabId = 0;
  }
  public selectOrderSearchChanged(): void {
    const tmp = this.selectOrderSearchText.toLowerCase();
    this.orderOptions = (this.selectOrderTabId == 0 ? this.orderOptionsSrc : this.orderOptionsArchiveSrc).filter(
      (c) => c.orderNumber.toLowerCase().indexOf(tmp) != -1 || c.customerReference.toLowerCase().indexOf(tmp) != -1,
    );
  }
  public async switchSelectOrderTab(id: number) {
    this.selectOrderTabId = id;
    if (id === 1 && !this.archiveLoaded) {
      await this.loadOrdersArchive();
      this.archiveLoaded = true;
    }
    this.selectOrderSearchChanged();
  }
  private selectOrderSubscription;
  public showSelectOrderModal(): Observable<any> {
    this.orderSelectedSubject.next(false);

    this.selectedOrder = null;
    this.isOpenSelectOrderDialog = true;
    return new Observable((observer: Observer<boolean>) => {
      if (this.uiShareService.orders && !this.uiShareService.ordersNeedReload) {
        // we have orders in cache
        this.orderOptionsSrc = this.uiShareService.orders.sort((o1: IOrder, o2: IOrder) => {
          if (o1.orderNumber > o2.orderNumber) return 1;
          if (o1.orderNumber < o2.orderNumber) return -1;
          return 0;
        });
        this.orderOptions = this.orderOptionsSrc;
      } else {
        this.loaderService.showLoaderDelayed().subscribe(() => {
          this.loadOrders();
        });
      }

      this.selectOrderSubscription = this.orderSelectedSubject.subscribe((next) => {
        if (next) {
          this.isOpenSelectOrderDialog = false;
          this.selectOrderSubscription.unsubscribe();
          observer.next(this.selectedOrder);
          observer.complete();
        }
      });
    });
  }
  private loadOrders() {
    this.apiOrderService.getOrders().subscribe({
      next: (data) => {
        this.loaderService.hideLoader();
        this.orderOptionsSrc = data.sort((o1: IOrder, o2: IOrder) => {
          if (o1.orderNumber > o2.orderNumber) return 1;
          if (o1.orderNumber < o2.orderNumber) return -1;
          return 0;
        });
        this.uiShareService.orders = data;
        this.orderOptions = this.orderOptionsSrc;
      },
      error: (error) => {
        this.loaderService.hideLoader();
        console.log(error);
        this.orderOptions = this.orderOptionsSrc;
      },
    });
  }

  private async loadOrdersArchive() {
    return new Promise<void>((resolve, reject) => {
      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.apiOrderService.getArchive().subscribe({
          next: (data) => {
            this.orderOptionsArchiveSrc = data.sort((o1: IOrder, o2: IOrder) => {
              if (o1.orderNumber > o2.orderNumber) return 1;
              if (o1.orderNumber < o2.orderNumber) return -1;
              return 0;
            });
            resolve();
          },
          error: (error) => {
            console.error(error);
            reject();
          },
          complete: () => {
            this.loaderService.hideLoader();
          },
        });
      });
    });
  }

  private selectOrderPositionSubscription;
  public showSelectOrderPositionModal(orderNumber: string, elementIds: string[]): Observable<any> {
    this.orderPositionSelectedSubject.next(false);

    this.elementIds = elementIds;
    this.selectedPosition = null;
    this.isOpenSelectOrderPositionDialog = true;
    return new Observable((observer: Observer<boolean>) => {
      this.apiOrderService.getOrderByNumber(orderNumber).subscribe((data) => {
        this.orderPosOptionsSrc = data.positions
          .filter((p) => elementIds.indexOf(p.elementId) == -1)
          .sort((o1: IOrderPosition, o2: IOrderPosition) => {
            if (o1.elementId > o2.elementId) return 1;
            if (o1.elementId < o2.elementId) return -1;
            return 0;
          });
        this.orderPosOptionsSrc.forEach((op) => {
          op.articleNumberHtml = op.articleNumber.replace(/\//g, "/<wbr/>");
        });
        this.orderPosOptions = this.orderPosOptionsSrc;
      });

      this.selectOrderPositionSubscription = this.orderPositionSelectedSubject.subscribe((next) => {
        if (next) {
          this.isOpenSelectOrderPositionDialog = false;
          this.selectOrderPositionSubscription.unsubscribe();
          observer.next(this.selectedPosition);
          observer.complete();
        }
      });
    });
  }

  public selectFreeOrderPosition(): void {
    this.selectedPosition = this.freeElementId;
    this.orderPositionSelectedSubject.next(true);
    this.freeElementId = "";
  }
  public removeOrderPosition(pos: string): void {
    this.selectedPosition = "DEL-" + pos;
    this.orderPositionSelectedSubject.next(true);
  }
  public selectOrderPosition(pos: IOrderPosition): void {
    this.selectedPosition = pos;
    this.orderPositionSelectedSubject.next(true);
  }
  public closeSelectOrderPositionDialog(): void {
    this.selectedPosition = null;
    this.orderPositionSelectedSubject.next(true);
  }

  public editSetNewStatus() {
    const edit: IReclamationEdit = {
      id: this.item.id,
      status: this.editNewStatus,
    };

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiReclService.editReclamation(edit).subscribe({
        next: (data: any) => {
          this.requestDoneSubject.next(data);
          this.loaderService.hideLoader();
          //this.approve = Object.assign({});
          this.uiShareService.reclamationsNeedReload = true;
          this.isOpenAdminDialog = false;
          this.messageService.success("Successfully changed");
        },
        error: (error) => {
          this.loaderService.hideLoader();
          this.messageService.error("Failed to change!");
        },
      });
    });
  }
}
