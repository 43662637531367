import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Setting, SettingType } from "../../models/setting";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class ApiSettingsService {
  private dataUrl = environment.apiConfig.uri + "Settings";

  constructor(private http: HttpService) {}

  getAll(): Observable<Setting[]> {
    return this.http.get(this.dataUrl + "/GetAll");
  }
  getByType(type: SettingType): Observable<Setting[]> {
    return this.http.get(this.dataUrl + "/GetByType/" + type);
  }
  delete(id: string): Observable<Setting[]> {
    return this.http.delete(this.dataUrl + "/DeleteById/" + id);
  }
  createOrUpdate(setting: Setting): Observable<Setting> {
    if (setting?.id) {
      return this.http.put(this.dataUrl + "/UpdateById/" + setting?.id, JSON.stringify(setting));
    }
    return this.http.put(this.dataUrl + "/Update", JSON.stringify(setting));
  }
  update(setting: Setting): Observable<Setting> {
    return this.http.put(this.dataUrl + "/Update", JSON.stringify(setting));
  }
  testMail(): Observable<any> {
    return this.http.post(this.dataUrl + "/test/mail", JSON.stringify({}));
  }
  testPush(): Observable<any> {
    return this.http.post(this.dataUrl + "/test/push", JSON.stringify({}));
  }
  export(): Observable<any> {
    return this.http.post(this.dataUrl + "/export", JSON.stringify({}));
  }
  getImageConfig(): Observable<any> {
    return this.http.get(this.dataUrl + "/GetImageConfig");
  }
  getHolidaySettings(): Observable<any> {
    return this.http.get(this.dataUrl + "/GetHolidaySettings");
  }
}
