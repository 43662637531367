import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IOfferEdit, IOfferPositionEdit } from "src/app/models/edit";
import { ApiOfferService } from "src/app/services/api/api-offer.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-offer-dialog",
  templateUrl: "./admin-offer-dialog.component.html",
  styleUrls: ["./admin-offer-dialog.component.scss"],
})
export class AdminOfferDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fromBuilder: FormBuilder,
    private offerService: ApiOfferService,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.form = this.fromBuilder.group({
      status: [2],
      forPositions: [false],
    });
    this.dialogRef.afterClosed().subscribe(() => {
      // anti-flicker
      this.loaderService.hideLoader();
    });
  }

  setNewStatus() {
    const formValue = this.form.value;
    if (this.data.positionIndex > -1) {
      const edit: IOfferPositionEdit = {
        offerNumber: this.data.offerNumber,
        positionIndex: this.data.positionIndex,
        status: formValue.status,
        token: this.uiShareService.adminToken,
      };

      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.offerService.editOfferPosition(edit).subscribe({
          next: () => {
            this.uiShareService.offersNeedReload = true;
            this.messageService.success("Successfully changed");
            this.dialogRef.close(true);
          },
          error: () => {
            this.messageService.error("Failed to change!");
            this.dialogRef.close();
          },
        });
      });
    } else {
      const edit: IOfferEdit = {
        offerNumber: this.data.offerNumber,
        setStatusToPositions: formValue.forPositions,
        status: formValue.status,
        token: this.uiShareService.adminToken,
      };

      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.offerService.editOffer(edit).subscribe({
          next: () => {
            this.uiShareService.offersNeedReload = true;
            this.messageService.success("Successfully changed");
            this.dialogRef.close(true);
          },
          error: () => {
            this.messageService.error("Failed to change!");
            this.dialogRef.close();
          },
        });
      });
    }
  }
}
