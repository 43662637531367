<app-modal id="approve-dialog" [show]="isOpenApproveDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'OFFER_DIALOG.TITLE' | translate }}"></app-content-header>
    <div>
      <label class="field-title contact-title">{{ "OFFER_DIALOG.OFFER_NUMBER" | translate }}: {{ item?.offerNumber }}</label>
      <br />
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: right" (click)="releaseOffer()">
      {{ "OFFER_DIALOG.RELEASE" | translate }}
    </button>
    <button style="float: left" (click)="closeDialog()">
      {{ "OFFER_DIALOG.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="change-dialog" [show]="isOpenChangeDialog" minWidth="350px" maxWidth="800px">
  <ng-template #modalContent>
    <app-content-header contentTitle="Ändern/In Auftrag überführen"></app-content-header>
    <div>
      <label class="field-title plain">{{ "OFFER_DIALOG.OFFER_NUMBER" | translate }}: {{ item?.offerNumber }}</label>
      <br />
      <div class="table">
        <div class="cell-50">
          <label class="field-title plain">{{ "OFFER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
          <div class="field">
            <textarea class="form-control" name="changeMessage" [(ngModel)]="approve.changeMessage" placeholder=""></textarea>
          </div>
        </div>
        <div class="cell-50">
          <div *ngFor="let position of approve.positions; let i = index" class="field-container">
            <label class="field-title plain"
              >{{ "OFFER_DIALOG.CHANGE_MESSAGE_FOR_ARTICLE" | translate }}
              {{ position?.articleNumber }}
            </label>
            <div class="field">{{ position.changeMessage }}</div>
          </div>
          <div class="no-articles" *ngIf="!approve.positions || approve.positions.length == 0">
            {{ "OFFER_DIALOG.NO_CHANGE_ARTICLES_SET" | translate }}
          </div>
        </div>
      </div>
      <div class="table" style="padding-top: 5px">
        <div class="cell-cb">
          <app-checkbox [checked]="releaseOnChange" (onChanged)="releaseOnChange = $event"> </app-checkbox>
        </div>
        <div class="cell-cb-text">
          <span class="field-title plain small">Angebot nach Änderung in Auftrag überführen</span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="changeOffer()" [disabled]="getChangeDialogDisabledState()">
      {{ changeDialogSaveStateText }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="change-message-dialog" [show]="isOpenChangePerPositionDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'OFFER_DIALOG.CHANGE_POSITION_TITLE' | translate }}"></app-content-header>
    <diV>
      <label class="field-title plain"><span [innerHtml]="'OFFER.ARTICLE_NUMBER' | translate"></span>: {{ itemPosition?.articleNumber }}</label>
      <br />
      <label class="field-title plain">{{ "OFFER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
      <div class="field">
        <input class="form-control" [(ngModel)]="approve.changeMessage" maxlength="80" />
      </div>
    </diV>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="setChangeToPosition()">
      {{ "OFFER_DIALOG.SET" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="preview-image-dialog" [show]="isOpenPreviewImageDialog" maxWidth="400px">
  <ng-template #modalContent>
    <img [src]="previewImage" width="100%" alt="" />
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="confirm-offer-dialog" [show]="isOpenConfirmDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'OFFER_DIALOG.CONFIRM_TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeConfirm()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="confirmOffer()">
      {{ "OFFER_DIALOG.CONFIRM" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="confirm-offer-batch-archive-dialog" [show]="showConfirmOfferBatchArchiveDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="Archivieren"></app-content-header>
    <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeBatchModal()" [disabled]="batchRunning || batchCompleted">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="confirmBatchModal()" [disabled]="batchRunning">
      {{ "ORDER_DIALOG.CONFIRM" | translate }}
    </button>
  </ng-template>
</app-modal>
