import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input("checked") checked: boolean;
  @Output() onChanged = new EventEmitter<boolean>();
  isChecked = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isChecked = changes.checked.currentValue;
    this.checked = this.isChecked;
  }

  ngOnInit() {
    this.isChecked = this.checked == true;
  }

  toggle() {
    this.isChecked = !this.isChecked;
    this.onChanged.emit(this.isChecked);
  }
}
