import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { GeoService } from "src/app/services/geo-service.service";
import { PermissionService } from "src/app/services/permission.service";
import { RoutingService } from "src/app/services/routing.service";
import { Breadcrumb } from "../../models/breadcrumb";
import { IFrame, IFrameStatus } from "../../models/frame";
import { User } from "../../models/user";
import { ApiFrameService } from "../../services/api/api-frame.service";
import { ApiUsersService } from "../../services/api/api-users.service";
import { FormService } from "../../services/form.service";
import { LoaderService } from "../../services/loader.service";
import { LogService } from "../../services/log.service";
import { MessageService } from "../../services/message.service";
import { UiShareService } from "../../services/ui-share.service";

@Component({
  selector: "app-frame-create",
  templateUrl: "./frame-create.component.html",
  styleUrls: ["./frame-create.component.scss"],
})
export class FrameCreateComponent implements OnInit {
  public form: UntypedFormGroup;
  public formAdress: UntypedFormGroup;
  public breadcrumb: [Breadcrumb];
  public type: string = "";
  public typeNumber: string;
  public useMerchantAddress: boolean = false;
  public useDeliverAddress: boolean = false;
  public noGeo: boolean = true;
  public appMode = Capacitor.isNativePlatform();
  public frames: any[];
  public latitude: number = -1;
  public longitude: number = -1;
  public locationState = "";
  public isOpenAddDialog = false;
  public isOpenAdTypeInvalidDialog = false;
  public framesSrc: IFrame[];
  private mainMerchant: User;
  public countryOptions = [];
  public customFrameNumber: string;
  geoResolveResult: Subject<any> = new Subject();

  constructor(
    private router: RoutingService,
    private alertController: AlertController,
    private formService: FormService,
    private loaderService: LoaderService,
    private frameService: ApiFrameService,
    private userService: ApiUsersService,
    private translateService: TranslateService,
    private uiShareService: UiShareService,
    private messageService: MessageService,
    private logService: LogService,
    private premissionService: PermissionService,
    private geoService: GeoService,
  ) {
    this.breadcrumb = [
      <Breadcrumb>{
        title: this.translateService.instant("MENU.FRAME_LIST"),
        page: "frame",
      },
    ];
    this.breadcrumb.push(<Breadcrumb>{
      title: this.translateService.instant("MENU.FRAME_CREATE"),
      page: null,
    });
  }
  public ngOnInit(): void {
    this.initForm();
    try {
      this.framesSrc = this.uiShareService.frames.filter((f) => {
        return f.status == IFrameStatus.Delivered;
      });
      this.countryOptions = this.uiShareService.getCountryOptions(this.translateService.currentLang);

      if (window.history.state.frames) {
        window.history.state.frames.forEach((element) => {
          this.addFrameNumber(element.number);
        });

        // this.breadcrumb.splice(1, 0, <Breadcrumb>{
        //   title: window.history.state.frame.number,
        //   page: { name: "frame-details", item: window.history.state.frame },
        // });
      }
    } catch (e) {
      console.log("FrameCreateComponent.ngOnInit() somehow failed");
    }

    if (Capacitor.isNativePlatform()) {
      this.geoResolveResult.subscribe((res) => {
        if (res) {
          this.formAdress.get("street").setValue(res["thoroughfare"]);
          this.formAdress.get("number").setValue(res["subThoroughfare"]);
          this.formAdress.get("zipCode").setValue(res["postalCode"]);
          this.formAdress.get("city").setValue(res["locality"]);
          this.formAdress.get("country").setValue(res["countryName"]);
        }
      });
      this.geoService.geoUsableSubject.subscribe((status) => {
        if (status) {
          //console.log("STATE: " + status);
          if (status == "skip") {
            // do nothing
          } else if (status == "granted") {
            this.updateGeoCoordinates(false);
          } else {
            this.locationState = "declined";
            this.initAdressForm(false);
          }
          if (status != "skip") {
            // reset subject
            this.geoService.geoUsableSubject.next("skip");
          }
        }
      });
      Geolocation.checkPermissions().then((premmision) => {
        if (premmision.location === "granted") {
          this.updateGeoCoordinates(false);
        } else {
          this.locationState = "declined";
          this.initAdressForm(false);
        }
      });
    }
  }

  private initForm() {
    if (this.form) {
      this.form.setValue = null;
    }
    this.form = new UntypedFormGroup({});
    this.initAdressForm(false);
  }
  public enableLocationService() {
    if (localStorage.getItem("location_service")) {
      localStorage.removeItem("location_service");
    }
    if (localStorage.getItem("location_app")) {
      localStorage.removeItem("location_app");
    }
  }
  public useGeoCoordinates(): void {
    this.noGeo = true;
    this.useMerchantAddress = false;
    this.useDeliverAddress = false;
    this.updateGeoCoordinates(false);
  }

  private resolveRunning = false;
  public updateGeoCoordinates(initForm: boolean): void {
    this.logService.log("updateGeoCoordinates() running: " + this.resolveRunning);
    if (!this.resolveRunning) {
      this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
        this.logService.log("updateGeoCoordinates() resolve geos");
        this.geoService.resolveGeoCoords().subscribe(
          (coords) => {
            this.logService.log("updateGeoCoordinates() done");
            this.loaderService.hideLoader();
            console.debug("received geo data");
            this.latitude = coords[0];
            this.longitude = coords[1];
            this.geoResolveResult.next(coords[2]);
            this.resolveRunning = false;
            if (initForm) {
              this.initAdressForm(true);
            }
          },
          (error) => {
            this.logService.log("updateGeoCoordinates() failed");
            this.resolveRunning = false;
            this.loaderService.hideLoader();
          },
        );
      });
    }
  }
  public emptyAdress(): void {
    this.formAdress.clearValidators();
    this.formAdress = null;
    this.initAdressForm(false);
    this.noGeo = true;
  }
  public fillMerchantAdress(): void {
    if (!this.mainMerchant) {
      this.userService.getMerchantAddress().subscribe({
        next: (next) => {
          this.mainMerchant = next;
          this.postFillMerchantAddress();
        },
        error: (error) => {
          this.postFillMerchantAddress();
        },
      });
    } else {
      this.postFillMerchantAddress();
    }
  }
  private postFillMerchantAddress() {
    this.useDeliverAddress = false;
    this.noGeo = false;
    if (this.useMerchantAddress) {
      this.useMerchantAddress = false;
      this.formAdress.clearValidators();
      this.initAdressForm(false);
    } else {
      this.useMerchantAddress = true;
      this.fillAdressForm();
    }
  }
  public fillDeliveryAdress(): void {
    this.useMerchantAddress = false;
    this.noGeo = false;
    this.useDeliverAddress = !this.useDeliverAddress;
    this.formAdress.clearValidators();
    this.initAdressForm(this.useDeliverAddress);
  }
  public fillAdressForm(): void {
    if (this.useMerchantAddress) {
      if (this.mainMerchant) {
        this.formAdress.get("street").setValue(this.mainMerchant.street);
        this.formAdress.get("number").setValue(this.mainMerchant.houseNumber);
        this.formAdress.get("zipCode").setValue(this.mainMerchant.zipCode);
        this.formAdress.get("city").setValue(this.mainMerchant.city);
        this.formAdress.get("country").setValue(this.uiShareService.getCountryTitle(this.mainMerchant.country));
      } else {
        this.uiShareService.loggedInUser$.subscribe((user) => {
          if (user.city) {
            this.formAdress.get("street").setValue(user.street);
            this.formAdress.get("number").setValue(user.houseNumber);
            this.formAdress.get("zipCode").setValue(user.zipCode);
            this.formAdress.get("city").setValue(user.city);
            this.formAdress.get("country").setValue(user.country);
          }
        });
      }
    }
  }

  public closeDialog(): void {
    this.isOpenAddDialog = false;
    this.isOpenAdTypeInvalidDialog = false;
  }
  public removeFrame(idx: number): void {
    this.frames.splice(idx, 1);
    this.framesSrc = this.uiShareService.frames.filter((f) => this.frames.indexOf(f.number) == -1);
  }
  public addFrameNumberDirect(number: string, scanned: boolean = false): void {
    this.isOpenAddDialog = false;
    if (number && number != "") {
      if (this.frames) {
        if (this.frames.indexOf(number) == -1) {
          this.frames.push(number);
        }
      } else {
        this.frames = [number];
      }

      if (scanned) {
        // geos not resolved yet
        // ask user
        this.premissionService.askForGeoUsage();
      }
    }
    this.customFrameNumber = "";
  }
  public addFrameNumber(number: string, scanned: boolean = false): void {
    this.isOpenAddDialog = false;
    if (number && number != "") {
      if (Capacitor.isNativePlatform() && scanned) {
        this.addFrameNumberDirect(number, scanned);
        return;
      }

      // check if frame number exists
      if (this.uiShareService.frames.filter((f) => f.number == number && f.status == 0).length > 0) {
        if (this.frames) {
          if (this.frames.indexOf(number) == -1) {
            this.frames.push(number);
          }
        } else {
          this.frames = [number];
        }
      } else {
        this.numberNotValidError();
      }
    }
    this.framesSrc = this.uiShareService.frames.filter((f) => {
      return this.frames.indexOf(f.number) == -1 && f.status == IFrameStatus.Delivered;
    });
  }

  private async numberNotValidError() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.translateService.instant("FRAME.NUMBER_INVALID_TITLE"),
      message: this.translateService.instant("FRAME.NUMBER_INVALID_TEXT"),
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button",
          handler: () => {},
        },
      ],
    });

    await alert?.present();
  }
  public initAdressForm(noValidators: boolean): void {
    if (noValidators) {
      this.formAdress = new UntypedFormGroup({
        street: new UntypedFormControl(""),
        number: new UntypedFormControl(""),
        zipCode: new UntypedFormControl(""),
        city: new UntypedFormControl(""),
        country: new UntypedFormControl(""),
      });
    } else {
      this.formAdress = new UntypedFormGroup({
        street: new UntypedFormControl("", Validators.required),
        number: new UntypedFormControl("", Validators.required),
        zipCode: new UntypedFormControl("", Validators.required),
        city: new UntypedFormControl("", Validators.required),
        country: new UntypedFormControl("", Validators.required),
      });
    }
    this.formAdress.updateValueAndValidity();
  }
  public onSubmit(): void {
    if (this.useDeliverAddress) {
      // all addresses must have a deliver address!
      for (let i = 0; i < this.frames.length; i++) {
        const tmp = this.uiShareService.frames.filter((f) => f.number == this.frames[i]);
        if (tmp.length == 0) {
          this.isOpenAdTypeInvalidDialog = true;
          return;
        }
      }
    }
    this.loaderService.showLoaderDelayed().subscribe(() => {
      const frame = Object.assign({});
      frame.numbers = [];
      if (!this.noGeo) {
        frame.pickupLatitude = this.latitude;
        frame.pickupLongitude = this.longitude;
      } else {
        frame.pickupLatitude = -1;
        frame.pickupLongitude = -1;
      }
      //frame.pickupAddressDiffers = this.isAdress;
      frame.numbers = this.frames;
      frame.pickupAddressType = "manual";
      try {
        frame.pickupHouseNumber = this.formAdress.get("number").value;
        frame.pickupStreet = this.formAdress.get("street").value;
        frame.pickupZipCode = this.formAdress.get("zipCode").value;
        frame.pickupCity = this.formAdress.get("city").value;
        frame.pickupCountry = this.uiShareService.getCountryCode(this.formAdress.get("country").value);
      } catch (e) {}
      if (this.useMerchantAddress) frame.pickupAddressType = "merchantAddress";
      else if (this.useDeliverAddress) frame.pickupAddressType = "deliverAddress";

      this.frameService.postFrame(frame).subscribe({
        next: (data) => {
          this.uiShareService.framesNeedReload = true;

          this.messageService.success(this.translateService.instant("FRAME.RELEASE_SUCCESS"));
          this.useMerchantAddress = false;
          this.useDeliverAddress = false;
          this.loaderService.hideLoader();
          this.router.forwardByUrl("frame");
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("FRAME.RELEASE_ERROR"));
          this.loaderService.hideLoader();
        },
      });
    });
  }

  public getFormControl(formControlName: string, formGroup?: UntypedFormGroup): string {
    if (formGroup) {
      return this.formService.getFormControl(formGroup, formControlName);
    }
    return this.formService.getFormControl(this.form, formControlName);
  }

  public selectCountry(): void {
    if (this.useMerchantAddress) return;
    this.uiShareService.commonModals.showCountryModal().subscribe((country) => {
      if (country) {
        this.formAdress.get("country").setValue(country.title);
      }
    });
  }
}
