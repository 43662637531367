<div class="container">
  <div class="inner-container">
    <span class="breadcrumb" [routerLink]="['/']"><img src="./assets/icons/BreadcrumbArrow.png" alt="" /> {{ "MENU.ALDRA" | translate }}</span>
    <span class="breadcrumb" *ngFor="let b of breadcrumb" (click)="openBreadcrumb(b)" [ngClass]="b.page ? 'link' : 'no-link'">
      <img src="./assets/icons/BreadcrumbArrow.png" alt="" />&nbsp;<span *ngIf="b.withKey">{{ b.title | translate }}</span
      ><span *ngIf="!b.withKey">{{ b.title }}</span>
    </span>
    <br />
    <br />
    <ng-template [ngTemplateOutlet]="wrapperContent"></ng-template>
  </div>
</div>
