<app-custom-ion-header #header [selected]="'MENU.SHIPMENT-NOTES'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header #header (onReload)="reloadData(true)" [selected]="'MENU.SHIPMENT-NOTES'" [homeIcon]="true" [reloadIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header #header [selected]="'MENU.SHIPMENT-NOTES'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-condense-header>

  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb" [ngClass]="appMode ? 'appMode' : ''">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'SHIPMENT_NOTES.TITLE' | translate"></app-content-header>
        <div *ngIf="!appMode" class="reload" (click)="reloadData(true)">
          {{ "COMMON.RELOAD" | translate }}
        </div>
        <div class="app-header" style="align-items: center">
          <input class="search" type="search" [formControl]="globalSearchField" placeholder="Suche" />
        </div>
        <br />
        <ng-container *ngFor="let tabGroup of tabGroups">
          <div class="tab" (click)="switchTab(tabGroup, tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabGroup.tabs">
            <ng-container *ngIf="!tab.needReload && tab.count > -1; else nocount"> {{ tab.title }} ({{ tab.count }}) </ng-container>
            <ng-template #nocount>
              {{ tab.title }}
            </ng-template>
          </div>
        </ng-container>
        <br /><br />
        <div class="main-view">
          <div class="desktop">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <button
                    class="button-table"
                    *ngIf="row.orders && row.orders.length > 0"
                    (click)="addOrRemove(expandedRows, row); updateInnerSort(); $event.stopPropagation()"
                  >
                    <span *ngIf="!expandedRows.includes(row)" class="add"></span>
                    <span *ngIf="expandedRows.includes(row)" class="minus"></span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="shipmentNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorgang</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="expandedRows.includes(row) ? 'identifier' : ''">{{ row.shipmentNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getFixedDate(row.createdDate) }}</td>
              </ng-container>

              <ng-container matColumnDef="suggestedDeliveryDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Avisierter Liefertermin</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ readableSuggestedDeliveryDate(row) }}</td>
              </ng-container>

              <ng-container matColumnDef="customerReference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Referenz Kunde</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getCustomerReference(row) }}</td>
              </ng-container>

              <ng-container matColumnDef="shipmentAVPdf">
                <th mat-header-cell *matHeaderCellDef>Sendungsavis</th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.hasDeliveryAvisPdf">
                    <div class="pdf inline" title="{{ row.shipmentNumber }}" (click)="orderService.getShipmentAVPdf($event, row)">
                      <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                    </div>
                  </span>
                  <span *ngIf="!row.hasDeliveryAvisPdf"> - </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="shipmentPdf">
                <th mat-header-cell *matHeaderCellDef>Lieferschein</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="row.hasPdf" class="pdf inline" title="{{ row.shipmentNumber }}" (click)="orderService.getShipmentPdf($event, row)">
                    <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                  </div>
                  <span *ngIf="!row.hasPdf"> - </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Kontakt</th>
                <td mat-cell *matCellDef="let row">
                  <div class="message-container">
                    <img class="message mail" (click)="openContactForm('contact', row)" src="./assets/icons/email-02.svg" alt="" />
                    <span class="vertical-line"></span>
                    <img class="message tel" (click)="openContactForm('callback', row)" src="./assets/icons/telefon-01.svg" alt="" />
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let row">
                  <a class="link" (click)="archiveShipment(row)">{{ "SHIPMENT_NOTES.ARCHIVE" | translate }} </a>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!expandedRows.includes(row)">
                  <ng-container *ngIf="expandedRows.includes(row)">
                    <ng-container *ngFor="let order of row.orders">
                      <div class="expandedContent">
                        <div>
                          <div>
                            <button
                              class="button-table"
                              (click)="addOrRemove(detailExpandedRows, order); updateInnerSort(); loadImages(order); $event.stopPropagation()"
                            >
                              <span *ngIf="!detailExpandedRows.includes(order)" class="add"></span>
                              <span *ngIf="detailExpandedRows.includes(order)" class="minus"></span>
                            </button>
                            <span #highlightCell class="identifier"> Auftrag {{ order.orderNumber }} </span>
                            <span class="status">({{ "ORDER_STATUS." + order.status | translate }})</span><br />
                          </div>
                          <div #highlightCell class="customer-reference">
                            {{ order.customerReference }}
                          </div>
                        </div>
                        <div class="inner-table" *ngIf="detailExpandedRows.includes(order)">
                          <table
                            #innerTables
                            mat-table
                            #innerSort="matSort"
                            [dataSource]="order.positionsDataSource"
                            matSort
                            matSortActive="positionIndex"
                            matSortDisableClear
                            matSortDirection="asc"
                          >
                            <ng-container matColumnDef="thumbnailFileName">
                              <th mat-header-cell *matHeaderCellDef>Bild</th>
                              <td mat-cell *matCellDef="let row">
                                <img
                                  *ngIf="row.thumbnailFileBase64"
                                  id="image{{ row.thumbnailFileName }}"
                                  width="50"
                                  src="data:image/gif;base64,{{ row.thumbnailFileBase64 }}"
                                  alt="{{ row.thumbnailFileName }}"
                                  (click)="previewImage(row)"
                                />
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="positionIndex">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Positionsnummer</th>
                              <td mat-cell *matCellDef="let row">{{ row.positionIndex }}</td>
                            </ng-container>
                            <ng-container matColumnDef="articleNumber">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Artikelnummer</th>
                              <td mat-cell *matCellDef="let row">{{ row.articleNumber }}</td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                              <td mat-cell *matCellDef="let row">{{ "ORDER_STATUS." + row.status | translate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="quantityDelivered">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Anzahl geliefert</th>
                              <td mat-cell *matCellDef="let row">{{ row.quantityDelivered }}</td>
                            </ng-container>
                            <ng-container matColumnDef="performanceStatementPdf">
                              <th mat-header-cell *matHeaderCellDef>Leistungserklärung</th>
                              <td mat-cell *matCellDef="let row">
                                <span class="pdf" *ngIf="row.hasPerformanceStatementPdf" (click)="orderService.getShipmentPdf($event, order, row)">
                                  <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                                </span>
                                <span class="pdf" *ngIf="!row.hasPerformanceStatementPdf"> - </span>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns"></tr>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">Keine Lieferscheine gefunden</td>
              </tr>
            </table>
            <mat-paginator
              [hidden]="dataSource.filteredData.length < environment.ENTRIES_PER_PAGE"
              [pageSize]="environment.ENTRIES_PER_PAGE"
              hidePageSize
              showFirstLastButtons
            ></mat-paginator>
          </div>

          <div class="mobile">
            <app-pager #pager1 (pageChanged)="onPageChanged($event)"></app-pager>

            <mat-card class="card mat-mdc-elevation-specific mat-elevation-z2" *ngFor="let item of mobileData; let i = index" appearance="raised">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title class="card-title">
                    <span class="title" #highlightCell>{{ item.shipmentNumber }}</span>
                    <span #highlightCell class="date-mobile" *ngIf="item.createdDate !== null">({{ getFixedDate(item.createdDate) }})</span>
                  </mat-card-title>
                  <mat-card-subtitle #highlightCell class="pre-line">{{ getCustomerReference(item) }}</mat-card-subtitle>
                  <button *ngIf="!uiShareService.readOnlyMode()" class="menu-button more-button" mat-icon-button [matMenuTriggerFor]="actionMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <button *ngIf="currentTab?.id === 0" mat-menu-item (click)="archiveShipment(item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">archive</mat-icon>
                      <span>Archivieren</span>
                    </button>
                    <button mat-menu-item (click)="openContactForm('contact', item)">
                      <mat-icon fontSet="material-icons-outlined" class="menu-icon">email</mat-icon>
                      <span>Kontakt</span>
                    </button>
                  </mat-menu>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content>
                <dl class="grid">
                  <dt>Avisierter Liefertermin:</dt>
                  <dd #highlightCell>{{ item.suggestedDeliveryDate | date: "dd.MM.yyyy HH:mm:ss" }}</dd>
                </dl>
              </mat-card-content>
              <mat-card-actions class="card-actions">
                <button class="menu-button" *ngIf="!item.expanded" mat-icon-button (click)="item.expanded = !item.expanded">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button class="menu-button" *ngIf="item.expanded" mat-icon-button (click)="item.expanded = !item.expanded">
                  <mat-icon>expand_less</mat-icon>
                </button>

                <button *ngIf="item.hasDeliveryAvisPdf || item.hasPdf" class="menu-button" mat-icon-button [matMenuTriggerFor]="pdfMenu">
                  <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                </button>
                <mat-menu #pdfMenu="matMenu">
                  <button *ngIf="item.hasDeliveryAvisPdf" mat-menu-item (click)="orderService.getShipmentAVPdf($event, item)">
                    <mat-icon fontSet="material-icons-outlined" class="menu-icon">file_download</mat-icon>
                    <span>Sendungsavis</span>
                  </button>
                  <button *ngIf="item.hasPdf" mat-menu-item (click)="orderService.getShipmentPdf($event, item)">
                    <mat-icon fontSet="material-icons-outlined" class="menu-icon">file_download</mat-icon>
                    <span>Lieferschein</span>
                  </button>
                </mat-menu>
              </mat-card-actions>
              <mat-card-footer class="footer" *ngIf="item.expanded">
                <div class="sub" *ngFor="let subItem of item.orders" (click)="showShipmentOrderPositions(subItem)">
                  <div>
                    <span class="sub-title"> {{ subItem?.orderNumber }}</span>
                    <span class="date-mobile">({{ "ORDER_STATUS." + subItem.status | translate }})</span>
                  </div>
                  <img src="./assets/icons/arrow.png" alt="" />
                </div>
              </mat-card-footer>
            </mat-card>

            <div *ngIf="mobileData.length === 0">Keine Lieferscheine gefunden</div>
            <app-pager #pager2 (pageChanged)="onPageChanged($event)"></app-pager>
          </div>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
