import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { BarcodeService } from "src/app/services/barcode.service";
import { LoaderService } from "src/app/services/loader.service";
import { LogService } from "src/app/services/log.service";
import { PermissionService } from "src/app/services/permission.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-reclamation-scan",
  templateUrl: "./reclamation-scan.component.html",
  styleUrls: ["./reclamation-scan.component.scss"],
})
export class ReclamationScanComponent implements OnInit {
  private subscription: Subscription;
  private isGlas: boolean = false;
  public searchNumber: string;

  constructor(
    private barcodeService: BarcodeService,
    private orderService: ApiOrderService,
    private loaderService: LoaderService,
    private router: RoutingService,
    private translateService: TranslateService,
    private uiShareService: UiShareService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private logService: LogService
  ) {}

  public ngOnInit(): void {
    try {
      this.subscription = this.route.params.subscribe({
        next: (value) => {
          this.isGlas = value["type"] == "reclamation-glass";
        },
        error: (err) => {
          this.logService.error(err);
        },
      });
    } catch (error) {
      this.logService.log(error);
    }
  }
  public scanQRCode(): void {
    this.barcodeService.scan().then(
      (data) => {
        if (data.ScanResult.indexOf("article/") != -1) {
          this._searchArticle(data.ScanResult.substring(data.ScanResult.indexOf("article/") + 8, data.ScanResult.length));
        } else {
          // use input as number
          this._searchArticle(data.ScanResult);
        }
      },
      (error) => {
        console.error(error);
        this.permissionService.checkCamera();
      }
    );
  }

  public searchArticle() {
    this._searchArticle(this.searchNumber);
  }

  public _searchArticle(searchData: string) {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.orderService.findByElementId(searchData).subscribe({
        next: (data) => {
          this.loaderService.hideLoader();
          if (data) {
            if (this.isGlas) {
              this.router.forward([
                "/reclamation-create",
                "reclamation-glass",
                this.validateData(data.position.orderNumber, 9),
                this.validateData(searchData, 10),
              ]);
            } else {
              this.router.forward(["/reclamation-create", "", this.validateData(data.position.orderNumber, 9), this.validateData(searchData, 10)]);
            }
          }
        },
        error: (error) => {
          console.error(error);
          this.loaderService.hideLoader();
          this.uiShareService.commonModals
            .showErrorModal(
              null,
              this.translateService.instant("PRODUCTS.WRONG_ELEMENT_ID"),
              this.translateService.instant("RECLAMATIONS.CONTINUE_WITH_CUSTOM_NUMBER")
            )
            .subscribe((type) => {
              if (type == 1) {
                // use number anyway
                if (this.isGlas) {
                  this.router.forward(["/reclamation-create", "reclamation-glass", this.validateData("", 9), this.validateData(searchData, 10)]);
                } else {
                  this.router.forward(["/reclamation-create", "", this.validateData("", 9), this.validateData(searchData, 10)]);
                }
              }
            });
        },
      });
    });
  }
  private validateData(data: string, len: number): string {
    while (data.length < len) {
      data = "0" + data;
    }
    return data;
  }
}
