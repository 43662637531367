import { Injectable } from "@angular/core";
import { LoaderService } from "./loader.service";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";

@Injectable({
  providedIn: "root",
})
export class NativeFileService {
  constructor(
    private loaderService: LoaderService,
    private fileOpener: FileOpener,
    private file: File,
  ) {}

  public openPdf(data: any, object: any): void {
    this.file
      .writeFile(this.file.cacheDirectory, object.fileName, data, {
        replace: true,
      })
      .then((res) => {
        this.loaderService.hideTransparentLoader();
        this.fileOpener
          .open(this.file.cacheDirectory + object.fileName, "application/pdf")
          .then((file) => {
            console.log(file);
          })
          .catch((error) => {
            console.log("file", error);
          });
        //this._openDocument(document);
      })
      .catch((error) => {
        this.loaderService.hideTransparentLoader();
        console.log("file write error", JSON.stringify(error));
      });
  }
}
