import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Approve } from "../../../models/approve";
import { ApiOfferService } from "../../../services/api/api-offer.service";
@Component({
  selector: "app-action-archive-dialog",
  templateUrl: "./action-archive-dialog.component.html",
  styleUrls: ["./action-archive-dialog.component.scss"],
})
export class ActionArchiveDialogComponent implements OnInit {
  public confirmText: string;
  public approve: Approve = Object.assign({});
  constructor(
    public dialogRef: MatDialogRef<ActionArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private apiOfferService: ApiOfferService,
  ) {}

  ngOnInit(): void {
    this.confirmText = this.translateService.instant("OFFER_DIALOG.CONFIRM_ARCHIVE", { offerNumber: this.data.item.offerNumber });
    this.approve.offerNumber = this.data.item.offerNumber;
  }
  public closeConfirm() {
    this.dialogRef.close();
  }
  public confirmOffer() {
    this.apiOfferService.archive(this.approve.offerNumber).subscribe({
      next: (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.dialogRef.close(false);
      },
    });
  }
}
