import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IOrderEdit, IOrderPositionEdit } from "src/app/models/edit";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-order-dialog",
  templateUrl: "./admin-order-dialog.component.html",
  styleUrls: ["./admin-order-dialog.component.scss"],
})
export class AdminOrderDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderNumber: string; positionIndex: number },
    private fromBuilder: FormBuilder,
    private orderService: ApiOrderService,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.form = this.fromBuilder.group({
      status: [7],
      forPositions: [false],
    });
    this.dialogRef.afterClosed().subscribe(() => {
      // anti-flicker
      this.loaderService.hideLoader();
    });
  }

  setNewStatus() {
    const formValue = this.form.value;

    if (this.data.positionIndex > -1) {
      const edit: IOrderPositionEdit = {
        orderNumber: this.data.orderNumber,
        positionIndex: this.data.positionIndex,
        status: formValue.status,
      };

      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.orderService.editOrderPosition(edit).subscribe({
          next: () => {
            this.uiShareService.ordersNeedReload = true;
            this.messageService.success("Successfully changed");
            this.dialogRef.close(true);
          },
          error: () => {
            this.messageService.error("Failed to change!");
            this.dialogRef.close();
          },
        });
      });
    } else {
      const edit: IOrderEdit = {
        orderNumber: this.data.orderNumber,
        setStatusToPositions: formValue.forPositions,
        status: formValue.status,
      };

      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.orderService.editOrder(edit).subscribe({
          next: () => {
            this.uiShareService.ordersNeedReload = true;
            this.messageService.success("Successfully changed");
            this.dialogRef.close(true);
          },
          error: () => {
            this.messageService.error("Failed to change!");
            this.dialogRef.close();
          },
        });
      });
    }
  }
}
