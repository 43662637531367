import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  public showLoader: boolean = false;
  public showAsSpinner: boolean = false;
  constructor(private loaderService: LoaderService) {}

  public ngOnInit() {
    this.loaderService.getLoaderObservable().subscribe((show) => {
      this.showLoader = show;
    });
    this.loaderService.getTransparentLoaderObservable().subscribe((show) => {
      this.showLoader = show;
      this.showAsSpinner = show;
    });
  }
}
