import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-edit",
  templateUrl: "./admin-edit.component.html",
  styleUrls: ["./admin-edit.component.scss"],
})
export class AdminEditComponent {
  @Output() editFired: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Input() mobile: boolean;
  public enabled = false;

  constructor(private uiShareService: UiShareService) {
    uiShareService.isAdminEditAllowed().subscribe((allowed) => (this.enabled = allowed));
  }

  doEdit() {
    if (this.editFired) {
      this.editFired.emit(true);
    }
  }
}
