export const TABS = [
  {
    title: "Firmenstammdaten",
    active: true,
    id: 0,
  },
  {
    title: "Ansprechpartner",
    active: false,
    id: 1,
  },
  {
    title: "Lieferadressen",
    active: false,
    id: 2,
  },
  {
    title: "Rabatte",
    active: false,
    id: 3,
  },
  {
    title: "Dokumente",
    active: false,
    id: 4,
  },
];
