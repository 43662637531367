import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment from "moment";
import { Setting } from "../models/setting";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

export function dateTimeValidator(fieldDate: string, fieldTime: string, min: number[], max: number[], holidaySettings: Setting[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const date = formGroup.get(fieldDate).value;
    const time = formGroup.get(fieldTime).value;
    try {
      const now = moment();
      if (now.get("minutes") >= 30) {
        now.add(1, "hour");
      }
      now.startOf("hours");
      const dateTime = moment(date + time, dateFormat + timeFormat, true).startOf("hours");
      if (!dateTime.isValid()) {
        return { format: date + time };
      }
      const minTime = dateTime.clone().set("hours", min[dateTime.day()]).startOf("hours");
      const maxTime = dateTime.clone().set("hours", max[dateTime.day()]).startOf("hours");

      for (const setting of holidaySettings) {
        if (moment(setting.key, dateFormat, true).isSame(dateTime, "day")) {
          return { holiday: setting.value };
        }
      }

      if (dateTime.isBefore(now)) {
        return { past: date + time };
      }
      if (dateTime.isBefore(minTime)) {
        return { min: min[dateTime.day()] };
      }
      if (dateTime.isSameOrAfter(maxTime)) {
        return { max: max[dateTime.day()] };
      }
      return null;
    } catch (e) {
      console.error(e);
      return { format: date + time };
    }
  };
}
