export interface IOfferActionHistory {
  id: string;
  userId: string;
  createdDate: Date;
  merchantNumber: string;
  offerNumber: string;
  message: string;
  historyType: IOfferActionHistoryType;
  convertedMessage?: string;
  userData?: string;
}
export enum IOfferActionHistoryType {
  Release,
  Change,
  Archive,
  StateChange,
  Parked,
  UnParked,
  Contact,
  Callback,
}
export interface IOfferActionHistoryMessage {
  Message: string;
  MessagesByPosition: IOfferActionHistoryMessageByPosition[];
}
export interface IOfferActionHistoryMessageByPosition {
  Message: string;
  Index: string;
}
