import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  public confirmText: string;
  public title: string;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit(): void {
    this.confirmText = this.data.confirmText;
    this.title = this.data.title;
  }
  public closeBatchModal() {
    this.dialogRef.close();
  }
  public confirmBatchModal() {
    this.dialogRef.close(true);
  }
}
