import { Location } from "@angular/common";
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { IonBackButton, MenuController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-custom-ion-header",
  templateUrl: "./custom-ion-header.component.html",
  styleUrls: ["./custom-ion-header.component.scss"],
})
export class CustomIonHeaderComponent implements OnInit, OnChanges {
  @Input() selected: string;
  @Input() displayHeader: boolean;
  @Input() icon: string = Capacitor.isNativePlatform() ? "home" : "arrow-back";
  public isScroll: boolean = false;
  public isMenuSelected: boolean = false;
  public reclamationMenuVisible: boolean = false;
  public frameMenuVisible: boolean = false;
  private menuWidth: string = "260px";
  public menuHover: boolean;
  public closeHover: boolean;
  public appMode = Capacitor.isNativePlatform();
  public onReloadClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() homeIcon: boolean;
  @Input() secondaryIcon: string;
  @Input() reloadIcon: boolean;
  @ViewChild(IonBackButton)
  backButton: IonBackButton;
  public disabled: boolean = true;
  public onSecondaryClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router, public uiShareService: UiShareService, private location: Location, private menuController: MenuController) {
    this.router.events.subscribe((evt) => {
      if (this.isMenuSelected) {
        this.closeMenuFast();
      }
    });
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["homeIcon"]) {
      if (changes["homeIcon"]?.currentValue) {
        this.icon = "home";
      }
    }
  }

  @HostListener("window:resize", [])
  onResize() {
    if (window.innerWidth < 500) {
      this.menuWidth = "160px";
    } else {
      this.menuWidth = "260px";
    }
    if (window.innerWidth > 1015 && this.isMenuSelected) {
      this.openOrCloseMenu();
    }
  }
  public toggleReclamationMenu(): void {
    this.reclamationMenuVisible = !this.reclamationMenuVisible;
  }
  public toggleFrameMenu(): void {
    this.frameMenuVisible = !this.frameMenuVisible;
  }
  public openOrCloseMenu(): void {
    this.menuController.open();
  }
  public closeMenuFast(): void {
    this.isMenuSelected = false;
  }
  public openExperthek() {
    window.open("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", Capacitor.isNativePlatform() ? "_system" : "_blank");
  }

  goBack() {
    this.location.back();
  }
  secondaryClicked() {
    this.onSecondaryClickedSubject.next(true);
  }
  reloadClicked() {
    this.onReloadClickedSubject.next(true);
  }
}
