import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiOfferService } from "src/app/services/api/api-offer.service";
import { Approve } from "../../../models/approve";
@Component({
  selector: "app-action-change-dialog",
  templateUrl: "./action-change-dialog.component.html",
  styleUrls: ["./action-change-dialog.component.scss"],
})
export class ActionChangeDialogComponent implements OnInit {
  public approve: Approve = Object.assign({});
  public releaseOnChange = false;
  public confirmText: string;
  public actionChangeWorked: boolean;
  constructor(
    public dialogRef: MatDialogRef<ActionChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private apiOfferService: ApiOfferService,
  ) {}

  ngOnInit(): void {
    this.approve.offerNumber = this.data.item.offerNumber;
    this.approve.changeMessage = "";
    this.approve.positions = this.data.item.positions.filter((p) => p.changeMessage);
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  public changeOffer(): any {
    // if (this.approve.positions.length == 0) {
    //   // double ask for positions!
    //   this.confirmText = this.translateService.instant(
    //     "OFFER_DIALOG.CONFIRM_APPROVE_NO_ARTICLES"
    //   );
    // } else {
    this.postChangeOrReleaseOffer();
    // }
  }
  private postChangeOrReleaseOffer(): void {
    this.approve.type = 0; //Change
    this.approve.changeMessagesPerPosition = Object.assign({});
    this.approve.approveOnChange = this.releaseOnChange;
    this.approve.positions.forEach((p) => {
      this.approve.changeMessagesPerPosition[p.positionIndex] = p.changeMessage;
    });
    if (this.approve.changeMessage == "" && this.approve.positions.every((p) => p.changeMessage == "")) {
      this.approve.type = 1; //Release
    }
    this.organizeRequest();
  }
  public organizeRequest(): any {
    this.apiOfferService.approve(this.approve).subscribe({
      next: (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.dialogRef.close(false);
      },
    });
  }
  changeDialogSaveStateText = "";
  getChangeDialogDisabledState(): boolean {
    if (this.releaseOnChange) {
      if (this.approve.changeMessage == "" && this.approve.positions.every((p) => p.changeMessage == "")) {
        this.changeDialogSaveStateText = "Überführen";
      } else {
        this.changeDialogSaveStateText = "Ändern und Überführen";
      }
      return false;
    } else {
      this.changeDialogSaveStateText = "Ändern";

      return this.approve.changeMessage == "";
    }
  }
}
