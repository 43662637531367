import { Location } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { IonBackButton, MenuController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-header-side-menu",
  templateUrl: "./header-side-menu.component.html",
  styleUrls: ["./header-side-menu.component.scss"],
})
export class HeaderSideMenuComponent {
  @Input() displayHeader: boolean;
  @Input() icon: string = Capacitor.isNativePlatform() ? "home" : "arrow-back";
  public selected: string;
  public isScroll: boolean = false;
  public isMenuSelected: boolean = true;
  public reclamationMenuVisible: boolean = false;
  public frameMenuVisible: boolean = false;
  public menuHover: boolean;
  public closeHover: boolean;
  public appMode = Capacitor.isNativePlatform();
  public onReloadClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() homeIcon: boolean;
  @Input() secondaryIcon: string;
  @Input() reloadIcon: boolean;
  @ViewChild(IonBackButton)
  backButton: IonBackButton;
  public disabled: boolean = true;
  public onSecondaryClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router, public uiShareService: UiShareService, private location: Location, private menuController: MenuController) {
    this.router.events.subscribe((evt) => {
      if (this.isMenuSelected) {
        this.closeMenuFast();
      }
    });
  }

  public isRouteHighlighed(routeToCheck: string): boolean {
    const url = this.router.url;

    if (routeToCheck === "MENU.DASHBOARD") {
      return url.includes("dashboard");
    }

    if (routeToCheck === "MENU.OFFER") {
      return url.includes("offer");
    }

    if (routeToCheck === "MENU.ORDER") {
      return url.includes("order");
    }

    if (routeToCheck === "SHIPMENT-NOTES") {
      return url.includes("shipment-notes");
    }

    if (routeToCheck === "Rechnungen") {
      return url.includes("invoices");
    }

    if (routeToCheck === "MENU.RECLAMATION") {
      return url.includes("reclamation");
    }

    if (routeToCheck === "MENU.FRAME") {
      return url.includes("frame");
    }

    return false;
  }

  public closeMenu() {
    this.menuController.close();
  }

  public toggleReclamationMenu(): void {
    this.reclamationMenuVisible = !this.reclamationMenuVisible;
  }
  public toggleFrameMenu(): void {
    this.frameMenuVisible = !this.frameMenuVisible;
  }
  public openOrCloseMenu(): void {}
  public closeMenuFast(): void {}
  public openExperthek() {
    window.open("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", Capacitor.isNativePlatform() ? "_system" : "_blank");
  }

  goBack() {
    this.location.back();
  }
  secondaryClicked() {
    this.onSecondaryClickedSubject.next(true);
  }
  reloadClicked() {
    this.onReloadClickedSubject.next(true);
  }
}
