import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { Breadcrumb } from "../../models/breadcrumb";
import { UpdatePassword, User } from "../../models/user";
import { LoaderService } from "../../services/loader.service";
import { MessageService } from "../../services/message.service";
import { UiShareService } from "../../services/ui-share.service";
import { FormService } from "./../../services/form.service";

@Component({
  selector: "app-my-data",
  templateUrl: "./my-data.component.html",
  styleUrls: ["./my-data.component.scss"],
})
export class MyDataComponent implements OnInit {
  public dataForm: UntypedFormGroup;
  public infoForm: UntypedFormGroup;
  public passwordForm: UntypedFormGroup;
  user: User = new User();
  public infoOptions = [];
  public intervalOptions = [];
  public passwordValid = false;
  public breadcrumb: [Breadcrumb];
  public summaryDoubleChecked: boolean;
  public summaryAddOrdersChecked: boolean;
  public summaryAddOffersChecked: boolean;
  public orderPDFwithPrices: boolean;
  public offerPDFwithPrices: boolean;

  constructor(
    private formService: FormService,
    private loaderService: LoaderService,
    public uiShareService: UiShareService,
    private apiUsersService: ApiUsersService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.breadcrumb = [{ title: this.translateService.instant("MENU.MY_DATA"), page: null }];
    this.user = this.uiShareService.loggedInUser || new User();

    this.intervalOptions = [
      {
        value: 0,
        label: this.translateService.instant("MYDATA.INTERVAL_OPTION.NEVER"),
      },
      {
        value: 1,
        label: this.translateService.instant("MYDATA.INTERVAL_OPTION.ONEDAY"),
      },
      {
        value: 2,
        label: this.translateService.instant("MYDATA.INTERVAL_OPTION.TWODAYS"),
      },
      {
        value: 3,
        label: this.translateService.instant("MYDATA.INTERVAL_OPTION.THREEDAYS"),
      },
    ];
    this.infoOptions = [
      {
        value: 0,
        label: this.translateService.instant("MYDATA.INFO_OPTION.BOTH"),
      },
      {
        value: 1,
        label: this.translateService.instant("MYDATA.INFO_OPTION.EMAIL"),
      },
      {
        value: 2,
        label: this.translateService.instant("MYDATA.INFO_OPTION.PUSH"),
      },
      {
        value: 3,
        label: this.translateService.instant("MYDATA.INFO_OPTION.NONE"),
      },
    ];
  }

  ngOnInit() {
    this.summaryDoubleChecked = this.user.infoOnSummaryTwiceADay;
    this.summaryAddOffersChecked = this.user.infoOnSummaryAddOffers;
    this.summaryAddOrdersChecked = this.user.infoOnSummaryAddOrders;
    this.orderPDFwithPrices = this.user.orderPDFwithPrices;
    this.offerPDFwithPrices = this.user.offerPDFwithPrices;
    this.dataForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.user.email, Validators.required),
      merchantNumber: new UntypedFormControl(this.user.merchantNumber, Validators.required),
      companyName: new UntypedFormControl(this.user.companyName, Validators.required),
      firstName: new UntypedFormControl(this.user.firstName, Validators.required),
      lastName: new UntypedFormControl(this.user.lastName, Validators.required),
      street: new UntypedFormControl(this.user.street),
      houseNumber: new UntypedFormControl(this.user.houseNumber),
      city: new UntypedFormControl(this.user.city),
      country: new UntypedFormControl(this.uiShareService.getCountryTitle(this.user.country)),
      zipCode: new UntypedFormControl(this.user.zipCode, Validators.compose([Validators.pattern("^[0-9]+$")])),
      phone: new UntypedFormControl(this.user.phone, Validators.compose([Validators.pattern("^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{5,}$")])),
      mobile: new UntypedFormControl(this.user.mobile, Validators.compose([Validators.pattern("^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{10,}$")])),
    });
    this.infoForm = new UntypedFormGroup({
      infoOnNewOrder: new UntypedFormControl(this.user.infoOnNewOrder),
      infoOnChangedOrder: new UntypedFormControl(this.user.infoOnChangedOrder),
      infoOnChangedOrderDelivery: new UntypedFormControl(this.user.infoOnChangedOrderDelivery),
      infoOnOrderToConfirm: new UntypedFormControl(this.user.infoOnOrderToConfirm),
      infoOnOrderInPayment: new UntypedFormControl(this.user.infoOnOrderInPayment),
      infoOnOrderDelivered: new UntypedFormControl(this.user.infoOnOrderDelivered),
      orderPDFwithPrices: new UntypedFormControl(this.user.orderPDFwithPrices),
      infoOnOrderInProduction: new UntypedFormControl(this.user.infoOnOrderInProduction),
      infoOnNewOffer: new UntypedFormControl(this.user.infoOnNewOffer),
      infoOnChangedOffer: new UntypedFormControl(this.user.infoOnChangedOffer),
      infoOnOfferToConfirm: new UntypedFormControl(this.user.infoOnOfferToConfirm),
      infoOnOfferExpiring: new UntypedFormControl(this.user.infoOnOfferExpiring),
      offerPDFwithPrices: new UntypedFormControl(this.user.offerPDFwithPrices),
      infoOnSummaryIntervalInDays: new UntypedFormControl(this.user.infoOnSummaryIntervalInDays),
      infoOnSummaryAddOffers: new UntypedFormControl(this.user.infoOnSummaryAddOffers),
      infoOnSummaryAddOrders: new UntypedFormControl(this.user.infoOnSummaryAddOrders),
      infoOnNewReclamation: new UntypedFormControl(this.user.infoOnNewReclamation),
      infoOnChangedReclamation: new UntypedFormControl(this.user.infoOnChangedReclamation),
      infoOnDoneReclamation: new UntypedFormControl(this.user.infoOnDoneReclamation),
      infoOnDeliveredFrame: new UntypedFormControl(this.user.infoOnDeliveredFrame),
      infoOnPickedUpFrame: new UntypedFormControl(this.user.infoOnPickedUpFrame),
      infoOnFrameInPickup: new UntypedFormControl(this.user.infoOnFrameInPickup),
      infoOnFrameInDelivery: new UntypedFormControl(this.user.infoOnFrameInDelivery),
      infoOnChangedShipmentNote: new UntypedFormControl(this.user.infoOnChangedShipmentNote),
      infoOnNewShipmentNote: new UntypedFormControl(this.user.infoOnNewShipmentNote),
    });

    this.passwordForm = new UntypedFormGroup({
      password: new UntypedFormControl("", Validators.required),
      password2: new UntypedFormControl("", Validators.required),
    });
  }
  public getFormControl(formControlName: string): string {
    let err: string = "";
    return this.formService.getFormControl(this.dataForm, formControlName);
  }

  passwordValidated(evt: any) {
    this.passwordValid = evt;
  }
  public summaryAddOrdersChanged(evt: any) {
    this.summaryAddOrdersChecked = evt;
  }
  public summaryAddOffersChanged(evt: any) {
    this.summaryAddOffersChecked = evt;
  }
  public summaryDoubleChanged(evt: any) {
    this.summaryDoubleChecked = evt;
  }
  public onSubmit(): void {
    let vm = this.dataForm.value;
    vm.id = this.user.id;
    vm.country = this.uiShareService.getCountryCode(this.dataForm.get("country").value);
    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.apiUsersService.updateMyData(vm).subscribe({
        next: (data) => {
          this.uiShareService.loggedInUser = data;
          this.messageService.success(this.translateService.instant("MYDATA.SUCCESS"));
          this.loaderService.hideLoader();
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("MYDATA.ERROR"));
          this.loaderService.hideLoader();
        },
      });
    });
  }

  public onSubmitInfos(): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let vm = this.infoForm.value;
      vm.id = this.user.id;
      vm.infoOnSummaryTwiceADay = this.summaryDoubleChecked;
      vm.infoOnSummaryAddOffers = this.summaryAddOffersChecked;
      vm.infoOnSummaryAddOrders = this.summaryAddOrdersChecked;
      vm.orderPDFwithPrices = this.orderPDFwithPrices;
      vm.offerPDFwithPrices = this.offerPDFwithPrices;
      vm.infoOnSummaryAddOrders = this.summaryAddOrdersChecked;
      this.apiUsersService.updateMyDataInfos(vm).subscribe({
        next: (data) => {
          this.uiShareService.loggedInUser = data;
          this.messageService.success(this.translateService.instant("MYDATA.SUCCESS"));
          this.loaderService.hideLoader();
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("MYDATA.ERROR"));
          this.loaderService.hideLoader();
        },
      });
    });
  }
  public onSubmitPassword(): void {
    this.loaderService.showLoaderDelayed().subscribe(() => {
      let vm: UpdatePassword = {
        id: this.user.id,
        password: this.passwordForm.value.password,
      };
      this.apiUsersService.updatePassword(vm).subscribe({
        next: (data) => {
          this.loaderService.hideLoader();
          this.messageService.success(this.translateService.instant("MYDATA.SUCCESS"));
        },
        error: (error) => {
          this.messageService.error(this.translateService.instant("MYDATA.ERROR"));
          this.loaderService.hideLoader();
        },
      });
    });
  }

  public switchNotifications(activate: boolean): void {
    this.infoForm.controls["infoOnNewOrder"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnChangedOrder"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnChangedOrderDelivery"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOrderToConfirm"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOrderInPayment"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOrderDelivered"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOrderInProduction"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnNewOffer"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnChangedOffer"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOfferToConfirm"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnOfferExpiring"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnNewShipmentNote"].setValue(activate ? 0 : 3);

    this.infoForm.controls["infoOnSummaryAddOffers"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnSummaryAddOrders"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnNewReclamation"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnChangedReclamation"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnDoneReclamation"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnDeliveredFrame"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnPickedUpFrame"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnFrameInPickup"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnFrameInDelivery"].setValue(activate ? 0 : 3);
    this.infoForm.controls["infoOnChangedShipmentNote"].setValue(activate ? 0 : 3);
    this.onSubmitInfos();
  }

  public selectCountry(): void {
    this.uiShareService.commonModals.showCountryModal().subscribe((country) => {
      if (country) {
        this.dataForm.get("country").setValue(country.title);
      }
    });
  }
}
