import { Injectable } from "@angular/core";
import { ISearchPosition } from "../models/searchOrder";
import { File } from "@awesome-cordova-plugins/file/ngx";

@Injectable({
  providedIn: "root",
})
export class ProductDetailsService {
  constructor(private file: File) {}

  public deleteAllDocuments(position: ISearchPosition): void {
    position.documents.forEach((document) => {
      const file = document.fileName ? document.fileName : "aldra.pdf";
      this.file
        .removeFile(this.file.cacheDirectory, file)
        .then((deleted) => {})
        .catch((error) => {});
    });
  }
}
