import { MatTableDataSource } from "@angular/material/table";
import { IOfferActionHistory } from "./offer-action-history";
import { IOfferPosition } from "./offer-position";

export enum IOfferStatus {
  Cancelled, // 0
  Created, // 1
  Processing, // 2
  Confirmed, // 3
  None, // 4
  Expired // 5
}
export interface IOffer {
  id: string;
  merchantNumber: string;
  zdemId: string;
  offerNumber: string;
  calculatedStatus: IOfferStatus;
  positions: IOfferPosition[];
  createdDate: Date;
  calculatedAmountNet: number;
  calculatedAmountGross: number;
  discountPercentage: number;
  plannedDeliveryWeek: number;
  deliveryWeekDiffers: boolean;
  customerReference: string;
  longDescription: string;
  date: Date;
  validTo: Date;

  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressZipCode: string;
  deliveryAddressHouseNumber: string;

  hasDivergentDeliveryAddress: boolean;

  archived?: boolean;
  confirmationPdfType: number;
  showDescription?: boolean;

  parked: boolean;
  selected: boolean;
  expanded: boolean;
  positionsDataSource: MatTableDataSource<IOfferPosition>;
  historyDataSource: MatTableDataSource<IOfferActionHistory>;
}
