import { Injectable } from "@angular/core";
import { Approve } from "../../models/approve";
import { IOrderEdit, IOrderPositionEdit } from "../../models/edit";

import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class ApiOrderService {
  private dataUrl = environment.apiConfig.uri + "Orders";

  constructor(private http: HttpService) {}

  public getOrders(): Observable<any> {
    return this.http.get(this.dataUrl + "/WithoutImages");
  }
  public getOrderImages(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/OrderImages/" + number);
  }
  public getActionHistory(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/ActionHistory/" + number);
  }
  public getShipmentNotes(): Observable<any> {
    return this.http.get(this.dataUrl + "/ShipmentNotes/WithoutImages");
  }
  public getShipmentNotesArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/ShipmentNotesArchive/WithoutImages");
  }
  public getOrderByNumber(number: string): Observable<any> {
    return this.http.get(this.dataUrl + "/ByNumber/" + number);
  }
  public findByElementId(id: string): Observable<any> {
    return this.http.get(this.dataUrl + "/Positions/FindByElementId/" + id);
  }
  public archive(orderNumber: string): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Archive/" + orderNumber, {});
  }

  public archiveShipment(shipmentNumber: string): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/ShipmentNotes/Archive/" + shipmentNumber, {});
  }
  public approve(approve: Approve): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Approve", approve);
  }
  public pdf(object: any): Observable<any> {
    return this.http.postBlob(this.dataUrl + "/PDF", object, { observe: "response" });
  }
  public getArchive(): Observable<any> {
    return this.http.get(this.dataUrl + "/Archive");
  }

  public editOrder(edit: IOrderEdit): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Edit/Order", edit);
  }

  public editOrderPosition(edit: IOrderPositionEdit): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/Edit/Position", edit);
  }
  public getOrderImagesPdf(orderNumber: string): Observable<any> {
    return this.http.getBinary(`${this.dataUrl}/OrderImagesPdf/${orderNumber}`);
  }
}
