import { Component, OnInit } from "@angular/core";
import { RoutingService } from "src/app/services/routing.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  constructor(private router: RoutingService) {}

  ngOnInit(): void {}
  public doClosePrivacyModal(): void {
    this.router.forwardByUrl("/");
  }
}
