import { Injectable } from "@angular/core";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { AlertController } from "@ionic/angular";
import { Observable, Observer } from "rxjs";
import { BarcodeService } from "./barcode.service";
import { UiShareService } from "./ui-share.service";

@Injectable({
  providedIn: "root",
})
export class FrameService {
  constructor(
    private barcodeService: BarcodeService,
    private uiShareService: UiShareService,
    private diagnostic: Diagnostic,
    private alertController: AlertController,
  ) {}

  public scanBarCode(): Observable<any> {
    return new Observable((observer: Observer<string>) => {
      this.uiShareService.cameraOrScanMode = 1;
      this.barcodeService.scan().then(
        (data) => {
          //this.frameNumber.emit(data);
          observer.next(data.ScanResult);
          observer.complete();
        },
        (error) => {
          console.error(error);
          observer.error(error);
          this.diagnostic
            .isCameraAuthorized()
            .then((data) => {
              if (!data) {
                this.showError();
              }
            })
            .catch((error2) => {});
        },
      );
    });
  }

  private async showError() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: "Fehler",
      message: "Um Produkte scannen zu können, müssen Sie dieser App die Erlaubnis geben die Camera zu benutzen.",
      buttons: [
        {
          text: "Ablehnen",
          role: "cancel",
          cssClass: "alert-button",
          handler: () => {},
        },
        {
          text: "OK",
          cssClass: "alert-button",
          handler: () => {
            this.uiShareService.cameraOrScanMode = 1;
            this.diagnostic.switchToSettings();
          },
        },
      ],
    });

    await alert.present();
  }
}
