<ion-header *ngIf="!appMode" [translucent]="true" class="ion-no-border">
  <div>
    <ng-template #defaultMenu>
      <div class="nav-item scroll right-menu-hide" [routerLink]="['/home']">
        <div class="home"></div>
      </div>

      <li class="nav-item right-menu" [routerLink]="['/home']">
        <a>{{ "MENU.START" | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5"
        [routerLink]="['/dashboard']" [ngClass]="{ selected: selected === 'MENU.DASHBOARD' }">
        <a>{{ "MENU.DASHBOARD" | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5"
        [routerLink]="['/offer']" [ngClass]="{ selected: selected === 'MENU.OFFER' }">
        <a>{{ "MENU.OFFER" | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5"
        [routerLink]="['/order']" [ngClass]="{ selected: selected === 'MENU.ORDER' }">
        <a>{{ "MENU.ORDER" | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [routerLink]="['/shipment-notes']"
        [ngClass]="{ selected: selected === 'MENU.SHIPMENT-NOTES' }">
        <a>{{ "MENU.SHIPMENT-NOTES" | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [routerLink]="['/invoices']"
        [ngClass]="{ selected: selected === 'Rechnungen' }">
        <a>Rechnungen</a>
      </li>
      <li class="nav-item has-sub default-menu reclamations" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [ngClass]="{
          selected:
            selected === 'MENU.RECLAMATION' ||
            selected === 'MENU.RECLAMATION_LIST' ||
            selected === 'MENU.RECLAMATION_DEFAULT' ||
            selected === 'MENU.RECLAMATION_GLASS'
        }">
        <a [routerLink]="['/reclamation']">{{ "MENU.RECLAMATION" | translate }}</a>
        <div class="whiteline"></div>
        <ul class="submenu">
          <li [routerLink]="['/reclamation']">
            {{ "MENU.RECLAMATION_LIST" | translate }}
          </li>
          <li *ngIf="!uiShareService.readOnlyMode()" [routerLink]="['/reclamation-create', 'reclamation-glass']">
            {{ "MENU.RECLAMATION_GLASS" | translate }}
          </li>
          <li *ngIf="!uiShareService.readOnlyMode()" [routerLink]="['/reclamation-create']">
            {{ "MENU.RECLAMATION_DEFAULT" | translate }}
          </li>
        </ul>
      </li>
      <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn && uiShareService.loggedInUser.type != 5" [ngClass]="{
          selected:
            selected === 'MENU.RECLAMATION' ||
            selected === 'MENU.RECLAMATION_LIST' ||
            selected === 'MENU.RECLAMATION_DEFAULT' ||
            selected === 'MENU.RECLAMATION_GLASS'
        }" (click)="toggleReclamationMenu()">
        <a>{{ "MENU.RECLAMATION" | translate }}</a>
        <div class="icon">
          <img [src]="
              reclamationMenuVisible ||
              selected === 'MENU.RECLAMATION' ||
              selected === 'MENU.RECLAMATION_LIST' ||
              selected === 'MENU.RECLAMATION_DEFAULT' ||
              selected === 'MENU.RECLAMATION_GLASS'
                ? './assets/icons/MenuIconDownRed.png'
                : './assets/icons/MenuIconDownGray.png'
            " alt="" />
        </div>
      </li>
      <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
        [ngClass]="{ selected: selected === 'MENU.RECLAMATION_LIST' }" [routerLink]="['/reclamation']">
        <a>{{ "MENU.RECLAMATION_LIST" | translate }}</a>
      </li>
      <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
        [ngClass]="{ selected: selected === 'MENU.RECLAMATION_GLASS' }" [routerLink]="['/reclamation-create', 'reclamation-glass']">
        <a>{{ "MENU.RECLAMATION_GLASS" | translate }}</a>
      </li>
      <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && reclamationMenuVisible && uiShareService.loggedInUser.type != 5"
        [ngClass]="{ selected: selected === 'MENU.RECLAMATION_DEFAULT' }" [routerLink]="['/reclamation-create']">
        <a>{{ "MENU.RECLAMATION_DEFAULT" | translate }}</a>
      </li>

      <li class="nav-item has-sub default-menu" *ngIf="uiShareService.loggedIn" [ngClass]="{
          selected: selected === 'MENU.FRAME' || selected === 'MENU.FRAME_LIST'
        }">
        <a [routerLink]="['/frame']">{{ "MENU.FRAME" | translate }}</a>
        <div class="whiteline"></div>
        <ul class="submenu">
          <li [routerLink]="['/frame']">{{ "MENU.FRAME_LIST" | translate }}</li>
          <li *ngIf="!uiShareService.readOnlyMode()" [routerLink]="['/frame-create']">
            {{ "MENU.FRAME_CREATE" | translate }}
          </li>
        </ul>
      </li>
      <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn" [ngClass]="{
          selected: selected === 'MENU.FRAME' || selected === 'MENU.FRAME_LIST'
        }" (click)="toggleFrameMenu()">
        <a>{{ "MENU.FRAME" | translate }}</a>
        <div class="icon">
          <img [src]="
              frameMenuVisible || selected === 'MENU.FRAME' || selected === 'MENU.FRAME_LIST'
                ? './assets/icons/MenuIconDownRed.png'
                : './assets/icons/MenuIconDownGray.png'
            " alt="" />
        </div>
      </li>
      <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && frameMenuVisible" [ngClass]="{ selected: selected === 'MENU.FRAME_LIST' }"
        [routerLink]="['/frame']">
        <a>{{ "MENU.FRAME_LIST" | translate }}</a>
      </li>
      <li class="nav-item right-menu sub" *ngIf="uiShareService.loggedIn && frameMenuVisible" [ngClass]="{ selected: selected === 'MENU.FRAME_CREATE' }"
        [routerLink]="['/frame-create']">
        <a>{{ "MENU.FRAME_CREATE" | translate }}</a>
      </li>

      <li class="nav-item" *ngIf="uiShareService.loggedIn" [ngClass]="{ selected: selected === 'MENU.EXPERTHEK' }" (click)="openExperthek()">
        <a>{{ "MENU.EXPERTHEK" | translate }}</a>
      </li>

      <div class="nav-item scroll"
        *ngIf="uiShareService.loggedIn && !isMenuSelected && uiShareService.loggedInUser.type != 2 && uiShareService.loggedInUser.type != 5"
        [routerLink]="['/user-administration']">
        <div class="avatar-plus"></div>
      </div>
      <div class="nav-item scroll" *ngIf="uiShareService.loggedIn && !isMenuSelected" [routerLink]="['/mydata']">
        <div class="avatar"></div>
      </div>
      <div class="nav-item scroll" *ngIf="uiShareService.loggedIn && !isMenuSelected" [routerLink]="['/logout']">
        <div class="logout"></div>
      </div>

      <li class="nav-item right-menu" *ngIf="uiShareService.loggedIn" [routerLink]="['/logout']">
        <a>{{ "MENU.LOGOUT" | translate }}</a>
      </li>
    </ng-template>

    <div class="scroll-header" id="scrollHeader">
      <div class="scroll-navbar">
        <div class="scroll-header-left">
          <img src="./assets/images/MeinAldra_Logo.png" width="100" alt="" />
        </div>
        <div class="scroll-header-middle">
          <div class="nav-menubar">
            <div class="nav-header">
              <ul>
                <ng-template [ngTemplateOutlet]="defaultMenu"></ng-template>
              </ul>
            </div>
          </div>
        </div>
        <div class="scroll-header-right">
          <img class="scroll-menu" *ngIf="!isMenuSelected" (click)="openOrCloseMenu()" (mouseover)="menuHover = true" (mouseout)="menuHover = false"
            [src]="menuHover ? './assets/icons/menuHover.png' : './assets/icons/menuRed.png'" alt="" />
          <img class="scroll-menu-close" *ngIf="isMenuSelected" (click)="openOrCloseMenu()" (mouseover)="closeHover = true" (mouseout)="closeHover = false"
            [src]="closeHover ? './assets/icons/closeHover.png' : './assets/icons/close.png'" alt="x" />
        </div>
      </div>
    </div>

    <div class="sidebar-right" *ngIf="isMenuSelected">
      <div class="logo">
        <img src="./assets/images/MeinAldra_Logo.png" width="160" alt="" />
      </div>
      <div class="sidebar">
        <ul class="sidebar-header">
          <ng-template [ngTemplateOutlet]="defaultMenu"></ng-template>
        </ul>
      </div>
    </div>
  </div>
  <!-- Needed to let the header work -->
  <ion-toolbar class="ion-color" [style.background-color]="'white'">
    <ion-title> </ion-title>
  </ion-toolbar>
</ion-header>