import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { ActionChangeOrderDialogComponent } from "src/app/dialogs/action/action-change-order-dialog/action-change-order-dialog.component";
import { AdminOrderDialogComponent } from "src/app/dialogs/admin/admin-order-dialog/admin-order-dialog.component";
import { EditIconDialogComponent } from "src/app/dialogs/edit-icon-dialog/edit-icon-dialog.component";
import { ImageDialogComponent } from "src/app/dialogs/image-dialog/image-dialog.component";
import { IOrder } from "src/app/models/order";
import { IOrderActionHistoryMessage, IOrderActionHistoryType } from "src/app/models/order-action-history";
import { IOrderPosition } from "src/app/models/order-position";
import { Tab } from "src/app/models/tab";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { OrderService } from "src/app/services/order.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { OrderComponent } from "../order.component";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit, AfterViewInit {
  @Input() order: IOrder;
  @Input() displayedColumns: string[];
  @Input("currentTab") parentTab: Tab;
  @ViewChildren("sort") innerSort: QueryList<MatSort>;
  @ViewChildren("table") innerTables: QueryList<MatTable<any>>;

  tabs: Tab[] = [
    {
      title: "Details",
      active: true,
      id: 0,
    },
    {
      title: "Historie",
      id: 1,
    },
  ];
  currentTab = this.tabs[0];
  detailExpandedRows: IOrderPosition[] = [];
  public lastChangeHistory: any;
  public lastChangeHistoryDate: any;
  public positionMessages: string[] = [];

  constructor(
    private dialog: MatDialog,
    public orderService: OrderService,
    private orderComponent: OrderComponent,
    private apiOrderService: ApiOrderService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    public uiShareService: UiShareService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.loadImages(this.order);
    this.loadHistory(this.order);

    this.order.positionsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case "description":
          return data["description"] + data["articleNumber"];
        default:
          return data[sortHeaderId];
      }
    };
  }

  ngAfterViewInit(): void {
    this.innerTables.forEach((table, index) => {
      (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
    });
  }

  public switchTab(tab: Tab): void {
    this.tabs.forEach((t) => (t.active = false));
    tab.active = true;
    this.currentTab = tab;
  }

  public updateInnerSort() {
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => {
      (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index];
    });
    // this.cd.detectChanges();
  }

  addOrRemove(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public previewImage(item?: IOrderPosition) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "450px",
      data: { item },
    });
  }

  public isValidSuggestedDelDate(item: IOrderPosition): boolean {
    return item.suggestedDeliveryDate && new Date(item.suggestedDeliveryDate).getFullYear() > 1970;
  }

  openEditIconDialog(item: IOrderPosition) {
    const dialogRef = this.dialog.open(EditIconDialogComponent, {
      width: "470px",
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  public adminEdit(order: IOrder, position?: IOrderPosition) {
    const dialogRef = this.dialog.open(AdminOrderDialogComponent, {
      width: "450px",
      data: { orderNumber: order.orderNumber, positionIndex: position ? position.positionIndex : -1 },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderComponent.reloadData(true);
      }
    });
  }

  public loadImages(order: IOrder) {
    this.apiOrderService.getOrderImages(order.orderNumber).subscribe((data: IOrder) => {
      // transfer images
      if (data) {
        data.positions.forEach((p) => {
          const found = order.positions.find((a) => a.positionIndex == p.positionIndex);
          if (found) {
            found.thumbnailFileBase64 = p.thumbnailFileBase64;
          }
        });
        // order.positions = data.positions;
        order.positionsDataSource.data = order.positions;
      }
    });
  }

  public loadHistory(order: IOrder): void {
    this.apiOrderService.getActionHistory(order.orderNumber).subscribe({
      next: (data) => {
        // get last change message
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].historyType == 1) {
            this.lastChangeHistoryDate = data[i].createdDate;
            this.lastChangeHistory = JSON.parse(data[i].message);
            break;
          }
        }

        // update for ui

        data.forEach((h) => {
          if (h.historyType == IOrderActionHistoryType.Approve) {
            if (h.wishDeliveryDate == "") {
              h.convertedMessage = "Keine Änderung am Liefertermin";
            } else {
              const splitted = h.wishDeliveryDate.split(";");
              h.convertedMessage = "Gewünschter Liefertermin: KW " + splitted[0] + ", " + splitted[1];
            }
          } else if (h.historyType == IOrderActionHistoryType.StateChange || h.historyType == IOrderActionHistoryType.Create) {
            let tmp = h.message;
            if (tmp == "WaitForConfirmation") tmp = "0";
            else if (tmp == "WaitForInternalRelease") tmp = "1";
            else if (tmp == "WaitForPayment") tmp = "2";
            else if (tmp == "Production") tmp = "3";
            else if (tmp == "Delivering") tmp = "4";
            else if (tmp == "Delivered") tmp = "5";
            else if (tmp == "Cancelled") tmp = "6";
            else if (tmp == "Processing") tmp = "7";
            else if (tmp == "PartialyDelivered") tmp = "8";
            else if (tmp == "PartialyInDelivery") tmp = "9";
            h.convertedMessage = this.translateService.instant("ORDER_STATUS." + tmp);
          } else if (h.historyType == IOrderActionHistoryType.Change) {
            const msg: IOrderActionHistoryMessage = JSON.parse(h.message);
            let tmp = msg.Message;
            for (let i = 0; i < msg.MessagesByPosition.length; i++) {
              const p = msg.MessagesByPosition[i];
              tmp += "<br>Position " + p.Index + ": " + p.Message;
            }
            h.convertedMessage = tmp;
          } else if (h.historyType == IOrderActionHistoryType.Contact || h.historyType == IOrderActionHistoryType.Callback) {
            h.convertedMessage = h.message ? h.message.replace(/\n/g, "<br>") : "";
          }
        });
        order.historyDataSource.data = data;
        this.tabs[1].count = order.historyDataSource.data.length;
      },
      error: (error) => {},
      complete: () => {
        this.loaderService.hideLoader();
        if (this.order) {
          this.order.positions.forEach((position, idx) => {
            this.positionMessages[idx] = this.getChangeMessageForPosition(position);
          });
        }
      },
    });
  }

  public getChangeMessageForPosition(item: IOrderPosition): string {
    if (!this.lastChangeHistory) return null;
    const tmp = this.lastChangeHistory.MessagesByPosition;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].Index == item.positionIndex) {
        return tmp[i].Message;
      }
    }
    return null;
  }

  public isChangeMessagesSet(): boolean {
    return this.order.positions.filter((p) => p.changeMessage).length > 0;
  }

  openActionChangeDialog(item: any) {
    const dialogRef = this.dialog.open(ActionChangeOrderDialogComponent, {
      height: "430px",
      width: "850px",
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const successMessage = this.translateService.instant("ORDER_DIALOG.CHANGE_SUCCESS");
      const errorMessage = this.translateService.instant("ORDER_DIALOG.CHANGE_ERROR");
      if (result == true) {
        this.orderComponent.reloadData(true);
        setTimeout(() => {
          this.messageService.success(successMessage);
        }, 1000);
      } else if (result == false) {
        this.loaderService.hideLoader();
        this.messageService.error(errorMessage);
      }
    });
  }
}
