import { Location } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { IonBackButton } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-mobile-ion-header",
  templateUrl: "./mobile-header.component.html",
  styleUrls: ["./mobile-header.component.scss"],
})
export class MobileHeaderIonComponent implements OnInit, OnChanges {
  @Input() selected: string;
  @Input() icon: string = Capacitor.isNativePlatform() ? "home" : "arrow-back";
  public isScroll: boolean = false;
  public isMenuSelected: boolean = false;
  public reclamationMenuVisible: boolean = false;
  public frameMenuVisible: boolean = false;
  private menuWidth: string = "260px";
  public menuHover: boolean;
  public closeHover: boolean;
  public appMode = Capacitor.isNativePlatform();
  @Output("onReload") reloadEvent = new EventEmitter<void>();
  @Input() homeIcon: boolean;
  @Input() secondaryIcon: string;
  @Input() reloadIcon: boolean;
  @ViewChild(IonBackButton)
  backButton: IonBackButton;
  public disabled: boolean = true;
  public onSecondaryClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private router: Router,
    public uiShareService: UiShareService,
    private location: Location,
  ) {
    this.router.events.subscribe((evt) => {
      if (this.isMenuSelected) {
        this.closeMenuFast();
      }
    });
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["homeIcon"]) {
      if (changes["homeIcon"]?.currentValue) {
        this.icon = "home";
      }
    }
  }

  @HostListener("window:resize", [])
  onResize() {
    if (window.innerWidth < 500) {
      this.menuWidth = "160px";
    } else {
      this.menuWidth = "260px";
    }
    if (window.innerWidth > 1015 && this.isMenuSelected) {
      this.openOrCloseMenu();
    }
  }
  public toggleReclamationMenu(): void {
    this.reclamationMenuVisible = !this.reclamationMenuVisible;
  }
  public toggleFrameMenu(): void {
    this.frameMenuVisible = !this.frameMenuVisible;
  }
  public openOrCloseMenu(): void {
    if (this.isMenuSelected) {
      this.isMenuSelected = false;
      document.body.style.position = "none";
      document.body.style.transition = "right 0.2s ease 0s";
      document.body.style.right = "0px";
      document.body.style.width = "100%";
      document.body.style.position = "absolute";
    } else {
      this.isMenuSelected = true;
      document.body.style.overflow = "hidden";
      document.body.style.transition = "right 0.2s ease 0s";
      //document.body.style.right = this.menuWidth;
      //document.body.style.width = "calc(100% - " + this.menuWidth + ")";
      document.body.style.overflowY = "auto";
      document.body.style.position = "absolute";
    }
  }
  public closeMenuFast(): void {
    this.isMenuSelected = false;
    document.body.style.position = "none";
    document.body.style.transition = "right 0s ease 0s";
    document.body.style.right = "0px";
    document.body.style.width = "100%";
    document.body.style.position = "absolute";
  }
  public openExperthek() {
    window.open("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", Capacitor.isNativePlatform() ? "_system" : "_blank");
  }

  goBack() {
    this.location.back();
  }
  secondaryClicked() {
    this.onSecondaryClickedSubject.next(true);
  }
  reloadClicked() {
    this.reloadEvent.emit();
  }
}
