import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-barcode-premission-dialog",
  templateUrl: "./barcode-premission-dialog.component.html",
  styleUrls: ["./barcode-premission-dialog.component.scss"],
})
export class BarcodePremissionDialogComponent {
  constructor(public dialogRef: MatDialogRef<BarcodePremissionDialogComponent>) {}

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
