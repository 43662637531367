import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ApiOrderService } from "src/app/services/api/api-order.service";
import { Approve } from "../../../models/approve";
@Component({
  selector: "app-action-stornieren-dialog",
  templateUrl: "./action-stornieren-dialog.component.html",
  styleUrls: ["./action-stornieren-dialog.component.scss"],
})
export class ActionStornierenDialogComponent implements OnInit {
  public approve: Approve = Object.assign({});
  public confirmText: string;
  constructor(
    public dialogRef: MatDialogRef<ActionStornierenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private orderService: ApiOrderService
  ) {}
  ngOnInit(): void {
    if (this.data.item.positions.filter((p) => p.changeMessage).length > 0) {
      // messages set!
      this.confirmText = "(Achtung: Sie haben Änderungsnachrichten auf Positionsebene.)";
    }
    this.approve.orderNumber = this.data.item.orderNumber;
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  public cancelOrder(): void {
    this.approve.type = 2;
    this.organizeRequest();
  }
  public organizeRequest(): void {
    this.orderService.approve(this.approve).subscribe({
      next: (data) => {
        this.approve = Object.assign({});
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.dialogRef.close(false);
      },
    });
  }
}
