<div class="dialog-modal-content">
  <app-content-header contentTitle="{{ 'OFFER_DIALOG.CHANGE_POSITION_TITLE' | translate }}"></app-content-header>
  <diV>
    <label class="field-title plain"><span [innerHtml]="'OFFER.ARTICLE_NUMBER' | translate"></span>: {{ itemPosition?.articleNumber }}</label>
    <br />
    <label class="field-title plain">{{ "OFFER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
    <div class="field">
      <input class="form-control" [(ngModel)]="approve.changeMessage" maxlength="80" />
    </div>
  </diV>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" mat-button (click)="onCancel()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="setChangeToPosition()">
    {{ "OFFER_DIALOG.SET" | translate }}
  </button>
  <div></div>
</div>
