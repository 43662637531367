<div class="tabs">
  <div class="tab active">{{ "ADMIN.MENU.MANAGE-ACCESS" | translate }}</div>
</div>
<div class="access-content">
  <div class="section-top">
    <mat-form-field class="filter-form-field" appearance="fill">
      <mat-label>Filter User</mat-label>
      <input matInput #userSearch type="text" [ngModel]="userFilterValue" (ngModelChange)="onUserChange($event)" />
      <span style="cursor: pointer" matSuffix mat-icon-button aria-label="Clear" (click)="onUserChange('')">
        <mat-icon>close</mat-icon>
      </span>
    </mat-form-field>
    <mat-selection-list #userList [multiple]="false" (selectionChange)="userSelectionChange($event)">
      <cdk-virtual-scroll-viewport [itemSize]="48" class="aldra-user-list-viewport">
        <mat-list-option *cdkVirtualFor="let user of filteredUsers | async" [value]="user">
          {{ user.firstName }}
          {{ user.lastName }} | {{ user.email }} |
          {{ user.typeString }}
        </mat-list-option>
      </cdk-virtual-scroll-viewport>
    </mat-selection-list>
    <p>
      Ausgewählter User:
      {{ userList.selectedOptions.selected[0]?.value?.email }}
      <br />
      <span *ngIf="userList.selectedOptions.selected[0]?.value">
        <mat-checkbox
          class="margin"
          [checked]="userList.selectedOptions.selected[0]?.value?.accountType === 1 || userList.selectedOptions.selected[0]?.value?.accountType === 3"
          (change)="onAccountTypeChange($event, userList.selectedOptions.selected[0]?.value, 1)"
          >Shared
        </mat-checkbox>
        <mat-checkbox
          class="margin"
          [checked]="userList.selectedOptions.selected[0]?.value?.accountType === 2 || userList.selectedOptions.selected[0]?.value?.accountType === 3"
          (change)="onAccountTypeChange($event, userList.selectedOptions.selected[0]?.value, 2)"
          >GVL</mat-checkbox
        >
      </span>
    </p>
  </div>
  <div
    [ngStyle]="{
      visibility: userList.selectedOptions.selected[0]?.value ? 'visible' : 'hidden',
    }"
  >
    <mat-form-field class="filter-form-field" appearance="fill">
      <mat-label>Filter Kunden</mat-label>
      <input matInput type="text" [ngModel]="merchantFilterValue" (ngModelChange)="onMerchantChange($event)" />
      <span style="cursor: pointer" matSuffix mat-icon-button aria-label="Clear" (click)="onMerchantChange('')">
        <mat-icon>close</mat-icon>
      </span>
    </mat-form-field>
    <button class="aldra-list-button" labelPosition="before" (click)="setAll($event, 2)" [disabled]="updating | async">Set all Read</button>
    <button class="aldra-list-button" labelPosition="before" (click)="setAll($event, 4)" [disabled]="updating | async">Set all Write</button>
    <button class="aldra-list-button" labelPosition="before" (click)="removeAll($event, 2)" [disabled]="updating | async">Remove all Read</button>
    <button class="aldra-list-button" labelPosition="before" (click)="removeAll($event, 4)" [disabled]="updating | async">Remove all Write</button>

    <mat-list #merchantList [multiple]="false">
      <cdk-virtual-scroll-viewport [itemSize]="72" class="aldra-merchant-list-viewport">
        <ng-container *cdkVirtualFor="let merchant of filteredMerchants | async">
          <mat-list-item [value]="merchant">
            <div>
              <span matLine>
                {{ merchant.merchantNumber }} | {{ merchant.companyName }} | {{ merchant.region || "-" }} | {{ merchant.regionName || "-" }} |
                {{ merchant.email }}
              </span>
              <span matLine>
                <mat-checkbox class="margin" [checked]="merchant?.read" (change)="checkBoxChange($event, merchant, 2)"> Read</mat-checkbox>
                <mat-checkbox class="margin" [checked]="merchant?.write" (change)="checkBoxChange($event, merchant, 4)"> Write</mat-checkbox>
                <mat-checkbox class="margin" [checked]="merchant?.active" (change)="checkBoxChange($event, merchant, 1)" disabled>Aktiv</mat-checkbox>
              </span>
            </div>
          </mat-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </mat-list>
  </div>
</div>
