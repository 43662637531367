import { Component, OnInit } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Capacitor } from "@capacitor/core";
import { User } from "src/app/models/user";
import { LogService } from "src/app/services/log.service";
import { RoutingService } from "src/app/services/routing.service";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home-cards",
  templateUrl: "./home-cards.component.html",
  styleUrls: ["./home-cards.component.scss"],
})
export class HomeCardsComponent implements OnInit {
  private isPopupOpen: boolean = false;
  public appMode = Capacitor.isNativePlatform();
  public adminHover: boolean;
  public loginHover: boolean;
  public searchHover: boolean;
  public dashboardHover: boolean;
  public offerHover: boolean;
  public experthekHover: boolean;
  public reclamation1Hover: boolean;
  public frameHover: boolean;
  public mydataHover: boolean;
  public userHover: boolean;
  public exitHover: boolean;
  public orderHover: boolean;
  public orderHover2: boolean;
  public invoicesHover: boolean;
  public togglePricesHover: boolean;
  public toggleMultiMerchantsHover: boolean;
  public gvlHover: boolean;
  public stammdatenHover: boolean;
  public user: User;
  public multiMerchantEnabled: boolean = environment.MULTIMERCHANT;

  constructor(
    public uiShareService: UiShareService,
    private router: RoutingService,
    private iab: InAppBrowser,
    private logService: LogService,
  ) {
    this.loginHover = false;
    this.searchHover = false;
    this.dashboardHover = false;
    this.offerHover = false;
    this.experthekHover = false;
    this.reclamation1Hover = false;
    this.frameHover = false;
    this.mydataHover = false;
    this.userHover = false;
    this.exitHover = false;
    this.orderHover = false;
    this.orderHover2 = false;
    this.invoicesHover = false;
    this.togglePricesHover = false;
    this.toggleMultiMerchantsHover = false;
  }
  public ngOnInit(): void {
    this.logService.debug("HomeCardsComponent.ngOnInit()");
    if (Capacitor.isNativePlatform()) {
      this.uiShareService.appReady.subscribe((next) => {
        this.logService.debug("HomeCardsComponent.ngOnInit() platform ready: " + next);
      });
    }
  }

  public toggleShowPrices() {
    this.uiShareService.showPrices.update((prev) => !prev);
  }

  public openPage(page) {
    this.router.forwardByUrl(page);
  }

  public openExperthek() {
    if (Capacitor.isNativePlatform()) {
      this.iab.create("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", "_system");
    } else {
      window.open("https://www.aldra.de/experthek/?byToken=639877504732114908c8c3f3e60cb096eb184ee7", "_blank");
    }
  }
}
