import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerAndroidScanningLibrary,
  CapacitorBarcodeScannerCameraDirection,
  CapacitorBarcodeScannerOptions,
  CapacitorBarcodeScannerScanOrientation,
  CapacitorBarcodeScannerScanResult,
  CapacitorBarcodeScannerTypeHintALLOption
} from "@capacitor/barcode-scanner";

@Injectable({
  providedIn: "root",
})
export class BarcodeService {
  constructor(public dialog: MatDialog) {}

  public async scan(): Promise<CapacitorBarcodeScannerScanResult> {
    try {
      const options: CapacitorBarcodeScannerOptions = {
        hint: CapacitorBarcodeScannerTypeHintALLOption.ALL,
        scanButton: true,
        scanText: "Produktnummer scannen",
        cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK,
        scanOrientation: CapacitorBarcodeScannerScanOrientation.ADAPTIVE,
        android: { scanningLibrary: CapacitorBarcodeScannerAndroidScanningLibrary.ZXING },
        web: { scannerFPS: 60, showCameraSelection: true },
      };
      return CapacitorBarcodeScanner.scanBarcode(options);
      // await BarcodeScanner.prepare();
      // const premission = await BarcodeScanner.checkPermission();
      // if (premission.granted) {
      //   await BarcodeScanner.hideBackground();

      //   document.body.classList.add("qrscanner"); // add the qrscanner class to body
      //   document.querySelector("body").classList.add("scanner-active");

      //   const scanResult: ScanResult = await BarcodeScanner.startScan({
      //     targetedFormats: [SupportedFormat.QR_CODE],
      //   });

      //   await BarcodeScanner.stopScan();
      //   console.log("Barcode data", JSON.stringify(scanResult));

      //   document.body.classList.remove("qrscanner"); // remove the qrscanner from the body
      //   document.querySelector("body").classList.remove("scanner-active");

      //   await BarcodeScanner.showBackground();
      //   return scanResult;
      // } else {
      //   await BarcodeScanner.stopScan();
      //   Camera.requestPermissions({ permissions: ["camera"] }).then((status) => {
      //     if (status.camera !== "denied") {
      //       return this.scan();
      //     } else {
      //       const scanResult: ScanResult = {
      //         hasContent: false,
      //         content: undefined,
      //         format: undefined,
      //       };
      //       return scanResult;
      //     }
      //   });
      // }
    } catch (error) {
      // await BarcodeScanner.stopScan();
      console.error(error);
      throw error;
    }
  }

  // public async stopScan(): Promise<void> {
  //   await BarcodeScanner.stopScan();
  //   document.body.classList.remove("qrscanner"); // remove the qrscanner from the body
  //   document.querySelector("body").classList.remove("scanner-active");
  //   await BarcodeScanner.showBackground();
  // }
}
