import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IOrderShipmentNoteOrder, IOrderShipmentNoteOrderPosition } from "src/app/models/order-shipment-note";
import { OrderService } from "src/app/services/order.service";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";

@Component({
  selector: "app-shipment-order-positions-dialog",
  templateUrl: "./shipment-order-positions-dialog.component.html",
  styleUrls: ["./shipment-order-positions-dialog.component.scss"],
})
export class ShipmentOrderPositionsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ShipmentOrderPositionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public shipmentOrder: IOrderShipmentNoteOrder,
    public orderService: OrderService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  public previewImage(item?: IOrderShipmentNoteOrderPosition) {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "450px",
      data: { item },
    });
  }
}
