export enum DocumentType {
  PerformanceStatement,
  PreConfirmation,
  Bill,
  DeliveryBill,
  ProductInformation,
  Confirmation,
  Quotation,
  DeliveryAvis,
  DeliveryNote,
}

export interface IDocument {
  type: number;
  translations: string;
  elementId: string;
  orderNumber: string;
  offerNumber: string;
  fileName: string;
  downloaded?: boolean;
  fileSize?: string;
}
