import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { ReclamationModalsComponent } from "src/app/includes/reclamation-modals/reclamation-modals.component";
import { LogService } from "src/app/services/log.service";
import { RoutingService } from "src/app/services/routing.service";
import { Breadcrumb } from "../../models/breadcrumb";
import { ImageConfig } from "../../models/imageConfig";
import { ApiReclamationService } from "../../services/api/api-reclamation.service";
import { FormService } from "../../services/form.service";
import { LoaderService } from "../../services/loader.service";
import { MessageService } from "../../services/message.service";
import { UiShareService } from "../../services/ui-share.service";

class PhotoFile {
  file: any;
  b64: string;
  loading: boolean;
  compressed: boolean;
  valid: boolean;
}
@Component({
  selector: "app-reclamation-create",
  templateUrl: "./reclamation-create.component.html",
  styleUrls: ["./reclamation-create.component.scss"],
})
export class ReclamationCreateComponent implements OnInit, OnDestroy {
  @ViewChild("reclamationModals")
  reclamationModals: ReclamationModalsComponent;
  public reclamationForm: UntypedFormGroup;
  public isGlas: boolean = false;
  public offsetTop: number;
  public offsetLeft: number;
  private subscription: any = null;
  public photoSrc: Array<PhotoFile>;
  private positions: any[] = new Array();
  public breadcrumb: [Breadcrumb];
  public appMode = Capacitor.isNativePlatform();
  public reclamation1Hover: boolean;
  public reclamation2Hover: boolean;
  private orderNumber: string = "";
  private elementIds: string = "";

  constructor(
    private formService: FormService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: RoutingService,
    private reclamationService: ApiReclamationService,
    private route: ActivatedRoute,
    private logService: LogService,
    private uiShareService: UiShareService,
    private ref: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    try {
      this.subscription = this.route.params.subscribe({
        next: (param) => {
          this.isGlas = param["type"] == "reclamation-glass" || param["id"] == "reclamation-glass";
          if (this.appMode) {
            this.orderNumber = param["orderId"];
            this.elementIds = param["elementId"];
          }
        },
        error: (error) => {
          this.logService.error(error);
        },
      });

      this.breadcrumb = [
        <Breadcrumb>{
          title: this.translateService.instant("MENU.RECLAMATION_LIST"),
          page: "reclamation",
        },
      ];
      this.breadcrumb.push(<Breadcrumb>{
        title: this.translateService.instant(this.isGlas ? "MENU.RECLAMATION_GLASS" : "MENU.RECLAMATION"),
        page: null,
      });
      this.reclamationForm = new UntypedFormGroup({
        contactPerson: new UntypedFormControl("", Validators.required),
        phone: new UntypedFormControl("", Validators.compose([Validators.required, Validators.pattern("^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{5,}$")])),
        customerName: new UntypedFormControl("", Validators.required),
        customerAdress: new UntypedFormControl("", Validators.required),
        customerPhone: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.pattern("^[+0, 0]+[\\s{0,3} (){0,1} -{0,1}]+[0-9]{5,}$")])
        ),
        customerEmail: new UntypedFormControl("", Validators.compose([Validators.required, Validators.email])),
        orderNumber: new UntypedFormControl(this.orderNumber, Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])),
        elementIds: new UntypedFormControl(this.elementIds, Validators.compose([Validators.required, Validators.minLength(10)])),
        email: new UntypedFormControl(null, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")),
        delivery: new UntypedFormControl(),
        acceptPrivacy: new UntypedFormControl(false, Validators.requiredTrue),
      });
      if (!this.isGlas) {
        this.reclamationForm.addControl(
          "reason",
          new UntypedFormGroup({
            surface: new UntypedFormControl(),
            pane: new UntypedFormControl(),
            sprouted: new UntypedFormControl(),
            shutters: new UntypedFormControl(),
            malfunction: new UntypedFormControl(),
            transport: new UntypedFormControl(),
            delivery: new UntypedFormControl(),
            other: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl("errorDescription", new UntypedFormControl(null, Validators.required));
      } else {
        this.reclamationForm.addControl(
          "position2",
          new UntypedFormGroup({
            0: new UntypedFormControl(),
            1: new UntypedFormControl(),
            2: new UntypedFormControl(),
            3: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl(
          "position3",
          new UntypedFormGroup({
            0: new UntypedFormControl(),
            1: new UntypedFormControl(),
            2: new UntypedFormControl(),
            3: new UntypedFormControl(),
            4: new UntypedFormControl(),
            5: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl("orderNumberInfo", new UntypedFormControl(""));
        this.reclamationForm.addControl("distanceName", new UntypedFormControl());
        this.reclamationForm.addControl("glassDimensions", new UntypedFormControl());
        this.reclamationForm.addControl("heightGlassDimensions", new UntypedFormControl());
        this.reclamationForm.addControl(
          "defect1",
          new UntypedFormGroup({
            amount: new UntypedFormControl(),
            mm: new UntypedFormControl(),
            area: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl(
          "defect2",
          new UntypedFormGroup({
            amount: new UntypedFormControl("", Validators.required),
            mm: new UntypedFormControl("", Validators.required),
            area: new UntypedFormControl("", Validators.required),
          })
        );
        this.reclamationForm.addControl(
          "defect3",
          new UntypedFormGroup({
            amount: new UntypedFormControl(),
            mm: new UntypedFormControl(),
            area: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl(
          "defect4",
          new UntypedFormGroup({
            amount: new UntypedFormControl(),
            mm: new UntypedFormControl(),
            area: new UntypedFormControl(),
          })
        );
        this.reclamationForm.addControl(
          "defect5",
          new UntypedFormGroup({
            amount: new UntypedFormControl(),
            mm: new UntypedFormControl(),
            area: new UntypedFormControl(),
          })
        );
      }
      this.reclamationForm.addControl("photo", new UntypedFormControl(null));
      //console.log(this.reclamationForm);
    } catch (e) {
      this.messageService.error(this.translateService.instant("RECLAMTION.LOADING_FAIL"));
    }

    this.checkValidation();
  }

  public clearOrder(): void {
    this.reclamationForm.get("orderNumber").setValue(null);
    this.reclamationForm.get("elementIds").setValue(null);
  }
  public chooseOrder(): void {
    this.reclamationModals.showSelectOrderModal().subscribe((data) => {
      if (data) {
        const old = this.reclamationForm.get("orderNumber").value || "";
        if (typeof data == "string") {
          this.reclamationForm.get("orderNumber").setValue(data);
        } else {
          this.reclamationForm.get("orderNumber").setValue(data.orderNumber);
        }
        if (this.reclamationForm.get("orderNumber").value != old) {
          this.reclamationForm.get("elementIds").setValue(null);
        }
      }
    });
  }
  public chooseOrderPositions(): void {
    this.reclamationModals
      .showSelectOrderPositionModal(this.reclamationForm.get("orderNumber").value, (this.reclamationForm.get("elementIds").value || "").split(";"))
      .subscribe((data) => {
        if (data) {
          let ids = "";
          if (this.reclamationForm.get("elementIds").value) {
            ids = this.reclamationForm.get("elementIds").value;
          }

          if (typeof data == "string") {
            if (data.startsWith("DEL-")) {
              const tmp = data.replace("DEL-", "");
              if (ids == tmp) {
                ids = "";
              } else if (ids.startsWith(tmp + ";")) {
                ids = ids.replace(tmp + ";", "");
              } else if (ids.endsWith(";" + tmp)) {
                ids = ids.replace(";" + tmp, "");
              } else {
                ids = ids.replace(tmp + ";", "");
              }
            } else {
              if (ids != "") ids += ";";
              ids += data;
            }
          } else {
            if (ids != "") ids += ";";
            ids += data.elementId;
          }
          this.reclamationForm.get("elementIds").setValue(ids);
        }
      });
  }

  public checkDefectsValidation(name: string): void {
    this.reclamationForm.get([name, "amount"]).setValidators([Validators.required]);
    this.reclamationForm.get([name, "mm"]).setValidators([Validators.required]);
    this.reclamationForm.get([name, "area"]).setValidators([Validators.required]);
    this.reclamationForm.get([name, "amount"]).updateValueAndValidity();
    this.reclamationForm.get([name, "mm"]).updateValueAndValidity();
    this.reclamationForm.get([name, "area"]).updateValueAndValidity();
  }

  private getFileSizeMB(size: number): string {
    let kb = size / 1024;
    let mb = kb / 1024;
    //console.log(size + " -> " + kb + " -> " + mb);
    if (mb > 0) {
      return mb.toFixed(1) + "MB";
    } else {
      return kb.toFixed(1) + "KB";
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public checkValidation(): void {
    if (this.reclamationForm.controls["photo"]) {
      if (!this.isGlas) {
        if (this.reclamationForm.get("reason").get("surface").value) {
          if (this.photoSrc && this.photoSrc.length > 0) {
            // all fine
            this.reclamationForm.controls["photo"].validator = null;
          } else {
            this.reclamationForm.controls["photo"].setValidators([Validators.required]);
          }
          this.reclamationForm.controls["photo"].updateValueAndValidity();
        } else {
          this.reclamationForm.controls["photo"].validator = null;
          this.reclamationForm.controls["photo"].updateValueAndValidity();
        }
      }
    }
    this.reclamationForm.updateValueAndValidity();
  }
  public getFormControl(formControlName: string): string {
    if (!this.reclamationForm) return;
    let err: string = "";
    return this.formService.getFormControl(this.reclamationForm, formControlName);
  }
  public isDefectValid(): boolean {
    return false;
  }
  public onSubmit(): void {
    if (this.isGlas) {
      this.onSubmitGlas();
    } else {
      this.onSubmitDefault();
    }
  }
  public onSubmitDefault(): void {
    // all photos valid?
    let valid = true;
    if (this.photoSrc) {
      for (let i = 0; i < this.photoSrc.length; i++) {
        if (!this.photoSrc[i].valid) {
          valid = false;
          break;
        }
      }
    }

    if (valid) {
      let reasons = this.getReasonsArray();
      let data = {
        reclamationType: 0,
        contactPerson: this.reclamationForm.get("contactPerson").value,
        phone: this.reclamationForm.get("phone").value,
        customerName: this.reclamationForm.get("customerName").value,
        customerAddress: this.reclamationForm.get("customerAdress").value,
        customerPhone: this.reclamationForm.get("customerPhone").value,
        customerEmail: this.reclamationForm.get("customerEmail").value,
        orderNumber: this.reclamationForm.get("orderNumber").value,
        elementIds: this.reclamationForm.get("elementIds").value,
        reasons: reasons,
        errorDescription: this.reclamationForm.get("errorDescription").value,
        delivery: this.reclamationForm.get("delivery").value,
        email: this.reclamationForm.get("email").value,
      };
      this.loaderService.showLoaderDelayed().subscribe(() => {
        this.reclamationService.create(this.convertPhotosArray(), data).subscribe({
          next: (dataResult) => {
            this.uiShareService.reclamationsNeedReload = true;
            this.loaderService.hideLoader();
            this.messageService.success(this.translateService.instant("RECLAMATION.SUCCESS"));
            this.uiShareService.currentReclamationTabId = 0;
            this.router.forwardByUrl("/reclamation");
          },
          error: (errorResult) => {
            this.loaderService.hideLoader();
            this.messageService.error(this.translateService.instant("RECLAMATION.ERROR"));
          },
        });
      });
    } else {
      this.messageService.error(this.translateService.instant("RECLAMATION.PHOTO_ERROR"));
    }
  }
  public onSubmitGlas(): void {
    // all photos valid?
    let valid = true;
    if (this.photoSrc) {
      for (let i = 0; i < this.photoSrc.length; i++) {
        if (!this.photoSrc[i].valid) {
          valid = false;
          break;
        }
      }
    }

    if (valid) {
      let positions_2 = this.getPositions("position2", 4);
      let positions_3 = this.getPositions("position3", 6);
      let defects = this.getDefectsObject();
      let data = {
        reclamationType: 1,
        contactPerson: this.reclamationForm.get("contactPerson").value,
        phone: this.reclamationForm.get("phone").value,
        customerName: this.reclamationForm.get("customerName").value,
        customerAddress: this.reclamationForm.get("customerAdress").value,
        customerPhone: this.reclamationForm.get("customerPhone").value,
        customerEmail: this.reclamationForm.get("customerEmail").value,
        orderNumber: this.reclamationForm.get("orderNumber").value,
        elementIds: this.reclamationForm.get("elementIds").value,
        orderNumberInfo: this.reclamationForm.get("orderNumberInfo").value,
        distanceName: this.reclamationForm.get("distanceName").value,
        glassDimensions: this.reclamationForm.get("glassDimensions").value,
        heightGlassDimensions: this.reclamationForm.get("heightGlassDimensions").value,
        defects: defects,
        window: {
          image: null,
          width: 300,
          height: 300,
          positions: this.positions,
        },
        positions2SidedGlas: positions_2,
        positions3SidedGlas: positions_3,
        delivery: this.reclamationForm.get("delivery").value,
        email: this.reclamationForm.get("email").value,
      };

      // create window image
      let image = new Image();
      image.onload = () => {
        let imageCross = new Image();
        imageCross.onload = () => {
          let content = document.getElementById("crossWindow");

          let canvas = document.createElement("canvas");
          canvas.width = 300;
          canvas.height = 300;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, 300, 300);

          for (let i = 1; i < content.childNodes.length; i++) {
            const child: HTMLImageElement = content.children[i] as any;
            ctx.drawImage(imageCross, child.offsetLeft, child.offsetTop);
          }
          data.window.image = canvas.toDataURL("image/jpeg", 1).replace("data:image/jpeg;base64,", "");

          this.loaderService.showLoaderDelayed().subscribe(() => {
            this.reclamationService.create(this.convertPhotosArray(), data).subscribe({
              next: (dataResult) => {
                this.uiShareService.reclamationsNeedReload = true;
                this.loaderService.hideLoader();
                this.messageService.success(this.translateService.instant("RECLAMATION.SUCCESS"));
                this.uiShareService.currentReclamationTabId = 1;
                this.router.forwardByUrl("/reclamation");
              },
              error: (errorResult) => {
                this.loaderService.hideLoader();
                this.messageService.error(this.translateService.instant("RECLAMATION.ERROR"));
              },
            });
          });
        };
        imageCross.src = "../../../../assets/icons/cross.png";
      };
      image.src = "../../../../assets/images/aldra_fenster.png";
    } else {
      this.messageService.error(this.translateService.instant("RECLAMATION.PHOTO_ERROR"));
    }
  }
  public getPositions(formControlName: string, length: number): Array<number> {
    let result = [];
    for (let i = 0; i < length; i++) {
      if (this.reclamationForm.get(formControlName).get(JSON.stringify(i)).value) {
        result.push(i);
      }
    }
    return result;
  }
  public getReasonsArray(): Array<number> {
    let result = new Array();
    if (this.reclamationForm.get("reason").get("surface").value) result.push(0);
    if (this.reclamationForm.get("reason").get("pane").value) result.push(1);
    if (this.reclamationForm.get("reason").get("sprouted").value) result.push(2);
    if (this.reclamationForm.get("reason").get("shutters").value) result.push(3);
    if (this.reclamationForm.get("reason").get("malfunction").value) result.push(4);
    if (this.reclamationForm.get("reason").get("transport").value) result.push(5);
    if (this.reclamationForm.get("reason").get("delivery").value) result.push(6);
    if (this.reclamationForm.get("reason").get("other").value) result.push(7);

    return result;
  }
  public getDefectsObject(): any {
    let defects = Object.assign({});
    for (let i = 1; i < 6; i++) {
      let name = "defect" + i;
      let form = this.reclamationForm.get(name);
      if (form.get("amount").value && form.get("mm") && form.get("area")) defects[name] = this.reclamationForm.get(name).value;
    }
    return defects;
  }
  private convertPhotosArray(): [any] {
    let files: [any];
    if (this.photoSrc) {
      this.photoSrc.forEach((p) => {
        if (!files) {
          files = [p.file];
        } else {
          files.push(p.file);
        }
      });
    }
    return files;
  }
  private convertPhotosArrayToNames(): [any] {
    let files: [string];
    if (this.photoSrc) {
      this.photoSrc.forEach((p) => {
        if (!files) {
          files = [p.file.name];
        } else {
          files.push(p.file.name);
        }
      });
    }
    return files;
  }
  public addFile(fileData: any): void {
    const photoFile: PhotoFile = {
      file: fileData,
      b64: "",
      compressed: false,
      loading: true,
      valid: true,
    };
    //console.log(photoFile);

    this.convertFile(photoFile);
  }
  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);

    if (file == null) {
      return;
    }
    const that = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let base64data: any = reader.result;
      base64data = base64data.substring(base64data.indexOf(",") + 1, base64data.length);
      //console.log(base64data);
      let photoFile: PhotoFile = {
        file: {
          name: file.name,
          b64: base64data,
        },
        b64: "",
        compressed: false,
        loading: true,
        valid: true,
      };
      //console.log(photoFile);

      that.convertFile(photoFile);
    };
  }
  private getFixedImageData(data: string): string {
    if (data.indexOf(",") == -1) {
      return data;
    }
    return data.substring(data.indexOf(",") + 1, data.length);
  }
  private convertFile(photoFile: PhotoFile) {
    console.log("convertFile()");

    // get config
    const imageConfig: ImageConfig = JSON.parse(localStorage.getItem("imageConfig"));

    if (this.photoSrc) {
      let index = this.photoSrc.findIndex((x) => x.file.name === photoFile.file.name);
      if (index == -1) {
        console.log("convertFile() add to array");
        this.photoSrc.push(photoFile);
      } else {
        console.log("convertFile() already in array");
      }
    } else if (!this.photoSrc) {
      this.photoSrc = [photoFile];
      console.log("convertFile() new array");
    }

    const image = new Image();
    image.src = "data:image/jpeg;base64," + photoFile.file.b64;
    image.onload = (rs) => {
      const srcW = rs.currentTarget["width"];
      const srcH = rs.currentTarget["height"];
      console.log("convertFile() img loaded: " + srcW + "x" + srcH);
      this.getResizedImage(image, srcW, srcH, imageConfig.maxWidthPixel, imageConfig.downscalePercentage, photoFile.file.name).subscribe((img) => {
        console.log("convertFile() img resized: " + img.width + "x" + img.height);
        // get thumb
        //let fileName = photoFile.file.name;
        //fileName = fileName.substring(0, fileName.lastIndexOf(".")) + ".jpeg";
        this.getResizedImage(image, srcW, srcH, imageConfig.thumbMaxWidthPixel, imageConfig.thumbDownscalePercentage, photoFile.file.name + "_thumb").subscribe(
          (thumb) => {
            console.log("convertFile() thumb resized: " + thumb.width + "x" + thumb.height);
            for (let i = 0; i < this.photoSrc.length; i++) {
              let p = this.photoSrc[i];

              if (p.file.name == photoFile.file.name) {
                if (img.file.b64.length != p.file.b64.length) {
                  // file has changed
                  p.file.b64 = this.getFixedImageData(img.file.b64);
                  p.compressed = img.width != srcW || img.height != srcH;
                  console.log("convertFile() file has changed (" + p.compressed + ")");
                } else {
                  console.log("convertFile() file is the same");
                  p.compressed = false;
                }
                p.b64 = thumb.file.b64;
                p.loading = false;

                // estimate file size
                const stringLength = p.file.b64.length;
                const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
                p.file.sizeSrc = sizeInBytes;
                p.valid = p.file.sizeSrc <= imageConfig.maxSizeBytes;
                p.file.size = this.getFileSizeMB(sizeInBytes);
                //console.log(p);
                break;
              }
            }
            this.ref.detectChanges();
            this.ref.markForCheck();
            //console.log(this.reclamationForm);
            this.checkValidation();
          }
        );
      });
    };
  }
  private getResizedImage(image: any, w: number, h: number, target: number, quality: number, name: string): any {
    let newW = 0;
    let newH = 0;

    if (w > h) {
      if (w > target) {
        const factor = target / w;
        newW = target;
        newH = h * factor;
      } else {
        newW = w;
        newH = h;
      }
    } else {
      if (h > target) {
        const factor = target / h;
        newH = target;
        newW = w * factor;
      } else {
        newW = w;
        newH = h;
      }
    }

    const elem = document.createElement("canvas");
    elem.width = newW;
    elem.height = newH;
    const ctx = elem.getContext("2d");
    // img.width and img.height will contain the original dimensions
    ctx.drawImage(image, 0, 0, newW, newH);

    const b64Data = ctx.canvas.toDataURL("image/jpeg", quality);

    const img = {
      file: {
        b64: b64Data,
        name: name,
      },
      width: newW,
      height: newH,
      b64: "", //ctx.canvas.toDataURL("image/jpeg", 0.5)
    };
    return of(img);
  }
  public removeFile(index: number): void {
    this.photoSrc.splice(index, 1);
    if (!this.photoSrc || (this.photoSrc && this.photoSrc.length == 0)) this.reclamationForm.get("photo").setValue(null);
    this.checkValidation();
  }

  public setPosition(e): void {
    if (e.toElement && e.toElement.className == "cross") {
      this.offsetTop = e.toElement.offsetTop + e.offsetY;
      this.offsetLeft = e.toElement.offsetLeft + e.offsetX;
    } else {
      this.offsetTop = e.offsetY;
      this.offsetLeft = e.offsetX;
    }
    let content = document.getElementById("crossWindow");
    let img = document.createElement("img");
    img.src = "../../../../assets/icons/cross.png";
    content.appendChild(img);
    img.className = "cross";
    img.style.left = this.offsetLeft - 10 + "px";
    img.style.top = this.offsetTop - 10 + "px";
    this.positions.push({ x: this.offsetLeft - 10, y: this.offsetTop - 10 });
    img.style.position = "absolute";
  }
  public deleteMarker(): void {
    let content = document.getElementById("crossWindow");
    let counter = content.childElementCount;

    if (counter > 1) {
      content.removeChild(content.children[counter - 1]);
    }
  }
}
