import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { debounceTime } from "rxjs/operators";
import { User, UserWorkflowState } from "src/app/models/user";
import { ApiUsersService } from "src/app/services/api/api-users.service";
import { LoaderService } from "src/app/services/loader.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserCredentials } from "src/app/services/token.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-switch-user",
  templateUrl: "./admin-switch-user.component.html",
  styleUrls: ["./admin-switch-user.component.scss"],
})
export class AdminSwitchUserComponent implements OnInit {
  public form: FormGroup;
  public users: User[] = [];
  public usersFiltered: User[] = [];
  constructor(
    public dialogRef: MatDialogRef<AdminSwitchUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fromBuilder: FormBuilder,
    private apiUsersService: ApiUsersService,
    private loaderService: LoaderService,
    private uiShareService: UiShareService,
    private router: RoutingService
  ) {}

  ngOnInit() {
    this.form = this.fromBuilder.group({
      userSearch: [""],
    });

    // this.loaderService.showLoaderDelayed().subscribe(() => {
    this.apiUsersService.getAllUser().subscribe({
      next: (data: User[]) => {
        this.users = data
          .filter((u) => u.workflowState == UserWorkflowState.Confirmed)
          .sort((u1, u2) => {
            if (u1.merchantNumber > u2.merchantNumber) return 1;
            if (u1.merchantNumber < u2.merchantNumber) return -1;
            return 0;
          });
        this.usersFiltered = this.users;
        // this.loaderService.hideLoader();
      },
      error: (e) => {
        console.log(e);
        // this.loaderService.hideLoader();
      },
    });
    // });

    this.form
      .get("userSearch")
      .valueChanges.pipe(debounceTime(300))
      .subscribe((filter) => {
        this.usersFiltered = this.users.filter((user) => {
          let term = user.merchantNumber + user.email;
          term = term.toLowerCase().trim();
          filter = filter.toLowerCase().trim();
          return filter.split(" ").every((e) => term.includes(e));
        });
      });

    this.dialogRef.afterClosed().subscribe(() => this.loaderService.hideLoader());
  }

  switchUser(user: User) {
    this.loaderService.showTransparentLoaderDelayed().subscribe(() => {
      const credentials: UserCredentials = {
        email: "-",
        password: "-",
        device: this.uiShareService.tokenService.deviceData,
        uniqueID: user.uniqueID.trim(),
      };

      this.apiUsersService.switchUser(credentials).subscribe({
        next: (token) => {
          //console.log(token);
          this.uiShareService.adminToken = this.uiShareService.tokenService.currentToken.refreshToken;
          this.uiShareService.tokenService.cryptionService.set("jwtAdminToken", this.uiShareService.adminToken);
          this.uiShareService.tokenService.setNewToken(token);
          this.uiShareService.loggedInUser = token.user;
          this.uiShareService.selectedMerchant = {
            merchantNumber: token.user.merchantNumber,
            companyName: token.user.companyName,
            email: token.user.email,
            uniqueID: token.user.uniqueID,
          };
          this.uiShareService.multiMerchantLinks = token.multiMerchantLinks;
          this.uiShareService.ordersNeedReload = true;
          this.uiShareService.offersNeedReload = true;
          this.uiShareService.reclamationsNeedReload = true;
          this.uiShareService.framesNeedReload = true;

          this.router.forwardByUrl("/home");

          this.dialogRef.close();
        },
        error: (error) => {
          console.log(error);
          this.dialogRef.close();
        },
      });
    });
  }
}
