<div class="dialog-modal-content">
  <app-content-header contentTitle="Archivieren"></app-content-header>
  <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="closeBatchModal()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="confirmBatchModal()">
    {{ "ORDER_DIALOG.CONFIRM" | translate }}
  </button>
</div>
