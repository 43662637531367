<div class="tabs">
  <div class="tab" [ngClass]="currentTab === 'settings' ? 'active' : ''" (click)="loadSettings()">
    {{ "ADMIN.PUSH.SETTINGS" | translate }}
  </div>
  <div class="tab" [ngClass]="currentTab === lng ? 'active' : ''" *ngFor="let lng of availableLanguages" (click)="loadLng(lng)">
    {{ lng }}
  </div>
  <div class="tab" (click)="sendTest()">Test push</div>
</div>

<div *ngIf="currentTab === 'settings'">
  <form>
    <label class="field-title">{{ "ADMIN.PUSH.PASSWORD" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="password" [(ngModel)]="password.value" placeholder="" />
    </div>
    <br />
    <br />
    <button (click)="saveSettings()">
      {{ "ADMIN.PUSH.SAVE" | translate }}
    </button>
  </form>
</div>

<div *ngIf="currentTab !== 'settings'" class="box">
  <div class="list">
    <div class="setting" *ngFor="let setting of lngData" (click)="loadSetting(setting)">
      {{ setting.dspKey }}
    </div>
    <div *ngIf="lngData.length === 0">No data</div>
  </div>
  <div class="code">
    <button (click)="newSetting()">
      {{ "ADMIN.PUSH.NEW_SETTING" | translate }}</button
    ><br />
    <br />
    <form>
      <label class="field-title">{{ "ADMIN.PUSH.KEY" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="key" [(ngModel)]="key" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.PUSH.SUBJECT" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="subject" [(ngModel)]="subject" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.PUSH.BODY" | translate }}:</label>
      <div class="field">
        <textarea class="form-control" name="body" [(ngModel)]="body" placeholder=""></textarea>
      </div>
      <br />
      <button (click)="savePushSetting()" [disabled]="subject === '' || body === '' || key === ''">
        {{ "ADMIN.PUSH.SAVE" | translate }}
      </button>
    </form>
  </div>
</div>

<app-modal id="testPushModal" [show]="testPushModalShow" maxWidth="400px">
  <ng-template #modalContent>
    <label class="field-title">{{ "ADMIN.PUSH.TEST_PUSH_RECIPIENT" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="testPush" [(ngModel)]="stTestPush.value" placeholder="" />
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="doCloseModal()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="doSendTest()">Send</button>
  </ng-template>
</app-modal>
