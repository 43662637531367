import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { AdminSwitchUserComponent } from "src/app/dialogs/admin/admin-switch-user/admin-switch-user.component";
import { ApiAdminService } from "src/app/services/api/api-admin.service";
import { ApiSettingsService } from "src/app/services/api/api-settings.service";

@Component({
  selector: "app-admin-frame",
  templateUrl: "./admin-frame.component.html",
  styleUrls: ["./admin-frame.component.scss"],
})
export class AdminFrameComponent implements OnInit, OnDestroy {
  public dmLastRun: string = "-";
  public onlineUsers: any[] = [];
  private timer;
  private ngUnsubscribe = new Subject<void>();
  public active = "";

  constructor(private rtr: Router, private apiSettingsService: ApiSettingsService, private apiAdminService: ApiAdminService, private dialog: MatDialog) {
    rtr.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (evt.urlAfterRedirects.indexOf("admin/") > -1) {
          this.active = evt.urlAfterRedirects.substring(evt.urlAfterRedirects.indexOf("admin/") + "admin/".length);
        }
      }
    });
  }

  ngOnInit() {
    this.updateOnlineUsers();
  }

  ngOnDestroy(): void {
    try {
      clearTimeout(this.timer);
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    } catch (e) {}
  }
  private updateOnlineUsers() {
    this.apiAdminService
      .getEstimatedOnlineUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((users) => {
        users.forEach((ou) => {
          const ot = new Date(ou.dateTime);
          const diff = new Date().getTime() - ot.getTime();
          ou.dsp = "" + (diff / 1000 / 60).toFixed(1);
          ou.onlineTime = diff;
        });
        this.onlineUsers = users.sort((u1, u2) => {
          if (u1.onlineTime > u2.onlineTime) return 1;
          if (u1.onlineTime < u2.onlineTime) return -1;
          return 0;
        });
      });
    this.apiAdminService
      .getLastRun("DataManager")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lastRun) => {
        this.dmLastRun = lastRun;
      });
    this.timer = setTimeout(() => {
      this.updateOnlineUsers();
    }, 30000);
  }

  public exportSettings(): void {
    this.apiSettingsService.export().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }
  public restartTask(task: string): void {
    this.apiAdminService.restart(task).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  public switchUser(): void {
    let dialogRef = this.dialog.open(AdminSwitchUserComponent, {
      width: "450px",
    });
  }
}
