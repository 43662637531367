<app-modal id="approve-dialog" [show]="isOpenApproveDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ORDER_DIALOG.TITLE' | translate }}"></app-content-header>
    <div cell="100">
      <label class="field-title">{{ "CONTACT_FORM.ORDER_NUMBER" | translate }}: {{ item?.orderNumber }}</label>
      <br />
      <label class="field-title plain"
        >{{ "ORDER_DIALOG.EARLIEST_WISH_DELIVERY_DATE" | translate }}: KW {{ wishDeliveryDateMinWeek }}, {{ wishDeliveryDateMinYear }}</label
      >
      <div *ngIf="wishDeliveryDateDelay > 0" class="delay">
        Das maximale Freigabedatum für diesen Auftrag ist verstrichen. Die geplante Lieferwoche wurde um {{ wishDeliveryDateDelay }} Tage verschoben.
      </div>
      <div *ngIf="holidayDelay > 0" class="delay">
        Die frühest mögliche Auswahlwoche wurde aufgrund von Feiertagen um
        {{ holidayDelay }} Woche(n) verschoben.
      </div>
      <br />
      <br />
      <label class="field-title plain">{{ "ORDER_DIALOG.WISH_DELIVERY_DATE" | translate }}</label>
      <div class="field">
        Jahr:
        <select class="form-control short" name="wishDeliveryDateYear" [(ngModel)]="wishDeliveryDateYear" (ngModelChange)="wishDeliveryDateYearChanged($event)">
          <option *ngFor="let y of wishDeliveryYears" value="{{ y }}">
            {{ y }}
          </option>
        </select>
        Woche:
        <select class="form-control short" name="wishDeliveryDateWeek" [(ngModel)]="wishDeliveryDateWeek">
          <option *ngFor="let w of wishDeliveryWeeks" value="{{ w }}">
            {{ w }}
          </option>
        </select>
      </div>
      <br />
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="acceptOrder()">
      {{ "ORDER_DIALOG.ACCEPT" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="cancel-dialog" [show]="isOpenCancelDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ORDER_DIALOG.ORDER_CANCEL' | translate }}"></app-content-header>
    <div>
      <span>{{ "ORDER_DIALOG.CANCEL_REQUEST" | translate: { orderNumber: item?.orderNumber } }}</span>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="cancelOrder()">
      {{ "ORDER_DIALOG.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="change-dialog" [show]="isOpenChangeDialog" minWidth="350px" maxWidth="800px" height="80%">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ORDER_DIALOG.CHANGE_TITLE' | translate }}"></app-content-header>
    <div>
      <label class="field-title plain">{{ "CONTACT_FORM.ORDER_NUMBER" | translate }}: {{ item?.orderNumber }}</label>
      <br />
      <div class="table">
        <div class="cell-50">
          <label class="field-title plain">{{ "ORDER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
          <div class="field">
            <textarea class="form-control" name="changeMessage" [(ngModel)]="approve.changeMessage" placeholder=""></textarea>
          </div>
        </div>
        <div class="cell-50">
          <div *ngFor="let position of approve.positions; let i = index" class="field-container">
            <label class="field-title plain"
              >{{ "ORDER_DIALOG.CHANGE_MESSAGE_FOR_ARTICLE" | translate }}
              {{ position?.articleNumber }}
            </label>
            <div class="field">{{ position.changeMessage }}</div>
          </div>
          <div class="no-articles" *ngIf="!approve.positions || approve.positions.length === 0">
            {{ "ORDER_DIALOG.NO_CHANGE_ARTICLES_SET" | translate }}
          </div>
        </div>
      </div>

      <div class="table" style="padding-top: 5px" *ngIf="approveOnChangeEnabled">
        <div class="cell-cb">
          <app-checkbox [checked]="approveOnChange" (onChanged)="approveAfterChangeChanged($event)"> </app-checkbox>
        </div>
        <div class="cell-cb-text">
          <span class="field-title plain small">{{ "ORDER_DIALOG.APPROVE_ON_CHANGE" | translate }}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button
      style="float: right"
      (click)="changeOrder()"
      [disabled]="(!approve.changeMessage || approve.changeMessage === '') && approve.positions.length === 0"
    >
      {{ "ORDER_DIALOG.CHANGE" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="change-message-dialog" [show]="isOpenChangePerPositionDialog" minWidth="350px" maxWidth="450px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ORDER_DIALOG.CHANGE_POSITION_TITLE' | translate }}"></app-content-header>
    <diV>
      <label class="field-title plain"><span [innerHtml]="'ORDER.ARTICLE_NUMBER' | translate"></span>: {{ itemPosition?.articleNumber }}</label>
      <br />
      <label class="field-title plain">{{ "ORDER_DIALOG.CHANGE_MESSAGE" | translate }}:</label>
      <div class="field">
        <input class="form-control" [(ngModel)]="approve.changeMessage" maxlength="80" />
      </div>
    </diV>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="setChangeToPosition()">
      {{ "ORDER_DIALOG.SET" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="preview-image-dialog" [show]="isOpenPreviewImageDialog" maxWidth="450px" [higherIndex]="true">
  <ng-template #modalContent>
    <img [src]="previewImage" width="100%" alt="" />
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeDialog()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="confirm-order-dialog" [show]="isOpenConfirmDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ORDER_DIALOG.CONFIRM_TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeConfirm()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="confirmOrder()">
      {{ "ORDER_DIALOG.CONFIRM" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="confirm-order-batch-archive-dialog" [show]="showConfirmOrderBatchArchiveDialog" minWidth="350px" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="Archivieren"></app-content-header>
    <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="closeBatchModal()" [disabled]="batchRunning || batchCompleted">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="confirmBatchModal()" [disabled]="batchRunning">
      {{ "ORDER_DIALOG.CONFIRM" | translate }}
    </button>
  </ng-template>
</app-modal>
