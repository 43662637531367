import { Component, OnInit, ContentChild, Input, TemplateRef, ElementRef, SimpleChanges, OnChanges, Output, EventEmitter, HostListener } from "@angular/core";

@Component({
  selector: "modal-content",
  template: "content-bla",
})
export class ModalContent {}

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnChanges {
  @ContentChild("modalContent") modalContent: TemplateRef<ElementRef>;
  @ContentChild("modalFooter") modalFooter: TemplateRef<ElementRef>;
  @Input() id: string;
  @Input() show: boolean;
  @Input("height") _height: string;
  @Input("maxWidth") _maxWidth: string;
  @Input("minWidth") _minWidth: string;
  @Input("maximize") _maximize: boolean = false;
  @Input() higherIndex: boolean = false;
  @Input() okText: string = "OK";
  @Output() modalClosed = new EventEmitter<boolean>();
  showModal = false;
  modalClass = "";
  maxWithCalculated = "";
  minWidthCalculated = "300px";
  minWidthSize = 300;
  minWidthPx = true;
  maxWidthSize = 0;
  maxWidthPx = true;
  calculatedHeightContainer = "inherit";
  calculatedHeightContent = "inherit";

  constructor() {}

  ngOnInit() {
    if (this._maxWidth) {
      if (this._maxWidth.endsWith("px")) {
        this.maxWidthSize = parseInt(this._maxWidth.replace("px", ""));
      } else if (this._maxWidth.endsWith("%")) {
        this.maxWidthSize = parseInt(this._maxWidth.replace("%", ""));
        this.maxWidthPx = false;
      }
    }
    if (this._minWidth) {
      if (this._minWidth.endsWith("px")) {
        this.minWidthSize = parseInt(this._minWidth.replace("px", ""));
      } else if (this._minWidth.endsWith("%")) {
        this.minWidthSize = parseInt(this._minWidth.replace("%", ""));
        this.minWidthPx = false;
      }
    }

    this.minWidthCalculated = this._minWidth ? this._minWidth : "300px";
    this._onResize(window.innerWidth);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeShowState(changes["show"].currentValue);
  }

  changeShowState(show: boolean) {
    const lastShowState = this.showModal;

    if (show) this.showModal = true;
    this.modalClass = "animate-" + (show ? "in" : "out");
    if (!show) {
      if (lastShowState == false) return;
      setTimeout(() => {
        this.showModal = false;
        if (this.modalClosed) this.modalClosed.emit(true);
      }, 300);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this._onResize(event.target.innerWidth);
  }
  _onResize(width) {
    if (this._height) {
      if (this._height.endsWith("px")) {
        this.calculatedHeightContainer = parseInt(this._height.replace("px", "")) - 50 + "px";
        this.calculatedHeightContent = parseInt(this._height.replace("px", "")) - 100 + "px";
      } else if (this._height.endsWith("%")) {
        const tmp = Math.ceil(window.innerHeight * (parseInt(this._height.replace("%", "")) / 100));
        this.calculatedHeightContainer = tmp - 50 + "px";
        this.calculatedHeightContent = tmp - 100 + "px";
      }
    }

    if (this._maximize) {
      let tmp = this.maxWidthSize;
      if (!this.maxWidthPx) tmp = (width / 100) * this.maxWidthSize;
      if (width < tmp) {
        this.maxWithCalculated = "100%";
        this.minWidthCalculated = "95%";
      } else {
        this.minWidthCalculated = this._minWidth ? this._minWidth : "300px";
        this.maxWithCalculated = this.maxWidthSize + (this.maxWidthPx ? "px" : "%");
      }
    } else {
      if (this.maxWidthSize != 0) {
        this.maxWithCalculated = this._maxWidth;

        let tmp = this.minWidthSize;
        if (!this.minWidthPx) tmp = (width / 100) * this.minWidthSize;
        // calc min width
        if (width - 50 < tmp) {
          // width is smaller than min width take min width
          this.minWidthCalculated = this._minWidth;
        } else {
          // width is bigger than min width
          let tmpMax = this.maxWidthSize;
          if (!this.maxWidthPx) tmpMax = (width / 100) * this.maxWidthSize;
          if (width > tmpMax) {
            // width is bigger than max
            this.minWidthCalculated = this._maxWidth;
          } else {
            this.minWidthCalculated = width - 50 + "px";
          }
        }
      } else {
        // no max width set
        this.minWidthCalculated = this._minWidth ? this._minWidth : "300px";
      }
    }
  }
}
