<app-content-header mat-dialog-title contentTitle="Admin-Edit"></app-content-header>
<div mat-dialog-content>
  <form [formGroup]="form">
    <span>New status:</span>
    <select class="form-control" formControlName="status">
      <option value="0">Cancelled</option>
      <option value="1">Created</option>
      <option value="2">Processing</option>
      <option value="3">Confirmed</option>
    </select>
    <div *ngIf="data.positionIndex < 0" class="field-checkbox">
      <input id="forPositions" class="form-control" type="checkbox" formControlName="forPositions" />
      <label for="forPositions" class="cell-text-checkbox">Set status to positions</label>
    </div>
  </form>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-button (click)="setNewStatus()">Set data</button>
</div>
