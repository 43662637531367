import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-selected-archives-dialog",
  templateUrl: "./selected-archives-dialog.component.html",
  styleUrls: ["./selected-archives-dialog.component.scss"],
})
export class SelectedArchivesDialogComponent implements OnInit {
  public confirmText: string;
  constructor(
    public dialogRef: MatDialogRef<SelectedArchivesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit(): void {
    this.confirmText = "Wollen Sie die ausgewählten " + this.data.itemName + " archivieren? \n Dieser Schritt ist nicht umkehrbar.";
  }
  public closeBatchModal() {
    this.dialogRef.close();
  }
  public confirmBatchModal() {
    this.dialogRef.close(true);
  }
}
