<app-content-header mat-dialog-title contentTitle="User wechseln"></app-content-header>
<div mat-dialog-content>
  <form [formGroup]="form">
    <input type="text" class="cell-header-search" placeholder="Suche" formControlName="userSearch" autofocus />
    <div class="dialog-row" *ngFor="let user of usersFiltered">
      <div class="cell-75-fixed">{{ user.merchantNumber }} {{ user.email }}</div>
      <div class="cell-25-fixed">
        <button class="dialog-add-button" (click)="switchUser(user)">wechseln</button>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="button-footer">
  <button mat-button mat-dialog-close>Abbruch</button>
</div>
