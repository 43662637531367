<div class="dialog-modal-content">
  <app-content-header contentTitle="{{ 'ORDER_DIALOG.CONFIRM_TITLE' | translate }}"></app-content-header>
  <p innerHtml="{{ confirmText }}" style="word-wrap: break-word"></p>
</div>
<div class="dialog-modal-footer">
  <button style="float: left" (click)="closeConfirm()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button style="float: right" (click)="confirmOrder()">
    {{ "ORDER_DIALOG.CONFIRM" | translate }}
  </button>
</div>
