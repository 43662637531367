import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Callback, Contact, RequestAgain } from "src/app/models/contact";
import { environment } from "src/environments/environment";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class ApiContactService {
  private dataUrl = environment.BASE_URL + "Contact";

  constructor(private http: HttpService) {}

  public sendContact(body: Contact): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/ContactInquiry", body);
  }

  public sendCallback(body: Callback): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/CallbackInquiry", body);
  }

  public sendOfferRequestAgain(body: RequestAgain): Observable<any> {
    return this.http.postNoContentType(this.dataUrl + "/OfferRequestAgain", body);
  }
}
