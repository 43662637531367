<div class="tabs">
  <div class="tab" [ngClass]="currentTab === 'smtp' ? 'active' : ''" (click)="loadSmtp()">
    {{ "ADMIN.MAIL.SMTP" | translate }}
  </div>
  <div class="tab" [ngClass]="currentTab === 'sendto' ? 'active' : ''" (click)="loadSendTo()">
    {{ "ADMIN.MAIL.SENDTO" | translate }}
  </div>
  <div class="tab" [ngClass]="currentTab === lng ? 'active' : ''" *ngFor="let lng of availableLanguages" (click)="loadLng(lng)">
    {{ lng }}
  </div>
  <div class="tab" (click)="sendTest()">Test mail</div>
</div>

<div *ngIf="currentTab === 'smtp'">
  <form>
    <label class="field-title">{{ "ADMIN.MAIL.HOST" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="host" [(ngModel)]="host.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.PORT" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="port" [(ngModel)]="port.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.USER" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="user" [(ngModel)]="user.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.PASSWORD" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="password" [(ngModel)]="password.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.FROM" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="from" [(ngModel)]="from.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SSL" | translate }}:</label>
    <div class="field">
      <input type="checkbox" class="form-control" name="ssl" [(ngModel)]="ssl.boolValue" placeholder="" />
    </div>
    <br />
    <br />
    <button (click)="saveSmtp()">
      {{ "ADMIN.MAIL.SAVE" | translate }}
    </button>
  </form>
</div>

<div *ngIf="currentTab === 'sendto'">
  <form>
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_CONTACT_ORDER" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="order" [(ngModel)]="stContactOrder.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_CONTACT_OFFER" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="offer" [(ngModel)]="stContactOffer.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_CONTACT_RECLAMATION" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="reclamation" [(ngModel)]="stContactReclamation.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_CONTACT_FRAME" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="frame" [(ngModel)]="stContactFrame.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_CONTACT_SHIPMENT_NOTE" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="shipmentNote" [(ngModel)]="stContactShipmentNote.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_INVALID_IMPORT" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="invalidImport" [(ngModel)]="stInvalidImport.value" placeholder="" />
    </div>
    <br />
    <label class="field-title">{{ "ADMIN.MAIL.SENDTO_IMPORTER_ERROR" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="importerError" [(ngModel)]="stImporterError.value" placeholder="" />
    </div>
    <br />
    <br />
    <button (click)="saveSendTo()">
      {{ "ADMIN.MAIL.SAVE" | translate }}
    </button>
  </form>
</div>

<div class="box" *ngIf="currentTab !== 'smtp' && currentTab !== 'sendto' && currentTab !== 'resend'">
  <div class="list">
    <div class="setting" *ngFor="let setting of lngData" (click)="loadSetting(setting)">
      {{ setting.dspKey }}
    </div>
    <div *ngIf="lngData.length === 0">No data</div>
  </div>
  <div class="code">
    <button (click)="newSetting()">
      {{ "ADMIN.MAIL.NEW_SETTING" | translate }}</button
    ><br />
    <br />
    <form>
      <label class="field-title">{{ "ADMIN.MAIL.KEY" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="key" [(ngModel)]="key" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.MAIL.SUBJECT" | translate }}:</label>
      <div class="field">
        <input class="form-control" name="subject" [(ngModel)]="subject" placeholder="" />
      </div>
      <br />
      <label class="field-title">{{ "ADMIN.MAIL.BODY" | translate }}:</label>
      <div class="field">
        <textarea class="form-control" name="body" [(ngModel)]="body" placeholder=""></textarea>
      </div>
      <br />
      <button (click)="saveMailSetting()" [disabled]="subject === '' || body === '' || key === ''">
        {{ "ADMIN.MAIL.SAVE" | translate }}
      </button>
    </form>
  </div>
</div>

<app-modal id="confirmMailModal" [show]="confirmMailModalShow" maxWidth="400px">
  <ng-template #modalContent>
    <app-content-header contentTitle="{{ 'ADMIN.MAIL.CONFIRM_TITLE' | translate }}"></app-content-header>
    <p innerHtml="{{ confirmText }}"></p>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="doCloseModal()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="doConfirmModal()">
      {{ "COMMON.SAVE" | translate }}
    </button>
  </ng-template>
</app-modal>

<app-modal id="testMailModal" [show]="testMailModalShow" maxWidth="400px">
  <ng-template #modalContent>
    <label class="field-title">{{ "ADMIN.MAIL.TEST_MAIL_RECIPIENT" | translate }}:</label>
    <div class="field">
      <input class="form-control" name="testMail" [(ngModel)]="stTestMail.value" placeholder="" />
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button style="float: left" (click)="doCloseModal()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button style="float: right" (click)="doSendTest()">Send</button>
  </ng-template>
</app-modal>
