<div class="box">
  <div class="menu">
    <div class="entry" [routerLink]="['/']">Home</div>
    <div class="entry" [routerLink]="['./mail']" [ngClass]="active == 'mail' ? 'active' : ''">
      {{ "ADMIN.MENU.MAIL" | translate }}
    </div>
    <div class="entry" [routerLink]="['./push']" [ngClass]="active == 'push' ? 'active' : ''">
      {{ "ADMIN.MENU.PUSH" | translate }}
    </div>
    <div class="entry" [routerLink]="['./translations']" [ngClass]="active == 'translations' ? 'active' : ''">
      {{ "ADMIN.MENU.TRANSLATIONS" | translate }}
    </div>
    <div class="entry" [routerLink]="['./system']" [ngClass]="active == 'system' ? 'active' : ''">
      {{ "ADMIN.MENU.SYSTEM" | translate }}
    </div>
    <div class="entry" [routerLink]="['./holiday']" [ngClass]="active == 'holiday' ? 'active' : ''">
      {{ "ADMIN.MENU.HOLIDAY" | translate }}
    </div>
    <div class="entry" [routerLink]="['./manage-access']" [ngClass]="active == 'manage-access' ? 'active' : ''">
      {{ "ADMIN.MENU.MANAGE-ACCESS" | translate }}
    </div>
    <br />
    <div class="entry" [routerLink]="['./users']" [ngClass]="active == 'users' ? 'active' : ''">Benutzerverwaltung</div>
    <div class="entry" [routerLink]="['./customers']" [ngClass]="active == 'customers' ? 'active' : ''">Händlerverwaltung</div>
    <br />
    <div class="entry" [routerLink]="['./eventlog']" [ngClass]="active == 'eventlog' ? 'active' : ''">Event log</div>
    <br />
    <div class="entry" (click)="switchUser()">Switch user</div>
    <br />
    <div class="entry" (click)="exportSettings()">Export settings</div>
    <div class="warning">(Handle with care!)</div>
    <br />
    DataManager last run:<br />{{ dmLastRun }}<br />
    <div *ngIf="onlineUsers">
      <br />
      Estimated online users: {{ onlineUsers.length }}<br />
      <div *ngFor="let ou of onlineUsers; let i = index">{{ i + 1 }}) {{ ou.dsp }}min</div>
    </div>
  </div>
  <div class="content">
    <!-- <ng-template [ngTemplateOutlet]="frameContent"></ng-template> -->
    <!-- <ion-router-outlet [animated]="false"></ion-router-outlet> -->
    <router-outlet></router-outlet>
  </div>
</div>
