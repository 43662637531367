import { Component } from "@angular/core";
import { UiShareService } from "src/app/services/ui-share.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-custom-content-footer",
  templateUrl: "./custom-content-footer.component.html",
  styleUrls: ["./custom-content-footer.component.scss"],
})
export class CustomContentFooterComponent {
  public multiMerchantEnabled: boolean = environment.MULTIMERCHANT;

  constructor(public uiShareService: UiShareService) {}
}
